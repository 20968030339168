import { getSeasonIndex, Season } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import { useCallback, useEffect, useState } from 'react';
import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { defaultSort } from '@core/utils/array';
import { useSeasonalActivityBaseFilters } from '@core/selectors/useSeasonalActivityFilters';

type Response = components["schemas"]["SeasonalKeywordGroup"][];

export type iSeasonalFood = components['schemas']['SeasonalKeyword'] & {
    name: string;
    index: number;
}

export interface iSeasonalFoodsBySeason {
    [Season.Winter]: iSeasonalFood[];
    [Season.Spring]: iSeasonalFood[];
    [Season.Summer]: iSeasonalFood[];
    [Season.Fall]: iSeasonalFood[];
}

const defaultSeasonalFoodsBySeason: iSeasonalFoodsBySeason = {
    [Season.Winter]: [],
    [Season.Spring]: [],
    [Season.Summer]: [],
    [Season.Fall]: [],
};

export function useSeasonalByFoods() {
    const {
        options: { keywords },
    } = useFiltersContext();

    const [data, setData] = useState<iSeasonalFoodsBySeason>(defaultSeasonalFoodsBySeason);
    const payload = useSeasonalActivityBaseFilters();

    const result = useFetch<Response>({
        url: '/api/Seasonal/GetKeywords',
        method: 'post',
        payload,
    });

    const mapItem = useCallback(
        (item: components['schemas']['SeasonalKeyword']): iSeasonalFood => {
            const option = keywords.find(o => o.id === item.id);
            const index = item.index;
            const indexValue = (index !== null && index !== undefined) ? index : Infinity;

            return {
                ...item,
                name: option?.title || '',
                index: indexValue,
            };
        },
        [keywords]
    );

    useEffect(() => {
        if (!result.isLoading && !!result.data) {
            result.data.forEach(group => {
                (group?.seasonalKeywords || []).sort(
                    (a, b) => defaultSort(a.count, b.count)
                );
            });

            const newData: iSeasonalFoodsBySeason = { ...defaultSeasonalFoodsBySeason };

            [Season.Winter, Season.Spring, Season.Summer, Season.Fall].forEach(season => {
                const seasonData = (result.data || []).find(
                    group => group.season === getSeasonIndex(season)
                );
                newData[season] = (seasonData?.seasonalKeywords || []).map(mapItem);
            });
            setData(newData)
        } else {
            setData(defaultSeasonalFoodsBySeason);
        }
    }, [result.isLoading, result.data, mapItem]);

    return {
        isLoading: result.isLoading,
        data,
    }
}