import React from 'react';
import cn from 'classnames';
import Stars from '@components/Stars';

const OPTIONS = [{
    name: 'Top performer',
    description: '>90<sup>th</sup> percentile',
    starRating: 5,
}, {
    name: 'Above Average',
    description: '70-90<sup>th</sup> percentile',
    starRating: 4,
}, {
    name: 'Average',
    description: '30-70<sup>th</sup> percentile',
    starRating: 3,
}, {
    name: 'Below Average',
    description: '10-30<sup>th</sup> percentile',
    starRating: 2,
}, {
    name: 'Weak Performer',
    description: '<10<sup>th</sup> percentile',
    starRating: 1,
}];

export default function LegendScoreTooltipContent() {
    return (
        <div className={cn('legend-score-tooltip-content')}>
            {OPTIONS.map((score, index) => {
                return (
                    <div key={index} className="legend-score-tooltip-content__section">
                        <Stars
                            count={score.starRating}
                            style={{
                                justifyContent: 'center',
                                marginBlock: 8,
                            }}
                        />
                        <div className="legend-score-tooltip-content__name">{score.name}</div>
                        <div
                            className="legend-score-tooltip-content__description"
                            dangerouslySetInnerHTML={{ __html: score.description }}
                        />
                    </div>
                )
            })}
        </div>
    );
};