import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useFiltersContext } from '@core/FiltersContext';
import { FiltersVariant } from '@models/Filter';
import { ID } from '@models';

const SearchComponent: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { updateSpecificValue } = useFiltersContext();
    const { chainId } = qs.parse(location.search);

    if (chainId) {
        updateSpecificValue(FiltersVariant.Homepage, {
            chainId: +chainId as ID,
        });
    }

    navigate(FiltersVariant.Homepage);

    return null;
};

const Search: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <SearchComponent/>;
}

export default Search;