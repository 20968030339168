import { Season, Timeframe, UnitType } from '@models/index';
import dayjs, { Dayjs } from 'dayjs';
import { getSeason } from '@core/utils/date';
import { components } from '@api/api';
import { deepCopy } from '@core/utils/object';

export interface iCharValue {
    id: string;
    title?: string;
    value: number;
}

export interface iTimeframesBarChartGroup {
    label: string;
    totalValue?: number;
    values: iCharValue[];
}

export interface iTimeframesBarChartData {
    monthView: iTimeframesBarChartGroup[];
    seasonView: iTimeframesBarChartGroup[];
    yearView: iTimeframesBarChartGroup[];
    seasonMonthsView: {
        [Season.Winter]: iTimeframesBarChartGroup[];
        [Season.Spring]: iTimeframesBarChartGroup[];
        [Season.Summer]: iTimeframesBarChartGroup[];
        [Season.Fall]: iTimeframesBarChartGroup[];
    };
}

type ApiModel = ({
    [key: string]: (number | null) | undefined;
});
type ApiModel2 = components['schemas']['ChainProfileIndexItem'][];

interface iData {
    apiModel1?: ApiModel;
    apiModel2?: ApiModel2;
    unitType?: UnitType;
}

interface iTimeframeObject {
    count: number;
    month: number;
    season: string;
    year: number;
    obj: Dayjs;
}

export default class TimeframesBarChartData implements iTimeframesBarChartData{
    static defaultData: iTimeframesBarChartData = {
        monthView: [],
        seasonView: [],
        yearView: [],
        seasonMonthsView: {
           [Season.Winter]: [],
           [Season.Spring]: [],
           [Season.Summer]: [],
           [Season.Fall]: [],
        },
    };

    monthView = TimeframesBarChartData.defaultData.monthView;
    seasonView = TimeframesBarChartData.defaultData.seasonView;
    yearView = TimeframesBarChartData.defaultData.yearView;
    seasonMonthsView = TimeframesBarChartData.defaultData.seasonMonthsView;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel1) {
                this.mapFromApiModel1(data.apiModel1, data.unitType ?? UnitType.Count);
            }
            if (data.apiModel2) {
                this.mapFromApiModel2(data.apiModel2, data.unitType ?? UnitType.Count);
            }
        }
    }

    private setValue(model: iTimeframesBarChartData) {
        Object.assign(this, model);
    }

    static getAllTimeFrames() {
        const maxYear = Number(dayjs(new Date()).format('YYYY'));
        const minYear = 2014;
        const yearsCount = maxYear - minYear + 1;

        const allDates: iTimeframeObject[] = Array(yearsCount).fill(null).flatMap((_, index) => {
            const year = minYear + index;

            return Array(12).fill(null).map((_, monthIndex) => {
                const obj = dayjs(`${year}/${monthIndex + 1}/2`);

                return {
                    count: 0,
                    month: obj.month(),
                    season: `${getSeason(obj.month())} ${obj.month() === 11 ? obj.year() + 1 : obj.year()}`,
                    year: obj.year(),
                    obj,
                };
            });
        });

        return {
            maxYear,
            minYear,
            yearsCount,
            allDates,
        };
    }

    private mapFromApiModel (unitType: UnitType, mapValues: (allData: iTimeframeObject[]) => void) {
        const {
            maxYear,
            minYear,
            yearsCount,
            allDates,
        } = TimeframesBarChartData.getAllTimeFrames();

        mapValues(allDates);

        const monthView: iTimeframesBarChartGroup[] = [];
        const seasonView: iTimeframesBarChartGroup[] = [];
        const yearView: iTimeframesBarChartGroup[] = [];
        const seasonMonthsView = deepCopy(TimeframesBarChartData.defaultData.seasonMonthsView);

        Array(yearsCount).fill(null).forEach((_, index) => {
            const year = minYear + index;
            const months = allDates.filter(date => date.year === year);
            const seasonMonths = allDates.filter(date => date.season.includes(`${year}`));

            const yearValue = months.reduce((acc, i) => acc + i.count, 0);
            const seasonsYearValue = seasonMonths.reduce((acc, i) => acc + i.count, 0);

            monthView.push({
                label: `${year}`,
                totalValue: yearValue,
                values: months.map(item => ({
                    id: item.obj.format('MMM YYYY'),
                    title: item.obj.format('MMMM YYYY'),
                    value: unitType === UnitType.Count ? item.count : ((item.count / yearValue) * 100 || 0),
                })).reverse(),
            });

            seasonView.push({
                label: `${year}`,
                totalValue: seasonsYearValue,
                values: [
                    Season.Winter,
                    Season.Spring,
                    Season.Summer,
                    Season.Fall,
                ].map(season => {
                    const months = allDates.filter(i => i.season === `${season} ${year}`);
                    const count = months.reduce((acc, i) => acc + i.count, 0);
                    const additionalMonthsBefore = [];

                    if (season === Season.Winter && year === minYear) {
                        additionalMonthsBefore.push({
                            id: `Dec ${year - 1}`,
                            title: 'Dec',
                            value: 0,
                        })
                    }

                    seasonMonthsView[season].push({
                        label: `${year}`,
                        totalValue: count,
                        values: [
                            ...additionalMonthsBefore,
                            ...months.map(i => ({
                                id: i.obj.format('MMM YYYY'),
                                title: i.obj.format('MMM'),
                                value: unitType === UnitType.Count ? i.count : ((i.count / count) * 100 || 0),
                            })),
                        ].reverse(),
                    });

                    return {
                        id: `${season} ${year}`,
                        title: `${season} ${year}`,
                        value: unitType === UnitType.Count ? count : ((count / seasonsYearValue) * 100 || 0),
                    };
                }).reverse(),
            });

            yearView.push({
                label: `${year}${year === maxYear ? ' YTD' : ''}`,
                values: [{
                    id: `${year}`,
                    title: `${year}${year === maxYear ? ' YTD' : ''}`,
                    value: months.reduce((acc, i) => acc + i.count, 0),
                }],
            });
        });

        this.setValue({
            monthView: monthView.reverse(),
            seasonView: seasonView.reverse(),
            yearView: yearView.reverse(),
            seasonMonthsView: {
                [Season.Winter]: seasonMonthsView[Season.Winter].reverse(),
                [Season.Spring]: seasonMonthsView[Season.Spring].reverse(),
                [Season.Summer]: seasonMonthsView[Season.Summer].reverse(),
                [Season.Fall]: seasonMonthsView[Season.Fall].reverse(),
            }
        });
    }

    static getBarData (barId: string, chartInterval: Timeframe) {
        switch (chartInterval) {
            case 'month':
                return {
                    month: barId?.split(' ')[0],
                    season: undefined,
                    year: Number(barId?.split(' ')[1]),
                };
            case 'season':
                return {
                    month: undefined,
                    season: barId?.split(' ')[0],
                    year: Number(barId?.split(' ')[1]),
                };
            case 'year':
                return {
                    month: undefined,
                    season: undefined,
                    year: Number(barId),
                };
            default:
                return {
                    month: undefined,
                    season: undefined,
                    year: undefined,
                }
        }
    }

    private mapFromApiModel1 (apiData: ApiModel, unitType: UnitType) {
        const mapValues = (allDates: iTimeframeObject[]) => {
            Object.keys(apiData).forEach((date) => {
                const obj = dayjs(date).add(1, 'day');
                const slot = allDates.find(date => date.month === obj.month() && date.year === obj.year());
                if (slot) {
                    slot.count = apiData[date] || 0;
                }
            });
        };

        this.mapFromApiModel(unitType, mapValues);
    }

    private mapFromApiModel2 (apiData: ApiModel2, unitType: UnitType) {
        const mapValues = (allDates: iTimeframeObject[]) => {
            apiData.forEach((item) => {
                const { year, month, value } = item;
                const obj = dayjs(`${year}/${month}/2`);
                const slot = allDates.find(date => date.month === obj.month() && date.year === obj.year());
                if (slot) {
                    slot.count = value || 0;
                }
            });
        };

        this.mapFromApiModel(unitType, mapValues);
    }
}