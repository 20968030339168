import { useState } from 'react';
import { components, paths } from '@api/api';
import { api } from '@hooks/useFetch';
import { downloadReport } from '@core/utils/file';

export function useXlsReportSearch () {
    const [isLoading, setIsLoading] = useState(false);

    const load = async (requestData: components['schemas']['SearchFilterModel']) => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/ExcelSearchExport';
        await downloadReport(api.request({
            url,
            method: 'post',
            data: requestData,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    };
}