import React, { FC, useMemo, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import FiltersPopup from '@vms/FiltersPopup';
import ActionsBar from '@vms/ActionsBar';
import FiltersButton from '@vms/FiltersButton';
import Wrapper from '@components/Wrapper';
import BreadCrumbs from '@components/BreadCrumbs';
import { Route } from '@core/routes/Route';
import Switch from '@components/Switch';
import { Season, Timeframe, UnitType } from '@models';
import { useSeasonalBarChartData } from '@api/useSeasonalBarChartData';
import AnimatedNumber from '@components/AnimatedNumber';
import HeaderBar from '@components/HeaderBar';
import TimeframesBarChart from '@components/TimeframesBarChart';
import TimeframesBarChartData from '@models/TimeframesBarChartData';
import Loader from '@components/Loader';
import { getFormattedThousand } from '@core/utils/number';
import { FiltersVariant } from '@models/Filter';
import { useNavigate } from 'react-router-dom';
import RecentlyReleasedItems from '@pages/SeasonalActivity/RecentlyReleasedItems';
import { useFilterDateRangeString } from '@hooks/useFilterDateRangeString';
import CircleChart from '@components/CircleChart';
import LaunchesByCategory from '@pages/SeasonalActivity/LaunchesByCategory';
import { useDefaultSeason } from '@hooks/useDefaultSeason';
import MostActiveChains from '@pages/SeasonalActivity/MostActiveChains';
import LaunchesBySegments from '@pages/SeasonalActivity/LaunchesBySegments';
import FoodsFlavors from '@pages/SeasonalActivity/FoodsFlavors';
import ExportButton from '@vms/ExportButton';
import ExportByCategoryPopup from '@vms/ExportByCategoryPopup';
import { useSeasonalReport } from '@api/useSeasonalReport';

const SeasonalActivityContent: FC = () => {
    const defaultSeason = useDefaultSeason(true);
    const navigate = useNavigate();
    const {
        resetSpecificValue,
        value: {
            chainId,
            date: filterDate,
            segments,
            categories,
        },
    } = useFiltersContext();
    const report = useSeasonalReport();
    const [isExportPopupOpened, setIsExportPopupOpened] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<UnitType>(UnitType.Count);
    const [selectedSeason, setSelectedSeason] = useState<Season>(defaultSeason);

    const { isLoading, data: rawData } = useSeasonalBarChartData();

    const data = useMemo(
        () => {
            if (!rawData?.itemsData?.items) return new TimeframesBarChartData();

            return new TimeframesBarChartData({
                apiModel1: rawData.itemsData.releasedItems || undefined,
                unitType: selectedUnit,
            });
        },
        [selectedUnit, rawData]
    );

    const seasonData = useMemo(
        () => {
            if (!selectedSeason) return new TimeframesBarChartData();

            return {
                ...new TimeframesBarChartData(),
                monthView: data.seasonMonthsView[selectedSeason],
            };
        },
        [data, selectedSeason]
    );

    const seasonTotalCount = useMemo(
        () => seasonData.monthView.reduce(
            (acc, i) => acc + (i.totalValue || 0),
            0
        ),
        [seasonData]
    );

    const seasonPctValue = useMemo(
        (): number => {
            if (rawData?.itemsData?.count) {
                return Math.round(
                    (seasonTotalCount / rawData.itemsData.count) * 100
                );
            }
            return 0;
        },
        [rawData, seasonTotalCount]
    );

    const seasonAccentRender = useMemo(
        () => (
            <div className="color-dimyellow" style={{ display: 'inline' }}>{selectedSeason}</div>
        ),
        [selectedSeason],
    );

    const filterDateRangeString = useFilterDateRangeString();

    return (
        <>
            <div className="SeasonalActivity">
                <Wrapper>
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            minHeight: 70
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BreadCrumbs items={[
                                    { link: Route.Home, title: 'Home', },
                                    { link: '', title: 'Seasonal Activity', }
                                ]}/>
                                <ExportButton
                                    isLoading={report.isLoading}
                                    onClick={() => setIsExportPopupOpened(true)}
                                />
                                {isExportPopupOpened && (
                                    <ExportByCategoryPopup
                                        close={() => setIsExportPopupOpened(false)}
                                        onConfirm={report.load}
                                        description="Select a category for your seasonal report."
                                    />
                                )}
                            </div>
                            <Switch
                                leftLabel={{
                                    title: "Counts",
                                    id: UnitType.Count,
                                    icon: '#',
                                    color: 'blue',
                                }}
                                rightLabel={{
                                    title: "Percent",
                                    id: UnitType.Pct,
                                    icon: '%',
                                    color: 'green',
                                }}
                                value={selectedUnit}
                                onChange={(value) => setSelectedUnit(value as UnitType)}
                                style={{ marginLeft: 20 }}
                            />
                        </div>
                        <HeaderBar className="SeasonalActivity__header-bar">
                            <div className="Home__header-section">
                                {!!rawData?.itemsData?.count && (
                                    <div
                                        style={{
                                            fontSize: 30,
                                            paddingRight: 16,
                                        }}
                                        className="font-caption color-dimyellow"
                                    >
                                        <AnimatedNumber value={rawData.itemsData.count}/>
                                    </div>
                                )}
                                <div>Launched menu items & LTO’s</div>
                            </div>
                            <div>Launched by Season</div>
                        </HeaderBar>
                        <div className="card">
                            {isLoading ? (
                                <div style={{ padding: 20 }}>
                                    <div style={{ position: 'relative', height: 380 }}>
                                        <Loader/>
                                    </div>
                                </div>
                            ) : (
                                <TimeframesBarChart
                                    key={1}
                                    data={data}
                                    currentView={Timeframe.Season}
                                    selectCurrentSeasonFirst
                                    legendMessage={`click on a bar to see that season's launches`}
                                    valueFormatter={
                                        selectedUnit === UnitType.Count
                                            ? getFormattedThousand
                                            : (value) => `${Math.round(value)}%`
                                    }
                                    onBarClick={(barId) => {
                                        resetSpecificValue(FiltersVariant.Homepage, {
                                            chainId: chainId,
                                            date: filterDate,
                                            categories,
                                            segments,
                                        });
                                        navigate(`${Route.Home}?timeframe=${Timeframe.Season}&barId=${barId}`);
                                    }}
                                    showGroupValue
                                    onSeasonSelect={setSelectedSeason}
                                />
                            )}
                        </div>
                        <HeaderBar className="SeasonalActivity__header-bar is-sticky">
                            <div className="Home__header-section">
                                {!!rawData?.itemsData?.count && (
                                    <div
                                        style={{
                                            fontSize: 30,
                                            paddingRight: 16,
                                        }}
                                        className="font-caption color-dimyellow"
                                    >
                                        <AnimatedNumber value={seasonTotalCount}/>
                                    </div>
                                )}
                                <div>Launched items & LTO’s in {seasonAccentRender}</div>
                            </div>
                            <div
                                style={{ opacity: 0.5, textTransform: 'uppercase', }}
                                className="font-medium"
                            >
                                {filterDateRangeString}
                            </div>
                        </HeaderBar>
                        <div style={{ display: 'flex' }}>
                            <div className="card" style={{ marginRight: 20, flex: '0 0 380px' }}>
                                <div className="card-header">
                                    {seasonAccentRender}&nbsp;Share of Total Launches
                                </div>
                                {isLoading ? (
                                    <div style={{ padding: 20 }}>
                                        <div style={{ position: 'relative', height: 300 }}>
                                            <Loader/>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="card-content"
                                        style={{
                                            height: 340,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CircleChart
                                            value={Math.round(seasonPctValue)}
                                            realValue={seasonPctValue}
                                        />
                                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                                            of all launches are {selectedSeason}
                                            <br/>
                                            items & LTO's
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="card" style={{ flex: '0 1 100%' }}>
                                <div className="card-header">
                                    {seasonAccentRender}&nbsp;Launches: Time Trend
                                </div>
                                {isLoading ? (
                                    <div style={{ padding: 20 }}>
                                        <div style={{ position: 'relative', height: 300 }}>
                                            <Loader/>
                                        </div>
                                    </div>
                                ) : (
                                    <TimeframesBarChart
                                        key={selectedSeason}
                                        data={seasonData}
                                        currentView={Timeframe.Month}
                                        variant={Timeframe.Season}
                                        valueFormatter={
                                            selectedUnit === UnitType.Count
                                                ? getFormattedThousand
                                                : (value) => `${Math.round(value)}%`
                                        }
                                        onBarClick={(barId) => {
                                            resetSpecificValue(FiltersVariant.Homepage, {
                                                chainId: chainId,
                                                date: filterDate,
                                                categories,
                                                segments,
                                            });
                                            navigate(`${Route.Home}?timeframe=${Timeframe.Month}&barId=${barId}`);
                                        }}
                                        showGroupValue
                                        renderLabels
                                        hideLegend
                                    />
                                )}
                            </div>
                        </div>
                        <LaunchesByCategory
                            selectedSeason={selectedSeason}
                            selectedUnit={selectedUnit}
                            renderSeasonName={seasonAccentRender}
                            isGlobalLoading={isLoading}
                            totalLaunchesCount={seasonTotalCount}
                        />
                        <div style={{ display: 'flex' }}>
                            <LaunchesBySegments
                                selectedSeason={selectedSeason}
                                renderSeasonName={seasonAccentRender}
                                selectedUnit={selectedUnit}
                                totalLaunchesCount={seasonTotalCount}
                                isGlobalLoading={isLoading}
                            />
                            <MostActiveChains
                                selectedSeason={selectedSeason}
                                renderSeasonName={seasonAccentRender}
                                isGlobalLoading={isLoading}
                            />
                        </div>
                        <FoodsFlavors
                            selectedSeason={selectedSeason}
                            renderSeasonName={seasonAccentRender}
                            isGlobalLoading={isLoading}
                        />
                    </>
                </Wrapper>
                <Wrapper style={{ overflow: 'hidden' }}>
                    <RecentlyReleasedItems
                        selectedSeason={selectedSeason}
                        renderSeasonName={seasonAccentRender}
                        isGlobalLoading={isLoading}
                    />
                </Wrapper>
            </div>
            <FiltersPopup/>
            <ActionsBar>
                <FiltersButton id="actions-bar-filters-popup-open"/>
            </ActionsBar>
        </>
    );
};
const SeasonalActivity: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <SeasonalActivityContent/>;
};

export default SeasonalActivity;