import useFetch, { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import TimeframesBarChartData, { iTimeframesBarChartData } from '@models/TimeframesBarChartData';
import { getMonthIndexFromString, getSeasonIndex, iKeyValue, Season } from '@models';

const url: keyof paths = '/api/Search/GetItemsExtended';
type Response = components['schemas']['SearchExtendedResultModel'];
type Payload = components['schemas']['SearchExtendedFilterModel'];

function getKeyValueStructure (data: Response['mostPopular'] | Response['fastestGrowing']): iKeyValue[] {
    if (data === undefined || data === null) return [];

    const result = Object.keys(data).map((key) => ({
        key: key as string,
        value: Number(data[key] || 0),
    }));

    result.sort((a, b) => b.value - a.value);

    return result;
}

export function useHomeChartData () {
    const [data, setData] = useState<iTimeframesBarChartData | null>(null);
    const [totalMostPopularData, setTotalMostPopularData] = useState<iKeyValue[]>([]);
    const [mostPopularData, setMostPopularData] = useState<iKeyValue[]>([]);
    const [fastestGrowingData, setFastestGrowingData] = useState<iKeyValue[]>([]);
    const [totalRecords, setTotalRecords] = useState<number | undefined>();
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [isInitialLoadPerformed, setIsInitialLoadPerformed] = useState(false);

    const {
        value: { concepts },
        defaultFiltersPayload,
    } = useFiltersContext();

    const payload = useMemo((): Payload => {
        return {
            searchFilter: {
                filter: {
                    ...defaultFiltersPayload,
                    conceptTypeIds: [1, ...concepts] as number[],
                    respondentTypes: [1],
                },
                page: {
                    perPage: 0,
                    currentPage: 1,
                },
            }
        };
    }, [defaultFiltersPayload, concepts]);

    const { isLoading, data: resultData } = useFetch<Response>({
        url,
        method: 'post',
        payload,
    });

    useEffect(() => {
        setData(null);
        if (!isLoading) {
            setIsInitialLoadPerformed(true);
        }
    }, [isLoading])

    useEffect(() => {
        setData(new TimeframesBarChartData({
            apiModel1: resultData?.itemsData?.releasedItems || undefined,
        }));
        setTotalMostPopularData(getKeyValueStructure(resultData?.mostPopular));
        setTotalRecords(resultData?.itemsData?.count);
    }, [resultData]);

    const fetchIntervalDetails = useCallback(async (year?: number, season?: string, month?: string) => {
        setIsDetailsLoading(true);
        setMostPopularData([]);
        setFastestGrowingData([]);
        const requestPayload: Payload = {
            ...payload,
            selectedYear: year,
            selectedSeason: season ? getSeasonIndex(season as Season) as Payload['selectedSeason'] : null,
            selectedMonth: month ? getMonthIndexFromString(month) : null,
        };
        const result = await api.request<Response>({
            url,
            method: 'post',
            data: requestPayload,
        });

        setMostPopularData(getKeyValueStructure(result.data.mostPopular));
        setFastestGrowingData(getKeyValueStructure(result.data.fastestGrowing));
        setIsDetailsLoading(false);
    }, [payload]);

    return {
        isLoading,
        isDetailsLoading,
        data,
        totalRecords,
        totalMostPopularData,
        mostPopularData,
        fastestGrowingData,
        fetchIntervalDetails,
        isInitialLoadPerformed,
    };
}