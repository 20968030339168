import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { ID } from '@models';
import { useEffect, useMemo } from 'react';

type ResponseType = components['schemas']['BenchmarkCompareModel'];
type RequestPayloadType = components['schemas']['BenchmarkFilterModel'];

export interface iBenchmarkRequestCompareTo {
    typeId: ID;
    id: ID;
}

export interface iBenchmarkRequestData {
    compareTo: iBenchmarkRequestCompareTo;
    metrics: Array<number | undefined> | null;
    chainId: ID | null;
    among: ID;
    withinMonth: ID;
    imageType: ID;
}

export function useBenchmark(data: iBenchmarkRequestData) {
    const payload = useMemo((): RequestPayloadType => ({
        itemId: data.chainId as number,
        indexes: data.metrics as RequestPayloadType['indexes'],
        respondentTypeId: data.among as RequestPayloadType['respondentTypeId'],
        dateRange: data.withinMonth as RequestPayloadType['dateRange'],
        imageState: data.imageType as RequestPayloadType['imageState'],
        compareTo: {
            typeId: data.compareTo.typeId as number,
            id: data.compareTo.id as number,
        },
    }), [data]);

    const { doFetch, ...fetchData } = useFetch<ResponseType, RequestPayloadType>({
        url: '/api/Benchmark/Compare',
        method: 'post',
        payload,
        lazy: true,
    });

    useEffect(() => {
        if (!!data.chainId || !!data.metrics) {
            const timer = setTimeout(() => {
                doFetch().catch(err => {
                    if (err.code !== "ERR_CANCELED") {
                        console.error(err);
                    }
                });
            }, 50);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [data.chainId, data.metrics, doFetch]);

    return fetchData;
}