import { FCX } from '@models';
import { includesInLC } from '@core/utils/string';
import { FaCertificate, FaClock, FaSyncAlt } from 'react-icons/fa';

const StatusIcon: FCX<{
    status: string;
}> = ({
    status,
    className,
    style,
}) => {
    let Component;

    if (includesInLC(status, 'limited')) {
        Component = FaClock;
    }
    else if (includesInLC(status, 'new')) {
        Component = FaCertificate;
    }
    else if (includesInLC(status, 'return')) {
        Component = FaSyncAlt;
    }

    if (!Component) return null;

    return <Component style={style} className={className}/>;
}

export default StatusIcon;