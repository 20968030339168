import { FCX } from '@models';
import cn from 'classnames';
import { equalInLC } from '@core/utils/string';

const DATA = [{
    name: 'Superstar',
    description: 'exceptional performance with broad appeal and strong uniqueness',
},{
    name: 'Volume Driver',
    description: 'strong interest driven by product appeal and/or price, but not necessarily very unique',
},{
    name: 'Specialty Appeal',
    description: 'strong uniqueness but lacks broad appeal; may help target specific audiences',
},{
    name: 'Consider',
    description: 'moderate appeal; may benefit from further refinement',
},{
    name: 'Low Potential',
    description: 'underperformer overall',
}]

const MetricScoreTooltip: FCX<{
    viability: string;
}> = ({
    viability,
}) => {
    return (
        <div className="metric-score-tooltip">
            <div className="metric-score-tooltip__content">
                <span className="caption">SCORE</span>&nbsp;=&nbsp;composite score based on performance across all 6 key
                metrics (SCORES above 70 are considered good, while SCORES above 90 are considered great).
            </div>
            <div className="metric-score-tooltip__bottom">
                <div className="viability">
                    <span className="caption">VIABILITY RATING</span>
                    &nbsp;=&nbsp;
                    <span className="name">{viability}</span>
                </div>
                <div className="viability-list">
                    {DATA.map(row => (
                        <div
                            key={row.name}
                            className={cn(
                                "viability-item",
                                equalInLC(row.name, viability) && 'is-selected'
                            )}>
                            <span className="viability-item__name">{row.name}</span>
                            &nbsp;-&nbsp;
                            {row.description}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MetricScoreTooltip;