import { ID } from '@models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiModel, FoodWatchItem, iFoodWatchItem } from '@models/FoodWatchItem';
import { api } from '@hooks/useFetch';
import { paths } from './api';

const URL: keyof paths = '/api/FoodWatch';

export function useFoodWatchItem(id?: ID) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<iFoodWatchItem>(new FoodWatchItem());

    const isEditMode = useMemo(
        () => !!id,
        [id]
    );
    const getData = useCallback(async () => {
        if (isEditMode) {
            setIsLoading(true);

            const data = await api.request<ApiModel>({
                method: 'get',
                url: `${URL}/${id}`,
            });

            setData(new FoodWatchItem({ apiModel: data.data }));
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [isEditMode, id]);

    const updateData = useCallback(async () => {
        const url = isEditMode ? `${URL}/${id}` : URL;
        const method = isEditMode ? 'put' : 'post';

        setIsLoading(true);

        return await api.request({
            method,
            url,
            data: FoodWatchItem.mapForApi(data),
        });

    }, [isEditMode, data, id]);

    useEffect(() => {
        getData();
    }, [getData]);

    return {
        isLoading,
        data,
        setData,
        updateData,
    };
}