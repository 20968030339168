import { FCX, ID, iOption } from '@models';
import { useState } from 'react';
import cn from 'classnames';
import SelectCustom from '@components/Select/SelectCustom';
import Dropdown from '@components/Select/Dropdown';
import Options from '@components/Select/Options';

const SelectCustomSingle: FCX<{
    value?: ID | null;
    options: iOption[];
    onOptionClick: (id: ID) => void;
    dpdnClassName?: string;
    label: string;
    isDisabled?: boolean;
}> = ({
    value,
    label,
    options,
    onOptionClick,
    dpdnClassName,
    style,
    className,
    isDisabled,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const activeOption = options.find(o => o.id === value);
    return (
        <SelectCustom
            label={label}
            value={activeOption?.title}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            className={className}
            style={style}
            isDisabled={isDisabled}
        >
            <Dropdown
                className={cn(dpdnClassName, 'pos-r')}
            >
                <Options
                    value={activeOption ? [activeOption.id] : []}
                    data={options}
                    onOptionClick={(id) => {
                        setIsOpened(false);
                        onOptionClick(id);
                    }}
                    isButtonsStyle
                />
            </Dropdown>
        </SelectCustom>
    )
};

export default SelectCustomSingle;