import { FCX, ID, iOption, Sort, UnitType } from '@models';
import Loader from '@components/Loader';
import Scrollbar from 'react-scrollbars-custom';
import { getFormattedThousand, getWithSign } from '@core/utils/number';
import React, { memo, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { iChainProfileComparisonData, iChainProfileComparisonValue } from '@models/ChainProfileComparisonData';
import { useFiltersContext } from '@core/FiltersContext';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { getSortingOptions } from '@core/constants';
import TableHeadCellSorting from '@components/Table/TableHeadCellSorting';

const ChainComparisonTable: FCX<{
    isLoading: boolean;
    groupColumnName: string;
    chainName: string;
    chainId: ID;
    data: iChainProfileComparisonData | null;
    unitType: UnitType;
    isSingleChainComparison: boolean;
    filterDateRangeString: ReactNode;
}> = ({
    isLoading,
    chainName,
    chainId,
    groupColumnName,
    data,
    unitType,
    isSingleChainComparison,
    filterDateRangeString,
}) => {
    const { options: { categories: initialCategories } } = useFiltersContext();
    const headRef = useRef<HTMLDivElement>(null);
    const [sortingColumnId, setSortingColumnId] = useState<ID | undefined>(undefined);
    const [sortingDirection, setSortingDirection] = useState<Sort>(Sort.Desc);

    const getValue = useCallback(
        (data: iChainProfileComparisonValue, categoryId: ID): number => {
            if (unitType === UnitType.Pct) return data.pctByCategories.get(categoryId) || 0;
            return data.countsByCategories.get(categoryId) || 0;
        },
        [unitType]
    );

    const valueFormatter = useCallback(
        (value: number) => unitType === UnitType.Pct
            ? `${Math.round(value)}%`
            : getFormattedThousand(value)
        ,
        [unitType],
    );

    const categories = useMemo(
        (): iOption[] => {
            const result = [...initialCategories];

            if (data !== null) {
                let sortByData: iChainProfileComparisonValue | undefined;

                if (sortingColumnId === 'group') {
                    sortByData = data.group;
                } else if (Number(sortingColumnId) === chainId) {
                    sortByData = data.currentChain;
                } else {
                    sortByData = data.otherChains.find(i => i.id === Number(sortingColumnId));
                }

                if (sortByData !== undefined) {
                    result.sort((a, b) => {
                        const aValue = getValue(sortByData!, a.id);
                        const bValue = getValue(sortByData!, b.id);

                        return (sortingDirection === Sort.Asc ? -1 : 1) * (bValue - aValue);
                    });
                }
            }

            return result;
        },
        [initialCategories, sortingColumnId, sortingDirection, data, getValue, chainId],
    );

    return (
        <div className="card ChainProfileComparisonTable">
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    Share of Launches:{' '}
                    <div className="color-dimyellow" style={{ display: 'inline' }}>{chainName}</div>
                    {' '}vs {groupColumnName}
                </div>
                <div style={{ opacity: 0.5, textTransform: 'uppercase' }}>
                    {filterDateRangeString}
                </div>
            </div>
            <div className="card-content" style={{ padding: isLoading ? undefined : 0 }}>
                {isLoading && (
                    <div style={{ position: 'relative', height: 500 }}>
                        <Loader/>
                    </div>
                )}
                {!isLoading && data !== null && (
                    <TableSortingContext.Provider value={{
                        value: {
                            id: sortingColumnId || -1,
                            direction: sortingDirection,
                        },
                        setValue: ({ id, direction }) => {
                            setSortingColumnId(id);
                            setSortingDirection(direction);
                        },
                        options: getSortingOptions([
                            'group',
                            `${data.currentChain.id}`,
                            ...data.otherChains.map(i => `${i.id}`),
                        ]),
                    }}>
                        <Scrollbar
                            translateContentSizesToHolder
                            noScrollY
                            style={{ maxWidth: '100%' }}
                            contentProps={{
                                style: {
                                    display: 'block',
                                }
                            }}
                        >
                            <div
                                className="Table"
                                style={{ width: 220 + (2 + data.otherChains.length) * 120 }}
                            >
                                <div className="TableHead" ref={headRef}>
                                    <div className="TableCell"/>
                                    <TableHeadCellSorting
                                        sortingColName={`${data.currentChain.id}`}
                                        className="center"
                                    >
                                        {data.currentChain.title}
                                    </TableHeadCellSorting>
                                    <TableHeadCellSorting
                                        sortingColName="group"
                                        className="center"
                                    >
                                        {groupColumnName}
                                    </TableHeadCellSorting>
                                    {!isSingleChainComparison && data.otherChains.map(chain => (
                                        <TableHeadCellSorting
                                            key={chain.id}
                                            sortingColName={`${chain.id}`}
                                            className="center"
                                        >
                                            {chain.title}
                                        </TableHeadCellSorting>
                                    ))}
                                </div>
                                {categories.map(category => (
                                    <div key={category.id} className="TableRow">
                                        <div className="TableCell">{category.title}</div>
                                        <div className="TableCell center">
                                            {valueFormatter(getValue(data.currentChain, category.id))}
                                        </div>
                                        <div className="TableCell center">
                                            {valueFormatter(getValue(data.group, category.id))}
                                        </div>
                                        {!isSingleChainComparison && data.otherChains.map(chain => (
                                            <div key={chain.id} className="TableCell center">
                                                {valueFormatter(getValue(chain, category.id))}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                <div className="TableRow total-row">
                                    <div className="TableCell">Total</div>
                                    <div className="TableCell center">
                                        {getFormattedThousand(data.currentChain.totalCount)}
                                    </div>
                                    <div className="TableCell center">
                                        {getFormattedThousand(data.group.totalCount)}
                                    </div>
                                    {!isSingleChainComparison && data.otherChains.map(chain => (
                                        <div key={chain.id} className="TableCell center">
                                            {getFormattedThousand(chain.totalCount)}
                                        </div>
                                    ))}
                                </div>
                                <div className="TableRow total-row">
                                    <div className="TableCell">
                                        LTO Activity vs. {chainName}
                                    </div>
                                    <div className="TableCell center">
                                        -
                                    </div>
                                    <div className="TableCell center">
                                        {isSingleChainComparison ? (
                                            <>{getWithSign(data.group.countDiff || 0, getFormattedThousand)}</>
                                        ) : '-'}
                                    </div>
                                    {!isSingleChainComparison && data.otherChains.map(chain => (
                                        <div key={chain.id} className="TableCell center">
                                            {getWithSign(chain.countDiff || 0, getFormattedThousand)}
                                        </div>
                                    ))}
                                </div>
                                <div className="TableRow total-row">
                                    <div className="TableCell">Activity Rank</div>
                                    <div className="TableCell center">
                                        {isSingleChainComparison ? '-' : (
                                            <># {data.currentChain.rank} of {data.otherChains.length + 1}</>
                                        )}
                                    </div>
                                    <div className="TableCell center">
                                        -
                                    </div>
                                    {!isSingleChainComparison && data.otherChains.map(chain => (
                                        <div key={chain.id} className="TableCell center">
                                            # {chain.rank} of {data.otherChains.length + 1}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Scrollbar>
                    </TableSortingContext.Provider>
                )}
            </div>
        </div>
    );
};

export default memo(ChainComparisonTable);