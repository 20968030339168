import { components } from '@api/api';
import { useMemo } from 'react';
import { useFiltersContext } from '@core/FiltersContext';

type FoodsFlavorsFilters = components['schemas']['FoodsFlavorsFilterModel'];

export function useFoodsFlavorsFilters () {
    const { defaultFiltersPayload, value } = useFiltersContext();
    return useMemo((): FoodsFlavorsFilters => ({
        date: defaultFiltersPayload.date,
        categoryIds: defaultFiltersPayload.categoryIds,
        segmentIds: defaultFiltersPayload.segmentIds,
        respondentTypes: [value.among] as FoodsFlavorsFilters['respondentTypes'],
        chainId: defaultFiltersPayload.chainId,
        includeAll: defaultFiltersPayload.includeAll,
        includeAny: defaultFiltersPayload.includeAny,
        excludeAll: defaultFiltersPayload.excludeAll,
    }), [defaultFiltersPayload, value]);
}