import { FCX, iOption } from '@models';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { SelectCustomSingle } from '@components/Select';

const SelectPageView: FCX<{
    views: iOption[];
}> = ({
    views,
}) => {
    const location = useLocation();
    const history = useNavigate();

    return (
        <SelectCustomSingle
            value={location.pathname}
            options={views}
            onOptionClick={(id) => history((id as To), { replace: true })}
            dpdnClassName="w-s"
            label="View"
        />
    );
};

export default SelectPageView;