import { ID } from '@models';
import { useMemo } from 'react';
import { Route } from '@core/routes/Route';

export default function useItemDetailsLink(id: ID, isConcept: boolean) {
    return useMemo(() => {
        const route = isConcept ? Route.CLItemDetails : Route.ItemDetails;

        return route.replace(':id', `${id}`);
    }, [isConcept, id]);
}