import { FCX, ID } from '@models';
import { ReactNode, memo } from 'react';
import { Tooltip } from 'react-tooltip';
import cn from 'classnames';
import Popup from '@components/Popup';

const TableTooltip: FCX<{
    id: ID;
    title: ReactNode;
    children: ReactNode;
    isLastCol?: boolean;
}> = ({
    children,
    className,
    id,
    style,
    title,
    isLastCol,
}) => {
    if (children === undefined) return null;
    return (
        <Popup hasWrapper={false}>
            <Tooltip
                variant="light"
                place={isLastCol ? 'top-end' : 'top'}
                positionStrategy="fixed"
                className={cn("TableTooltip", className)}
                id={id}
                style={{
                    padding: 0,
                    width: 280,
                    ...style,
                }}
            >
                <div className="TableTooltip__header">{title}</div>
                <div className="TableTooltip__content">{children}</div>
            </Tooltip>
        </Popup>
    )
};

export default memo(TableTooltip);