import { FCX, ID } from '@models';
import React, { ReactNode, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { Tooltip } from 'react-tooltip';
import { getFormattedThousand } from '@core/utils/number';
import { TableContext } from '@components/Table/TableContext';
import { iSeasonalFood } from '@api/useSeasonalByFoods';
import { iCategoryActivityFood } from '@api/useCategoyActivityByFoods';
import { iCategoryActivityChain } from '@api/useCategoyActivityByChains';

const MostActiveChainsTableConfig: FCX<{
    id: ID;
    data: iCategoryActivityChain[];
    renderCategoryName: ReactNode;
}> = ({
    id,
    data,
    renderCategoryName,
    children,
}) => {
    const tableConfig = useMemo((): iTableConfigItem[] => [
        {
            key: 'name',
            title: '',
            Value: ({ index }) => <>
                {data[index].name}
            </>,
        },
        {
            key: 'segment',
            title: '',
            Value: ({ index }) => <>
                {data[index].segmentName}
            </>,
            styles: {
                cell: {
                    flex: '0 0 25%',
                },
            },
        },
        {
            key: 'count',
            title: 'Count',
            sortingColName: 'count',
            Value: ({ index }) => <>
                {getFormattedThousand(data[index].count)}
            </>,
            styles: {
                cell: {
                    flex: '0 0 20%',
                    justifyContent: 'center',
                },
            },
        },
        {
            key: 'percentage',
            sortingColName: 'percentage',
            title: <>
                Percentage
                <Tooltip id={`table-category-activity-chains-${id}-percentage`}>
                    <div style={{ textAlign: 'center', width: 100 }}>
                        % of total{' '}
                        {renderCategoryName}
                        {' '}menu launches
                    </div>
                </Tooltip>
            </>,
            Value: ({ index }) => <>
                {Math.round(data[index].percentage || 0)}%
            </>,
            getAttrs: (index) => (index === -1 ? {
                'data-tooltip-id': `table-category-activity-chains-${id}-percentage`,
            } : {}),
            styles: {
                cell: {
                    flex: '0 0 20%',
                    justifyContent: 'center',
                },
            },
        },
    ], [data, renderCategoryName, id]);

    return (
        <TableContext.Provider value={{ config: tableConfig }}>
            {children}
        </TableContext.Provider>
    );
};

export default MostActiveChainsTableConfig;