import { FCX } from '@models';
import { useFiltersPopupContext } from '@vms/FiltersPopup/FiltersPopupContext';
import cn from 'classnames';
import { useFiltersContext } from '@core/FiltersContext';
import { useMemo } from 'react';
import { Filter, iFilterValue } from '@models/Filter';
import { hasAnyProperties } from '@core/utils/object';

enum FiltersSections {
    Sort = 'SORT TILES',
    Date = 'DATE',
    Concepts = 'CONCEPTS',
    Chain = 'CHAIN',
    Segments = 'SEGMENTS',
    Category = 'CATEGORY (ITEM TYPE)',
    Keywords = 'KEYWORDS',
    Status = 'ITEM STATUS',
    KeyMetrics = 'KEY METRIC',
}

const FiltersButton: FCX = ({
    className,
    id,
    style,
}) => {
    const { toggle } = useFiltersPopupContext();
    const {
        value, defaultValue,
    } = useFiltersContext();

    const filtersDiffMessage: string = useMemo(() => {
        const diff: Partial<iFilterValue> = Filter.getValueDiff(value, defaultValue);
        const changedSections: FiltersSections[] = [];

        if (hasAnyProperties(diff, 'sort')) {
            changedSections.push(FiltersSections.Sort);
        }
        if (hasAnyProperties(diff, 'date')) {
            changedSections.push(FiltersSections.Date);
        }
        if (hasAnyProperties(diff, 'concepts')) {
            changedSections.push(FiltersSections.Concepts);
        }
        if (hasAnyProperties(diff, 'segments', 'isMealKit', 'isGroceryDeli')) {
            changedSections.push(FiltersSections.Segments);
        }
        if (hasAnyProperties(diff, 'chainId')) {
            changedSections.push(FiltersSections.Chain);
        }
        if (hasAnyProperties(diff, 'categories')) {
            changedSections.push(FiltersSections.Category);
        }
        if (hasAnyProperties(diff, 'includeAll', 'includeAny', 'excludeAll')) {
            changedSections.push(FiltersSections.Keywords);
        }
        if (hasAnyProperties(diff, 'statuses')) {
            changedSections.push(FiltersSections.Status);
        }
        if (
            hasAnyProperties(
                diff,
                'viability',
                'score',
                'unbrandedStarsCount',
                'brandedStarsCount',
                'uniquenessStarsCount',
                'frequencyStarsCount',
                'drawStarsCount',
                'valueStarsCount',
                'dayPartFit',
                'brandFit'
            )
        ) {
            changedSections.push(FiltersSections.KeyMetrics);
        }

        if (changedSections.length === 1) return `Your results are currently filtered on ${changedSections[0]}`
        else if (changedSections.length === 0) return '';
        else return 'Your results have multiple filters applied';
    }, [value, defaultValue]);

    return (
        <div
            className={cn(
                "FiltersButton",
                className,
                filtersDiffMessage !== '' && 'is-active',
            )}
            style={style}
        >
            <div
                onClick={() => toggle('footer')}
                className="FiltersButton__button"
                id={id}
            >
                Filters
            </div>
            <div className="FiltersButton__message">
                {filtersDiffMessage}
            </div>
        </div>
    );
};

export default FiltersButton;