import { FCX, iOptionGroup } from '@models';
import SelectCustomColumns from '@vms/SelectCustomColumns';
import { useFiltersContext } from '@core/FiltersContext';

const SelectAmong: FCX = ({
    style,
}) => {
    const {
        options: {
            respondentTypes,
        },
        value: filtersValue,
        updateValue: updateFiltersValue,
    } = useFiltersContext();

    const { among, } = filtersValue;
    return (
        <SelectCustomColumns
            value={among}
            options={respondentTypes as iOptionGroup[]}
            style={style}
            onOptionClick={(id) => {
                updateFiltersValue({
                    among: id,
                })
            }}
            label="Among"
        />
    );
};

export default SelectAmong;