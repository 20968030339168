import { CSSProperties, ReactNode, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { getFormattedThousand } from '@core/utils/number';
import { UnitType } from '@models';
import { NAME_CELL_WIDTH, TOTAL_AVG_CELL_WIDTH } from '@pages/ChainPerformance/TimeframesTableConfig';

export function useTimeframeTotalAverageColumns({
    defaultStyle,
    data,
    valueFormatter,
    selectedUnit,
}: {
    defaultStyle: iTableConfigItem['styles'];
    data: Array<{
        total: number | null;
        average: number | null;
    }>;
    valueFormatter: (value: number | null) => ReactNode;
    selectedUnit: UnitType;
}) {
    return useMemo((): iTableConfigItem[] => {
        const totalColumn: iTableConfigItem = {
            key: 'total',
            title: 'Total',
            sortingColName: 'total',
            Value: ({ index }) => (
                <>
                    {data[index].total ? getFormattedThousand(data[index].total || 0) : '-'}
                    <div style={{
                        position: 'absolute',
                        height: '100%',
                        width: 4,
                        top: 0,
                        left: '100%',
                        background: 'linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent)',
                    }}/>
                </>
            ),
            styles: {
                cell: {
                    ...defaultStyle?.cell,
                    flex: `0 0 ${TOTAL_AVG_CELL_WIDTH}px`,
                    position: 'sticky',
                    left: NAME_CELL_WIDTH + 16,
                    zIndex: 2,
                }
            },
        };

        const averageColumn: iTableConfigItem = {
            key: 'average',
            title: 'Average',
            sortingColName: 'average',
            Value: ({ index }) => (
                <>
                    {valueFormatter(data[index].average)}
                    <div style={{
                        position: 'absolute',
                        height: '100%',
                        width: 4,
                        top: 0,
                        left: '100%',
                        background: 'linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent)',
                    }}/>
                </>
            ),
            styles: {
                cell: {
                    ...defaultStyle?.cell,
                    flex: `0 0 ${TOTAL_AVG_CELL_WIDTH}px`,
                    position: 'sticky',
                    left: NAME_CELL_WIDTH + TOTAL_AVG_CELL_WIDTH + 16,
                    zIndex: 2,
                }
            },
        };

        if (selectedUnit === UnitType.Count) {
            return [totalColumn];
        } else {
            return [totalColumn, averageColumn];
        }

    }, [data, selectedUnit, valueFormatter, defaultStyle]);
}