import { FCX } from '@models';
import BreadCrumbs from '@components/BreadCrumbs';
import { Route } from '@core/routes/Route';
import HeaderBar from '@components/HeaderBar';
import Wrapper from '@components/Wrapper';
import Button from '@components/Button';
import { ButtonType } from '@components/Button/Button';
import { useMyConcepts } from '@api/useMyConcepts';
import TextInput from '@components/TextInput';
import { getSortingOptions } from '@core/constants';
import Table from '@components/Table';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { useFilterSort } from '@hooks/useFilterSort';
import TableConfig from './TableConfig';
import ActionsBar from '@vms/ActionsBar';
import React from 'react';
import { useMyConceptsXlsReport } from '@api/useMyConceptsXlsReport';

export enum Columns {
    Date = 'fullDate',
    Company = 'companyName',
    Name = 'name',
    Description = 'description',
    Price = 'price',
    Type = 'typeId',
}

const MyConcepts: FCX = () => {
    const {
        isLoading,
        data: rawData,
        doFetch: reFetchData,
    } = useMyConcepts();

    const {
        items,
        itemsInfo,
        deadlineInfo,
        canAddConcept,
    } = rawData || {};

    const {
        data,
        sortingColumn,
        setSortingColumn,
        sortingDirection,
        setSortingDirection,
        query,
        setQuery,
    } = useFilterSort(
        items || [],
        'fullDate',
        ['companyName', 'name', 'description', 'type'],
    );

    const report = useMyConceptsXlsReport();

    return (
        <>
            <div className="MyConcepts" style={{ position: 'relative', minHeight: 'calc(100vh - 140px)' }}>
                <Wrapper>
                    <BreadCrumbs items={[
                        { link: Route.Home, title: 'Home', },
                        { link: Route.ConceptLocker, title: 'Concept Locker', },
                        { link: '', title: 'Items', }
                    ]}/>
                    <HeaderBar className="MyConcepts__header-bar">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                type={ButtonType.Link}
                                link={Route.ConceptLocker}
                                modifiers={['green']}
                                style={{ marginRight: 16 }}
                            >
                                CONCEPT LOCKER
                            </Button>
                            {canAddConcept && (
                                <Button
                                    type={ButtonType.Link}
                                    link={Route.ConceptPageCreate}
                                    modifiers={['orange']}
                                    style={{ marginRight: 16 }}
                                >
                                    <div
                                        className="font-icons"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    >
                                        &#59679;
                                    </div>
                                    CREATE CONCEPT
                                </Button>
                            )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="font-medium">READY TO FIELD CONCEPTS</div>
                        </div>
                    </HeaderBar>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 0',
                        marginBottom: 10,
                        borderBottom: '1px solid #cccccc',
                    }}>
                        <div>SEARCH</div>
                        <TextInput
                            value={query}
                            setValue={setQuery}
                            placeholder="Search by Company, Concept, Description"
                            style={{
                                width: 320,
                                height: 40,
                                marginLeft: 10,
                            }}
                        />
                    </div>
                    <div style={{ fontSize: 12, marginBottom: 20 }}>
                        <div>{itemsInfo}</div>
                        <div>{deadlineInfo}</div>
                    </div>
                    <TableSortingContext.Provider value={{
                        value: {
                            id: sortingColumn,
                            direction: sortingDirection,
                        },
                        setValue: (value) => {
                            setSortingColumn(value.id as Columns);
                            setSortingDirection(value.direction);
                        },
                        options: getSortingOptions([
                            Columns.Date,
                            Columns.Company,
                            Columns.Name,
                            Columns.Description,
                            Columns.Price,
                            Columns.Type,
                        ]),
                    }}>
                        <TableConfig
                            data={isLoading ? [] : data}
                            searchQuery={query}
                            allowActions={canAddConcept ?? false}
                            reFetchData={reFetchData}
                        >
                            <Table
                                rowsCount={isLoading ? 0 : data.length}
                                isLoading={isLoading}
                                noResultsTitle={''}
                                noResultsDescription={'No data available in table'}
                            />
                        </TableConfig>
                    </TableSortingContext.Provider>
                </Wrapper>
            </div>
            <ActionsBar>
                <div/>
                {report.isLoading ? (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}>
                        DOWNLOADING
                        <div className="ProgressLoader"/>
                    </div>
                ) : (
                    <div
                        className="ActionButton"
                        onClick={report.load}
                    >
                        <div className="ActionButton__icon font-icons">&#59700;</div>
                        XLS REPORT
                    </div>
                )}
                <div/>
            </ActionsBar>
        </>

    );
};

export default MyConcepts;