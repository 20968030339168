import React, { Fragment } from 'react';
import classNames from 'classnames';
import CardBenchmarkChart from './CardBenchmarkChart';
import { IconGraphPlaceholder } from '@svg/index';
import { FCX } from '@models';
import { components } from '@api/api';

import {  METRIC_TYPE } from '../../ItemDetails.constants';

const CardBenchmark: FCX<{
	benchmark: components["schemas"]["BenchmarkData"]["benchmark"];
	selectedMetric: number | undefined;
	categoryName: string;
	segmentName: string;
	chainName: string;
	isLoading: boolean;
}> = ({
	className = null,
	benchmark,
	selectedMetric = null,
	categoryName = null,
	segmentName = null,
	chainName = null,
	isLoading = false,
}) => {
	const allMetric = (benchmark?.default as components["schemas"]["Graph"][])?.find(metricData => metricData.indexType === selectedMetric) as components["schemas"]["Graph"];
	const chainItems = (benchmark?.chain as components["schemas"]["Graph"][])?.find(metricData => metricData.indexType === selectedMetric) as components["schemas"]["Graph"];
	const segmentMetric = (benchmark?.segment as components["schemas"]["Graph"][])?.find(metricData => metricData.indexType === selectedMetric) as components["schemas"]["Graph"];
	const categoryMetric = (benchmark?.type as components["schemas"]["Graph"][])?.find(metricData => metricData.indexType === selectedMetric) as components["schemas"]["Graph"];

	const benchmarkBrandedPi = (chainItems && chainItems && chainItems.totalConceptsCount && chainItems.totalConceptsCount > 10) ? {
		name: chainName,
		benchmark: chainItems,
	} : {
		name: segmentName,
		benchmark: segmentMetric,
	}

	const {
		indexName = 'Metric'
	} = allMetric || {};

	return (
		<div className={classNames('card', '__card-benchmark', className)}>
			<div className="card-header card-benchmark-header">
				<div className="card-benchmark-header__caption">{indexName}</div>
				<div className="card-benchmark-header__description">
					benchmarks versus other menu items<br /> higher scores are better
				</div>
			</div>
			{isLoading ? (
				<div className="card-benchmark-content card-benchmark-content--full-width">
					<div className="card-benchmark-content__column">
						<IconGraphPlaceholder />
					</div>
					<div className="card-benchmark-content__column">
						<IconGraphPlaceholder />
					</div>
					<div className="card-benchmark-content__column">
						<IconGraphPlaceholder />
					</div>
				</div>
				) : (
				<Fragment>
					{(selectedMetric === METRIC_TYPE.BRANDED_PI) ? (
							<div className="card-benchmark-content card-benchmark-content--full-width">
								<CardBenchmarkChart
									metricData={benchmarkBrandedPi.benchmark}
									bottomCaption={`items from ${benchmarkBrandedPi.name}`}
									bottomValue={benchmarkBrandedPi.benchmark.totalConceptsCount || 0}
								/>
							</div>
						) : (
							<div className="card-benchmark-content">
								{(allMetric && !!allMetric.itemsCount && allMetric.itemsCount > 0) && (
									<div className="card-benchmark-content__column">
										<CardBenchmarkChart 
											metricData={allMetric}
											bottomCaption="items of any type"
											bottomValue={allMetric.totalConceptsCount || 0}
										/>
									</div>
								)}
								{(categoryMetric && !!categoryMetric.itemsCount && categoryMetric.itemsCount > 0) && (
									<div className="card-benchmark-content__column">
										<CardBenchmarkChart
											metricData={categoryMetric}
											bottomCaption={categoryName || ''}
											bottomValue={categoryMetric.totalConceptsCount || 0}
										/>
									</div>
								)}
								{(segmentMetric && !!segmentMetric.itemsCount && segmentMetric.itemsCount > 0) && (
									<div className="card-benchmark-content__column">
										<CardBenchmarkChart 
											metricData={segmentMetric}
											bottomCaption={`${segmentName} items`}
											bottomValue={segmentMetric.totalConceptsCount || 0}
										/>
									</div>
								)}
							</div>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CardBenchmark;