import { FCX, ID } from '@models';
import HeaderBar from '@components/HeaderBar';
import AnimatedNumber from '@components/AnimatedNumber';
import TextInput from '@components/TextInput';
import SelectKeywordCategories from '@vms/SelectKeywordCategories';
import SelectAmong from '@vms/SelectAmong';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { getSortingOptions } from '@core/constants';
import TableConfig from '@pages/FoodsFlavors/TotalTableConfig';
import React, { memo, useMemo } from 'react';
import { intersect } from '@core/utils/array';
import { useFoodsFlavorsData } from '@api/useFoodsFlavorsData';
import { useFilterSort } from '@hooks/useFilterSort';
import { renderMetricValue } from '@core/utils/string';
import VirtualizedTable from '@components/Table/VirtualizedTable';
import { useUserContext } from '@core/UserContext';
import LockAccessOverlay from '@components/LockAccessOverlay';

export enum Columns {
    Name = 'name',
    ItemsCount = 'itemsCount',
    UnbrandedPi = 'unbrandedPi',
    BrandedPi = 'brandedPi',
    Uniqueness = 'uniqueness',
    Frequency = 'frequency',
    Draw = 'draw',
    Value = 'value',
}

const TotalTable: FCX<{
    selectedMetricId: ID;
    selectedCategories: ID[];
    setSelectedCategories: (ids: ID[]) => void;
}> = ({
    selectedMetricId,
    selectedCategories,
    setSelectedCategories,
}) => {
    const { fullAccess } = useUserContext();
    const { data: rawData, isLoading } = useFoodsFlavorsData(!fullAccess);

    const filteredData = useMemo(() => {
        if (selectedCategories.length === 0) {
            return rawData;
        }
        return rawData?.filter(i => intersect(selectedCategories as number[], i.groupKeyWordIds || []));
    }, [rawData, selectedCategories]);

    const {
        data,
        sortingColumn,
        setSortingColumn,
        sortingDirection,
        setSortingDirection,
        query,
        setQuery,
    } = useFilterSort(filteredData || [], 'itemsCount', 'name');

    const copyTableData = useMemo((): string => {
        const cells: string[] = [];

        cells.push([
            'Keyword',
            'Total items',
            'Unbranded PI',
            'Branded PI',
            'Uniqueness',
            'Frequency',
            'Draw',
            'Value',
            'Unbranded PI Percentile Stars',
            'Branded PI Percentile Stars',
            'Uniqueness Percentile Stars',
            'Frequency Percentile Stars',
            'Draw Percentile Stars',
            'Value Percentile Stars',
            'Unbranded PI Percentile',
            'Branded PI Percentile',
            'Uniqueness Percentile',
            'Frequency Percentile',
            'Draw Percentile',
            'Value Percentile',
        ].join('\t'));
        data.forEach(i => {
            cells.push([
                i.name,
                i.itemsCount,
                renderMetricValue(i.unbrandedPi || 0),
                renderMetricValue(i.brandedPi || 0),
                renderMetricValue(i.uniqueness || 0),
                renderMetricValue(i.frequency || 0),
                renderMetricValue(i.draw || 0),
                renderMetricValue(i.value || 0),
                Array(i.unbrandedPiStar || 0).fill('★').join(''),
                Array(i.brandedPiStar || 0).fill('★').join(''),
                Array(i.uniquenessStar || 0).fill('★').join(''),
                Array(i.frequencyStar || 0).fill('★').join(''),
                Array(i.drawStar || 0).fill('★').join(''),
                Array(i.valueStar || 0).fill('★').join(''),
                i.unbrandedPercentile || 0,
                i.brandedPercentile || 0,
                i.uniquenessPercentile || 0,
                i.frequencyPercentile || 0,
                i.drawPercentile || 0,
                i.valuePercentile || 0,
            ].join('\t'));
        });

        return cells.join('\r\n');
    }, [data]);

    return (
        <div style={{ position: 'relative' }}>
            <HeaderBar style={{
                position: 'relative',
                zIndex: 3,
                marginBottom: 20,
                height: 70,
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(rawData || []).length > 0 && (
                        <div
                            style={{
                                fontSize: 30,
                                width: 85,
                                marginRight: 15,
                            }}
                            className="font-caption color-dimyellow"
                        >
                            <AnimatedNumber value={data.length}/>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div className="header-title">
                            Foods & Flavors
                        </div>
                        <div className="header-sub-title">
                            average for menu launches containing that food / flavor
                        </div>
                    </div>
                    <TextInput
                        placeholder="Type any keyword"
                        value={query}
                        setValue={setQuery}
                        style={{ width: 160, marginLeft: 20 }}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <SelectKeywordCategories
                        value={selectedCategories}
                        setValue={setSelectedCategories}
                    />
                    <SelectAmong/>
                </div>
            </HeaderBar>
            <TableSortingContext.Provider value={{
                value: {
                    id: sortingColumn,
                    direction: sortingDirection,
                },
                setValue: (value) => {
                    setSortingColumn(value.id as Columns);
                    setSortingDirection(value.direction);
                },
                options: getSortingOptions([
                    Columns.Name,
                    Columns.ItemsCount,
                    Columns.UnbrandedPi,
                    Columns.BrandedPi,
                    Columns.Uniqueness,
                    Columns.Frequency,
                    Columns.Draw,
                    Columns.Value,
                ]),
            }}>
                <TableConfig
                    data={isLoading ? [] : data}
                    searchQuery={query}
                    highlightedMetricId={selectedMetricId}
                >
                    <div className="FoodsFlavors__table">
                        <VirtualizedTable
                            rowHeight={48}
                            copyTable={fullAccess ? {
                                isLoading: false,
                                data: copyTableData,
                            } : undefined}
                            rowsCount={isLoading ? 0 : data.length}
                            isLoading={isLoading}
                        />
                    </div>
                </TableConfig>
            </TableSortingContext.Provider>
            {!fullAccess && (
                <LockAccessOverlay
                    subTitle="of these foods & flavors"
                    toolLocation="Specific Foods & Flavors"
                />
            )}
        </div>
    );
};

export default memo(TotalTable);