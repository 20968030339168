import { FCX } from '@models';
import cn from 'classnames';
import { KeyboardEventHandler, ClipboardEvent } from 'react';

const TextInput: FCX<{
    value: string;
    setValue: (value: string) => void;
    placeholder: string;
    autoFocus?: boolean;
    onFocus?: () => void;
    onPaste?: (event: ClipboardEvent) => void;
    onBlur?: () => void;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}> = ({
    placeholder,
    value,
    setValue,
    style,
    className,
    autoFocus,
    onFocus,
    onBlur,
    onKeyDown,
    onPaste,
}) => {
    return (
        <input
            type="text"
            placeholder={placeholder}
            className={cn("TextInput", className)}
            style={style}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={onBlur}
            onPaste={onPaste}
            onKeyDown={onKeyDown}
        />
    )
};

export default TextInput;