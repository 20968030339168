import { ID, iSortingOption, NormType, Sort } from '@models';
import { equalInLC } from '@core/utils/string';
import { Route } from '@core/routes/Route';

const ASC_COLS: string[] = ['name', 'chain', 'company', 'Tested for', 'companyName'];
const DESC_COLS: string[] = ['date'];

export const DEFAULT_SORT_DIRECTION = Sort.Desc;

export function getDefaultSortDirection(colName: string): Sort {
    if (ASC_COLS.some(i => equalInLC(i, colName))) {
        return Sort.Asc;
    }

    if (DESC_COLS.some(i => equalInLC(i, colName))) {
        return Sort.Desc;
    }

    return DEFAULT_SORT_DIRECTION;
}

export function getSortingOptions (keys: ID[]): iSortingOption[] {
    return keys.map(key => ({
        id: key,
        name: `${key}`,
        defaultDirection: getDefaultSortDirection(`${key}`),
    }));
}

export const TABLE_TOOLTIPS = new Map([
    ['# of units', 'Number of menu items containing this flavor / ingredient'],
    ['unbranded pi', 'If it were available, how likely would you be to order / buy this item? '],
    ['branded pi', 'How likely would you be to order this item if it were offered at this [chain/segment]?',],
    ['price pi', 'How likely would you be to order this item if offered at this restaurant for this price?',],
    ['uniqueness', 'How new and different is this item? '],
    ['frequency', 'Is this an item you could see yourself having all the time? '],
    ['draw', 'Would you visit a restaurant just for this item? '],
    ['value', 'If offered at this restaurant at this price, would you consider this a good value for the dollar? '],
    ['delivery', '% interested in having it...'],
    ['score', 'This is a composite of the key metrics shown here. Higher SCORES are better.'],
    ['heavy', 'Consumers who visit that chain frequently'],
    ['medium', 'Consumers who visit that chain occasionally'],
    ['light', 'Consumers who visit that chain rarely'],
    ['aware non user', 'Consumers who know, but do not visit that chain'],
    ['unaware', 'Consumers who are not familiar with that chain'],
    ['delivery', '% interested in having it...'],
    ['to-go', '% interested in having it...'],
    ['dine-in', '% interested in having it...'],
]);
export const PAGES_HOME: Route[] = [
    Route.Home,
    Route.HomeViewTable,
    Route.HomeViewPeople,
    Route.HomeViewChart,
]
export const PAGES_WITH_FILTER: Route[] = [
    Route.Home,
    Route.HomeViewTable,
    Route.HomeViewPeople,
    Route.HomeViewChart,
    Route.ConceptLocker,
    Route.ConceptLockerViewTiles,
    Route.ConceptLockerViewPeople,
    Route.ConceptLockerViewChart,
];
export const COLORS = [
    '#66DEB2',
    '#7B16F5',
    '#F19B38',
    '#50B1F9',
    '#D82E85',
    '#74B235',
    '#F7CF46',
    '#2D2DEB',
    '#C2E548',
    '#C0281F',
];

export enum Colors {
    Blue = '#50B1F9',
    Green = '#74B235',
    Orange = '#ED722E',
    Pink = '#D82E85',
}

export const CONCEPT_LOCKER_INDICATOR_COLOR = '#F6C143';

export function getNormTypeById(id: ID | null | undefined): NormType {
    switch (id) {
        case 0: return 'all';
        case 1: return 'segment';
        case 2: return 'category';
        case 3: return 'chain';
        default: return 'category';
    }
}