import React, { Fragment, useMemo, useState } from 'react';
import Button from '@components/Button';
import ButtonClipboardCopy from '@components/ButtonClipboardCopy';
import { FCX } from '@models';
import cn from 'classnames';
import { components } from '@api/api';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

const displayValue = (value: number | null | undefined) => {
    return (value === null || value === undefined || Number.isNaN(value)) ? '-' : `${Math.round(value)}%`;
}

const Rows: FCX<{
    groupName: string;
    rowList: components["schemas"]["TargetAudienceRow"][];
    groupClassName: string;
}> = ({
    groupName = null,
    rowList = [],
    groupClassName = null,
}) => {
    if (rowList.length === 0)
        return null;

    return (
        <Fragment>
            <tr>
                <td
                    className={cn(
                        'table-target-audiences-cell',
                        'table-target-audiences-cell--name',
                        groupClassName
                    )}
                    colSpan={4}
                >
                    {groupName}
                </td>
                <td
                    className={cn(
                        'table-target-audiences-cell',
                        'table-target-audiences-cell--name',
                        groupClassName,
                    )}
                    colSpan={5}
                />
            </tr>
            {rowList.map((row, index) => {
                return (
                    <tr key={index}>
                        <td className="table-target-audiences-cell table-target-audiences-cell--name">
                            {row.name}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.unbrandedPi)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.brandedPi)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.pricePi)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.uniqueness)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.frequency)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.draw)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value">
                            {displayValue(row.value)}
                        </td>
                        <td className="table-target-audiences-cell table-target-audiences-cell--value table-target-audiences-cell--base">
                            {row.baseSize}
                        </td>
                    </tr>
                )
            })}
        </Fragment>
    )
};

const CardTargetAudiences: FCX<{
    audience: components['schemas']['TargetAudience'];
    userType?: components["schemas"]["TargetAudienceRow"][] | null;
}> = ({
    className = null,
    audience: {
        total,
        ...audience
    },
    userType,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const copyTableData = useMemo(() => {
        const getRows = (rows: components["schemas"]["TargetAudienceRow"][] = []) => {
            return rows.map(row => {
                return [
                    row.name,
                    displayValue(row.unbrandedPi),
                    displayValue(row.brandedPi),
                    displayValue(row.pricePi),
                    displayValue(row.uniqueness),
                    displayValue(row.frequency),
                    displayValue(row.draw),
                    displayValue(row.value),
                    row.baseSize,
                ];
            })
        }

        const clipboardData = [
            ['', 'Unbranded PI', 'Branded PI', 'Priced PI', 'Uniqueness', 'Frequency', 'Draw', 'Value', 'Base size'],
            [	'Total',
                displayValue(total?.unbrandedPi),
                displayValue(total?.brandedPi),
                displayValue(total?.pricePi),
                displayValue(total?.uniqueness),
                displayValue(total?.frequency),
                displayValue(total?.draw),
                displayValue(total?.value),
                total?.baseSize,
            ],
            ['Gender'], ...getRows(audience?.gender || []),
            ['Generation'], ...getRows(audience?.generation || []),
            ...((audience?.etnicity || []).length > 0) ? [
                ['Ethnicity'], ...getRows(audience?.etnicity || []),
            ] : [],
            ...((audience?.region || []).length > 0) ? [
                ['Region'], ...getRows(audience?.region || []),
            ] : [],
            ...((audience?.area || []).length > 0) ? [
                ['Area type'], ...getRows(audience?.area || []),
            ] : [],
            ['Income'], ...getRows(audience?.income || []),
            ['Kids'], ...getRows(audience?.kids || []),
            ['EatingHabits'], ...getRows(audience?.eatingHabits || []),
            ...(Array.isArray(userType) === true && (userType || []).length > 0) ? [
                ['User type'], ...getRows(userType || []),
            ] : []
        ];

        return clipboardData.map(i => i.join('\t')).join('\r\n');
    }, [total, audience, userType]);

    return (
        <div className={cn('card card-target-audiences', className)}>
            <div className="card-header">
                TARGET AUDIENCES
            </div>
            <div className="card-target-audiences-content">
                <div className="card-target-audiences-expander">
                    <div
                        className={cn(
                            'card-target-audiences-expander__table',
                            isExpanded && 'is-expanded',
                        )}
                    >
                        <table className="table-target-audiences">
                            <thead className={cn(
                                'table-head',
                                isExpanded && 'table-head--sticky'
                            )}>
                            <tr>
                                <th className="table-target-audiences-head-cell"/>
                                <th className="table-target-audiences-head-cell">Unbranded PI</th>
                                <th className="table-target-audiences-head-cell">Branded PI</th>
                                <th className="table-target-audiences-head-cell">Priced PI</th>
                                <th className="table-target-audiences-head-cell">Uniqueness</th>
                                <th className="table-target-audiences-head-cell">Frequency</th>
                                <th className="table-target-audiences-head-cell">Draw</th>
                                <th className="table-target-audiences-head-cell">Value</th>
                                <th className="table-target-audiences-head-cell">Base size</th>
                            </tr>
                            </thead>
                            <tbody className="table-body">
                            {!!total && (
                                <tr className={cn(isExpanded && "table-body-sticky-row")}>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--name">Total</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.unbrandedPi)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.brandedPi)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.pricePi)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.uniqueness)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.frequency)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.draw)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value">{displayValue(total.value)}</td>
                                    <td
                                        className="table-target-audiences-cell table-target-audiences-cell--total table-target-audiences-cell--value table-target-audiences-cell--base">{total.baseSize}</td>
                                </tr>
                            )}
                            {!!audience.gender && (
                                <Rows
                                    groupName="Gender"
                                    rowList={audience.gender}
                                    groupClassName="table-target-audiences-cell--group"
                                />
                            )}
                            {!!audience.generation && (
                                <Rows
                                    groupName="Generation"
                                    rowList={audience.generation}
                                    groupClassName="table-target-audiences-cell--group"
                                />
                            )}
                            {isExpanded &&
                                <>
                                    {!!audience.etnicity && (
                                        <Rows
                                            groupName="Ethnicity"
                                            rowList={audience.etnicity}
                                            groupClassName="table-target-audiences-cell--group"
                                        />
                                    )}
                                    {!!audience.region && (
                                        <Rows
                                            groupName="Region"
                                            rowList={audience.region}
                                            groupClassName="table-target-audiences-cell--group"
                                        />
                                    )}
                                    {!!audience.area && (
                                        <Rows
                                            groupName="Area type"
                                            rowList={audience.area}
                                            groupClassName="table-target-audiences-cell--group"
                                        />
                                    )}
                                    {!!audience.income && (
                                        <Rows
                                            groupName="Income"
                                            rowList={audience.income}
                                            groupClassName="table-target-audiences-cell--group"
                                        />
                                    )}
                                    {!!audience.kids && (
                                        <Rows
                                            groupName="Kids"
                                            rowList={audience.kids}
                                            groupClassName="table-target-audiences-cell--group"
                                        />
                                    )}
                                    {!!audience.eatingHabits && (
                                        <Rows
                                            groupName="Eating Habits"
                                            rowList={audience.eatingHabits}
                                            groupClassName="table-target-audiences-cell--group"
                                        />
                                    )}

                                    {(Array.isArray(userType) && userType.length > 0) && (
                                        <Rows
                                            groupName="User type"
                                            rowList={userType}
                                            groupClassName="table-target-audiences-cell--type"
                                        />
                                    )}
                                </>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="card-target-audiences-expander__bottom">
                        <Button
                            className="card-target-audiences-expander__button"
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            Show {isExpanded ? 'less' : 'more'} data rows
                        </Button>
                    </div>
                </div>
                <ButtonClipboardCopy
                    onClick={() => copy(copyTableData, { format: 'text/plain' })}
                />
            </div>
        </div>
    );
};

export default CardTargetAudiences;