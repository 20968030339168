import { FCAdditionalProps, FCX, ID, iOption } from '@models';
import Button from '@components/Button';
import { ButtonModifiers, iProps as iButtonProps } from '@components/Button/Button';
import cn from 'classnames';

export interface iButtonTabsItem extends iOption {
    buttonProps: FCAdditionalProps & iButtonProps
}

const ButtonTabs: FCX<{
    data: iButtonTabsItem[];
    activeItemId: ID;
    defaultButtonModifier?: ButtonModifiers;
}> = ({
    data,
    activeItemId,
    defaultButtonModifier= 'white',
    style,
}) => {
    return (
        <div
            className="ButtonTabs"
            style={style}
        >
            {data.map((button) => (
                <Button
                    key={button.id}
                    {...button.buttonProps}
                    className={cn(
                        button.buttonProps.className,
                        'ButtonTabs__item',
                    )}
                    modifiers={[
                        ...button.buttonProps.modifiers || [],
                        activeItemId === button.id ? 'blue' : defaultButtonModifier,
                    ]}
                >
                    {button.title}
                </Button>
            ))}
        </div>
    );
};

export default ButtonTabs;