import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { ID } from '@models';

type Request = components['schemas']['SeasonalPresentationExportFilter'];

export function useSeasonalReport() {
    const [isLoading, setIsLoading] = useState(false);

    const load = async (categoryId: ID) => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/PptSeasonal';

        const data: Request = {
            categoryId: categoryId as number,
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}