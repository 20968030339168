import { useMemo } from 'react';
import { iItem } from '@models/Item';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { Tooltip } from 'react-tooltip';
import Popup from '@components/Popup';
import StatusIcon from '@components/StatusIcon';

export function useStatusColumn (data: iItem[]): iTableConfigItem {
    return useMemo(() => ({
        key: 'status',
        title: 'Status',
        Value: ({ index}) => (
            <>
                {!!data[index].status && (
                    <>
                        <div data-tooltip-id={`item-table-${data[index].uuid}-status`}>
                            <StatusIcon
                                status={data[index].status || ''}
                                style={{
                                    width: 20,
                                    height: 20,
                                    color: '#808080',
                                }}
                            />
                        </div>
                        <Popup hasWrapper={false}>
                            <Tooltip
                                id={`item-table-${data[index].uuid}-status`}
                                place={'bottom'}
                                positionStrategy={'fixed'}
                                delayShow={400}
                                style={{ zIndex: 4, fontSize: 14, background: '#141932' }}
                            >
                                {data[index].status}
                            </Tooltip>
                        </Popup>
                    </>
                )}
                {!data[index].status}
            </>
        ),
        styles: {
            cell: {
                justifyContent: 'center',
                flex: '0 0 60px',
            }
        }
    }), [data])
}