import { FC } from 'react';
import Wrapper from '@components/Wrapper';
import { IconLogoDs } from '@svg/index';

const Footer: FC = () => {
    return (
        <div className="Footer">
            <Wrapper>
                <div>
                    © Copyright {new Date().getFullYear()}<br/>
                    All Rights Reserved Concept Testing by Datassential
                </div>
                <IconLogoDs width={200} height={60}/>
            </Wrapper>
        </div>
    )
};

export default Footer;