import { FCX } from '@models';
import { useTableContext } from './TableContext';
import TableCell from './TableCell';
import cn from 'classnames';

const TableRow: FCX<{
    index: number;
}> = ({
    index,
    style,
    className,
    children,
}) => {
    const { config } = useTableContext();
    return (
        <div
            style={style}
            className={cn("TableRow", className)}
        >
            {config.map(item => {
                const {
                    Value,
                    styles,
                    getAttrs,
                    key,
                } = item;
                return (
                    <TableCell
                        key={key}
                        style={styles?.cell}
                        attrs={getAttrs ? getAttrs(index) : undefined}
                    >
                        <Value index={index}/>
                    </TableCell>
                );
            })}
            {children}
        </div>
    );
}

export default TableRow;