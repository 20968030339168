import React from 'react';
import classNames from 'classnames';

import ScoresPlusSection from './ScoresPlusSection';
import { FCX } from '@models';
import { components } from '@api/api';


const CardScoresPlus: FCX<{
	scoresPlus: components["schemas"]["ScoresPlusResult"][];
}> = ({
	className = null,
	scoresPlus = [],
}) => {


	return (
		<div className={classNames('card card-scores-plus', className)}>
			<div className="card-header">
				SCORES+
			</div>			
			<div className="card-content">
				<div className="card-scores-plus-content">
					<div className="card-scores-plus-column">
						{scoresPlus.slice(0, 4).map((section, index) => {
							return (
								<ScoresPlusSection 
									key={index}
									section={section}
								/>
							)
						})}
					</div>
					<div className="card-scores-plus-column">
						{scoresPlus.slice(4,).map((section, index) => {
							return (
								<ScoresPlusSection 
									key={index}
									section={section}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardScoresPlus;