import { FCX, ID, iOption } from '@models';
import SelectCustom from '@components/Select/SelectCustom';
import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { toggleInArray } from '@core/utils/array';
import Toggle from '@components/Toggle';
import { ToggleType } from '@components/Toggle/Toggle';
import Dropdown from '@components/Select/Dropdown';
import { includesInLC } from '@core/utils/string';
import TextInput from '@components/TextInput';
import { COLORS } from '@core/constants';

export interface iOptionWithColor extends iOption {
    color?: string;
}

const SelectOptionsAndColors: FCX<{
    label?: string;
    options: iOptionWithColor[];
    value: ID[];
    setValue: (value: ID[]) => void;
    setColor?: (color: string) => void;
    hasSearch?: boolean;
}> = ({
    options: rawOptions,
    value,
    label,
    setValue,
    setColor,
    hasSearch,
    style,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [query, setQuery] = useState('');

    const options: iOptionWithColor[] = useMemo(() => {
        let result = [...rawOptions];

        if (hasSearch && query.trim() !== '') {
            result = result.filter(o => includesInLC(o.title, query.trim()));
        }

        return [
            ...result.filter(o => !o.isDisabled),
            ...result.filter(o => o.isDisabled),
        ];
    }, [query, rawOptions, hasSearch]);

    const renderDropdownValue = useMemo((): string => {
        if (value.length === 1) {
            const option = options.find(o => value.includes(o.id));
            return option?.title || '';
        } else if (value.length === 0) {
            return 'No filters selected';
        } else if (value.length === options.length) {
            return 'All items';
        }

        return `${value.length} of ${options.length} selected`;
    }, [options, value]);

    const handleOptionClick = useCallback((id: ID) => {
        const newValue = toggleInArray(value, id);
        const firstOptionFromValue = options.find(o => newValue.includes(o.id));
        setValue(newValue);
        if (setColor && firstOptionFromValue && firstOptionFromValue.color) {
            setColor(firstOptionFromValue.color);
        }
    }, [options, value, setValue, setColor]);

    return (
        <SelectCustom
            className="SelectOptionsAndColors"
            label={label || ''}
            value={(
                <div style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {renderDropdownValue}
                </div>
            )}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            style={{
                ...style,
                width: '100%',
                color: '#1a2024',
            }}
        >
            <Dropdown className="SelectOptionsAndColors__dropdown">
                {hasSearch && (
                    <TextInput
                        value={query}
                        setValue={setQuery}
                        placeholder="Type for search"
                        autoFocus
                    />
                )}
                <div className="SelectOptionsAndColors__options">
                    {options.map(option => (
                        <div
                            className={cn(
                                "SelectOptionsAndColors__option",
                                option.isDisabled && 'is-disabled',
                            )}
                        >
                            <Toggle
                                type={ToggleType.Checkbox}
                                isChecked={value.includes(option.id)}
                                onChange={() => handleOptionClick(option.id)}
                                label={option.title}
                                labelPosition="right"
                            />
                        </div>
                    ))}
                </div>
                {!!setColor && (
                    <div className="SelectOptionsAndColors__colors">
                        {COLORS.map((color) => (
                            <div
                                className="SelectOptionsAndColors__color"
                                style={{ backgroundColor: color }}
                                onClick={() => setColor(color)}
                            />
                        ))}
                    </div>
                )}
            </Dropdown>
        </SelectCustom>
    );
};

export default SelectOptionsAndColors;