import { ID } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import { useEffect, useMemo, useState } from 'react';
import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { useCategoryActivityFilters } from '@core/selectors/useCategoryActivityFilters';

type Request = components['schemas']['CategoryLaunchesRequest'];
type Response = components['schemas']['CategoryLaunchesByChainResponse'];

export interface iCategoryActivityChain {
    id: ID;
    name: string;
    segmentId: ID;
    segmentName: string;
    count: number;
    percentage: number;
}

export function useCategoryActivityByChains(categoryId: ID) {
    const {
        options: { chains, segments },
    } = useFiltersContext();

    const [data, setData] = useState<iCategoryActivityChain[]>([]);

    const filter = useCategoryActivityFilters();

    const payload = useMemo((): Request => {
        return {
            filter,
            id: categoryId as number,
        };
    }, [filter, categoryId]);

    const result = useFetch<Response>({
        url: '/api/Category/LaunchesByChain',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading && result.data) {
            setData((result.data.chains || []).map(i => {
                const chainOption = chains.find(o => o.id === i.chainId);
                const segment = segments.find(o => o.id === chainOption?.segmentId);

                return ({
                    id: chainOption?.id || 0,
                    name: chainOption?.title || '',
                    segmentId: segment?.id || 0,
                    segmentName: segment?.title || '',
                    count: i.count || 0,
                    percentage: i.percent || 0,
                });
            }))
        }
        else {
            setData([]);
        }
    }, [result.isLoading, result.data, chains, segments]);

    return {
        isLoading: result.isLoading,
        data,
    }
}