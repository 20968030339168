import { useEffect, useState } from 'react';
import { components, paths } from '@api/api';
import useFetch from '@hooks/useFetch';

type Item = components['schemas']['KeywordIndexViewModelForHistoricTrendByKeyword'];
export interface iFoodFlavorHistoricTrendItem extends Item {
    id: number;
    name: string;
    total: number | null;
    average: number;
}

const url: keyof paths = '/api/FoodFlavor/GetKeyWordsHistoricTrend';

export function useFoodFlavorsHistoricTrend(
    payload: components['schemas']['FoodsFlavorsFilterModel'],
    lazy: boolean = false,
) {
    const [data, setData] = useState<iFoodFlavorHistoricTrendItem[] | null>(null);

    const result = useFetch<Item[]>({
        method: 'POST',
        url,
        payload,
        lazy,
    });

    useEffect(() => {
        if (result.data) {
            setData(result.data.map(item => ({
                id: item.id || 0,
                name: item.name || '',
                groupKeyWordIds: item.groupKeyWordIds,
                data: item.data,
                total: item.totalCount || null,
                average: (item.data || []).reduce((acc, i) => acc + (i.value || 0), 0)/(item.data || []).length,
            })))
        }
    }, [result.data]);
    useEffect(() => {
        if(result.isLoading) {
            setData(null);
        }
    }, [result.isLoading])

    return {
        isLoading: result.isLoading,
        data,
    }
}