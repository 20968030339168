import { FCX } from '@models';
import cn from 'classnames';

const TilesWrapper: FCX = ({
    children,
    style,
    className,
    ...attrs
}) => {
    return (
        <div
            {...attrs}
            className={cn("TilesWrapper", className)}
            style={style}
        >
            {children}
        </div>
    );
}

export default TilesWrapper;