import { FCX } from '@models';

const SelectResetFiltersButtons: FCX<{
    onSelect: () => void;
    onClear: () => void;
}> = ({
    onSelect,
    onClear,
}) => {
    return (
        <>
            <div
                className="FiltersPopup__options-button"
                onClick={(event) => {
                    event.stopPropagation();
                    onSelect();
                }}
            >
                Select All
            </div>
            <div className="FiltersPopup__options-button-separator">|</div>
            <div
                className="FiltersPopup__options-button"
                onClick={(event) => {
                    event.stopPropagation();
                    onClear();
                }}
            >
                Clear All
            </div>
        </>
    )
};

export default SelectResetFiltersButtons;