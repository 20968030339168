import Highcharts from 'highcharts';

export const defaultSettings: Highcharts.Options = {
    chart: {
        height: 500,
        type: 'scatter',
        /* @ts-ignore */
        zoomType: 'xy',
        backgroundColor: '#2E343D',
        marginBottom: 30,
        animation: false,
        resetZoomButton: {
            theme: {
                fill: 'black',
                stroke: 'silver',
                r: 0,
                style: {
                    color: 'white',
                    cursor: 'pointer',
                    'font-family': '"Gotham Book", sans-serif',
                    'z-index': '2000 !important'
                },
                states: {
                    hover: {
                        fill: 'black',
                        style: {
                            color: 'white'
                        }
                    }
                }
            }
        },
        events: {
            render: (event: any) => {
                (event?.target?.series as any[]).forEach(set => {
                    set?.points.forEach((point: any) => {
                        point?.graphic?.element?.setAttribute(
                            'data-tooltip-id',
                            `scatter-chart-item-${point.id}`
                        );
                    });
                });
            }
        }
    },
    title: { text: '' },
    subtitle: { text: '' },
    xAxis: {
        visible: true,

        showLastLabel: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,

        title: { text: '' },
        labels: {
            enabled: true,
            style: {
                color: '#B9BFC7'
            },
        },

        lineWidth: 0,
        tickLength: 0
    },
    yAxis: {
        visible: true,

        showLastLabel: true,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: { text: '' },
        lineWidth: 0,
        tickLength: 0,
        startOnTick: false,
        endOnTick: false,

        labels: {
            style: {
                color: '#B9BFC7'
            },
        },

    },
    legend: { enabled: false },
    tooltip: { enabled: false, },
    plotOptions: {
        scatter: {
            turboThreshold: 0,
            animation: false,
            marker: {
                radius: 2.5,
                states: {
                    hover: {
                        enabled: true,
                        lineColor: "#ffffff",
                        lineWidthPlus: 1
                    },
                    select: {
                        enabled: true,
                        fillColor: 'red',
                        lineColor: undefined,
                        lineWidth: 1,
                        radius: 4
                    }
                },
            },
        },
        series: {
            allowPointSelect: true,
            stickyTracking: false,
            turboThreshold: 0,
            cursor: 'pointer',
            states: {
                inactive: {
                    enabled: false,
                }
            }
        },
    },
    credits: {
        enabled: false
    },

};