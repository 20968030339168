import { FCX } from '@models';
import cn from 'classnames';
import ColorPicker from '@components/ColorPicker';
import { FiTrash2 } from 'react-icons/fi';
import React from 'react';

export const SidebarContentRow: FCX<{
    color: string;
    setColor?: (value: string) => void;
    onDelete?: () => void;
    onColorSet?: (color: string) => void;
    isDisabled?: boolean;
}> = ({
    color,
    setColor,
    isDisabled,
    onDelete,
    children,
}) => {
    return (
        <div className={cn("ChartViewSidebar__row", isDisabled && 'is-disabled')}>
            <div
                className="ChartViewSidebar__color-column"
                style={{ color, }}
            >
                <ColorPicker
                    value={color}
                    setValue={setColor}
                    style={{
                        width: 16,
                        height: 16,
                        borderRadius: 8,
                    }}
                />
            </div>
            <div className="ChartViewSidebar__select-column">
                {children}
            </div>
            <div className="ChartViewSidebar__delete-column">
                {!!onDelete && (
                    <FiTrash2
                        onClick={onDelete}
                        className="ChartViewSidebar__delete-icon"
                    />
                )}
            </div>
        </div>
    )
};