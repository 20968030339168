import { FCX, ID } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import SelectCustom from '@components/Select/SelectCustom';
import cn from 'classnames';
import Dropdown from '@components/Select/Dropdown';
import { useMemo, useState } from 'react';
import Toggle from '@components/Toggle';
import { toggleInArray } from '@core/utils/array';
import { ToggleType } from '@components/Toggle/Toggle';

const SelectKeywordCategories: FCX<{
    value: ID[];
    setValue: (value: ID[]) => void;
    label?: string;
}> = ({
    value,
    setValue,
    label= "Filters",
    style,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const {
        options: {
            keywordCategories,
        },
    } = useFiltersContext();

    const renderDropdownValue = useMemo((): string => {
        if (value.length === 1) {
            const option = keywordCategories.find(o => value.includes(o.id));
            return option?.title || '';
        }
        else if (value.length === 0) {
            return 'No filters selected';
        }
        else if (value.length === keywordCategories.length) {
            return 'All items';
        }

        return `${value.length} of ${keywordCategories.length} selected`;
    }, [keywordCategories, value]);

    return (
        <SelectCustom
            value={renderDropdownValue}
            label={label}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            style={style}
        >
            <Dropdown
                className={cn( "pos-r", "SelectKeywordCategories__dropdown")}
            >
                <div className="SelectKeywordCategories__options">
                    <div
                        onClick={() => setValue(keywordCategories.map(i => i.id))}
                        className="link"
                    >
                        Select All
                    </div>
                    &nbsp;
                    &nbsp;
                    <div
                        onClick={() => setValue([])}
                        className="link"
                    >
                        Clear All
                    </div>
                </div>
                <div className="SelectKeywordCategories__columns">
                    {keywordCategories.map(category => (
                        <Toggle
                            key={category.id}
                            type={ToggleType.Checkbox}
                            isChecked={value.includes(category.id)}
                            onChange={() => setValue(toggleInArray(value, category.id))}
                            label={category.title}
                            labelPosition="right"
                        />
                    ))}
                </div>
            </Dropdown>
        </SelectCustom>
    );
};

export default SelectKeywordCategories;