export enum METRIC_TYPE {
    UNBRANDED_PI = 0,
    BRANDED_PI = 1,
    PRICED_PI = 2,
    UNIQUENESS = 3,
    FREQUENCY = 4,
    DRAW = 5,
    VALUE = 6,
}

export const VIABILITY_GROUP = [{
    index: 5,
    name: 'Superstar',
    description: 'exceptional performance with broad appeal and strong uniqueness',
}, {
    index: 4,
    name: 'Volume Driver',
    description: 'strong interest driven by product appeal and/or price, but not necessarily very unique',
}, {
    index: 3,
    name: 'Specialty Appeal',
    description: 'strong uniqueness but lacks broad appeal; may help target specific audiences',
}, {
    index: 1,
    name: 'Consider',
    description: 'moderate appeal; may benefit from further refinement',
}, {
    index: 2,
    name: 'Low Potential',
    description: 'underperformer overall',
}];

export const DESCRIPTION_METRIC_LIST = [
    'Definitely would buy',
    'Probably would buy',
    'Extremelynew & different',
    'Verynew & different',
    'Yes',
    'Excellentvalue for the dollar',
    'Goodvalue for the dollar',
    'Frequently / all the time'
];