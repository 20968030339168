import React from 'react';
import cn from 'classnames';
import { FCX } from '@models';
import { components } from '@api/api';


const CardDiningPreference: FCX<{
	diningPreference: components["schemas"]["DiningPreference"];
}> = ({
	className = null,
	diningPreference,
}) => {

	const {
		delivered = null,
		toGo = null,
		inRestaurant = null,
	} = diningPreference;


	const getGauge = (name: string, value: number) => {
		return (
			<div className="dining-preference-gauge">
				<div className="dining-preference-gauge__name">{name}</div>
				<div className="dining-preference-gauge__meter">
					<div className="gauge-line-meter" style={{width: `${value * 100}%`}} />
				</div>
				<div className="dining-preference-gauge__value">{value.toLocaleString('en-US',  { style: 'percent' })}</div>
			</div>
		)
	};


	return ( 
		<div className={cn('card', className)}>
			<div className="card-header card-dining-preference-header">
				<div>DINING PREFERENCE</div>
				<div className="card-dining-preference-header__description">% interest in having this item…</div>
			</div>
			<div className="card-dining-preference-content">
				{(delivered !== null) && getGauge('delivered', delivered)}

				{(toGo !== null) && getGauge('to go / drive thru', toGo)}

				{(inRestaurant !== null) && getGauge('in restaurant', inRestaurant,)}
			</div>
		</div>
	);
};

export default CardDiningPreference;