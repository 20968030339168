import { iTableConfigItem } from '@components/Table/TableConfig';
import { useCallback, useMemo, useState } from 'react';
import { iItem, iItemMetrics } from '@models/Item';
import StarsCell from './StarCell';
import { ID, iOption, NormType } from '@models';
import HeaderDpdn from '@vms/ItemsTable/HeaderDpdn';
import { useTableSorting } from '@vms/ItemsTable/TableSortingContext';
import TableTooltip from '@vms/TableTooltip';
import { getNormTypeById, TABLE_TOOLTIPS } from '@core/constants';
import { useFiltersContext } from '@core/FiltersContext';
import MetricMainTooltip from '@components/MetricTooltip/MetricMainTooltip';
import MetricTooltip from '@components/MetricTooltip';

enum PiMetric {
    Unbranded = 1,
    Branded,
    Price,
}
enum AppealMetric {
    Delivery = 1,
    ToGo,
    DineIn,
}

export enum AllMetricsSortingKeys {
    UnbrandedPi = 'unbranded pi',
    BrandedPi = 'branded pi',
    PricePi = 'priced pi',
    Uniqueness = 'Uniqueness',
    Frequency = 'Frequency',
    Draw = 'Draw',
    Value = 'Value',
    Score = 'score',
    Viability = 'viability',
    Delivery = 'Delivered',
    ToGo = 'ToGoDriveThru',
    DineIn = 'InRestaurant',
}

const PI_METRICS: Array<iOption & {
    sortingColName: AllMetricsSortingKeys;
}> = [
    {
        id: PiMetric.Unbranded,
        title: 'Unbranded PI',
        sortingColName: AllMetricsSortingKeys.UnbrandedPi,
    },
    {
        id: PiMetric.Branded,
        title: 'Branded PI',
        sortingColName: AllMetricsSortingKeys.BrandedPi,
    },
    {
        id: PiMetric.Price,
        title: 'Price PI',
        sortingColName: AllMetricsSortingKeys.PricePi,
    },
];

const APPEAL_METRICS: Array<iOption & {
    sortingColName: AllMetricsSortingKeys;
}> = [
    {
        id: AppealMetric.Delivery,
        title: 'Delivery',
        sortingColName: AllMetricsSortingKeys.Delivery,
    },
    {
        id: AppealMetric.ToGo,
        title: 'To-Go',
        sortingColName: AllMetricsSortingKeys.ToGo,
    },
    {
        id: AppealMetric.DineIn,
        title: 'Dine-In',
        sortingColName: AllMetricsSortingKeys.DineIn,
    },
];

export function useMetricColumns (
    data: iItem[],
    getMetrics: (index: number) => iItemMetrics,
    widthScale: number = 1
): iTableConfigItem[] {
    const { value, isVariantMatch } = useFiltersContext();
    const { getIsSorted, onSort, getDirection } = useTableSorting();
    const [selectedPIMetricId, setSelectedPIMetricId] = useState<PiMetric>(PiMetric.Unbranded);
    const [selectedAppealMetricId, setSelectedAppealMetricId] = useState<AppealMetric>(AppealMetric.Delivery);

    const selectedPIMetric = useMemo(() => {
        return PI_METRICS.find(i => i.id === selectedPIMetricId)!;
    }, [selectedPIMetricId]);

    const handlePiOptionSelect = useCallback((id: ID) => {
        if (getIsSorted(selectedPIMetric.sortingColName) && selectedPIMetricId !== id) {
            const newSelectedPIMetric = PI_METRICS.find(i => i.id === id)!;
            onSort(newSelectedPIMetric.sortingColName, getDirection(newSelectedPIMetric.sortingColName));
        }
        setSelectedPIMetricId(id as PiMetric);
    }, [selectedPIMetric, selectedPIMetricId, getDirection, getIsSorted, onSort]);

    const selectedAppealMetric = useMemo(() => {
        return APPEAL_METRICS.find(i => i.id === selectedAppealMetricId)!;
    }, [selectedAppealMetricId]);

    const handleAppealOptionSelect = useCallback((id: ID) => {
        if (getIsSorted(selectedAppealMetric.sortingColName) && selectedAppealMetricId !== id) {
            const newSelectedAppealMetric = APPEAL_METRICS.find(i => i.id === id)!;
            onSort(newSelectedAppealMetric.sortingColName, getDirection(newSelectedAppealMetric.sortingColName));
        }
        setSelectedAppealMetricId(id as AppealMetric);
    }, [selectedAppealMetric, selectedAppealMetricId, getDirection, getIsSorted, onSort]);

    const normType = useMemo((): NormType => {
        return getNormTypeById(isVariantMatch ? value.norm : undefined);
    }, [value, isVariantMatch]);

    return useMemo((): iTableConfigItem[] => {
        return [
            {
                key: `PI-${selectedPIMetricId}`,
                title: (
                    <>
                        <HeaderDpdn
                            options={PI_METRICS}
                            value={[selectedPIMetricId]}
                            onOptionClick={handlePiOptionSelect}
                        />
                        <TableTooltip
                            id={`table-column-pi-${selectedPIMetric.id}`}
                            title={selectedPIMetric.title}
                        >
                            {TABLE_TOOLTIPS.get(selectedPIMetric.title.toLowerCase())}
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-pi-${selectedPIMetric.id}`,
                } : {}),
                sortingColName: selectedPIMetric.sortingColName,
                Value: ({ index }) => {
                    let value: number = 0;
                    let starsCount: number = 0;
                    const metrics = getMetrics(index);
                    switch (selectedPIMetricId) {
                        case PiMetric.Unbranded: {
                            value = metrics.unbrandedValue;
                            starsCount = metrics.unbrandedStarsCount;
                            break;
                        }
                        case PiMetric.Branded: {
                            value = metrics.brandedValue;
                            starsCount = metrics.brandedStarsCount
                            break;
                        }
                        case PiMetric.Price: {
                            value = metrics.priceValue;
                            break;
                        }
                    }
                    return (
                        <>
                            <StarsCell
                                uuid={`table-column-pi-${data[index].uuid}`}
                                value={value}
                                starsCount={starsCount}
                            />
                            <MetricTooltip id={`table-column-pi-${data[index].uuid}`}>
                                <MetricMainTooltip data={data[index]} normType={normType}/>
                            </MetricTooltip>
                        </>
                    )
                },
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 120}px`,
                        justifyContent: 'center',
                    }
                },
            },
            {
                key: 'Uniqueness',
                title: (
                    <>
                        Uniqueness
                        <TableTooltip
                            id={`table-column-uniqueness`}
                            title={'Uniqueness'}
                        >
                            {TABLE_TOOLTIPS.get('uniqueness')}
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-uniqueness`,
                } : {}),
                sortingColName: AllMetricsSortingKeys.Uniqueness,
                Value: ({ index }) => (
                    <>
                        <StarsCell
                            uuid={`table-column-uniqueness-${data[index].uuid}`}
                            value={getMetrics(index).uniquenessValue}
                            starsCount={getMetrics(index).uniquenessStarsCount}
                        />
                        <MetricTooltip id={`table-column-uniqueness-${data[index].uuid}`}>
                            <MetricMainTooltip data={data[index]} normType={normType}/>
                        </MetricTooltip>
                    </>
                ),
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 85}px`,
                        justifyContent: 'center',
                    }
                },
            },
            {
                key: 'Frequency',
                title: (
                    <>
                        Frequency
                        <TableTooltip
                            id={`table-column-frequency`}
                            title={'Frequency'}
                        >
                            {TABLE_TOOLTIPS.get('frequency')}
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-frequency`,
                } : {}),
                sortingColName: AllMetricsSortingKeys.Frequency,
                Value: ({ index }) => (
                    <>
                        <StarsCell
                            uuid={`table-column-frequency-${data[index].uuid}`}
                            value={getMetrics(index).frequencyValue}
                            starsCount={getMetrics(index).frequencyStarsCount}
                        />
                        <MetricTooltip id={`table-column-frequency-${data[index].uuid}`}>
                            <MetricMainTooltip data={data[index]} normType={normType}/>
                        </MetricTooltip>
                    </>
                ),
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 85}px`,
                        justifyContent: 'center',
                    }
                },
            },
            {
                key: 'Draw',
                title: (
                    <>
                        Draw
                        <TableTooltip
                            id={`table-column-draw`}
                            title={'Draw'}
                        >
                            {TABLE_TOOLTIPS.get('draw')}
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-draw`,
                } : {}),
                sortingColName: AllMetricsSortingKeys.Draw,
                Value: ({ index }) => (
                    <>
                        <StarsCell
                            uuid={`table-column-draw-${data[index].uuid}`}
                            value={getMetrics(index).drawValue}
                            starsCount={getMetrics(index).drawStarsCount}
                        />
                        <MetricTooltip id={`table-column-draw-${data[index].uuid}`}>
                            <MetricMainTooltip data={data[index]} normType={normType}/>
                        </MetricTooltip>
                    </>
                ),
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 85}px`,
                        justifyContent: 'center',
                    }
                },
            },
            {
                key: 'Value',
                title: (
                    <>
                        Value
                        <TableTooltip
                            id={`table-column-value`}
                            title={'Value'}
                        >
                            {TABLE_TOOLTIPS.get('value')}
                        </TableTooltip>
                    </>
                ),
                sortingColName: AllMetricsSortingKeys.Value,
                Value: ({ index }) => (
                    <>
                        <StarsCell
                            uuid={`table-column-value-${data[index].uuid}`}
                            value={getMetrics(index).valueValue}
                            starsCount={getMetrics(index).valueStarsCount}
                        />
                        <MetricTooltip id={`table-column-value-${data[index].uuid}`}>
                            <MetricMainTooltip data={data[index]} normType={normType}/>
                        </MetricTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-value`,
                } : {}),
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 85}px`,
                        justifyContent: 'center',
                    }
                },
            },
            {
                key: `appeal-${selectedAppealMetricId}`,
                title: (
                    <>
                        <HeaderDpdn
                            label="appeal for"
                            options={APPEAL_METRICS}
                            value={[selectedAppealMetricId]}
                            onOptionClick={handleAppealOptionSelect}
                        />
                        <TableTooltip
                            id={`table-column-appeal-${selectedAppealMetric.id}`}
                            title={selectedAppealMetric.title}
                        >
                            {TABLE_TOOLTIPS.get(selectedAppealMetric.title.toLowerCase())}
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-appeal-${selectedAppealMetric.id}`,
                } : {}),
                sortingColName: selectedAppealMetric.sortingColName,
                Value: ({ index }) => {
                    let value: number = 0;
                    const metrics = getMetrics(index);

                    switch (selectedAppealMetricId) {
                        case AppealMetric.Delivery: {
                            value = metrics.delivery * 100;
                            break;
                        }
                        case AppealMetric.ToGo: {
                            value = metrics.toGo * 100;
                            break;
                        }
                        case AppealMetric.DineIn: {
                            value = metrics.dineIn * 100;
                            break;
                        }
                    }
                    return (
                        <>
                            <StarsCell
                                value={value}
                                starsCount={0}
                            />
                        </>
                    )
                },
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 100}px`,
                        justifyContent: 'center',
                    }
                },
            },
            {
                key: 'SCORE',
                title: (
                    <>
                        SCORE
                        <TableTooltip
                            id={`table-column-score`}
                            title={'SCORE'}
                        >
                            {TABLE_TOOLTIPS.get('score')}
                        </TableTooltip>
                    </>
                ),
                sortingColName: AllMetricsSortingKeys.Score,
                Value: ({ index }) => <>
                    <div className="font-medium">{getMetrics(index).score || '-'}</div>
                </>,
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 70}px`,
                        background: '#fef9f4',
                        justifyContent: 'center',
                    }
                },
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-score`,
                } : {}),
            },
            {
                key: 'VIABILITY',
                title: (
                    <>
                        VIABILITY
                        <TableTooltip
                            id={`table-column-viability`}
                            title={'VIABILITY'}
                            style={{
                                width: 'auto'
                            }}
                        >
                            <div style={{ textAlign: 'left' }}>
                                <div>SUPERSTAR – exceptional performance with broad appeal and strong uniqueness</div>
                                <div>VOLUME DRIVER – strong interest driven by product appeal and/or price, but not necessarily very unique</div>
                                <div>SPECIALTY APPEAL – strong uniqueness but lacks broad appeal; may help target specific audiences</div>
                                <div>CONSIDER - moderate appeal; may benefit from further refinement</div>
                                <div>LOW POTENTIAL – underperformer overall</div>
                            </div>
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-viability`,
                } : {}),
                sortingColName: AllMetricsSortingKeys.Viability,
                Value: ({ index }) => <>{getMetrics(index).viability || '-'}</>,
                styles: {
                    cell: {
                        flex: `0 0 ${widthScale * 115}px`,
                        background: '#fef9f4',
                        justifyContent: 'center',
                    }
                },
            },

        ];
    }, [
        data,
        normType,
        selectedPIMetricId,
        selectedAppealMetricId,
        getMetrics,
        handleAppealOptionSelect,
        handlePiOptionSelect,
        selectedAppealMetric,
        selectedPIMetric,
        widthScale,
    ]);
}