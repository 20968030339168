import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import HeaderBar from '@components/HeaderBar';
import FiltersPopup from '@vms/FiltersPopup';
import ActionsBar from '@vms/ActionsBar';
import FiltersButton from '@vms/FiltersButton';
import Wrapper from '@components/Wrapper';
import BreadCrumbs from '@components/BreadCrumbs';
import { Route } from '@core/routes/Route';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useChainsData } from '@api/useChainsData';
import VersusScatterChart from '@vms/VersusScatterChart';
import { ID, iOption } from '@models';
import { renderMetricValue } from '@core/utils/string';
import { useChainsChartItems } from '@api/useChainsChartItems';
import { ChartItemType } from '@vms/VersusScatterChart/VersusScatterChart';
import { COLORS } from '@core/constants';
import { getQueryString } from '@core/utils/url';
import { FiTrash2 } from 'react-icons/fi';
import { IconSpinner } from '@svg/index';
import SelectCustomSearch from '@components/Select/SelectCustomSearch';
import Loader from '@components/Loader';
import Button from '@components/Button';
import { FaRegCircleDown } from 'react-icons/fa6';
import { useChainPptReport } from '@api/useChainPptReport';
import { PptFormat } from '@api/usePptReport';

const ChainsChartContent: FC = () => {
    const pptReport = useChainPptReport();
    const { options: { mainMetrics } } = useFiltersContext();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const colorsRef = useRef<Map<ID, string>>(new Map());
    const { chainIds: rawChainIds } = useMemo(() => {
        return qs.parse(search);
    }, [search]);

    const chainsIds: ID[] = useMemo(() => {
        if (!rawChainIds) return [];

        if (Array.isArray(rawChainIds)) {
            return (rawChainIds as string[]).map(i => +i);
        }
        return [+rawChainIds];

    }, [rawChainIds]);

    useEffect(() => {
        const appliedChains: ID[] = Array.from(colorsRef.current.keys());
        const removedChains = appliedChains.filter(id => !chainsIds.includes(id));
        removedChains.forEach((id) => colorsRef.current.delete(id));

        const addedChains = chainsIds.filter(id => !appliedChains.includes(id));
        addedChains.forEach((id) => {
            const appliedColors: ID[] = Array.from(colorsRef.current.values());

            colorsRef.current.set(id, COLORS.filter(color => !appliedColors.includes(color))[0]);
        });
    }, [chainsIds, colorsRef]);

    const { data: chainsData, isLoading: isChainsDataLoading } = useChainsData();
    const { isLoading, data } = useChainsChartItems(chainsIds);
    const [activeMetricIds, setActiveMetricIds] = useState<ID[]>([]);

    const selectedChains = useMemo(() => {
        if (chainsData === null) return [];
        return chainsIds
            .filter(id => chainsData.some(i => i.id === id))
            .map(id => chainsData.find(i => i.id === id)!);
    }, [chainsIds, chainsData]);

    const chainsOptions = useMemo((): iOption[] => {
        return (chainsData || [])
            .filter(i => !chainsIds.includes(i.id as ID))
            .map(i => ({
                id: i.id ?? 0,
                title: i.name ?? '',
            }));
    }, [chainsIds, chainsData]);

    const activeMetrics = useMemo(() => {
        return mainMetrics.filter(metric => activeMetricIds.includes(metric.id));
    }, [activeMetricIds, mainMetrics]);

    const seriesData = useMemo((): ChartItemType[] => {
        return data
            .filter(i => chainsIds.includes(i.chainId as ID))
            .map(i => ({
                ...i,
                color: colorsRef.current.get(i.chainId as ID) || '#fff',
            }));
    }, [data, chainsIds, colorsRef]);

    const addChain = useCallback((id: ID) => {
        navigate(`${pathname}?${getQueryString({
            chainIds: [
                ...chainsIds,
                id,
            ]
        })}`);
    }, [chainsIds, pathname, navigate]);

    const removeChain = useCallback((id: ID) => {
        navigate(`${pathname}?${getQueryString({
            chainIds: chainsIds.filter(i => i !== id),
        })}`);
    }, [chainsIds, pathname, navigate]);

    return (
        <>
            <div className="ChainsChart">
                <Wrapper>
                    <BreadCrumbs items={[
                        { link: Route.Home, title: 'Home', },
                        { link: Route.ChainPerformance, title: 'Chain Performance', },
                        { link: '', title: 'Chart', },
                    ]}/>
                    <HeaderBar className="ChainsChart__header-bar">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="font-medium"
                                style={{ fontSize: 20, lineHeight: '26px', }}
                            >
                                Averages
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{
                                    width: 120,
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                                modifiers={['green']}
                                onClick={() => pptReport.load(chainsIds, activeMetricIds, PptFormat.Format16x9)}
                            >
                                {pptReport.isLoading ? (
                                    <>
                                        Downloading...
                                        <div className="ProgressLoader is-inside-button"/>
                                    </>
                                ) : (
                                    <>
                                        <FaRegCircleDown
                                            className="icon"
                                            style={{ width: 16, height: 16, marginRight: 10 }}
                                        />
                                        PPT 16:9
                                    </>
                                )}
                            </Button>
                        </div>
                    </HeaderBar>
                    <div className="ChainsChart__content">
                        <div className="ChainsChart__chart">
                            {isLoading && <Loader><IconSpinner/></Loader>}
                            <VersusScatterChart
                                data={seriesData}
                                setActiveMetricIds={setActiveMetricIds}
                            />
                        </div>
                        <div className="ChainsChart__sidebar ChainsChartSidebar">
                            {isChainsDataLoading && <Loader><IconSpinner/></Loader>}
                            <div className="ChainsChartSidebar__header">
                                <SelectCustomSearch
                                    options={chainsOptions}
                                    onOptionClick={addChain}
                                    label="Add chain"
                                />
                            </div>
                            <div className="ChainsChartSidebar__content">
                                {selectedChains.map(item => (
                                    <div
                                        key={item.id}
                                        className="ChainsChartSidebar__row"
                                        style={{
                                            borderLeft: `4px solid ${colorsRef.current.get(item.id as ID)}`,
                                        }}
                                    >
                                        <div className="ChainsChartSidebar__row-header">
                                            {item.name}
                                            <FiTrash2
                                                onClick={() => removeChain(item.id as ID)}
                                                className="action-icon"
                                                style={{ color: 'red' }}
                                            />
                                        </div>
                                        <div className="ChainsChartSidebar__metrics">
                                            {activeMetrics.map(metric => (
                                                <div
                                                    key={metric.id}
                                                    className="ChainsChartSidebar__metric"
                                                >
                                                    <div className="ChainsChartSidebar__metric-title">
                                                        Avg {metric.title}
                                                    </div>
                                                    <div className="ChainsChartSidebar__metric-value color-darkyellow">
                                                        {renderMetricValue(item[metric.valueChainField] as number)}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </div>
            <FiltersPopup/>
            <ActionsBar>
                <FiltersButton id="actions-bar-filters-popup-open"/>
            </ActionsBar>
        </>
    )
};

const ChainsChart: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <ChainsChartContent/>;
}

export default ChainsChart;