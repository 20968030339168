import { useFiltersContext } from '@core/FiltersContext';
import { useCallback } from 'react';
import { ID } from '@models';

export function useGetFrequencyString () {
    const { options: { frequencies } } = useFiltersContext();

    return useCallback((frequencyId: ID): string => {
        const frequency = frequencies.find(i => i.id === frequencyId);

        return frequency?.title || '';
    }, [frequencies]);
}