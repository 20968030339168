import { iItem } from '@models/Item';
import { useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import ChainLink from '@vms/ChainLink';
import { ID } from '@models';

export default function useChainAggregatedColumn(
    data: iItem[],
    title: string,
    label?: string,
) {
    return useMemo((): iTableConfigItem => ({
        key: title,
        title: label ? <div style={{ display: 'flex', flexDirection: 'column' }}>
            {title}
            <div
                style={{ fontSize: 12, }}
                className="font-normal"
            >
                {label}
            </div>
        </div> : title,
        sortingColName: title,
        Value: ({ index }) => <>{
            data[index].chainId === null ? (
                data[index].testedFor
            ) : (
                <ChainLink
                    className="link"
                    chainId={data[index].chainId as ID}
                >
                    {data[index].testedFor}
                </ChainLink>
            )
        }</>,
    }), [data, title, label]);
}