import { ID, Season } from '@models';
import { components } from '@api/api';
import useFetch from '@hooks/useFetch';
import { useFiltersContext } from '@core/FiltersContext';
import { useEffect, useState } from 'react';
import { useSeasonalActivityFilters } from '@core/selectors/useSeasonalActivityFilters';

type Result = components['schemas']['SeasonalSegment'][];

export interface iSeasonalSegment {
    id: ID;
    name: string;
    count: number;
}

export function useSeasonalBySegments (season: Season) {
    const [data, setData] = useState<iSeasonalSegment[]>([]);
    const {
        options: { segments },
    } = useFiltersContext();

    const payload = useSeasonalActivityFilters(season);

    const result = useFetch<Result>({
        url: '/api/Seasonal/GetSegments',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading && !!result.data) {
            setData(segments.map(o => {
                const item = result.data!.find(i => o.id === i.id);

                return {
                    id: item?.id || 0,
                    name: o?.title || '',
                    count: item?.count || 0,
                };
            }))
        } else {
            setData([]);
        }
    }, [result.isLoading, result.data, segments]);

    return {
        isLoading: result.isLoading,
        data,
    }
}