import { iChain, ID, iKeyword, iOption, iTableSorting, Sort } from '@models';
import { components, paths } from '@api/api';
import { equalInLC, includesInLC } from '@core/utils/string';
import { diff } from 'deep-object-diff';
import { MainMetrics } from '@models/MainMetrics';
import { getDefaultSortDirection } from '@core/constants';
import { iItemMetricsBase } from '@models/ItemBase';
import { iItemMetrics } from '@models/Item';

type MinMax = {
    min: number;
    max: number;
};

export enum FiltersVariant {
    Homepage = 'home',
    ConceptLocker = 'concepts',
    Chains = 'chains',
    Foods = 'keywords',
    ChainsChart = 'chainsChart',
    ChainProfile = 'chainProfile',
    Seasonal = 'seasonal',
    CategoryActivity = 'categoryActivity',
}

export interface iMainMetric extends iOption {
    id: ID;
    title: MainMetrics;
    valueField: keyof iItemMetricsBase;
    valueChainField: keyof components['schemas']['ChainIndexViewModel'];
    starsField: keyof iItemMetrics;
}

export interface iFilterValue {
    sort: ID;
    date: {
        withinMonth: ID | null;
        season: ID | null;
        period: {
            from: string | null;
            to: string | null;
        };
    };
    tableSort: iTableSorting;
    peopleSort: iTableSorting;
    concepts: ID[];
    segments: ID[];
    isGroceryDeli: boolean;
    isMealKit: boolean;
    categories: ID[];
    statuses: ID[];
    viability: ID[];
    score: MinMax;
    unbrandedStarsCount: MinMax;
    brandedStarsCount: MinMax;
    uniquenessStarsCount: MinMax;
    frequencyStarsCount: MinMax;
    drawStarsCount: MinMax;
    valueStarsCount: MinMax;
    dayPartFit: ID[];
    brandFit: ID[];
    chainId: ID | null;
    among: ID;
    metric: ID | null;
    norm: ID | null;
    search: string | null;
    searchCL: string | null;
    additionalSearch: string | null;
    display: ID;
    includeNewItemsPreview: boolean;
    includeAll: string[];
    includeAny: string[];
    excludeAll: string[];
}

export interface iFilter {
    options: {
        sortAllElements: iOption[];
        sortElements: iOption[];
        sortAllMetrics: iOption[];
        sortMetrics: iOption[];
        dateWithinMonth: iOption[];
        dateSeasons: iOption[];
        concepts: iOption[];
        segments: iOption[];
        otherSegments: iOption[];
        specially: iOption[];
        categories: iOption[];
        statuses: iOption[];
        viability: iOption[];
        dayPartFit: iOption[];
        brandFit: iOption[];
        respondentTypes: iOption[];
        metrics: iOption[];
        norms: iOption[];
        chains: iChain[];
        keywords: iKeyword[];
        totalRespondentType: iOption;
        mainMetrics: iMainMetric[];
        keywordCategories: iOption[];
        frequencies: iOption[];
        presets: iOption[];
    };
    [FiltersVariant.Homepage]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.ConceptLocker]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.Chains]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.ChainsChart]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.Foods]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.ChainProfile]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.Seasonal]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    [FiltersVariant.CategoryActivity]: {
        defaultValue: iFilterValue;
        value: iFilterValue;
    },
    rawData: ApiModel | null;
}
export const path: keyof paths = '/api/Common/GetAll';
export type ApiModel = paths['/api/Common/GetAll']['get']['responses']['200']['content']['application/json'];
export type FilterApiRequestModel = components['schemas']['FilterViewModel'];

interface iData {
    apiModel?: ApiModel | null;
    model?: iFilter;
    isDs?: boolean;
    isFreeUser?: boolean;
}

export const DEFAULT_FILTER_VALUE: iFilterValue = {
    sort: 0,
    date: {
        withinMonth: null,
        season: null,
        period: {
            from: null,
            to: null,
        },
    },
    tableSort: {
        id: 0,
        direction: Sort.Desc,
    },
    peopleSort: {
        id: 0,
        direction: Sort.Desc,
    },
    concepts: [],
    segments: [],
    isGroceryDeli: false,
    isMealKit: false,
    categories: [],
    statuses: [],
    viability: [],
    score: { min: 0, max: 100, },
    unbrandedStarsCount: { min: 1, max: 5 },
    brandedStarsCount: { min: 1, max: 5 },
    uniquenessStarsCount: { min: 1, max: 5 },
    frequencyStarsCount: { min: 1, max: 5 },
    drawStarsCount: { min: 1, max: 5 },
    valueStarsCount: { min: 1, max: 5 },
    dayPartFit: [],
    brandFit: [],
    chainId: null,
    among: 0,
    metric: null,
    norm: null,
    search: null,
    searchCL: null,
    additionalSearch: null,
    display: 0,
    includeNewItemsPreview: false,
    includeAll: [],
    includeAny: [],
    excludeAll: [],
};

export const DEFAULT_FILTER_OPTIONS: iFilter['options'] = {
    sortElements: [],
    sortAllElements: [],
    sortMetrics: [],
    sortAllMetrics: [],
    dateWithinMonth: [],
    dateSeasons: [],
    concepts: [],
    segments: [],
    otherSegments: [],
    specially: [],
    categories: [],
    statuses: [],
    viability: [],
    dayPartFit: [],
    brandFit: [],
    respondentTypes: [],
    metrics: [],
    norms: [],
    chains: [],
    keywords: [],
    totalRespondentType: { id: 0, title: '' },
    mainMetrics: [],
    keywordCategories: [],
    frequencies: [],
    presets: [],
};

export class Filter implements iFilter {
    static defaultData: iFilter = {
        options: DEFAULT_FILTER_OPTIONS,
        [FiltersVariant.Homepage]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.ConceptLocker]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.Chains]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.Foods]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.ChainsChart]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.ChainProfile]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.Seasonal]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        [FiltersVariant.CategoryActivity]: {
            defaultValue: DEFAULT_FILTER_VALUE,
            value: DEFAULT_FILTER_VALUE,
        },
        rawData: null,
    };

    options = Filter.defaultData.options;
    [FiltersVariant.Homepage] = Filter.defaultData[FiltersVariant.Homepage];
    [FiltersVariant.ConceptLocker] = Filter.defaultData[FiltersVariant.ConceptLocker];
    [FiltersVariant.Chains] = Filter.defaultData[FiltersVariant.Chains];
    [FiltersVariant.ChainsChart] = Filter.defaultData[FiltersVariant.ChainsChart];
    [FiltersVariant.Foods] = Filter.defaultData[FiltersVariant.Foods];
    [FiltersVariant.ChainProfile] = Filter.defaultData[FiltersVariant.ChainProfile];
    [FiltersVariant.Seasonal] = Filter.defaultData[FiltersVariant.Seasonal];
    [FiltersVariant.CategoryActivity] = Filter.defaultData[FiltersVariant.CategoryActivity];
    rawData = Filter.defaultData.rawData;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApiModel(data.apiModel, data.isDs, data.isFreeUser);
            }
            if (data.model) {
                this.setData(data.model);
            }
        }
    }

    private setData(model: iFilter) {
        Object.assign(this, model);
    }

    static mapOptionsFromApiModel(options: Array<components["schemas"]["FilterItemModel"]> | null | undefined): iOption[] {
        const subTitleRE = /(\(.*\))/gi;
        return (options || []).map(option => ({
            id: option.id ?? 0,
            title: (option.name ?? '').replace(subTitleRE, ''),
            isDisabled: !(option.isActive ?? true),
            subTitle: ((option.name ?? '').match(subTitleRE) || [])[0] || undefined,
        }));
    }

    static mapRespondentTypesFromApiModel(groups: Array<components["schemas"]["RespondentTypeGroup"]> | null | undefined): iOption[] {
        return (groups || []).map(group => ({
            id: group.id ?? 0,
            title: group.name ?? '',
            subItems: (group.items || []).map(item => ({
                id: item.id ?? 0,
                title: item.name ?? '',
            })),
        }))
    }

    static mapMetricsFromApiModel(groups: iOption[]): iOption[] {
        const options: iOption[] = [];
        const gender = groups?.find(i => includesInLC(i.title || '', 'gender'));
        const generation = groups?.find(i => includesInLC(i.title || '', 'generation'));
        const ethnicity = groups?.find(i => includesInLC(i.title || '', 'ethnicity'));
        const region = groups?.find(i => includesInLC(i.title || '', 'region'));
        const income = groups?.find(i => includesInLC(i.title || '', 'income'));
        const kids = groups?.find(i => includesInLC(i.title || '', 'household'));
        const eatingHabits = groups?.find(i => includesInLC(i.title || '', 'habits'));
        const userType = groups?.find(i => includesInLC(i.title || '', 'user'));

        if (gender || generation) {
            let title = '';

            if (gender && !generation) title = 'Gender';
            else if (!gender && generation) title = 'Generation';
            else title = 'Gender & Generation';

            options.push({
                id: 1,
                title,
                subItems: [
                    ...(gender?.subItems || []),
                    ...(generation?.subItems || []),
                ],
            });
        }

        if (ethnicity || region) {
            let title = '';

            if (ethnicity && !region) title = 'Ethnicity';
            else if (!ethnicity && region) title = 'Region';
            else title = 'Ethnicity & Region';

            options.push({
                id: 2,
                title,
                subItems: [
                    ...(ethnicity?.subItems || []),
                    ...(region?.subItems || []),
                ],
            });
        }

        if (income || kids) {
            let title = '';

            if (income && !kids) title = 'Income';
            else if (!income && kids) title = 'Kids';
            else title = 'Income & Kids';

            options.push({
                id: 3,
                title,
                subItems: [
                    ...(income?.subItems || []),
                    ...(kids?.subItems || []),
                ],
            });
        }

        if (eatingHabits) {
            options.push({
                id: 4,
                title: 'Eating Habits',
                subItems: eatingHabits.subItems,
            });
        }

        if (userType) {
            options.push({
                id: 5,
                title: 'User Type',
                subItems: userType.subItems,
            });
        }

        return options;
    }

    static mapKeywordsFromApiModel(keywords: Array<components['schemas']['KeywordViewModel']> | null | undefined): iKeyword[] {
        return (keywords || []).map(keyword => ({
            id: keyword.id ?? 0,
            title: keyword.name ?? '',
            includes: (keyword.includes || []).map( name  => name ?? ''),
            excludes: (keyword.excludes || []).map( name  => name ?? ''),
        }));
    }

    static mapChainsFromApiModel(chains: Array<components['schemas']['IdNameChainModel']> | null | undefined): iChain[] {
        return (chains || []).map(chain => ({
            id: chain.id ?? 0,
            title: chain.name ?? '',
            segmentId: chain.segmentId ?? 0,
            defaultPresetId: chain.defaultPresetId ?? 0,
        }));
    }

    static mapMainMetricsFromApiModel(sortMetrics: iOption[]): iMainMetric[] {
        const unbrandedPI = sortMetrics.find(i => equalInLC(i.title, 'unbranded pi'));
        const brandedPI = sortMetrics.find(i => equalInLC(i.title, 'branded pi'));
        const uniqueness = sortMetrics.find(i => equalInLC(i.title, 'uniqueness'));
        const frequency = sortMetrics.find(i => equalInLC(i.title, 'frequency'));
        const draw = sortMetrics.find(i => equalInLC(i.title, 'draw'));
        const value = sortMetrics.find(i => equalInLC(i.title, 'value'));
        return [
            {
                id: unbrandedPI?.id ?? 0,
                title: MainMetrics.UnbrandedPI,
                valueField: 'unbrandedValue',
                valueChainField: 'unbrandedPi',
                starsField: 'unbrandedStarsCount',
            },
            {
                id: brandedPI?.id ?? 0,
                title: MainMetrics.BrandedPI,
                valueField: 'brandedValue',
                valueChainField: 'brandedPi',
                starsField: 'brandedStarsCount',
            },
            {
                id: uniqueness?.id ?? 0,
                title: MainMetrics.Uniqueness,
                valueField: 'uniquenessValue',
                valueChainField: 'uniqueness',
                starsField: 'uniquenessStarsCount',
            },
            {
                id: frequency?.id ?? 0,
                title: MainMetrics.Frequency,
                valueField: 'frequencyValue',
                valueChainField: 'frequency',
                starsField: 'frequencyStarsCount',
            },
            {
                id: draw?.id ?? 0,
                title: MainMetrics.Draw,
                valueField: 'drawValue',
                valueChainField: 'draw',
                starsField: 'drawStarsCount',
            },
            {
                id: value?.id ?? 0,
                title: MainMetrics.Value,
                valueField: 'valueValue',
                valueChainField: 'value',
                starsField: 'valueStarsCount',
            },
        ];
    }

    static getSortElements(options: iOption[]): iOption[] {
        const filteredOptions = [
            options.find(i => equalInLC(i.title, 'date')),
            options.find(i => equalInLC(i.title, 'chain')),
            options.find(i => equalInLC(i.title, 'name')),
            options.find(i => equalInLC(i.title, 'price')),
        ];

        return filteredOptions.filter(o => o !== null) as iOption[];
    }

    static getSortMetrics(options: iOption[]): iOption[] {
        const filteredOptions = [
            options.find(i => equalInLC(i.title, 'score')),
            options.find(i => equalInLC(i.title, 'unbranded pi')),
            options.find(i => equalInLC(i.title, 'branded pi')),
            options.find(i => equalInLC(i.title, 'uniqueness')),
            options.find(i => equalInLC(i.title, 'frequency')),
            options.find(i => equalInLC(i.title, 'draw')),
            options.find(i => equalInLC(i.title, 'value')),
        ];

        return filteredOptions.filter(o => o !== null) as iOption[];
    }

    public mapFromApiModel (apiModel: ApiModel, isDs = false, isFreeUser = false) {
        const respondentTypes = Filter.mapRespondentTypesFromApiModel(apiModel.viewOptions?.respondentTypes);
        const sortAllElements = Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.sortBy);
        const sortAllMetrics = Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.metrics);
        const mainMetrics = Filter.mapMainMetricsFromApiModel(sortAllMetrics);

        const options: iFilter['options'] = {
            sortAllElements,
            sortElements: Filter.getSortElements(sortAllElements),
            sortAllMetrics,
            sortMetrics: Filter.getSortMetrics(sortAllMetrics),
            dateWithinMonth: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.timePreset),
            dateSeasons: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.seasons),
            concepts: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.concepts),
            segments: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.segments),
            otherSegments: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.otherSegments),
            specially: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.specially),
            categories: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.categories),
            statuses: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.statuses),
            viability: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.viability),
            dayPartFit: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.dayPart),
            brandFit: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.filterModel?.brandFit),
            respondentTypes: respondentTypes,
            metrics: Filter.mapMetricsFromApiModel(respondentTypes),
            norms: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.normTypes),
            chains: Filter.mapChainsFromApiModel(apiModel.chains),
            keywords: Filter.mapKeywordsFromApiModel(apiModel.keywords),
            totalRespondentType: Filter.defaultData.options.totalRespondentType,
            mainMetrics,
            keywordCategories: Filter.mapOptionsFromApiModel(apiModel.viewOptions?.keywordTypes),
            frequencies: Filter.mapOptionsFromApiModel(apiModel.frequencies),
            presets: Filter.mapOptionsFromApiModel(apiModel.presets),
        };

        options.frequencies.sort((a, b) => {
            if (equalInLC(a.title, 'off')) return 1;

            return -1;
        });

        const totalRespondentTypes = options.respondentTypes.find(o => includesInLC(o.title, 'total'));
        const totalRespondentType = totalRespondentTypes?.subItems?.find(o => includesInLC(o.title, 'total'))!;

        options.totalRespondentType = totalRespondentType;

        const allConceptsIds = options.concepts.map(o => o.id);

        const defaultSortingOption = options.sortElements[0]!;

        const defaultValue: iFilterValue = {
            ...DEFAULT_FILTER_VALUE,
            includeNewItemsPreview: isFreeUser,
            tableSort: {
                id: defaultSortingOption.id,
                direction: getDefaultSortDirection(defaultSortingOption.title),
            },
            peopleSort: {
                id: defaultSortingOption.id,
                direction: getDefaultSortDirection(defaultSortingOption.title),
            },
            sort: options.sortElements[0]?.id || DEFAULT_FILTER_VALUE.sort,
            norm: options.norms[0].id,
            among: totalRespondentType?.id || DEFAULT_FILTER_VALUE.among,
            metric: 1,
            date: {
                ...DEFAULT_FILTER_VALUE.date,
                withinMonth: options.dateWithinMonth[0]?.id ?? null,
            },
            concepts: isDs ? DEFAULT_FILTER_VALUE.concepts : allConceptsIds,
            display: mainMetrics[0].id,
        };

        this.setData({
            ...Filter.defaultData,
            options,
            [FiltersVariant.Homepage]: {
                defaultValue,
                value: defaultValue
            },
            [FiltersVariant.ConceptLocker]: {
                defaultValue: {
                    ...defaultValue,
                    concepts: allConceptsIds,
                },
                value: {
                    ...defaultValue,
                    concepts: allConceptsIds,
                },
            },
            [FiltersVariant.Chains]: {
                defaultValue,
                value: defaultValue
            },
            [FiltersVariant.ChainsChart]: {
                defaultValue,
                value: defaultValue
            },
            [FiltersVariant.Foods]: {
                defaultValue,
                value: defaultValue
            },
            [FiltersVariant.ChainProfile]: {
                defaultValue,
                value: defaultValue
            },
            [FiltersVariant.Seasonal]: {
                defaultValue,
                value: defaultValue
            },
            [FiltersVariant.CategoryActivity]: {
                defaultValue,
                value: defaultValue
            },
            rawData: apiModel,
        });
    }

    static mapForBackEnd (
        value: iFilterValue,
    ): FilterApiRequestModel {
        return {
            date: {
                from: value.date.period.from,
                to: value.date.period.to,
                /* @ts-ignore */
                range: (value.date.withinMonth as number) || 0,
            },
            seasonsList: value.date.season !== null ? [value.date.season as number] : [],
            conceptTypeIds: value.concepts as number[],
            segmentIds: value.segments as number[],
            isGroceryDeli: value.isGroceryDeli,
            isMealKit: value.isMealKit,
            chainId: value.chainId as number,
            categoryIds: value.categories as number[],
            kinds: value.statuses as number[],
            viabilityList: value.viability as number[],
            dayPartList: value.dayPartFit as number[],
            brandFitList: value.brandFit as number[],
            starRatings: {
                scoreMin: value.score.min,
                scoreMax: value.score.max,
                unBrandedMinStar: value.unbrandedStarsCount.min,
                unBrandedMaxStar: value.unbrandedStarsCount.max,
                brandedMinStar: value.brandedStarsCount.min,
                brandedMaxStar: value.brandedStarsCount.max,
                uniqueMinStar: value.uniquenessStarsCount.min,
                uniqueMaxStar: value.uniquenessStarsCount.max,
                frequencyMinStar: value.frequencyStarsCount.min,
                frequencyMaxStar: value.frequencyStarsCount.max,
                drawMinStar: value.drawStarsCount.min,
                drawMaxStar: value.drawStarsCount.max,
                valueMinStar: value.valueStarsCount.min,
                valueMaxStar: value.valueStarsCount.max,
            },
            normType: value.norm as FilterApiRequestModel['normType'],
            search: value.search,
            searchCL: value.searchCL,
            searchAdded: value.additionalSearch,
            itemIds: [],
            onlyScoresItems: !value.includeNewItemsPreview,
            includeAll: value.includeAll,
            includeAny: value.includeAny,
            excludeAll: value.excludeAll,
        };
    }

    static getValueDiff (value: iFilterValue, defaultValue: iFilterValue): Partial<iFilterValue> {
        return diff(defaultValue, value);
    }
}