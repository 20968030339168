import { ID } from '@models';
import { useEffect, useMemo, useState } from 'react';
import { components } from '@api/api';
import useFetch from '@hooks/useFetch';
import { useChainProfileFilters } from '@core/selectors/useChainProfileFilters';

type RequestType = components['schemas']['ChainProfileKeyWordsRequest'];
type ResponseType = components['schemas']['ChainProfileKeyWordsResponse'];
type ItemType = components['schemas']['ChainProfileKeyword'];

export function useChainProfileMostPopularFoodFlavors(chainId: ID) {
    const [data, setData] = useState<ItemType[]>([]);
    const filter = useChainProfileFilters();

    const payload = useMemo((): RequestType => {
        return {
            id: chainId as number,
            filter,
        };
    }, [filter, chainId]);

    const result = useFetch<ResponseType>({
        url: '/api/ChainProfile/KeyWords',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading) {
            setData(result.data?.items || []);
        }
    }, [result.isLoading, result.data])

    return {
        data,
        isLoading: result.isLoading,
    }
}