import { TableContext } from '@components/Table/TableContext';
import { FCX, iChartItemShort, ID, UnitType } from '@models';
import { memo, ReactNode, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { markMatch } from '@core/utils/string';
import { iChainHistoricTrendItem } from '@api/useChainsHistoricTrend';
import { iTimeframe, iValuesByTimeframeData } from '@models/ValuesByTimeframeData';
import { useTimeframeTotalAverageColumns } from '@vms/ItemsTable/useTimeframeTotalAverageColumns';
import TableChartButton from '@vms/ItemsTable/TableChartButton';

export const NAME_CELL_WIDTH = 200;
export const TOTAL_AVG_CELL_WIDTH = 100;
export const REGULAR_CELL_WIDTH = 110;

const defaultStyle: iTableConfigItem['styles'] = {
    cell: {
        justifyContent: 'center',
        flex: `0 0 ${REGULAR_CELL_WIDTH}px`,
        background: '#fff',
    },
};

const TimeframesTableConfig: FCX<{
    data: Array<iChainHistoricTrendItem & iValuesByTimeframeData>;
    availableTimeFrames: iTimeframe[];
    selectedUnit: UnitType;
    searchQuery: string;
    chartItems: iChartItemShort[];
    addChartItem: (id: ID) => void;
    removeChartItem: (id: ID) => void;
    valueFormatter: (value?: number | null) => ReactNode;
}> = ({
    data,
    children,
    availableTimeFrames,
    selectedUnit,
    searchQuery,
    chartItems,
    addChartItem,
    removeChartItem,
    valueFormatter,
}) => {

    const totalAverageColumns = useTimeframeTotalAverageColumns({
        data,
        valueFormatter,
        defaultStyle,
        selectedUnit,
    });

    const config = useMemo((): iTableConfigItem[] => [
        {
            key: 'name',
            title: 'Chain',
            sortingColName: 'name',
            Value: ({ index }) => {
                const chartItem = chartItems.find(i => i.id === data[index].id);
                return (
                    <>
                        <TableChartButton
                            activeItemsCount={chartItems.length}
                            itemId={data[index].id}
                            isActive={!!chartItem}
                            color={chartItem?.color}
                            onClick={() => {
                                if (!!chartItem) removeChartItem(data[index].id);
                                else addChartItem(data[index].id);
                            }}
                        />
                        <div
                            className="lines-3"
                            dangerouslySetInnerHTML={{ __html: markMatch(data[index].name || '', searchQuery) }}
                        />
                    </>
                );
            },
            styles: {
                cell: {
                    ...defaultStyle.cell,
                    flex: `0 0 ${NAME_CELL_WIDTH}px`,
                    position: 'sticky',
                    justifyContent: 'flex-start',
                    left: 16,
                    zIndex: 2,
                    boxShadow: `-${NAME_CELL_WIDTH}px 2px 0 0 #fff`,
                }
            },
        },
            ...totalAverageColumns,
        ...availableTimeFrames.map(frame => ({
            key: frame.id,
            title: frame.title,
            sortingColName: frame.id,
            Value: ({ index }) => (
                <>
                    {valueFormatter(data[index][frame.id])}
                </>
            ),
            styles: defaultStyle,
        }) as iTableConfigItem),
    ], [
        data,
        availableTimeFrames,
        searchQuery,
        addChartItem,
        removeChartItem,
        chartItems,
        valueFormatter,
        totalAverageColumns,
    ]);

    return (
        <TableContext.Provider value={{
            config,
        }}>
            {children}
        </TableContext.Provider>
    );
};

export default memo(TimeframesTableConfig);