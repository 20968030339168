import { FCX, ID, iOption } from '@models';
import { ReactNode, useMemo } from 'react';
import cn from 'classnames';

interface iLabelOption extends iOption {
    icon: ReactNode;
    color: 'green' | 'blue';
}

const Switch: FCX<{
    leftLabel: iLabelOption;
    rightLabel: iLabelOption;
    value: ID;
    onChange: (value: ID) => void;
}> = ({
    leftLabel,
    rightLabel,
    value,
    onChange,
    style,
    className,
}) => {
    const activeLabel = useMemo((): iLabelOption => {
        if (value === rightLabel.id) return rightLabel;
        return leftLabel;
    }, [value, leftLabel, rightLabel])

    const inactiveLabel = useMemo((): iLabelOption => {
        if (value === rightLabel.id) return leftLabel;
        return rightLabel;
    }, [value, leftLabel, rightLabel])

    return (
        <div
            className={cn(
                "Switch",
                className,
            )}
            style={style}
            onClick={() => onChange(inactiveLabel.id)}
        >
            <div className="Switch__label">{leftLabel.title}</div>
            <div className="Switch__control">
                <div
                    className={cn(
                        "Switch__control-thumb",
                        `is-${activeLabel.color}`,
                        value === rightLabel.id && 'is-toggled-on'
                    )}
                >
                    {activeLabel.icon}
                </div>
            </div>
            <div className="Switch__label">{rightLabel.title}</div>
        </div>
    );
};

export default Switch;