import MetricDescriptionTooltipComponent from './MetricDescriptionTooltip';
import MetricScoreTooltipComponent from './MetricScoreTooltip';
import MetricMainTooltipComponent from './MetricMainTooltip';
import MetricTooltip from './MetricTooltip';
import './Tooltips.scss';

export const MetricDescriptionTooltip = MetricDescriptionTooltipComponent;
export const MetricScoreTooltip = MetricScoreTooltipComponent;
export const MetricMainTooltip = MetricMainTooltipComponent;

export default MetricTooltip;