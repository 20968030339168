import { components } from '@api/api';
import dayjs from 'dayjs';
import { getMonthStrByIndex, getSeasonMonthsFull, getSeasonStrByIndex, Season, Timeframe } from '@models/index';

export interface iValuesByTimeframeData {
    [key: string]: any;
}

type ApiModelItem = components['schemas']['IndexViewModelForHistoricTrendData'];
type ApiModel = ApiModelItem[] | null | undefined;

interface iData {
    apiModel: ApiModel;
    timeframe: Timeframe;
}

export interface iTimeframe {
    year: number;
    id: string;
    title: string;
    description?: string;
    normalizedIndex: number;
    indicatorField: keyof ApiModelItem;
}
const MIN_YEAR = 2014;

export default class ValuesByTimeframeData implements ValuesByTimeframeData {
    constructor(data?: iData) {
        if (data) {
            this.mapFromApiModel(data.apiModel, data.timeframe);
        }
    }
    private setValue(model: iValuesByTimeframeData) {
        Object.assign(this, model);
    }

    private mapFromApiModel (apiData: ApiModel, timeframe: Timeframe) {
        if (!!apiData) {
            const result: iValuesByTimeframeData = {};

            ValuesByTimeframeData.getAllTimeframes(timeframe).forEach(({
                year,
                id,
                normalizedIndex,
                indicatorField,
            }) => {
                const value = apiData.find(i => i.year === year && i[indicatorField] === normalizedIndex)?.value;

                result[id] = value ?? null;
            });

            this.setValue(result);
        }
    }

    static getAllTimeframes (timeframe: Timeframe): iTimeframe[] {
        const currentYear = Number(dayjs(new Date()).format('YYYY'));
        const yearsCount = currentYear - MIN_YEAR + 1;

        return Array(yearsCount).fill(null).flatMap((_, index) => {
            const year = MIN_YEAR + index;

            return Array(ValuesByTimeframeData.getTimeframesPerYearCount(timeframe)).fill(null).map((_, frameIndex) => {
                let normalizedIndex: number = frameIndex;
                let indicatorField: keyof ApiModelItem;
                let title: string = '';
                let description: string | undefined;

                switch (timeframe) {
                    case Timeframe.Month: {
                        indicatorField = 'month';
                        title = `${getMonthStrByIndex(normalizedIndex)} ${year}`;
                        normalizedIndex += 1;
                        break;
                    }
                    case Timeframe.Season: {
                        indicatorField = 'season';
                        title = `${getSeasonStrByIndex(normalizedIndex)} ${year}`;
                        description = getSeasonMonthsFull(getSeasonStrByIndex(frameIndex) as Season).join(', ');
                        break;
                    }
                    case Timeframe.Year: {
                        normalizedIndex = year;
                        indicatorField = 'year';
                        title = `${year}`;
                        break;
                    }
                }
                const id = `${year}-${normalizedIndex}`;

                return {
                    id,
                    year,
                    title,
                    description,
                    normalizedIndex,
                    indicatorField,
                }
            });
        }).reverse();
    }

    static getTimeframesPerYearCount (timeframe: Timeframe): number {
        switch (timeframe) {
            case Timeframe.Month: return 12;
            case Timeframe.Season: return 4;
            case Timeframe.Year: return 1;
        }
    }
}