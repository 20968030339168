import { createContext, useContext } from 'react';
import { components, paths } from '@api/api';
import { FCX } from '@models';
import useFetch from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';

type ApiModel = components['schemas']['UserViewModel'];
const path: keyof paths = '/api/User';

export enum UiSettingsKey {
    PageSpecificFoodFlavors = "pageSpecificFoodFlavors",
    PagePerformanceByChain = "pagePerformanceByChain",
    PageChainProfile = "pageChainProfile",
    PageBenchmark = "pageBenchmark",
    PageSeasonalActivity = "pageSeasonalActivity",
    PageCategoryActivity = "pageCategoryActivity",
    PageConceptLocker = "pageConceptLocker",
    PageFoodWatch = "pageFoodWatch",
    UiHomePageBarChartDetails = "uiHomePageBarChartDetails",
    UiHomePageViewSettings = "uiHomePageViewSettings",
    FilterSortByMetrics = "filterSortByMetrics",
    FilterKeyMetrics = "filterKeyMetrics",
    FilterConcepts = "filterConcepts",
    FilterNewItemPreview = "filterNewItemPreview"
}

interface IUserData extends ApiModel {
    isDs: boolean;
    uiSettings: {
        currencySymbol: string;
        hiddenElements: UiSettingsKey[];
    };
}

const DEFAULT_USER_DATA: IUserData = {
    isDs: false,
    id: '',
    companyId: 0,
    companyName: '',
    countries: [],
    firstName: '',
    lastName: '',
    initials: '',
    freeAccess: false,
    fullAccess: true,
    hasConceptLockerAccess: true,
    hasAdminAccess: false,
    uiSettings: {
        currencySymbol: '$',
        hiddenElements: [],
    }
};

const UserContext = createContext<IUserData>(DEFAULT_USER_DATA);

export function useUserContext() {
    return useContext(UserContext);
}

export const UserContextProvider: FCX = ({
    children,
}) => {
    const { isLoading, data } = useFetch<ApiModel>({
        url: getApiLink(path) as keyof paths,
    });

    const isLoaded = !isLoading && data !== null;

    return (
        <UserContext.Provider
            value={
                isLoaded
                    ? {
                        ...data,
                        uiSettings: {
                            currencySymbol: data?.uiSettings?.currencySymbol ?? DEFAULT_USER_DATA.uiSettings.currencySymbol,
                            hiddenElements: (data?.uiSettings?.hiddenElements as UiSettingsKey[]) ?? DEFAULT_USER_DATA.uiSettings?.hiddenElements,
                        },
                        isDs: data?.companyId === 1,
                    }
                    : DEFAULT_USER_DATA
            }
        >
            {children}
        </UserContext.Provider>
    );
};