import { FCX } from '@models';
import React, { useEffect, useState } from 'react';
import { animated, useSpring, easings } from 'react-spring';
import AnimatedNumber from '@components/AnimatedNumber';

const CircleChart: FCX<{
    value: number;
    realValue: number;
}> = ({
    value,
    realValue
}) => {
    const [previousRealValue, setPreviousRealValue] = useState(0);

    const styles = useSpring({
        from: { mask: `conic-gradient(#000 ${previousRealValue}%, #0000 ${previousRealValue}%)` },
        to: { mask: `conic-gradient(#000 ${realValue}%, #0000 ${realValue}%)` },
        config: { duration: 500, easing: easings.easeInOutCubic }
    });

    useEffect(() => {
        return () => {
            setPreviousRealValue(realValue);
        };
    }, [realValue]);

    return (
        <div className="CircleChart">
            <animated.div
                className="CircleChart__line"
                style={styles}
            />
            <div className="CircleChart__value">
                <AnimatedNumber
                    value={value}
                    style={{ display: 'inline'}}
                    durationMs={200}
                />%
            </div>
        </div>
    );
};

export default CircleChart;