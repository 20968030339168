import { FCX } from '@models';
import { iItem, iItemMetrics, Item } from '@models/Item';
import { useCallback, useMemo } from 'react';
import useDateColumn from '@vms/ItemsTable/useDateColumn';
import useChainAggregatedColumn from '@vms/ItemsTable/useChainAggregatedColumn';
import useNameColumn from '@vms/ItemsTable/useNameColumn';
import { useRespondentsColumns } from '@vms/ItemsTable/useRespondentsColumns';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { useFiltersContext } from '@core/FiltersContext';
import { TableContext } from '@components/Table/TableContext';
import { useStatusColumn } from '@vms/ItemsTable/useStatusColumn';

const HomePeopleViewConfig: FCX<{
    data: iItem[];
}> = ({
    data,
    children,
}) => {
    const {
        options: { totalRespondentType },
    } = useFiltersContext();

    const getMetrics = useCallback((index: number): iItemMetrics => {
        return data[index].indices.get(totalRespondentType.id as number) || Item.defaultMetricsData;
    }, [totalRespondentType, data]);

    const dateColumn = useDateColumn(data);
    const chainColumn = useChainAggregatedColumn(data, 'Chain');
    const nameColumn = useNameColumn(data, getMetrics, true);
    const statusColumn = useStatusColumn(data);
    const respondentsColumns = useRespondentsColumns(data);

    const config: iTableConfigItem[] = useMemo(() => {
        return [
            dateColumn,
            chainColumn,
            nameColumn,
            statusColumn,
            ...respondentsColumns,
        ];
    }, [nameColumn, respondentsColumns, dateColumn, chainColumn, statusColumn]);

    return (
        <TableContext.Provider value={{ config }}>
            {children}
        </TableContext.Provider>
    );
};

export default HomePeopleViewConfig;