import { Season } from '@models';
import useFetch from '@hooks/useFetch';
import { useEffect, useState } from 'react';
import { components } from '@api/api';
import { iItem, Item } from '@models/Item';
import { useSeasonalActivityFilters } from '@core/selectors/useSeasonalActivityFilters';
import { useUserContext } from "@core/UserContext";

type Response = components['schemas']['SeasonalRecentlyReleasedItemsResponse'];

export function useSeasonRecentlyReleasedItems(season: Season) {
    const { uiSettings: { currencySymbol }} = useUserContext();
    const [data, setData] = useState<iItem[]>([]);

    const payload = useSeasonalActivityFilters(season);

    const result = useFetch<Response>({
        url: '/api/Seasonal/RecentlyReleasedItems',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading) {
            setData((result.data?.items || []).map(i => new Item({ apiModel: i, currencySymbol })))
        }
    }, [result.isLoading, result.data, currencySymbol]);

    return {
        isLoading: result.isLoading,
        data,
    }
}