import { FCX, ID } from '@models';
import TilesCarousel from '@components/TilesCarousel';
import React, { memo, ReactNode } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import { IconSpinner } from '@svg/index';
import DataFallback from '@components/DataFallback';
import { useCategoryActivityRecentlyReleasedItems } from '@api/useCategoryActivityRecentlyReleasedItems';

const RecentlyReleasedItems: FCX<{
    categoryId: ID;
    renderCategoryName: ReactNode;
    isGlobalLoading: boolean;
}> = ({
    categoryId,
    renderCategoryName,
}) => {
    const { options: { totalRespondentType } } = useFiltersContext();
    const { data, isLoading } = useCategoryActivityRecentlyReleasedItems(categoryId);

    return (
        <div className="flex-row flex-row-details">
            <div className="flex-col-12" style={{ position: 'relative' }}>
                <div className="card-header" style={{ display: 'flex', alignItems: 'center' }}>
                    {renderCategoryName}: Recently Launched Items & LTO's
                </div>
                {isLoading ? (
                    <div style={{
                        width: '100%',
                        height: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <IconSpinner/>
                    </div>
                ) : (
                    <>
                        {data.length > 0 ? (
                            <TilesCarousel
                                key={categoryId}
                                data={data}
                                tileIndexId={totalRespondentType.id}
                            />
                        ) : (
                            <div className="card">
                                <div className="card-content">
                                    <DataFallback
                                        style={{ opacity: isLoading ? 0 : 1 }}
                                        title="No items found"
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(RecentlyReleasedItems);