import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    ApiModel,
    DEFAULT_FILTER_OPTIONS,
    DEFAULT_FILTER_VALUE,
    Filter,
    FiltersVariant,
    iFilter,
    iFilterValue,
    path
} from '@models/Filter';
import useFetch from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import { useLocation, matchPath } from 'react-router-dom';
import { useUserContext } from '@core/UserContext';
import { Route } from '@core/routes/Route';
import { components, paths } from '@api/api';

interface iFilterTemplate {
    id: number;
    routes: Route[];
    variant: FiltersVariant;
}

const FiltersContext = createContext<{
    isLoading: boolean;
    isLoaded: boolean;
    options: iFilter['options'];
    defaultFiltersPayload: components['schemas']['FilterViewModel'];

    variant: FiltersVariant;
    isVariantMatch: boolean;
    value: iFilterValue;
    defaultValue: iFilterValue;
    updateValue: (data: Partial<iFilterValue>) => void;

    getSpecificValue: (variant: FiltersVariant) => iFilterValue;
    updateSpecificValue: (variant: FiltersVariant, data: Partial<iFilterValue>) => void;
    resetSpecificValue: (variant: FiltersVariant, subModel?: Partial<iFilterValue>) => void;
}>({
    isLoading: true,
    isLoaded: false,
    options: DEFAULT_FILTER_OPTIONS,
    value: DEFAULT_FILTER_VALUE,
    defaultValue: DEFAULT_FILTER_VALUE,
    variant: FiltersVariant.Homepage,
    isVariantMatch: false,
    updateValue: () => null,
    updateSpecificValue: () => null,
    getSpecificValue: () => DEFAULT_FILTER_VALUE,
    resetSpecificValue: () => null,
    defaultFiltersPayload: {},
});

const RESET_DATA: Record<FiltersVariant, Partial<iFilterValue>> = {
    [FiltersVariant.Homepage]: {
        chainId: null,
        search: null,
        additionalSearch: null,
    },
    [FiltersVariant.ConceptLocker]: {
        searchCL: null,
    },
    [FiltersVariant.Foods]: {},
    [FiltersVariant.Chains]: {},
    [FiltersVariant.ChainsChart]: {},
    [FiltersVariant.ChainProfile]: {},
    [FiltersVariant.Seasonal]: {},
    [FiltersVariant.CategoryActivity]: {},
};

const FILTER_TEMPLATES: iFilterTemplate[] = [
    {
        id: 1,
        routes: [
            Route.Home,
            Route.HomeViewTable,
            Route.HomeViewPeople,
            Route.HomeViewChart,
        ],
        variant: FiltersVariant.Homepage
    },
    {
        id: 2,
        routes: [
            Route.ConceptLocker,
            Route.ConceptLockerViewTiles,
            Route.ConceptLockerViewPeople,
            Route.ConceptLockerViewChart,
        ],
        variant: FiltersVariant.ConceptLocker,
    },
    {
        id: 3,
        routes: [
            Route.ChainPerformance,
        ],
        variant: FiltersVariant.Chains,
    },
    {
        id: 4,
        routes: [
            Route.FoodsFlavors,
        ],
        variant: FiltersVariant.Foods,
    },
    {
        id: 5,
        routes: [
            Route.ChainsChart,
        ],
        variant: FiltersVariant.ChainsChart,
    },
    {
        id: 6,
        routes: [
            Route.ChainProfile,
        ],
        variant: FiltersVariant.ChainProfile,
    },
    {
        id: 7,
        routes: [
            Route.SeasonalActivity,
        ],
        variant: FiltersVariant.Seasonal,
    },
    {
        id: 8,
        routes: [
            Route.CategoryActivity,
        ],
        variant: FiltersVariant.CategoryActivity,
    },
];

export const FiltersProvider: FC<{
    children: ReactNode;
}> = ({
    children,
}) => {
    const { data: rawData, isLoading } = useFetch<ApiModel>({
        url: getApiLink(path) as keyof paths,
    });

    const location = useLocation();
    const { isDs, freeAccess } = useUserContext();

    const filterTemplate: iFilterTemplate | undefined = useMemo(() => {
        return FILTER_TEMPLATES.find(
            i => i.routes.some(
                route => matchPath(
                    route,
                    location.pathname,
                )
            )
        );
    }, [location]);

    const variant = useMemo((): FiltersVariant => {
        return filterTemplate?.variant || FiltersVariant.Homepage;
    }, [filterTemplate]);

    const [data, setData] = useState<iFilter>(new Filter());

    useEffect(() => {
        if (rawData !== null) {
            setData(new Filter({ apiModel: rawData, isDs, isFreeUser: freeAccess }));
        }
    }, [rawData, isDs, freeAccess]);

    const updateValue = useCallback((subModel: Partial<iFilterValue>) => {
        setData({
            ...data,
            [variant]: {
                ...data[variant],
                value: {
                    ...data[variant].value,
                    ...subModel,
                },
            },
        });
    }, [data, variant]);

    const updateSpecificValue = useCallback((variant: FiltersVariant, subModel: Partial<iFilterValue>) => {
        setData((oldValue) => ({
            ...oldValue,
            [variant]: {
                ...oldValue[variant],
                value: {
                    ...oldValue[variant].value,
                    ...subModel,
                },
            },
        }));
    }, []);

    const getSpecificValue = useCallback((variant: FiltersVariant): iFilterValue => {
        return data[variant].value;
    }, [data]);

    const resetSpecificValue = useCallback((variant: FiltersVariant, subModel?: Partial<iFilterValue>) => {
        updateSpecificValue(variant, {
            ...data[variant].defaultValue,
            ...subModel,
        });
    }, [data, updateSpecificValue]);

    const defaultFiltersPayload: components['schemas']['FilterViewModel'] = useMemo(
        () => Filter.mapForBackEnd(data[variant].value),
        [data, variant]
    );

    useEffect(() => {
        return () => {
            setData((oldData) => ({
                ...oldData,
                [variant]: {
                    ...oldData[variant],
                    value: {
                        ...oldData[variant].value,
                        ...RESET_DATA[variant],
                    },
                },
            }))
        };
    }, [variant]);

    return (
        <FiltersContext.Provider value={{
            options: data.options,
            value: data[variant].value,
            defaultValue: data[variant].defaultValue,
            updateValue,
            updateSpecificValue,
            getSpecificValue,
            resetSpecificValue,
            variant,
            isVariantMatch: !!filterTemplate,
            isLoading: isLoading,
            isLoaded: !isLoading && data.options.mainMetrics.length > 0,
            defaultFiltersPayload,
        }}>
            {children}
        </FiltersContext.Provider>
    );
};

export const FiltersOverridesContext = createContext<Partial<iFilterValue> | undefined>(undefined);

export function useFiltersContext() {
    return useContext(FiltersContext);
}

export function useFiltersOverridesContext() {
    return useContext(FiltersOverridesContext);
}