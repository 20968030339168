import { FCX, getSeasonMonthsFull, Season, Timeframe } from '@models';
import { Scrollbar } from 'react-scrollbars-custom';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { iTimeframesBarChartData } from '@models/TimeframesBarChartData';
import cn from 'classnames';
import { getFormattedThousand } from '@core/utils/number';
import Button from '@components/Button';
import { includesInLC } from '@core/utils/string';
import Bar from '@components/TimeframesBarChart/Bar';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';
import Popup from '@components/Popup';
import { getAxisValues } from '@core/utils/chart';
import { useDefaultSeason } from '@hooks/useDefaultSeason';

const TimeframesBarChart: FCX<{
    data: iTimeframesBarChartData;
    currentView: Timeframe;
    onBarClick?: (id: string) => void;
    selectedBarId?: string;
    scrollToBarId?: string | null;
    valueFormatter?: (value: number) => ReactNode;
    totalValueFormatter?: (value: number) => ReactNode;
    valueSuffix?: string;
    legendMessage?: string;
    selectCurrentSeasonFirst?: boolean;
    showYAxis?: boolean;
    showGroupValue?: boolean;
    onSeasonSelect?: (season: Season) => void;
    variant?: Timeframe;
    renderLabels?: boolean;
    hideLegend?: boolean;
}> = ({
    data,
    currentView,
    onBarClick,
    selectedBarId,
    scrollToBarId,
    valueFormatter = getFormattedThousand,
    totalValueFormatter = getFormattedThousand,
    valueSuffix = '',
    legendMessage = '',
    selectCurrentSeasonFirst = false,
    showYAxis = false,
    showGroupValue= false,
    onSeasonSelect,
    variant,
    renderLabels,
    hideLegend,
}) => {
    const defaultSeason = useDefaultSeason(selectCurrentSeasonFirst);
    const scrollTargetRef = useRef<HTMLDivElement>(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [selectedSeason, setSelectedSeason] = useState<Season>(defaultSeason);
    const [hoveredItemId, setHoveredItemId] = useState<string | undefined>();

    useEffect(() => {
        if (onSeasonSelect) {
            onSeasonSelect(selectedSeason);
        }
    }, [onSeasonSelect, selectedSeason]);

    const viewData = useMemo(() => {
        switch (currentView) {
            case 'year':
                return data.yearView;
            case 'season':
                return data.seasonView;
            default:
                return data.monthView;
        }
    }, [data, currentView]);

    const availableSeasons = useMemo((): Season[] => {
        const flatItems = data.seasonView.flatMap(g => g.values);

        return [Season.Winter, Season.Spring, Season.Summer, Season.Fall].filter(
            (season) => flatItems.some(i => includesInLC(i.id, season) && i.value !== 0)
        );
    }, [data.seasonView]);

    const firstNonEmptyGroupIndex = useMemo(() => {
        return viewData.findIndex((group) => group.values.some(value => value.value !== 0));
    }, [viewData]);

    const scrollToBarGroupIndex = useMemo(() => {
        if (scrollToBarId) {
            return viewData.findIndex(group => group.values.some(value => value.id === scrollToBarId));
        }
        return -1;
    }, [scrollToBarId, viewData]);

    const scrollTargetGroupIndex = useMemo(() => {
        if (scrollToBarGroupIndex >= 0) {
            return scrollToBarGroupIndex;
        }
        return firstNonEmptyGroupIndex;
    }, [scrollToBarGroupIndex, firstNonEmptyGroupIndex]);

    useEffect(() => {
        if (availableSeasons[0] && !selectCurrentSeasonFirst) {
            setSelectedSeason(availableSeasons[0]);
        }
    }, [availableSeasons, selectCurrentSeasonFirst]);

    const maxValue = useMemo(() => {
        return Math.max(...viewData.flatMap(group => group.values.map(item => item.value)));
    }, [viewData]);

    const labels = useMemo(() => {
        return getAxisValues(maxValue);
    }, [maxValue]);

    const getBarHeightStyle = useCallback((value: number): string => {
        const finalMaxValue = currentView === 'month' ? labels[0] : maxValue * 1.1;
        return `${(value / finalMaxValue) * 100}%`;
    }, [maxValue, labels, currentView]);

    useEffect(() => {
        if (scrollTargetRef.current) {
            const parentLeft = (scrollTargetRef.current?.offsetParent as HTMLDivElement).getBoundingClientRect().left;
            const parentWidth = (scrollTargetRef.current?.offsetParent as HTMLDivElement).getBoundingClientRect().width;
            const right = scrollTargetRef.current?.getBoundingClientRect().right;
            setScrollLeft(right - parentLeft - parentWidth + 20);
        }
    }, [scrollTargetRef, scrollTargetGroupIndex]);

    const computedVariant = useMemo(
        () => variant || currentView,
        [variant, currentView]
    );

    useEffect(() => {
        if (selectedBarId && currentView === Timeframe.Season) {
            const correspondingSeason = [
                Season.Winter,
                Season.Spring,
                Season.Summer,
                Season.Fall,
            ].find(season => includesInLC(selectedBarId, season));
            if (correspondingSeason) {
                setSelectedSeason(correspondingSeason);
            }
        }
    }, [selectedBarId, currentView]);

    return (
        <div className={cn("TimeframesBarChart", `variant-${computedVariant}`)}>
            <div className={cn(
                "TimeframesBarChart__viewport",
                showYAxis && 'has-y-axis',
                renderLabels && 'is-gap',
                hideLegend && 'is-no-legend',
            )}>
                {!hideLegend && (
                    <div className="TimeframesBarChart__legend">
                        <div style={{ display: 'flex' }}>
                            {currentView === Timeframe.Season &&
                                [Season.Winter, Season.Spring, Season.Summer, Season.Fall].map((season) => (
                                    <Button
                                        className="TimeframesBarChart__legend-button"
                                        key={season}
                                        modifiers={['white']}
                                        onClick={() => setSelectedSeason(season)}
                                        data-tooltip-id={`bar-chart-season-filter-${season}`}
                                    >
                                        {selectedSeason === season
                                            ? <IoMdRadioButtonOn className="TimeframesBarChart__legend-button-icon"/>
                                            : <IoMdRadioButtonOff className="TimeframesBarChart__legend-button-icon"/>}
                                        {season}
                                        <Popup hasWrapper={false}>
                                            <Tooltip
                                                id={`bar-chart-season-filter-${season}`}
                                                place={'bottom'}
                                            >
                                                {getSeasonMonthsFull(season).join(', ')}
                                            </Tooltip>
                                        </Popup>
                                    </Button>
                                ))
                            }
                        </div>
                        <div className="TimeframesBarChart__legend-message">
                            {legendMessage}
                        </div>
                    </div>
                )}
                {showYAxis && (
                    <div className="TimeframesBarChart__y-axis">
                        {labels.map(label => (
                            <div
                                key={label}
                                className="TimeframesBarChart__y-axis-label"
                            >
                                {getFormattedThousand(label)}{valueSuffix}
                            </div>
                        ))}
                    </div>
                )}
                <Scrollbar
                    onScroll={(data) => {
                        // @ts-ignore
                        setScrollLeft(data.scrollLeft);
                    }}
                    scrollLeft={scrollLeft}
                    rtl
                    noScrollY
                    trackXProps={{
                        renderer: ({ elementRef, style, ...restProps }) => (
                            <div
                                {...restProps}
                                ref={elementRef}
                                style={{
                                    ...style,
                                    transform: `translateY(-${renderLabels ? 60 : 40}px)`,
                                    height: 8,
                                    width: '100%',
                                    left: 0,
                                }}
                            />
                        ),
                    }}
                >
                    <div className={cn(
                        "TimeframesBarChart__view",
                        renderLabels && 'is-gap',
                        hideLegend && 'is-no-legend',
                    )}>
                        <div className="TimeframesBarChart__groups">
                            {viewData.map((group, groupIndex) => (
                                <div
                                    key={group.label}
                                    ref={groupIndex === scrollTargetGroupIndex ? scrollTargetRef : undefined}
                                    className={cn(
                                        "TimeframesBarChart__group",
                                        `variant-${computedVariant}`,
                                        renderLabels && 'is-gap',
                                    )}
                                >
                                    {showGroupValue && group.totalValue !== undefined && (
                                        <div className="TimeframesBarChart__group-value">
                                            {totalValueFormatter(group.totalValue)}
                                        </div>
                                    )}
                                    <div className="TimeframesBarChart__values">
                                        {group.values.map((item) => (
                                            <Bar
                                                key={item.id}
                                                data={item}
                                                valueFormatter={valueFormatter}
                                                onClick={onBarClick ? () => onBarClick(item.id) : undefined}
                                                onMouseEnter={() => setHoveredItemId(item.id)}
                                                onMouseLeave={() => setHoveredItemId(undefined)}
                                                className={`variant-${computedVariant}`}
                                                style={{ height: getBarHeightStyle(item.value) }}
                                                isSelected={selectedBarId === item.id}
                                                isActiveDimmed={!!hoveredItemId && hoveredItemId !== item.id}
                                                isDisabled={!!selectedBarId && selectedBarId !== item.id}
                                                isDimmed={currentView === Timeframe.Season && !includesInLC(item.id, selectedSeason)}
                                                showTooltip={computedVariant === Timeframe.Month && hoveredItemId === item.id}
                                                renderValue={computedVariant !== Timeframe.Month}
                                                renderLabel={renderLabels}
                                            />
                                        ))}
                                    </div>
                                    <div className="TimeframesBarChart__group-label">
                                        {group.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Scrollbar>
            </div>
        </div>
    );
};

export default TimeframesBarChart;