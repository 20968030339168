import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { ID } from '@models';
import { useSeasonalActivityExportFilters } from "@core/selectors/useSeasonalActivityFilters";

export function useSeasonalKeywordsReport(selectedCategoriesIds: ID[]) {
    const [isLoading, setIsLoading] = useState(false);
    const filters = useSeasonalActivityExportFilters(selectedCategoriesIds);

    const load = async () => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/PptSeasonalitySlide';

        await downloadReport(api.request({
            method: 'post',
            url,
            data: filters,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}