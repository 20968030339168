import { FCX, iChain, ID, iOption, UnitType } from '@models';
import HeaderBar from '@components/HeaderBar';
import AnimatedNumber from '@components/AnimatedNumber';
import React, { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import ButtonTabs from '@components/ButtonTabs';
import Select from '@components/Select';
import { useFiltersContext } from '@core/FiltersContext';
import { useChainProfileComparisonData } from '@api/useChainProfileComparisonData';
import ChainComparisonTable from '@pages/ChainProfile/ChainComparisonTable';
import ChainComparisonChart from '@pages/ChainProfile/ChainComparisonChart';
import { ChainProfileComparisonData } from '@models/ChainProfileComparisonData';
import ChainComparisonChainsSelection from '@pages/ChainProfile/ChainComparisonChainsSelection';

export enum ChainComparisonType {
    Preset,
    OtherChains,
}

const ChainComparison: FCX<{
    itemsCount?: number;
    chainId: ID;
    chainName: string;
    isGlobalLoading: boolean;
    selectedUnitType: UnitType;
    selectedPresetId: ID;
    setSelectedPresetId: (id: ID) => void;
    selectedChainsIds: ID[];
    setSelectedChainsIds: (ids: ID[]) => void;
    comparisonType: ChainComparisonType;
    setComparisonType: (type: ChainComparisonType) => void;
    filterDateRangeString: ReactNode;
}> = ({
    itemsCount,
    chainId,
    chainName,
    selectedUnitType,
    selectedPresetId,
    setSelectedPresetId,
    selectedChainsIds,
    setSelectedChainsIds,
    comparisonType,
    setComparisonType,
    filterDateRangeString,
}) => {
    const { options: { presets, chains } } = useFiltersContext();
    const [localComparisonType, setLocalComparisonType] = useState(comparisonType);

    const selectedPreset = useMemo(
        (): iOption | undefined => {
            return presets.find(i => i.id === selectedPresetId);
        },
        [presets, selectedPresetId]
    );
    const selectedChains = useMemo(
        (): iChain[] => {
            return chains.filter(i => selectedChainsIds.includes(i.id));
        },
        [chains, selectedChainsIds]
    );

    const { isLoading, data } = useChainProfileComparisonData(
        chainId,
        comparisonType === ChainComparisonType.Preset ? selectedPresetId : undefined,
        comparisonType === ChainComparisonType.Preset ? undefined : selectedChainsIds,
    );

    useEffect(() => {
        setLocalComparisonType(comparisonType);
    }, [comparisonType]);

    const competitorTitle = useMemo((): string => {
        if (comparisonType === ChainComparisonType.Preset) {
            return selectedPreset?.title || '';
        }
        else if (selectedChains.length > 1) {
            return 'Custom Competitive Set';
        }
        else {
            return selectedChains[0]?.title;
        }
    }, [selectedPreset, selectedChains, comparisonType]);

    const isSingleChainComparison = useMemo(
        () => comparisonType === ChainComparisonType.OtherChains && selectedChainsIds.length === 1,
        [comparisonType, selectedChainsIds]
    );

    const tabsData = useMemo(
        () => [
            {
                id: ChainComparisonType.Preset,
                title: 'Competitive set',
                buttonProps: {
                    onClick: () => {
                        setComparisonType(ChainComparisonType.Preset);
                        setLocalComparisonType(ChainComparisonType.Preset);
                        setSelectedChainsIds([]);
                    },
                },
            },
            {
                id: ChainComparisonType.OtherChains,
                title: 'Specific Chain(s)',
                buttonProps: {
                    onClick: () => setLocalComparisonType(ChainComparisonType.OtherChains),
                },
            },
        ],
        [setComparisonType, setSelectedChainsIds]
    );

    const chainsIdsList = useMemo(
        (): ID[] => {
            if (!!data) {
                if (comparisonType === ChainComparisonType.Preset) {
                    const result = data.otherChains.map(i => i.id);

                    if (data.isCurrentChainIncluded) {
                        result.push(data.currentChain.id)
                    }

                    return result;
                }
                return selectedChainsIds;
            }

            return [];
        },
        [comparisonType, data],
    );

    return (
        <>
            <HeaderBar style={{
                marginBottom: 20,
                height: 70,
                position: 'relative',
                zIndex: 3,
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            fontSize: 30,
                            marginRight: 15,
                        }}
                        className="font-caption color-dimyellow"
                    >
                        {itemsCount !== undefined && (
                            <AnimatedNumber value={itemsCount}/>
                        )}
                    </div>
                    <div className="header-sub-title" style={{ display: 'block' }}>
                        Launched items & LTO's at&nbsp;
                        <div className="color-dimyellow" style={{ display: 'inline' }}>{chainName}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ textAlign: 'right' }}>
                        Compare {chainName} to:
                    </div>
                    <ButtonTabs
                        style={{ marginLeft: 10 }}
                        data={tabsData}
                        activeItemId={localComparisonType}
                    />
                    {localComparisonType === ChainComparisonType.Preset && (
                        <Select
                            style={{ marginLeft: 10, height: 40 }}
                            options={presets}
                            value={[selectedPresetId]}
                            onOptionClick={setSelectedPresetId}
                            closeOnOptionClick
                            hasSearch
                            className="width-l"
                        />
                    )}
                    {localComparisonType === ChainComparisonType.OtherChains && (
                        <ChainComparisonChainsSelection
                            chainId={chainId}
                            value={selectedChainsIds}
                            setValue={(newValue) => {
                                setSelectedChainsIds(newValue);
                                setComparisonType(ChainComparisonType.OtherChains);
                            }}
                            onEmptyApply={() => {
                                setSelectedChainsIds([]);
                                setComparisonType(ChainComparisonType.Preset);
                                setLocalComparisonType(ChainComparisonType.Preset);
                            }}
                        />
                    )}
                </div>
            </HeaderBar>
            <ChainComparisonChart
                isLoading={isLoading}
                groupColumnName={competitorTitle}
                chainName={chainName}
                chainId={chainId}
                data={data || new ChainProfileComparisonData()}
                unitType={selectedUnitType}
                isSingleChainComparison={isSingleChainComparison}
                selectedChainsIds={chainsIdsList}
                filterDateRangeString={filterDateRangeString}
            />
            <ChainComparisonTable
                isLoading={isLoading}
                groupColumnName={competitorTitle}
                chainName={chainName}
                chainId={chainId}
                data={data}
                unitType={selectedUnitType}
                isSingleChainComparison={isSingleChainComparison}
                filterDateRangeString={filterDateRangeString}
            />
        </>
    );
};

export default memo(ChainComparison);