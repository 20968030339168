import { useEffect, useMemo, useState } from 'react';
import { FCX, ID, Sort } from '@models';
import Table from '@components/Table';
import { useFiltersContext } from '@core/FiltersContext';
import { useItems } from '@api/useItems';
import TableViewSorting from '@vms/ItemsTable/TableViewSorting';
import HomeTableViewConfig from '@vms/ItemsTable/HomeTableViewConfig';
import { components } from '@api/api';
import { FilterApiRequestModel } from '@models/Filter';
import { CONCEPT_LOCKER_INDICATOR_COLOR } from '@core/constants';

const HomeTableView: FCX<{
    isTopChartOpened?: boolean;
    setTotalRecords: (value: number) => void;
    setAppliedFilterPayload: (value: components['schemas']['SearchFilterModel']) => void;
}> = ({
    isTopChartOpened,
    setTotalRecords,
    setAppliedFilterPayload,
}) =>  {
    const {
        value: { among, concepts, tableSort },
        defaultFiltersPayload,
    } = useFiltersContext();

    const [sortingColId, setSortingColId] = useState<ID>(tableSort.id);
    const [sortingDirection, setSortingDirection] = useState<Sort>(tableSort.direction);

    const filtersPayload = useMemo((): components['schemas']['SearchFilterModel'] => {
        return {
            filter: {
                ...defaultFiltersPayload,
                respondentTypes: [among] as FilterApiRequestModel['respondentTypes'],
                conceptTypeIds: [1, ...concepts] as number[],
            },
            page: {
                sortId: sortingColId as number,
                sortDirection: sortingDirection,
            }
        };
    }, [defaultFiltersPayload, among, concepts, sortingColId, sortingDirection]);

    const {
        data,
        totalRecords,
        fetchMore,
        isLoading,
        copyTableData,
        isCopyTableDataLoading,
    } = useItems(filtersPayload, 60, true);

    useEffect(() => {
        if (!isLoading) {
            setTotalRecords(totalRecords || 0);
        }
    }, [isLoading, totalRecords, setTotalRecords]);

    useEffect(() => {
        setAppliedFilterPayload(filtersPayload);
    }, [filtersPayload, setAppliedFilterPayload]);

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: -16,
                marginBottom: 8,
            }}>
                <div style={{
                    position: 'relative',
                    marginLeft: 12,
                }}>
                    <div
                        className="Indicator"
                        style={{ background: CONCEPT_LOCKER_INDICATOR_COLOR }}
                    />
                    Concept Locker item
                </div>
            </div>
            <TableViewSorting
                sortingColId={sortingColId}
                setSortingColId={setSortingColId}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
            >
                <HomeTableViewConfig data={data}>
                    <Table
                        scrollerKey={isTopChartOpened}
                        rowsCount={data.length}
                        onLoadMore={fetchMore}
                        isLoading={isLoading}
                        copyTable={{
                            data: copyTableData,
                            isLoading: isCopyTableDataLoading,
                        }}
                    />
                </HomeTableViewConfig>
            </TableViewSorting>
        </>
    );
}

export default HomeTableView;