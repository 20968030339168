import { FCX, ID } from '@models';
import Button from '@components/Button';
import DataFallback from '@components/DataFallback';
import React, { useMemo, useState } from 'react';
import PlatesSelect from '@components/PlatesSelect';
import { useFiltersContext } from '@core/FiltersContext';
import EditCategoriesPopup from '@pages/FoodWatchItem/EditCategoriesPopup';

const Categories: FCX<{
    value: ID[];
    setValue: (value: ID[]) => void;
}> = ({
    value,
    setValue,
}) => {
    const { options: { categories } } = useFiltersContext();
    const [isPopupOpened, setIsPopupOpened] = useState(false);

    const selectedItems = useMemo(
        () => value.map(id => categories.find(i => i.id === id)!),
        [value, categories]
    );

    return (
        <div className="card">
            <div
                className="card-header"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <div className="font-medium">
                    Track Categories
                </div>
                <div style={{ display: 'flex' }}>
                    {value.length > 0 && (
                        <Button
                            modifiers={['blue', 'naked']}
                            onClick={() => setValue([])}
                            style={{ marginRight: 10 }}
                        >
                            Reset All Selections
                        </Button>
                    )}
                    <Button
                        modifiers={['blue']}
                        onClick={() => setIsPopupOpened(true)}
                    >
                        EDIT CATEGORY SELECTIONS
                    </Button>
                </div>
            </div>
            <div className="card-content">
                {value.length > 0 ? (
                    <PlatesSelect
                        value={[]}
                        options={selectedItems}
                        onOptionClick={(id) => setValue(value.filter(i => i !== id))}
                        isGenericItemWidth
                        isTagDecoration
                    />
                ) : (
                    <DataFallback
                        title=""
                        subTitle="No categories added yet."
                    />
                )}
            </div>
            {isPopupOpened && (
                <EditCategoriesPopup
                    value={value}
                    setValue={setValue}
                    onClose={() => setIsPopupOpened(false)}
                />
            )}
        </div>
    );
};

export default Categories;