import { TableContext } from '@components/Table/TableContext';
import { FCX, ID } from '@models';
import { components } from '@api/api';
import { memo, useCallback, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { getFormattedThousand } from '@core/utils/number';
import TableTooltip from '@vms/TableTooltip';
import { TABLE_TOOLTIPS } from '@core/constants';
import StarsCell from '@vms/ItemsTable/StarCell';
import { Columns } from './TotalTable';
import { markMatch } from '@core/utils/string';
import TableRowCheck from '@components/Table/TableRowCheck';
import { useFiltersContext } from '@core/FiltersContext';
import { MainMetrics } from '@models/MainMetrics';
import ChainLink from '@vms/ChainLink';

function getDefaultStyle(isActive?: boolean): iTableConfigItem['styles'] {
    return {
        cell: {
            justifyContent: 'center',
            backgroundColor: isActive ? '#fffae5' : undefined,
            flex: '0 0 10%',
        },
    };
}

const TotalTableConfig: FCX<{
    data: components['schemas']['ChainIndexViewModel'][];
    searchQuery: string;
    isSelectionEnabled: boolean;
    setIsSelectionEnabled: (value: boolean) => void;
    highlightedMetricId: ID;
}> = ({
    data,
    children,
    searchQuery,
    isSelectionEnabled,
    setIsSelectionEnabled,
    highlightedMetricId,
}) => {
    const {
        options: { mainMetrics },
        value: {
            among,
            date,
            categories,
            segments,
        },
    } = useFiltersContext();

    const enableSelection = useCallback(() => {
        setIsSelectionEnabled(true);
    }, [setIsSelectionEnabled]);

    const [
        unbrandedPIId,
        brandedPIId,
        uniquenessId,
        frequencyId,
        drawId,
        valueId,
    ] = useMemo(() => {
        return [
            mainMetrics.find(i => i.title === MainMetrics.UnbrandedPI)?.id,
            mainMetrics.find(i => i.title === MainMetrics.BrandedPI)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Uniqueness)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Frequency)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Draw)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Value)?.id,
        ];
    }, [mainMetrics]);

    const config = useMemo((): iTableConfigItem[] => [
        {
            key: Columns.Name,
            title: 'Chain',
            sortingColName: Columns.Name,
            Value: ({ index }) => (
                <>
                    <TableRowCheck
                        option={{
                            id: data[index].id || 0,
                            title: data[index].name || '',
                        }}
                        isPermanentlyShown={isSelectionEnabled}
                        onToggle={enableSelection}
                    />
                    <ChainLink
                        chainId={data[index].id}
                        className="link"
                        additionalFilters={{
                            among,
                            date,
                            categories,
                            segments,
                        }}
                    >
                        <div
                            className="lines-3"
                            dangerouslySetInnerHTML={{ __html: markMatch(data[index].name || '', searchQuery) }}
                        />
                    </ChainLink>
                </>
            ),
        },
        {
            key: Columns.ItemsCount,
            title: 'Total items',
            sortingColName: Columns.ItemsCount,
            Value: ({ index }) => (
                <>{getFormattedThousand(data[index].itemsCount)}</>
            ),
            styles: getDefaultStyle(),
        },
        {
            key: Columns.UnbrandedPi,
            title: (<>
                Unbranded PI
                <TableTooltip
                    title="Unbranded PI"
                    id="chains-table-unbranded-pi"
                >
                    {TABLE_TOOLTIPS.get('unbranded pi')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'chains-table-unbranded-pi'
            } : {})),
            sortingColName: Columns.UnbrandedPi,
            Value: ({ index }) => (
                <StarsCell value={data[index].unbrandedPi || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === unbrandedPIId),
        },
        {
            key: Columns.BrandedPi,
            title: (<>
                Branded PI
                <TableTooltip
                    title="Branded PI"
                    id="chains-table-branded-pi"
                >
                    {TABLE_TOOLTIPS.get('branded pi')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'chains-table-branded-pi'
            } : {})),
            sortingColName: Columns.BrandedPi,
            Value: ({ index }) => (
                <StarsCell value={data[index].brandedPi || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === brandedPIId),
        },
        {
            key: Columns.Uniqueness,
            title: (<>
                Uniqueness
                <TableTooltip
                    title="Uniqueness"
                    id="chains-table-uniqueness"
                >
                    {TABLE_TOOLTIPS.get('uniqueness')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'chains-table-uniqueness'
            } : {})),
            sortingColName: Columns.Uniqueness,
            Value: ({ index }) => (
                <StarsCell value={data[index].uniqueness || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === uniquenessId),
        },
        {
            key: Columns.Frequency,
            title: (<>
                Frequency
                <TableTooltip
                    title="Frequency"
                    id="chains-table-frequency"
                >
                    {TABLE_TOOLTIPS.get('frequency')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'chains-table-frequency'
            } : {})),
            sortingColName: Columns.Frequency,
            Value: ({ index }) => (
                <StarsCell value={data[index].frequency || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === frequencyId),
        },
        {
            key: Columns.Draw,
            title: (<>
                Draw
                <TableTooltip
                    title="Draw"
                    id="chains-table-draw"
                >
                    {TABLE_TOOLTIPS.get('draw')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'chains-table-draw'
            } : {})),
            sortingColName: Columns.Draw,
            Value: ({ index }) => (
                <StarsCell value={data[index].draw || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === drawId),
        },
        {
            key: Columns.Value,
            title: (<>
                Value
                <TableTooltip
                    title="Value"
                    id="chains-table-value"
                >
                    {TABLE_TOOLTIPS.get('value')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'chains-table-value'
            } : {})),
            sortingColName: Columns.Value,
            Value: ({ index }) => (
                <StarsCell value={data[index].value || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === valueId),
        },
    ], [
        data,
        searchQuery,
        isSelectionEnabled,
        enableSelection,
        highlightedMetricId,
        unbrandedPIId,
        brandedPIId,
        uniquenessId,
        frequencyId,
        drawId,
        valueId,
        among,
        date,
        categories,
        segments,
    ]);

    return (
        <TableContext.Provider value={{
            config,
        }}>
            {children}
        </TableContext.Provider>
    );
};

export default memo(TotalTableConfig);