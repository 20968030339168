import { FCX, ID, iOption } from '@models';
import { useState, memo, useRef } from 'react';
import cn from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Dropdown from '@components/Select/Dropdown';
import Options from '@components/Select/Options';
import useOutsideClick from '@hooks/useOutsideClick';

const HeaderDpdn: FCX<{
    options: iOption[];
    value: ID[];
    label?: string;
    onOptionClick: (id: ID) => void;
}> = ({
    options,
    value,
    onOptionClick,
    label,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const ref = useRef(null);
    useOutsideClick({
        ref,
        fn: () => setIsOpened(false),
    });

    return (
        <div
            className={cn("Select", isOpened && 'is-opened')}
            style={{ width: 'auto', height: 'auto' }}
        >
            <div
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {!!label && (
                    <div
                        className="font-normal"
                        style={{
                            position: 'absolute',
                            bottom: 18,
                            fontSize: 12,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {label}
                    </div>
                )}
                {options.find(i => value.includes(i.id))?.title}
                <div onClick={(event) => {
                    event.stopPropagation();
                    setIsOpened(!isOpened)
                }} ref={ref}>
                    <MdKeyboardArrowDown
                        style={{ width: 20, height: 20, }}
                    />
                    <Dropdown className="w-auto" style={{ whiteSpace: 'nowrap' }}>
                        <Options
                            data={options}
                            value={value}
                            isButtonsStyle
                            onOptionClick={(id) => {
                                setIsOpened(false);
                                onOptionClick(id);
                            }}
                        />
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default memo(HeaderDpdn);