import { useEffect, useMemo, useState } from 'react';
import { FCX, ID, Sort } from '@models';
import Table from '@components/Table';
import { useFiltersContext } from '@core/FiltersContext';
import { FilterApiRequestModel } from '@models/Filter';
import { useItems } from '@api/useItems';
import CLTableViewConfig from '@vms/ItemsTable/CLTableViewConfig';
import TableViewSorting from '@vms/ItemsTable/TableViewSorting';
import { components } from '@api/api';

const CLTableView: FCX<{
    setTotalRecords?: (value: number) => void;
    setAppliedFilterPayload?: (value: components['schemas']['SearchFilterModel']) => void;
}> = ({
    setTotalRecords= () => null,
    setAppliedFilterPayload= () => null,
}) =>  {
    const {
        value: { among, concepts, tableSort },
        defaultFiltersPayload,
    } = useFiltersContext();

    const [sortingColId, setSortingColId] = useState<ID>(tableSort.id);
    const [sortingDirection, setSortingDirection] = useState<Sort>(tableSort.direction);

    const filtersPayload = useMemo((): components['schemas']['SearchFilterModel'] => {
        return {
            filter: {
                ...defaultFiltersPayload,
                respondentTypes: [among] as FilterApiRequestModel['respondentTypes'],
                conceptTypeIds: concepts as number[],
            },
            page: {
                sortId: sortingColId as number,
                sortDirection: sortingDirection,
            }
        };
    }, [defaultFiltersPayload, among, concepts, sortingColId, sortingDirection]);

    const {
        data,
        totalRecords,
        fetchMore,
        isLoading,
        copyTableData,
        isCopyTableDataLoading,
    } = useItems(filtersPayload, 120, true);

    useEffect(() => {
        if (!isLoading) {
            setTotalRecords(totalRecords || 0);
        }
    }, [isLoading, totalRecords, setTotalRecords]);

    useEffect(() => {
        setAppliedFilterPayload(filtersPayload);
    }, [filtersPayload, setAppliedFilterPayload]);

    return (
        <TableViewSorting
            sortingColId={sortingColId}
            setSortingColId={setSortingColId}
            sortingDirection={sortingDirection}
            setSortingDirection={setSortingDirection}
        >
            <CLTableViewConfig data={data}>
                <Table
                    rowsCount={data.length}
                    onLoadMore={fetchMore}
                    isLoading={isLoading}
                    copyTable={{
                        data: copyTableData,
                        isLoading: isCopyTableDataLoading,
                    }}
                />
            </CLTableViewConfig>
        </TableViewSorting>
    );
}

export default CLTableView;