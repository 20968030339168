import { useMemo } from 'react';
import dayjs from 'dayjs';
import { components } from '@api/api';
import { iFilterValue } from '@models/Filter';

export function useFromToOverrides (
    filtersPayload: components['schemas']['SearchFilterModel'],
    filtersOverrides?: Partial<iFilterValue>
) {
    return useMemo(() => {
        if (!filtersOverrides) return undefined;
        const filterFrom = filtersPayload.filter?.date?.from;
        const filterTo = filtersPayload.filter?.date?.to;
        const overrideFrom = filtersOverrides?.date?.period.from;
        const overrideTo = filtersOverrides?.date?.period.to;

        let from, to;

        if (!filterFrom || !overrideFrom) {
            from = filterFrom || overrideFrom;
        } else {
            from = dayjs(Math.max(dayjs(filterFrom).valueOf(), dayjs(overrideFrom).valueOf())).format('MMM YYYY');
        }

        if (!filterTo || !overrideTo) {
            to = filterTo || overrideTo;
        } else {
            to = dayjs(Math.min(dayjs(filterTo).valueOf(), dayjs(overrideTo).valueOf())).format('MMM YYYY');
        }

        return { from, to };
    }, [filtersPayload, filtersOverrides])
}