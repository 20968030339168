import React from 'react';
import classNames from 'classnames';

import Preloader from '@components/Preloader';

import { FCX } from '@models';
import { MdCheck } from 'react-icons/md';

const CardObservations: FCX<{
	data: string[];
	isLoading: boolean;
}> = ({
	className = null,
	data = [],
	isLoading = false,
}) => {

	return (
		<div className={classNames('card card-observations', className)}>
			<div className="card-observations-aside">
				<div className="card-observations-aside__caption">Observations</div>
			</div>
			{isLoading ? (
				<div className="card-observations-content">
					<Preloader 
						backgroundColor="#ffffff"
					/>
				</div>
				) : (
				<div className="card-observations-content">
					{data.map((item, index) => {
						return (
							<div key={index} className="card-observations-item">
								<MdCheck className="card-observations-item__icon"/>
								<span dangerouslySetInnerHTML={{ __html: item }} />
							</div>
						)
					})}
				</div>
			)}
		</div>
	);
};

export default CardObservations;