import { FCX } from '@models';
import cn from 'classnames';

const DataFallback: FCX<{
    title: string | null;
    subTitle?: string | null;
}> = ({
    title= 'Your search conditions do not match any results.',
    subTitle,
    style,
    className,
    children,
}) => {
    return (
        <div
            className={cn("DataFallback", className)}
            style={style}
        >
            {!!title && (
                <div className="DataFallback__title">
                    {title}
                </div>
            )}
            {!!subTitle && (
                <div className="DataFallback__sub-title">
                    {subTitle}
                </div>
            )}
            {children}
        </div>
    );
};

export default DataFallback;