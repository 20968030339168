import { FCX } from '@models';
import cn from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { ReactNode, useRef } from 'react';
import useOutsideClick from '@hooks/useOutsideClick';

const SelectCustom: FCX<{
    label: string;
    value: ReactNode | undefined;
    placeholder?: string;
    closeOnChange?: boolean;
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
    isDisabled?: boolean;
    isSearch?: boolean;
}> = ({
    label,
    value,
    placeholder= 'Select',
    isOpened,
    setIsOpened,
    className,
    style,
    isDisabled,
    children,
    ...attrs
}) => {
    const ref = useRef(null);
    useOutsideClick({ ref, fn: () => setIsOpened(false) });

    return (
        <div
            {...attrs}
            className={cn(
                'Select',
                'SelectCustom',
                isOpened && 'is-opened',
                isDisabled && 'is-disabled',
                !!label && 'is-with-label',
                className,
            )}
            style={style}
            ref={ref}
        >
            <div
                className="Select__thumb SelectCustom__thumb"
                onClick={() => setIsOpened(!isOpened)}
                style={{ color: style?.color }}
            >
                {!!label && (
                    <div
                        style={{ color: style?.color }}
                        className="SelectCustom__label"
                    >
                        {label}
                    </div>
                )}
                {value || placeholder}
                {!isDisabled && (
                    <div className="Select__arrow">
                        <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                    </div>
                )}
            </div>
            {children}
            <div className="Select__dropdown-triangle"/>
        </div>
    );
};

export default SelectCustom;