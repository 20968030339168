import { createContext, useCallback, useContext, useEffect } from 'react';
import { FCX, iOption } from '@models';
import { includesInLC } from '@core/utils/string';


export interface iSelectOption extends iOption {
    isBlocked?: boolean;
}

const SelectedOptionsContext = createContext<{
    items: iSelectOption[];
    toggleItem: (item: iSelectOption) => void;
} | undefined>(undefined);

export function useSelectedOptions() {
    const ctx = useContext(SelectedOptionsContext);

    if (ctx === undefined) {
        return {
            items: [],
            toggleItem: () => null,
        };
    }

    return ctx;
}

export const SelectedOptionsProvider: FCX<{
    value: iSelectOption[];
    setValue: (value: iSelectOption[]) => void;
}> = ({
    children,
    value,
    setValue,
}) => {
    const onItemSelect = useCallback((data: iSelectOption) => {
        const itemIndex = value.findIndex(i => i.id === data.id);
        if (itemIndex >= 0) {
            setValue([
                ...value.slice(0, itemIndex),
                ...value.slice(itemIndex + 1),
            ]);
        } else {
            setValue([
                ...value,
                data,
            ]);
        }
    }, [value, setValue]);

    useEffect(() => {
        const handleKeyDown = function (event: KeyboardEvent) {
            if (includesInLC(event.key, 'esc')) {
                setValue([]);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setValue]);

    return (
        <SelectedOptionsContext.Provider value={{
            items: value,
            toggleItem: onItemSelect,
        }}>
            {children}
        </SelectedOptionsContext.Provider>
    );
};