import { FCX, Season } from '@models';
import React, { ReactNode, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { getFormattedThousand } from '@core/utils/number';
import { Tooltip } from 'react-tooltip';
import { TableContext } from '@components/Table/TableContext';
import SimpleTable from '@components/Table/SimpleTable';
import { useSeasonalMostActiveChains } from '@api/useSeasonalMostActiveChains';
import DataFallback from '@components/DataFallback';

const MostActiveChains: FCX<{
    selectedSeason: Season;
    renderSeasonName: ReactNode;
    isGlobalLoading: boolean;
}> = ({
    selectedSeason,
    renderSeasonName,
}) => {
    const { data: rawData, isLoading } = useSeasonalMostActiveChains(selectedSeason);

    const data = useMemo(
        () => rawData.slice(0, 10),
        [rawData]
    );

    const tableConfig = useMemo((): iTableConfigItem[] => [
        {
            key: 'name',
            title: '',
            Value: ({ index }) => <>
                {data[index].name}
            </>,
        },
        {
            key: 'segment',
            title: '',
            Value: ({ index }) => <>
                {data[index].segmentName}
            </>,
            styles: {
                cell: {
                    flex: '0 0 25%',
                },
            },
        },
        {
            key: 'count',
            title: 'Count',
            Value: ({ index }) => <>
                {getFormattedThousand(data[index].count)}
            </>,
            styles: {
                cell: {
                    flex: '0 0 20%',
                    justifyContent: 'center',
                },
            },
        },
        {
            key: 'percentage',
            title: <>
                Percentage
                <Tooltip
                    id="table-most-popular-percentage"
                >
                    <div style={{ textAlign: 'center', width: 100 }}>
                        % of total{' '}
                        {renderSeasonName}
                        {' '}menu launches
                    </div>
                </Tooltip>
            </>,
            Value: ({ index }) => <>
                {Math.round(data[index].percentage || 0)}%
            </>,
            getAttrs: (index) => (index === -1 ? {
                'data-tooltip-id': `table-most-popular-percentage`,
            } : {}),
            styles: {
                cell: {
                    flex: '0 0 20%',
                    justifyContent: 'center',
                },
                head: {
                    cursor: 'default',
                }
            },
        },
    ], [data, renderSeasonName]);

    return (
        <div className="card" style={{ flex: '0 1 100%' }}>
            <div className="card-header">
                Most Active Chain for&nbsp;{renderSeasonName}&nbsp;Launches
            </div>
            {isLoading || data.length > 0 ? (
                <TableContext.Provider value={{ config: tableConfig }}>
                    <SimpleTable
                        style={{
                            minHeight: 350
                        }}
                        rowsCount={isLoading ? 10 : data.length}
                        isLoading={isLoading}
                    />
                </TableContext.Provider>
            ) : (
                <div className="card-content" style={{ height: 350 }}>
                    <DataFallback
                        style={{ opacity: isLoading ? 0 : 1, height: '100%' }}
                        title="No results"
                    />
                </div>
            )}
        </div>
    );
};

export default MostActiveChains;