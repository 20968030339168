import { useMemo, useState } from 'react';
import { ID } from '@models';
import { components, paths } from '@api/api';
import { api } from '@hooks/useFetch';
import { downloadReport } from '@core/utils/file';
import { useFiltersContext } from '@core/FiltersContext';

type Request = components['schemas']['ExcelExportByIdFilterModel'];

export function useXlsReport(isDefaultNormType: boolean = false, isDefaultRespondentType: boolean = false) {
    const [isLoading, setIsLoading] = useState(false);
    const {
        options: { norms, totalRespondentType },
        value: { norm, among, },
    } = useFiltersContext();

    const normType = useMemo((): Request['normType'] => {
        if (isDefaultNormType || norm === null) {
            return norms[0].id as Request['normType'];
        }
        return norm as Request['normType'];
    }, [isDefaultNormType, norm, norms]);

    const respondentType = useMemo((): Request['respondentType'] => {
        if (isDefaultRespondentType) {
            return totalRespondentType.id as Request['respondentType'];
        }
        return among as Request['respondentType'];
    }, [isDefaultRespondentType, totalRespondentType, among]);

    const load = async (ids: ID[]) => {
        setIsLoading(true);
        const url: keyof paths = '/api/Export/ExcelExportById';

        const data: Request = {
            items: ids as number[],
            normType,
            respondentType,
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}