import React, { FC, memo, useMemo, useState } from 'react';
import SelectAmong from '@vms/SelectAmong';
import HeaderBar from '@components/HeaderBar';
import TextInput from '@components/TextInput';
import { useChainsData } from '@api/useChainsData';
import TableConfig from './TotalTableConfig';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { getSortingOptions } from '@core/constants';
import { useFilterSort } from '@hooks/useFilterSort';
import { getEnding, renderMetricValue } from '@core/utils/string';
import AnimatedNumber from '@components/AnimatedNumber';
import { ID, iOption } from '@models';
import { SelectedOptionsProvider } from '@hooks/useSelectedOptions';
import Button from '@components/Button';
import { ButtonType } from '@components/Button/Button';
import { Route } from '@core/routes/Route';
import { getQueryString } from '@core/utils/url';
import { Tooltip } from 'react-tooltip';
import VirtualizedTable from '@components/Table/VirtualizedTable';
import Popup from '@components/Popup';
import { useUserContext } from '@core/UserContext';
import LockAccessOverlay from '@components/LockAccessOverlay';
import SelectionStatus from '@components/SelectionStatus';

export enum Columns {
    Name = 'name',
    ItemsCount = 'itemsCount',
    UnbrandedPi = 'unbrandedPi',
    BrandedPi = 'brandedPi',
    Uniqueness = 'uniqueness',
    Frequency = 'frequency',
    Draw = 'draw',
    Value = 'value',
}

const TotalTable: FC<{
    selectedMetricId: ID;
}> = ({
    selectedMetricId,
}) => {
    const { fullAccess } = useUserContext();
    const { data: rawData, isLoading } = useChainsData(!fullAccess);
    const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);
    const [selectedItems, setSelectedItems] = useState<iOption[]>([]);

    const {
        data,
        sortingColumn,
        setSortingColumn,
        sortingDirection,
        setSortingDirection,
        query,
        setQuery,
    } = useFilterSort(rawData || [], 'itemsCount', 'name');

    const copyTableData = useMemo((): string => {
        const cells: string[] = [];

        cells.push([
            'Chain',
            'Total items',
            'Unbranded PI',
            'Branded PI',
            'Uniqueness',
            'Frequency',
            'Draw',
            'Value',
            'Unbranded PI Percentile Stars',
            'Branded PI Percentile Stars',
            'Uniqueness Percentile Stars',
            'Frequency Percentile Stars',
            'Draw Percentile Stars',
            'Value Percentile Stars',
            'Unbranded PI Percentile',
            'Branded PI Percentile',
            'Uniqueness Percentile',
            'Frequency Percentile',
            'Draw Percentile',
            'Value Percentile',
        ].join('\t'));
        data.forEach(i => {
            cells.push([
                i.name,
                i.itemsCount,
                renderMetricValue(i.unbrandedPi || 0),
                renderMetricValue(i.brandedPi || 0),
                renderMetricValue(i.uniqueness || 0),
                renderMetricValue(i.frequency || 0),
                renderMetricValue(i.draw || 0),
                renderMetricValue(i.value || 0),
                Array(i.unbrandedPiStar || 0).fill('★').join(''),
                Array(i.brandedPiStar || 0).fill('★').join(''),
                Array(i.uniquenessStar || 0).fill('★').join(''),
                Array(i.frequencyStar || 0).fill('★').join(''),
                Array(i.drawStar || 0).fill('★').join(''),
                Array(i.valueStar || 0).fill('★').join(''),
                i.unbrandedPercentile || 0,
                i.brandedPercentile || 0,
                i.uniquenessPercentile || 0,
                i.frequencyPercentile || 0,
                i.drawPercentile || 0,
                i.valuePercentile || 0,
            ].join('\t'));
        });

        return cells.join('\r\n');
    }, [data]);

    return (
        <div style={{ position: 'relative' }}>
            <SelectedOptionsProvider value={selectedItems} setValue={setSelectedItems}>
                <HeaderBar style={{
                    position: 'relative',
                    zIndex: 3,
                    marginBottom: 20,
                    height: 70,
                }}>
                    {!isSelectionEnabled && (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(rawData || []).length > 0 && (
                                    <div
                                        style={{
                                            fontSize: 30,
                                            marginRight: 15,
                                            width: 55,
                                        }}
                                        className="font-caption color-dimyellow"
                                    >
                                        <AnimatedNumber value={data.length}/>
                                    </div>
                                )}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div className="header-title">
                                        Chains
                                    </div>
                                    <div className="header-sub-title">
                                        average for menu launches
                                    </div>
                                </div>
                                <TextInput
                                    placeholder="Type any chain"
                                    value={query}
                                    setValue={setQuery}
                                    style={{ width: 160, marginLeft: 20 }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SelectAmong style={{ display: 'flex', alignItems: 'center' }}/>
                                <Button
                                    modifiers={['green']}
                                    onClick={() => setIsSelectionEnabled(true)}
                                    style={{ marginLeft: 10 }}
                                >
                                    COMPARE CHAINS
                                </Button>
                            </div>
                        </>
                    )}
                    {isSelectionEnabled && (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    className="font-medium"
                                    style={{ fontSize: 16 }}
                                >
                                    Select the chains you want to compare
                                </div>
                                <TextInput
                                    placeholder="Type any chain"
                                    value={query}
                                    setValue={setQuery}
                                    style={{ width: 160, marginLeft: 10 }}
                                />
                                <div
                                    style={{ marginLeft: 10 }}
                                    className="font-light"
                                >
                                    <SelectionStatus isPopupOnBottom />
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div data-tooltip-id="compare-chains-tooltip">
                                    <Button
                                        type={ButtonType.Anchor}
                                        link={`${process.env.PUBLIC_URL}${Route.ChainsChart}?${getQueryString({
                                            chainIds: selectedItems.map(i => i.id),
                                        })}`}
                                        modifiers={['green']}
                                        onClick={() => setIsSelectionEnabled(true)}
                                        isDisabled={selectedItems.length < 2}
                                        style={{ pointerEvents: selectedItems.length < 2 ? 'none' : undefined}}
                                    >
                                        LAUNCH INTERACTIVE COMPARISON
                                    </Button>
                                </div>
                                {selectedItems.length < 2 && (
                                    <Popup hasWrapper={false}>
                                        <Tooltip
                                            id="compare-chains-tooltip"
                                            place="bottom"
                                            variant="light"
                                            positionStrategy="fixed"
                                            style={{ width: 150, textAlign: 'center', zIndex: 4 }}
                                        >
                                            please select at least 2 chains to compare
                                        </Tooltip>
                                    </Popup>
                                )}
                                <Button
                                    modifiers={['blue', 'naked']}
                                    onClick={() => {
                                        setIsSelectionEnabled(false);
                                        setSelectedItems([]);
                                    }}
                                    style={{ marginLeft: 10 }}
                                >
                                    cancel
                                </Button>
                            </div>
                        </>
                    )}
                </HeaderBar>

                <TableSortingContext.Provider value={{
                    value: {
                        id: sortingColumn,
                        direction: sortingDirection,
                    },
                    setValue: (value) => {
                        setSortingColumn(value.id as Columns);
                        setSortingDirection(value.direction);
                    },
                    options: getSortingOptions([
                        Columns.Name,
                        Columns.ItemsCount,
                        Columns.UnbrandedPi,
                        Columns.BrandedPi,
                        Columns.Uniqueness,
                        Columns.Frequency,
                        Columns.Draw,
                        Columns.Value,
                    ]),
                }}>
                    <TableConfig
                        data={isLoading ? [] : data}
                        searchQuery={query}
                        isSelectionEnabled={isSelectionEnabled}
                        setIsSelectionEnabled={setIsSelectionEnabled}
                        highlightedMetricId={selectedMetricId}
                    >
                        <div className="ChainPerformance__table">
                            <VirtualizedTable
                                rowHeight={48}
                                copyTable={fullAccess ? {
                                    isLoading: false,
                                    data: copyTableData,
                                } : undefined}
                                rowsCount={isLoading ? 0 : data.length}
                                isLoading={isLoading}
                                headStyle={{
                                    paddingTop: 8,
                                    height: 38,
                                }}
                            />
                        </div>
                    </TableConfig>
                </TableSortingContext.Provider>
            </SelectedOptionsProvider>
            {!fullAccess && (
                <LockAccessOverlay
                    subTitle="of menu launches from these chains"
                    toolLocation="Chain Performance"
                />
            )}
        </div>
    );
};

export default memo(TotalTable);