import { FCX, ID } from '@models';
import React, { useState } from 'react';
import Button from '@components/Button';
import PlatesSelect from '@components/PlatesSelect';
import { toggleInArray } from '@core/utils/array';
import Modal from '@components/Modal';
import { useFiltersContext } from '@core/FiltersContext';

const EditCategoriesPopup: FCX<{
    value: ID[];
    setValue: (value: ID[]) => void;
    onClose: () => void;
}> = ({
    value: appliedValue,
    setValue: applyValue,
    onClose,
}) => {
    const { options: { categories } } = useFiltersContext();
    const [value, setValue] = useState<ID[]>(appliedValue);

    return (
        <Modal
            onClose={onClose}
            style={{ width: 600 }}
        >
            <div
                className="font-medium"
                style={{
                    fontSize: 30,
                    lineHeight: '40px',
                    marginBottom: 30,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                Edit Category Selections
            </div>
            <PlatesSelect
                value={value}
                options={categories}
                onOptionClick={(id) => setValue(toggleInArray(value, id))}
                style={{
                    justifyContent: 'center'
                }}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 20,
            }}>
                <div style={{ display: 'flex' }}>
                    <Button
                        modifiers={['blue', 'naked']}
                        onClick={onClose}
                    >
                        CANCEL
                    </Button>
                    &nbsp;
                    <Button
                        modifiers={['green']}
                        onClick={() => {
                            applyValue(value);
                            onClose();
                        }}
                    >
                        SAVE
                    </Button>
                </div>
                {value.length > 0 && (
                    <Button
                        modifiers={['red', 'naked']}
                        onClick={() => setValue([])}
                        style={{ marginRight: 10 }}
                    >
                        Reset All Selections
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default EditCategoriesPopup;