import { ID } from '@models';
import { useMemo } from 'react';
import { components } from '@api/api';
import useFetch from '@hooks/useFetch';
import { useChainProfileFilters } from '@core/selectors/useChainProfileFilters';

export function useChainProfile (chainId: ID) {
    const filter = useChainProfileFilters();

    const payload = useMemo((): components['schemas']['ChainProfileRecentlyReleasedItemsRequest'] => {
        return {
            id: chainId as number,
            filter,
        };
    }, [filter, chainId]);

    return useFetch<components['schemas']['ChainProfileResponse']>({
        url: '/api/ChainProfile',
        method: 'post',
        payload,
    });
}