import { useEffect, useMemo, useState } from 'react';
import { FCX, ID, Sort } from '@models';
import Table from '@components/Table';
import { useFiltersContext } from '@core/FiltersContext';
import { FilterApiRequestModel } from '@models/Filter';
import { useItems } from '@api/useItems';
import CLPeopleViewConfig from '@vms/ItemsTable/CLPeopleViewConfig';
import PeopleViewSorting from '@vms/ItemsTable/PeopleViewSorting';
import { components } from '@api/api';

const CLPeopleView: FCX<{
    setTotalRecords?: (value: number) => void;
    setAppliedFilterPayload?: (value: components['schemas']['SearchFilterModel']) => void;
}> = ({
    setTotalRecords= () => null,
    setAppliedFilterPayload= () => null,
}) => {
    const {
        options: { totalRespondentType, metrics },
        value: { concepts, peopleSort, metric, display },
        defaultFiltersPayload,
    } = useFiltersContext();

    const [sortingColId, setSortingColId] = useState<ID>(peopleSort.id);
    const [sortingDirection, setSortingDirection] = useState<Sort>(peopleSort.direction);

    const activeRespondentTypesIds: ID[] = useMemo(() => {
        const metricObject = metrics.find(i => i.id === metric);

        if (metricObject) {
            return [
                totalRespondentType,
                ...(metricObject.subItems || [])
            ].map(i => i.id);
        }

        return [];
    }, [metric, metrics, totalRespondentType]);


    const filtersPayload = useMemo((): components['schemas']['SearchFilterModel'] => {
        return {
            filter: {
                ...defaultFiltersPayload,
                respondentTypes: activeRespondentTypesIds as FilterApiRequestModel['respondentTypes'],
                indexType: display as FilterApiRequestModel['indexType'],
                conceptTypeIds: concepts as number[],
            },
            page: {
                sortId: sortingColId as number,
                sortDirection: sortingDirection,
            }
        };
    }, [defaultFiltersPayload, concepts, activeRespondentTypesIds, sortingColId, sortingDirection]);

    const {
        data,
        totalRecords,
        fetchMore,
        isLoading,
        copyTableData,
        isCopyTableDataLoading,
    } = useItems(filtersPayload, 60, true);

    useEffect(() => {
        if (!isLoading) {
            setTotalRecords(totalRecords || 0);
        }
    }, [isLoading, totalRecords, setTotalRecords]);

    useEffect(() => {
        setAppliedFilterPayload(filtersPayload);
    }, [filtersPayload, setAppliedFilterPayload]);

    return (
        <PeopleViewSorting
            sortingColId={sortingColId}
            setSortingColId={setSortingColId}
            sortingDirection={sortingDirection}
            setSortingDirection={setSortingDirection}
        >
            <CLPeopleViewConfig data={data}>
                <Table
                    rowsCount={data.length}
                    onLoadMore={fetchMore}
                    isLoading={isLoading}
                    copyTable={{
                        data: copyTableData,
                        isLoading: isCopyTableDataLoading,
                    }}
                />
            </CLPeopleViewConfig>
        </PeopleViewSorting>
    );
}

export default CLPeopleView