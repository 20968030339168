import { FCX } from '@models';
import { CSSProperties, useMemo } from 'react';
import TableHead from '@components/Table/TableHead';
import TableRowLoading from '@components/Table/TableRowLoading';
import TableRow from '@components/Table/TableRow';
import DataFallback from '@components/DataFallback';

const SimpleTable: FCX<{
    isLoading: boolean;
    rowsCount: number;
    rowHeight?: number;
    copyTable?: {
        isLoading: boolean;
        data: string | null;
    };
    headStyle?: CSSProperties;
    dataPlaceholderText?: string;
}> = ({
    isLoading,
    rowsCount,
    rowHeight = 30,
    style,
    headStyle,
    dataPlaceholderText,
    children
}) => {
    const rowStyle = useMemo(
        (): CSSProperties => ({
            height: rowHeight,
        }),
        [rowHeight]
    );

    return (
        <div className="Table" style={{ ...style }}>
            <TableHead style={{
                top: 0,
                paddingTop: 0,
                height: 30,
                ...headStyle,
            }}/>
            {Array(rowsCount).fill(null).map((_, index) =>
                isLoading ? (
                    <TableRowLoading key={index} style={rowStyle} index={index}/>
                ) : (
                    <TableRow key={index} style={rowStyle} index={index}/>
                )
            )}

            {!isLoading && rowsCount === 0 && (
                <DataFallback
                    title="No results"
                    subTitle={dataPlaceholderText}
                />
            )}
            {children}
        </div>
    );
};

export default SimpleTable;