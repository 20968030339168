import { createPortal } from 'react-dom';
import cn from 'classnames';
import { FCX } from '@models';

const Popup: FCX<{
    hasWrapper?: boolean;
}> = ({
    children,
    style,
    className,
    hasWrapper= true,
    ...attrs
}) => {
    const content = hasWrapper ? (
        <div {...attrs} className={cn("Popup", className)} style={style}>
            {children}
        </div>
    ) : children;

    return createPortal(content, document.getElementById('modal-root')!);
};

export default Popup;