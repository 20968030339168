import { FCX } from '@models';
import cn from 'classnames';

const TableCell: FCX<{
    onClick?: () => void;
    attrs?: any;
}> = ({
    children,
    onClick,
    className,
    style,
    attrs,
}) => {
    return (
        <div
            {...attrs}
            className={cn("TableCell", className)}
            style={style}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default TableCell;