import ButtonClipboardCopy from "@components/ButtonClipboardCopy/index";
import React from "react";
import { FCX } from "@models";
import { ButtonClipboardCopyProps } from "@components/ButtonClipboardCopy/ButtonClipboardCopy";

const BadgeDownloadPptButton: FCX<{
    isBusy: ButtonClipboardCopyProps['isBusy'];
    onClick: ButtonClipboardCopyProps['onClick'];
}> = ({
    style,
    isBusy,
    onClick,
}) => {
    return (
        <ButtonClipboardCopy
            style={style}
            caption="PPT"
            isBusy={isBusy}
            onClick={onClick}
            notify={false}
        >
            <div
                className="ActionButton__icon font-icons"
                style={{
                    fontSize: 20,
                    marginBottom: 3,
                }}
            >
                &#59702;
            </div>
        </ButtonClipboardCopy>
    );
}

export default BadgeDownloadPptButton;