import { FCX } from '@models';
import { components } from '@api/api';
import { getFormattedThousand } from '@core/utils/number';
import Stars from '@components/Stars';
import Button from '@components/Button';
import React, { useEffect, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { useFilterSort } from '@hooks/useFilterSort';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { getSortingOptions } from '@core/constants';
import { TableContext } from '@components/Table/TableContext';
import TableHead from '@components/Table/TableHead';
import TableRow from '@components/Table/TableRow';
import Wrapper from '@components/Wrapper';
import { createPortal } from 'react-dom';

export enum Columns {
    Count = 'concepts',
    AvgScore = 'avgUnbrandedPi',
    HighScore = 'avgUniqueness',
}

const ConceptLockerSummaryPopup: FCX<{
    onClose: () => void;
    data: components['schemas']['ConceptLockerDashboardResult'];
}> = ({
    onClose,
    data: rawData,
}) => {
    const rawTableData = useMemo(() => rawData.table || [], [rawData]);
    const {
        data: tableData,
        sortingColumn,
        sortingDirection,
        setSortingColumn,
        setSortingDirection,
    } = useFilterSort(rawTableData, 'concepts', []);

    const tableDataWithTotal = useMemo(() => [
        {
            name: 'TOTAL',
            concepts: rawData.totalConcepts,
            avgUnbrandedPi: rawData.totalAvgUnbrandedPi,
            avgUniqueness: rawData.totalAvgUniqueness,
            highestUnbrPi: rawData.totalHighestUnbrPi,
        },
        ...tableData
    ], [tableData, rawData]);

    const tableConfig = useMemo((): iTableConfigItem[] => ([
        {
            key: 'name',
            title: '',
            Value: ({ index }) => <>{tableDataWithTotal[index].name}</>,
            styles: {
                cell: {
                    flex: '0 0 170px',
                    paddingLeft: 20,
                }
            },
        },
        {
            key: 'count',
            title: '# of Concepts',
            Value: ({ index }) => <>{getFormattedThousand(tableDataWithTotal[index].concepts)}</>,
            sortingColName: Columns.Count,
            styles: {
                cell: {
                    flex: '0 0 120px',
                    justifyContent: 'center',
                }
            },
        },
        {
            key: 'avg-score',
            title: 'Average SCORE',
            Value: ({ index }) => <>{tableDataWithTotal[index].avgUnbrandedPi}</>,
            sortingColName: Columns.AvgScore,
            styles: {
                cell: {
                    flex: '0 0 120px',
                    justifyContent: 'center',
                }
            },
        },
        {
            key: 'high-score',
            title: 'Highest SCORE',
            Value: ({ index }) => <>{tableDataWithTotal[index].avgUniqueness}</>,
            sortingColName: Columns.HighScore,
            styles: {
                cell: {
                    flex: '0 0 120px',
                    justifyContent: 'center',
                }
            },
        },
        {
            key: 'high-concept',
            title: 'Highest Scoring Concept',
            Value: ({ index }) => <>{tableDataWithTotal[index].highestUnbrPi}</>,
            styles: {
                cell: {
                    flex: '1 0 160px',
                }
            },
        },
    ]), [tableDataWithTotal]);

    useEffect(() => {
        document.body.classList.add('locked-scroll');

        return () => {
            document.body.classList.remove('locked-scroll');
        }
    }, [])

    return createPortal((
        <div
            className="ConceptLockerSummaryPopup"
            onClick={onClose}
        >
            <Wrapper>
                <div
                    className="ConceptLockerSummaryPopup__inner"
                    onClick={(event) => event.stopPropagation()}
                >
                    <button
                        onClick={onClose}
                        className="modal-window__close"
                    >×</button>
                    <div className="ConceptLockerSummaryPopup__table">
                        <TableSortingContext.Provider value={{
                            value: {
                                id: sortingColumn,
                                direction: sortingDirection,
                            },
                            setValue: (value) => {
                                setSortingColumn(value.id as Columns);
                                setSortingDirection(value.direction);
                            },
                            options: getSortingOptions([
                                Columns.Count,
                                Columns.AvgScore,
                                Columns.HighScore,
                            ]),
                        }}>
                            <TableContext.Provider value={{
                                config: tableConfig,
                            }}>
                                <TableHead className="ConceptLockerSummaryPopup__table-header"/>
                                <TableRow
                                    index={0}
                                    className="ConceptLockerSummaryPopup__table-sub-header"
                                />
                                {tableData.map((_, index) => (
                                    <TableRow
                                        index={index + 1}
                                        className="ConceptLockerSummaryPopup__table-row"
                                    />
                                ))}
                            </TableContext.Provider>
                        </TableSortingContext.Provider>
                    </div>
                    <div className="ConceptLockerSummaryPopup__overview">
                        <div className="ConceptLockerSummaryPopup__title">
                            <div className="color-lightblue">Your</div>
                            &nbsp;Concepts
                        </div>
                        <div className="ConceptLockerSummaryPopup__pills">
                            <div className="ConceptLockerSummaryPopup__pill accent">
                                <div className="ConceptLockerSummaryPopup__pill-title">
                                    {getFormattedThousand(rawData.concepts)}
                                </div>
                                CONCEPTS
                            </div>
                            <div className="ConceptLockerSummaryPopup__pill">
                                <div className="ConceptLockerSummaryPopup__pill-title">
                                    {getFormattedThousand(rawData.express)}
                                </div>
                                EXPRESS
                            </div>
                            <div className="ConceptLockerSummaryPopup__pill">
                                <div className="ConceptLockerSummaryPopup__pill-title">
                                    {getFormattedThousand(rawData.full)}
                                </div>
                                FULL
                            </div>
                            <div className="ConceptLockerSummaryPopup__pill">
                                <div className="ConceptLockerSummaryPopup__pill-title">
                                    {getFormattedThousand(rawData.scoresPlus)}
                                </div>
                                PLUS
                            </div>
                        </div>
                        <div
                            className="ConceptLockerSummaryPopup__description"
                            style={{ opacity: rawData.inField === 0 ? 0 : undefined, }}
                        >
                            You also have {rawData.inField} concepts that are currently in field. They will appear in your concept locker
                            shortly.
                        </div>
                        <div className="ConceptLockerSummaryPopup__metrics">
                            <div className="ConceptLockerSummaryPopup__metric">
                                <div className="ConceptLockerSummaryPopup__metric-value">
                                    {rawData.unbrandedPi}%
                                </div>
                                <div className="ConceptLockerSummaryPopup__metric-description">
                                    <div>of your concepts earn <Stars count={3}
                                                                      style={{ width: 100, display: 'inline-flex' }}/></div>
                                    <div>or higher for PURCHASE INTENT (unbranded)</div>
                                </div>
                            </div>
                            <div className="ConceptLockerSummaryPopup__metric">
                                <div className="ConceptLockerSummaryPopup__metric-value">
                                    {rawData.uniqueness}%
                                </div>
                                <div className="ConceptLockerSummaryPopup__metric-description">
                                    <div>of your concepts earn <Stars count={3}
                                                                      style={{ width: 100, display: 'inline-flex' }}/></div>
                                    <div>or higher for UNIQUENESS</div>
                                </div>
                            </div>
                            <div className="ConceptLockerSummaryPopup__metric">
                                <div className="ConceptLockerSummaryPopup__metric-value">
                                    {rawData.both}%
                                </div>
                                <div className="ConceptLockerSummaryPopup__metric-description">
                                    <div>of your concepts earn <Stars count={3}
                                                                      style={{ width: 100, display: 'inline-flex' }}/></div>
                                    <div>or higher for BOTH of these key metrics</div>
                                    <div>(which beats the industry average of {rawData.industryAvg}%)</div>
                                </div>
                            </div>
                        </div>
                        <Button
                            className="ConceptLockerSummaryPopup__button"
                            modifiers={['blue']}
                            onClick={onClose}
                        >
                            VIEW CONCEPTS
                        </Button>
                    </div>
                </div>
            </Wrapper>
        </div>
    ), document.getElementById('modal-root')!);
};

export default ConceptLockerSummaryPopup;