import React, { ReactNode, useMemo } from 'react';
import { getEnding } from '@core/utils/string';
import { useFiltersContext } from '@core/FiltersContext';

export function useFilterDateRangeString() {
    const { value: { date: filterDate } } = useFiltersContext();
    return useMemo(
        (): ReactNode => {
            if (filterDate.period.from && filterDate.period.to) {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                {filterDate.period.from} - {filterDate.period.to}
                </div>
            )
            } else if (filterDate.withinMonth !== null) {
                if (filterDate.withinMonth === 0) {
                    return (
                        <div style={{ whiteSpace: 'nowrap' }}>
                    Jun 2014 - present
                    </div>
                );
                } else {
                    return (
                        <div style={{ whiteSpace: 'nowrap' }}>
                    Past {filterDate.withinMonth} {getEnding('month', Number(filterDate.withinMonth) || 0)}
                    </div>
                );
                }
            }

            return '';
        },
        [filterDate]
    );
}