import { useMemo } from 'react';
import { getSeasonByMonth, Season } from '@models';
import dayjs from 'dayjs';

export function useDefaultSeason(selectCurrentSeasonFirst: boolean = false) {
    return useMemo(
        (): Season => {
            if (selectCurrentSeasonFirst) {
                const currentMonth = dayjs().format('MMMM');
                return getSeasonByMonth(currentMonth);
            }

            return Season.Winter;
        },
        [selectCurrentSeasonFirst]
    );
}