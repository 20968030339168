import { FCX } from '@models';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdCheck, MdClose, MdKeyboardArrowDown } from 'react-icons/md';

import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import useOutsideClick from '@hooks/useOutsideClick';

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MIN_YEAR = 2014;
const MIN_MONTH = 5;
const MAX_YEAR: number = new Date().getFullYear();
const MAX_MONTH: number = new Date().getMonth();

function getYearMonthMetric(year: number | null, month: number | null): number | undefined {
    if (year === null || month === null) return undefined;
    return year * 100 + month;
}

const HeaderColumn: FC<{
    label: string;
    year: number;
    onInc: () => void;
    onDec: () => void;
}> = ({
    label,
    year,
    onInc,
    onDec,
}) => {
    return (
        <div className="SelectDateRange__col">
            <div className="SelectDateRange__label">
                {label}
            </div>
            <div className="SelectDateRange__controls">
                <div
                    className={cn(
                        'SelectDateRange__arrow',
                        year <= MIN_YEAR && 'is-disabled'
                    )}
                    onClick={onDec}
                >
                    <MdKeyboardArrowLeft/>
                </div>
                {year}
                <div
                    className={cn(
                        'SelectDateRange__arrow',
                        year >= MAX_YEAR && 'is-disabled'
                    )}
                    onClick={onInc}
                >
                    <MdKeyboardArrowRight/>
                </div>
            </div>
        </div>
    );
};

const SelectDateRange: FCX<{
    from: string | null;
    to: string | null;
    onChange: (data: { from: string, to: string }) => void;
    placeholder?: string;
}> = ({
    from,
    to,
    onChange,
    placeholder= 'Select',
    className,
    style,
    ...attrs
}) => {
    const ref = useRef(null);
    const [isOpened, setIsOpened] = useState(false);
    useOutsideClick({ ref, fn: () => setIsOpened(false) });

    const [fromYear, setFromYear] = useState<number | null>(null);
    const [fromMonth, setFromMonth] = useState<number | null>(null);
    const [toYear, setToYear] = useState<number | null>(null);
    const [toMonth, setToMonth] = useState<number | null>(null);
    const [startYear, setStartYear] = useState<number>(MIN_YEAR);
    const [endYear, setEndYear] = useState<number>(MAX_YEAR);

    const selectionStart = useMemo(() => getYearMonthMetric(fromYear, fromMonth), [fromYear, fromMonth]);
    const selectionEnd = useMemo(() => getYearMonthMetric(toYear, toMonth), [toYear, toMonth]);
    const isSelectionComplete = useMemo(
        () => !!selectionStart && !!selectionEnd,
        [selectionStart, selectionEnd]
    );
    const isSelectionValid = useMemo(
        () => !!selectionStart && !!selectionEnd && selectionStart <= selectionEnd,
        [selectionStart, selectionEnd]
    );

    const isSelectionStart = useCallback((month: number, year: number) => {
        return getYearMonthMetric(year, month) === selectionStart;
    }, [selectionStart]);

    const isSelectionEnd = useCallback((month: number, year: number) => {
        return getYearMonthMetric(year, month) === selectionEnd;
    }, [selectionEnd]);

    const isMonthHighlighted = useCallback((month: number, year: number) => {
        if (!selectionStart || !selectionEnd) return false;

        const metric = getYearMonthMetric(year, month)!;

        return metric >= selectionStart && metric <= selectionEnd;

    }, [selectionStart, selectionEnd]);

    const isMonthDisabled = useCallback((month: number, year: number) => {
        const metric = getYearMonthMetric(year, month)!;
        const minDateMetric = getYearMonthMetric(MIN_YEAR, MIN_MONTH)!;
        const maxDateMetric = getYearMonthMetric(MAX_YEAR, MAX_MONTH)!;

        return metric < minDateMetric || metric > maxDateMetric;
    }, []);

    useEffect(() => {
        if (from && to) {
            const [monthFrom, yearFrom] = from.split(' ');
            const [monthTo, yearTo] = to.split(' ');
            setFromYear(+yearFrom);
            setToYear(+yearTo);
            setFromMonth(MONTHS.indexOf(monthFrom));
            setToMonth(MONTHS.indexOf(monthTo));
            setStartYear(+yearFrom);
            setEndYear(+yearTo)
        }
        else {
            setFromYear(null);
            setToYear(null);
            setFromMonth(null);
            setToMonth(null);
            setStartYear(MAX_YEAR -1);
            setEndYear(MAX_YEAR)
        }
    }, [from, to, isOpened]);

    const thumbValue: ReactNode = useMemo(() => {
        if (fromMonth === null || toMonth === null || fromYear === null || toYear === null) return placeholder;
        return (
            <div className={cn(isSelectionComplete && !isSelectionValid && 'is-error-cl')}>
                {MONTHS[fromMonth]} {fromYear} - {MONTHS[toMonth]} {toYear}
            </div>
        );
    }, [fromMonth, fromYear, toMonth, toYear, isSelectionValid, isSelectionComplete, placeholder]);

    return (
        <div
            {...attrs}
            className={cn('Select', isOpened && 'is-opened', className )}
            style={style}
            ref={ref}
        >
            <div
                className="Select__thumb"
                onClick={() => setIsOpened(!isOpened)}
            >
                {thumbValue}
                <div className="Select__arrow">
                    <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                </div>
            </div>
            <div className="Select__dropdown w-xl pos-r">
                <div className="SelectDateRange">
                    <div className="SelectDateRange__header">
                        <HeaderColumn
                            label={'start'}
                            year={startYear}
                            onInc={() => setStartYear(startYear + 1)}
                            onDec={() => setStartYear(startYear - 1)}
                        />
                        <HeaderColumn
                            label={'end'}
                            year={endYear}
                            onInc={() => setEndYear(endYear + 1)}
                            onDec={() => setEndYear(endYear - 1)}
                        />
                    </div>
                    <div className="SelectDateRange__content">
                        <div className="SelectDateRange__col">
                            {MONTHS.map((name, month) => (
                                <div
                                    key={month}
                                    className={cn(
                                        "SelectDateRange__month",
                                        isSelectionStart(month, startYear) && 'is-active',
                                        isSelectionStart(month, startYear) && isSelectionComplete && !isSelectionValid && 'is-error',
                                        isMonthHighlighted(month, startYear) && 'is-highlight',
                                        isMonthDisabled(month, startYear) && 'is-disabled'
                                    )}
                                    onClick={() => {
                                        setFromMonth(month);
                                        setFromYear(startYear);
                                    }}
                                >
                                    {name}
                                </div>
                            ))}
                        </div>
                        <div className="SelectDateRange__col">
                            {MONTHS.map((name, month) => (
                                <div
                                    key={month}
                                    className={cn(
                                        "SelectDateRange__month",
                                        isSelectionEnd(month, endYear) && 'is-active',
                                        isSelectionEnd(month, endYear) && isSelectionComplete && !isSelectionValid && 'is-error',
                                        isMonthHighlighted(month, endYear) && 'is-highlight',
                                        isMonthDisabled(month, endYear) && 'is-disabled'
                                    )}
                                    onClick={() => {
                                        setToMonth(month);
                                        setToYear(endYear);
                                    }}
                                >
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="SelectDateRange__footer">
                        <div
                            className="SelectDateRange__footer-button"
                            onClick={() => setIsOpened(false)}
                        >
                            <MdClose width={20} height={20}/>
                        </div>
                        <div
                            className={cn(
                                'SelectDateRange__footer-button',
                                !isSelectionValid && 'is-disabled',
                            )}
                            onClick={() => {
                                if (fromMonth !== null && fromYear !== null && toMonth !== null && toYear !== null) {
                                    onChange({
                                        from: `${MONTHS[fromMonth]} ${fromYear}`,
                                        to: `${MONTHS[toMonth]} ${toYear}`,
                                    });
                                    setIsOpened(false);
                                }
                            }}
                        >
                            <MdCheck/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectDateRange;