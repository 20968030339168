import './Wrapper.scss';
import { FCX } from '@models';
import cn from 'classnames';


const Wrapper: FCX = ({
    children,
    className,
    style,
    ...attrs
}) => {
    return (
        <div {...attrs} className={cn("Wrapper", className )} style={style}>
            {children}
        </div>
    )
};

export default Wrapper;