import { FCX, iChain, ID, iOption } from '@models';
import Select from '@components/Select';
import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import { toggleInArray } from '@core/utils/array';
import Button from '@components/Button';

const ChainComparisonChainsSelection: FCX<{
    chainId: ID;
    value: ID[];
    setValue: (ids: ID[]) => void;
    onEmptyApply: () => void;
}> = ({
    chainId,
    value: appliedValue,
    setValue: applyValue,
    onEmptyApply,
}) => {
    const { options: { chains: rawChains } } = useFiltersContext();
    const [value, setValue] = useState<ID[]>(appliedValue);

    // const handleSelectClose = useCallback(
    //     () => {
    //         setValue(appliedValue);
    //         if (appliedValue.length === 0) {
    //             onEmptyApply();
    //         }
    //     },
    //     [appliedValue, onEmptyApply]
    // );

    const chains = useMemo(
        (): iChain[] => rawChains.filter(i => i.id !== chainId),
        [rawChains, chainId]
    );

    const handleApply = useCallback(
        () => {
            if (value.length === 0) {
                onEmptyApply()
            } else {
                applyValue(value);
            }
        },
        [value, onEmptyApply, applyValue]
    );

    useEffect(() => {
        setValue(appliedValue);
    }, [appliedValue]);

    return (
        <Select
            style={{ marginLeft: 10, height: 40 }}
            options={chains}
            value={value}
            onOptionClick={(id) => setValue(toggleInArray(value, id))}
            className="width-l"
            hasSearch
            isOpenedByDefault
            isCheckBoxDecoration
            sortSelectedToTop={false}
            onClose={handleApply}
            subjectSingular="chain"
            innerRender={(onClose: () => void) => {
                return (
                    <>
                        <div style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            background: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 8,
                            filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.2)'
                        }}>
                            <Button
                                modifiers={['red', 'naked']}
                                style={{ height: 32 }}
                                onClick={() => setValue([])}
                            >
                                Deselect all
                            </Button>
                            <Button
                                modifiers={['green']}
                                style={{ height: 32 }}
                                onClick={onClose}
                            >
                                APPLY
                            </Button>
                        </div>
                    </>
                )
            }}
        />
    );
};

export default memo(ChainComparisonChainsSelection);