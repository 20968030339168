import { iSortingOption, iTableSorting, Sort } from '@models';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { equalInLC } from '@core/utils/string';
import { DEFAULT_SORT_DIRECTION } from '@core/constants';

const TableSortingContext = createContext<{
    value: iTableSorting;
    options: iSortingOption[];
    setValue: (value: iTableSorting) => void;
}>({
    value: {
        id: -1,
        direction: Sort.Asc,
    },
    options: [],
    setValue: () => null,
});

export default TableSortingContext;

export function useTableSortingContext() {
    return useContext(TableSortingContext);
}

export function useTableSorting() {
    const { value, setValue, options } = useTableSortingContext();

    const getOption = useCallback((colName: string) => {
        return options.find(o => equalInLC(o.name, colName));
    }, [options]);

    const getIsSorted = useCallback((colName: string) => {
        const option = getOption(colName);

        if (option) return option.id === value.id;

        return false;
    }, [value, getOption]);

    const getDirection = useCallback((colName: string) => {
        const option = getOption(colName);
        const isSorted = getIsSorted(colName);

        if (isSorted) {
            return value.direction ?? option?.defaultDirection ?? DEFAULT_SORT_DIRECTION;
        }
        return option?.defaultDirection ?? DEFAULT_SORT_DIRECTION;
    }, [value, getOption, getIsSorted]);

    const onSort = useCallback((colName: string, direction?: Sort) => {
        const option = getOption(colName);
        if (option) {
            const newSortValue: iTableSorting = {
                id: option.id,
                direction: value.direction,
            };

            if (getIsSorted(colName)) {
                newSortValue.direction = value.direction === Sort.Asc ? Sort.Desc : Sort.Asc;
            }
            else {
                newSortValue.direction = direction ?? option.defaultDirection ?? DEFAULT_SORT_DIRECTION;
            }
            setValue(newSortValue);
        }
    }, [value, setValue, getOption, getIsSorted]);

    return {
        getIsSorted,
        getDirection,
        onSort,
    };
}

export function useTableSortingColumn (colName: string) {
    const { getIsSorted, onSort: onColumnSort, getDirection, } = useTableSorting();

    const isSorted = useMemo(() => getIsSorted(colName), [getIsSorted, colName]);
    const direction = useMemo(() => getDirection(colName), [getDirection, colName]);
    const onSort = useCallback(() => onColumnSort(colName), [onColumnSort, colName]);

    return {
        isSorted,
        direction,
        onSort,
    };
}

