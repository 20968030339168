import { FCX, Sort } from '@models';
import { getSortingOptions } from '@core/constants';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { iCategoryActivityFood } from '@api/useCategoyActivityByFoods';

const FoodFlavorsTableSortingConfig: FCX<{
    sortingColumn: keyof iCategoryActivityFood;
    setSortingColumn: (key: keyof iCategoryActivityFood) => void;
    setSortingDirection: (value: Sort) => void;
    sortingDirection: Sort;
}> = ({
    sortingColumn,
    sortingDirection,
    setSortingColumn,
    setSortingDirection,
    children,
}) => {
    return (
        <TableSortingContext.Provider value={{
            value: {
                id: sortingColumn,
                direction: sortingDirection,
            },
            setValue: (value) => {
                setSortingColumn(value.id as keyof iCategoryActivityFood);
                setSortingDirection(value.direction);
            },
            options: getSortingOptions([
                'index',
                'count',
                'percentage',
            ]),
        }}>
            {children}
        </TableSortingContext.Provider>
    );
};

export default FoodFlavorsTableSortingConfig;