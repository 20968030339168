import React, { useRef } from 'react';
import classNames from 'classnames';

import Popup from '@components/Popup';
import { FCX } from '@models';
import useOutsideClick from '@hooks/useOutsideClick';

const Modal: FCX<{
	onClose: () => void;
	hasCloseButton?: boolean;
	closeOnOutsideClick?: boolean;
}> = ({
	onClose = () => {},
	hasCloseButton= true,
	closeOnOutsideClick = true,
	className = null,
	children = null,
	style,
}) => {
	const refNode = useRef<HTMLDivElement>(null);

	useOutsideClick({
		ref: refNode,
		fn: onClose,
		isDisabled: !closeOnOutsideClick,
	});

	return (
		<Popup style={{ zIndex: 4 }}>
			<div 
				className={classNames('modal', className)}
			>
				<div
					ref={refNode}
					className="modal-window"
					style={style}
				>
					{hasCloseButton && (
						<button onClick={onClose} className="modal-window__close">×</button>
					)}
					<div className="modal-body">
						{children}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default Modal;