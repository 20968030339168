import { FCX } from '@models';

const MetricDescriptionTooltip: FCX = () => {
    return (
        <div className="metric-description-tooltip">
            <div className="metric-description-tooltip__item">
                <span className="name">Unbranded Purchase Intent</span>
                If it were available, how likely would you be to order / buy this item?
            </div>
            <div className="metric-description-tooltip__item">
                <span className="name">Branded Purchase Intent</span>
                How likely would you be to order this item if it were offered at this restaurant?
            </div>
            <div className="metric-description-tooltip__item">
                <span className="name">Uniqueness</span>
                How new and different is this item?
            </div>
            <div className="metric-description-tooltip__item">
                <span className="name">Frequency</span>
                Is this an item you could see yourself having all the time?
            </div>
            <div className="metric-description-tooltip__item">
                <span className="name">Draw</span>
                Would you visit a restaurant just for this item?
            </div>
            <div className="metric-description-tooltip__item">
                <span className="name">Value</span>
                If offered at this restaurant at this price, would you consider this a good value for the
                dollar?
            </div>
        </div>
    );
}

export default MetricDescriptionTooltip;