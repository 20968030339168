import { FCX } from '@models';
import cn from 'classnames';
import { memo } from 'react';

const HeaderBar: FCX = ({
    children,
    className,
    style,
    ...attrs
}) => {
    return (
        <div
            {...attrs}
            style={style}
            className={cn(
                className,
                "HeaderBar",
            )}
        >
            {children}
        </div>
    );
};

export default memo(HeaderBar);