import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import FiltersPopup from '@vms/FiltersPopup';
import ActionsBar from '@vms/ActionsBar';
import FiltersButton from '@vms/FiltersButton';
import Wrapper from '@components/Wrapper';
import { useFiltersContext } from '@core/FiltersContext';
import { Route } from '@core/routes/Route';
import BreadCrumbs from '@components/BreadCrumbs';
import TotalTable from '@pages/ChainPerformance/TotalTable';
import Select from '@components/Select';
import { getTimeframeEnumValue, iChartItemShort, ID, Timeframe, UnitType } from '@models';
import TimeframesTable from '@pages/ChainPerformance/TimeframesTable';
import { useChainsHistoricTrend } from '@api/useChainsHistoricTrend';
import HeaderBar from '@components/HeaderBar';
import ButtonTabs from '@components/ButtonTabs';
import Switch from '@components/Switch';
import { equalInLC } from '@core/utils/string';
import TimeframesChart from '@components/TimeframesChart';
import { useFilterSort } from '@hooks/useFilterSort';
import { COLORS } from '@core/constants';
import { iSeries } from '@components/TimeframesChart/TimeframesChart';
import ValuesByTimeframeData from '@models/ValuesByTimeframeData';
import Loader from '@components/Loader';
import { getFormattedThousand } from '@core/utils/number';
import DataFallback from '@components/DataFallback';
import { useChainHistoricTrendPptReport } from '@api/useChainHistoricTrendPptReport';
import { components } from '@api/api';
import { iMainMetric } from '@models/Filter';
import { useUserContext } from '@core/UserContext';
import LockAccessOverlay from '@components/LockAccessOverlay';
import Footer from '@vms/Footer';
import { useChainPerformanceFilters } from '@core/selectors/useChainPerformanceFilters';
import BadgeDownloadPptButton from "@components/ButtonClipboardCopy/BadgeDownloadPptButton";

const ChainPerformanceContent: FC = () => {
    const {
        options: { mainMetrics },
        defaultFiltersPayload,
    } = useFiltersContext();
    const { fullAccess } = useUserContext();
    const unbrandedMetric = mainMetrics.find(i => equalInLC(i.title, 'unbranded pi'))!;
    const [selectedMetricId, setSelectedMetricId] = useState<ID>(unbrandedMetric.id);
    const [selectedUnit, setSelectedUnit] = useState<UnitType>(UnitType.Count);
    const [selectedPeriod, setSelectedPeriod] = useState<Timeframe>(Timeframe.Season);
    const [chartItems, setChartItems] = useState<iChartItemShort[]>([]);

    const filters = useChainPerformanceFilters();

    const payload = useMemo((): components['schemas']['ChainPerformanceFilterModel'] => {
        const indexType = selectedUnit === UnitType.Count ? null : selectedMetricId;

        return {
            ...filters,
            indexType: indexType as components['schemas']['FoodsFlavorsFilterModel']['indexType'],
            periodType: getTimeframeEnumValue(selectedPeriod),
        };
    }, [filters, selectedMetricId, selectedPeriod, selectedUnit]);

    const pptReport = useChainHistoricTrendPptReport(
        selectedPeriod,
        selectedUnit === UnitType.Count ? null : selectedMetricId,
        chartItems.map(i => i.id),
    );

    const { data, isLoading } = useChainsHistoricTrend(
        payload,
        selectedUnit === UnitType.Count ? false : !fullAccess
    );

    const { data: sortedData } = useFilterSort(
        data || [],
        'total',
        'name'
    );

    const chartData = useMemo((): iSeries[] => {
        return chartItems.map((i) => {
            const item = (data || []).find(item => i.id === item.id);

            return {
                ...i,
                values: new ValuesByTimeframeData({ apiModel: item?.data, timeframe: selectedPeriod }),
            };
        })
    }, [data, chartItems, selectedPeriod]);

    useEffect(() => {
        if (sortedData.length > 0 && chartItems.length === 0) {
            setChartItems(sortedData.slice(0, 5).map((i, index) => ({
                color: COLORS[index],
                id: i.id,
                title: i.name,
            })));
        }
    }, [sortedData]);

    useEffect(() => {
        setChartItems([]);
    }, [defaultFiltersPayload]);

    const addChartItem = useCallback((id: ID) => {
        const item = (data || []).find(i => i.id === id)!;
        const color = COLORS.find(c => chartItems.every(i => i.color !== c)) || 'black';

        setChartItems([
            ...chartItems,
            {
                id: item.id,
                title: item.name,
                color,
            },
        ]);
    }, [data, chartItems]);

    const removeChartItem = useCallback((id: ID) => {
        const itemIndex = chartItems.findIndex(i => i.id === id)!;

        setChartItems([
            ...chartItems.slice(0, itemIndex),
            ...chartItems.slice(itemIndex + 1),
        ]);
    }, [chartItems]);

    const selectedMetric = useMemo((): iMainMetric | undefined => {
        return mainMetrics.find(i => i.id === selectedMetricId);
    }, [mainMetrics, selectedMetricId]);

    const noAccess = useMemo(
        () => selectedUnit === UnitType.Pct && !fullAccess,
        [selectedUnit, fullAccess]
    )

    return (
        <>
            <div className="ChainPerformance" style={{ position: 'relative', minHeight: 'calc(100vh - 140px)' }}>
                <Wrapper>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            zIndex: 3,
                            position: 'relative',
                        }}>
                        <BreadCrumbs items={[
                            { link: Route.Home, title: 'Home', },
                            { link: '', title: 'Chain Performance', }
                        ]}/>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: '16px 0',
                        }}>
                            {fullAccess && selectedUnit === UnitType.Pct && (
                                <Select
                                    options={mainMetrics}
                                    value={[selectedMetricId]}
                                    closeOnOptionClick
                                    style={{ fontSize: 14 }}
                                    label={(
                                        <div
                                            style={{ padding: '0 2px', fontSize: 11 }}
                                            className="body-bg"
                                        >
                                            Display
                                        </div>
                                    )}
                                    onOptionClick={setSelectedMetricId}
                                />
                            )}
                            <Switch
                                leftLabel={{
                                    title: "Counts",
                                    id: UnitType.Count,
                                    icon: '#',
                                    color: 'blue',
                                }}
                                rightLabel={{
                                    title: "Ratings",
                                    id: UnitType.Pct,
                                    icon: '%',
                                    color: 'green',
                                }}
                                value={selectedUnit}
                                onChange={(value) => setSelectedUnit(value as UnitType)}
                                style={{ marginLeft: 20 }}
                            />
                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <HeaderBar style={{
                            marginBottom: 20,
                            height: 70,
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="font-medium" style={{ fontSize: 20 }}>
                                    Chain Activity: Historic Trend
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {selectedUnit === UnitType.Count ? 'New launches by' : (
                                    <>
                                        average&nbsp;
                                        <div className="color-dimyellow font-medium">{selectedMetric?.title.toUpperCase()}</div>
                                        &nbsp;by
                                    </>
                                )}
                                <ButtonTabs
                                    style={{ marginLeft: 10 }}
                                    data={[
                                        {
                                            id: Timeframe.Season,
                                            title: 'Season',
                                            buttonProps: {
                                                onClick: () => setSelectedPeriod(Timeframe.Season),
                                            }
                                        },
                                        {
                                            id: Timeframe.Year,
                                            title: 'Year',
                                            buttonProps: {
                                                onClick: () => setSelectedPeriod(Timeframe.Year),
                                            }
                                        },
                                    ]}
                                    activeItemId={selectedPeriod}
                                />
                            </div>
                        </HeaderBar>
                        <div
                            key={`${selectedUnit}-${selectedPeriod}-${selectedMetricId}`}
                            className="ChainPerformance__chart section"
                        >
                            {!isLoading && !noAccess && (
                                <BadgeDownloadPptButton
                                    style={{ top: 40 }}
                                    isBusy={pptReport.isLoading}
                                    onClick={() => pptReport.load()}
                                />
                            )}
                            {(isLoading || data === null) && (
                                <Loader/>
                            )}
                            {!isLoading && data !== null && chartData.length > 0 && (
                                <TimeframesChart
                                    data={chartData}
                                    timeframe={selectedPeriod}
                                    hasYAxis
                                    skipNullValues={selectedUnit === UnitType.Pct}
                                    valueFormatter={(value: number) => selectedUnit === UnitType.Pct
                                        ? `${Math.round(value)}%`
                                        : `${getFormattedThousand(value)}`
                                    }
                                    valueSuffix={selectedUnit === UnitType.Pct ? '%' : ''}
                                    removeSeries={removeChartItem}
                                />
                            )}
                            {!isLoading && data !== null && chartData.length === 0 && (
                                <DataFallback
                                    title="No chains selected"
                                    subTitle="Please select at least one item from the table below"
                                />
                            )}
                            {!isLoading && data?.length === 0 && (
                                <DataFallback
                                    title="No results"
                                />
                            )}
                        </div>
                        {noAccess && (
                            <LockAccessOverlay
                                subTitle="of menu launches from these chains"
                                toolLocation="Chain Performance"
                            />
                        )}
                    </div>
                    {!isLoading && data !== null && (
                        <TimeframesTable
                            data={data}
                            isLoading={isLoading}
                            selectedPeriod={selectedPeriod}
                            selectedUnit={selectedUnit}
                            selectedMetricId={selectedMetricId}
                            chartItems={chartItems}
                            addChartItem={addChartItem}
                            removeChartItem={removeChartItem}
                        />
                    )}
                    {selectedUnit === UnitType.Pct && (
                        <TotalTable selectedMetricId={selectedMetricId}/>
                    )}
                </Wrapper>
            </div>
            <FiltersPopup/>
            {noAccess ? (
                <Footer/>
            ) : (
                <ActionsBar>
                    <FiltersButton id="actions-bar-filters-popup-open"/>
                    <div/>
                    <div/>
                </ActionsBar>
            )}
        </>
    );
};

const ChainPerformance: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <ChainPerformanceContent/>;
};

export default ChainPerformance;