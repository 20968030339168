import { useState } from 'react';
import { paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { api } from '@hooks/useFetch';

export function useMyConceptsXlsReport() {
    const [isLoading, setIsLoading] = useState(false);

    const load = async () => {
        setIsLoading(true);
        const url: keyof paths = '/api/Export/ExcelConceptLockerExport';

        await downloadReport(api.request({
            method: 'post',
            url,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}