import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FiltersPopup from '@vms/FiltersPopup';
import { Route } from '@core/routes/Route';
import { iOption } from '@models';
import Wrapper from '@components/Wrapper';
import HeaderBar from '@components/HeaderBar';
import { SelectedOptionsProvider } from '@hooks/useSelectedOptions';
import TilesView from '@vms/TilesView';
import Button from '@components/Button';
import SelectAmong from '@vms/SelectAmong';
import SelectNorm from '@vms/SelectNorm';
import SelectPageView from '@vms/SelectPageView';
import CLTableView from '@vms/ItemsTable/CLTableView';
import CLPeopleView from '@vms/ItemsTable/CLPeopleView';
import SelectDisplay from '@vms/SelectDisplay';
import SelectMetric from '@vms/SelectMetric';
import { useFiltersContext } from '@core/FiltersContext';
import BreadCrumbs from '@components/BreadCrumbs';
import ChartView from '@vms/ChartView';
import { ButtonType } from '@components/Button/Button';
import SearchPageActionBar from '@vms/SearchPageActionBar/SearchPageActionBar';
import { components } from '@api/api';
import Search from '@pages/ConceptLocker/Search';
import { useConceptLockerSummary } from '@api/useConceptLockerSummary';
import { getFormattedThousand } from '@core/utils/number';
import ConceptLockerSummaryPopup from '@pages/ConceptLocker/ConceptLockerSummaryPopup';

const VIEWS: iOption[] = [
    { id: Route.ConceptLockerViewTiles, title: 'Tiles' },
    { id: Route.ConceptLocker, title: 'Table' },
    { id: Route.ConceptLockerViewPeople, title: 'People' },
    { id: Route.ConceptLockerViewChart, title: 'Chart' },
];

const AMONG_PAGES: Route[] = [
    Route.ConceptLocker,
    Route.ConceptLockerViewTiles,
    Route.ConceptLockerViewChart,
];

const METRIC_PAGES: Route[] = [
    Route.ConceptLockerViewPeople,
];

const NORM_PAGES: Route[] = [
    Route.ConceptLocker,
    Route.ConceptLockerViewTiles,
    Route.ConceptLockerViewPeople,
];

let isSummaryPopupShown = false;

const ConceptLockerComponent: FC = () => {
    const summary = useConceptLockerSummary();
    const [isSummaryPopupOpened, setIsSummaryPopupOpened] = useState(false);
    const [selectedItems, setSelectedItems] = useState<iOption[]>([]);
    const [appliedFilterPayload, setAppliedFilterPayload] = useState<components['schemas']['SearchFilterModel']>({});
    const { pathname } = useLocation();

    return (
        <SelectedOptionsProvider value={selectedItems} setValue={setSelectedItems}>
            <div className="ConceptLocker" style={{ position: 'relative', minHeight: 'calc(100vh - 140px)' }}>
                <Wrapper>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <BreadCrumbs items={[
                            { link: Route.Home, title: 'Home', },
                            { link: '', title: 'Concept Locker', }
                        ]}/>
                        {summary.data !== null && !!summary.data.inField && (
                            <div style={{ fontSize: 12 }}>
                                You have <b>{getFormattedThousand(summary.data.inField)} concepts</b> that are currently in field. They will appear here in your concept locker shortly.
                            </div>
                        )}
                    </div>
                    <HeaderBar className="ConceptLocker__header-bar">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                modifiers={['green']}
                                style={{ marginRight: 16 }}
                                onClick={() => setIsSummaryPopupOpened(true)}
                            >
                                VIEW SUMMARY
                            </Button>
                            <Button
                                type={ButtonType.Link}
                                link={Route.MyConcepts}
                                modifiers={['blue']}
                                style={{ marginRight: 16 }}
                            >
                                CREATE CONCEPTS
                            </Button>
                            <Search/>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <SelectPageView views={VIEWS}/>
                            {AMONG_PAGES.includes(pathname as Route) && <SelectAmong/>}
                            {METRIC_PAGES.includes(pathname as Route) && (
                                <>
                                    <SelectDisplay/>
                                    <SelectMetric/>
                                </>
                            )}
                            {NORM_PAGES.includes(pathname as Route) && <SelectNorm/>}
                        </div>
                    </HeaderBar>

                    {pathname === Route.ConceptLocker && (
                        <CLTableView setAppliedFilterPayload={setAppliedFilterPayload}/>
                    )}
                    {pathname === Route.ConceptLockerViewTiles && (
                        <TilesView setAppliedFilterPayload={setAppliedFilterPayload}/>
                    )}
                    {pathname === Route.ConceptLockerViewPeople && (
                        <CLPeopleView setAppliedFilterPayload={setAppliedFilterPayload}/>
                    )}
                    {pathname === Route.ConceptLockerViewChart && (
                        <ChartView setAppliedFilterPayload={setAppliedFilterPayload}/>
                    )}
                </Wrapper>
            </div>
            <FiltersPopup
                hideTilesSort={pathname !== Route.ConceptLockerViewTiles}
            />
            <SearchPageActionBar
                filtersPayload={appliedFilterPayload}
                useAmongRespondentType={AMONG_PAGES.includes(pathname as Route)}
            />
            {isSummaryPopupOpened && summary.data !== null && (
                <ConceptLockerSummaryPopup
                    onClose={() => setIsSummaryPopupOpened(false)}
                    data={summary.data}
                />
            )}
        </SelectedOptionsProvider>
    )
};

const ConceptLocker: FC = () => {
    const { isLoaded } = useFiltersContext();
    if (!isLoaded) return null;

    return <ConceptLockerComponent/>;
};

export default ConceptLocker;