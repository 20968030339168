import { FCX, ID, NormType } from "@models";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { GiHotMeal } from "react-icons/gi";
import Stars from '@components/Stars';
import { BiLinkExternal } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { copyToClipboard, elementToImage } from '@core/utils/image';
import { iItemInfo, iItemMetrics } from '@models/Item';
import { renderMetricValue } from '@core/utils/string';
import { useSelectedOptions } from '@hooks/useSelectedOptions';
import useItemDetailsLink from '@hooks/useItemDetailsLink';
import ChainLink from '@vms/ChainLink';
import Modal from '@components/Modal';
import MetricTooltip, {
    MetricDescriptionTooltip,
    MetricMainTooltip,
    MetricScoreTooltip
} from '@components/MetricTooltip';
import { FaRegCheckCircle, FaStickyNote } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import Popup from '@components/Popup';
import StatusIcon from '@components/StatusIcon';
import TileDownloadMenu from '@vms/Tile/TileDownloadMenu';
import { useContactUs } from '@api/useContactUs';
import { IconLock } from '@svg/index';
import Button from '@components/Button';

const Tile: FCX<{
    isExpanded?: boolean;
    expandToTop?: boolean;
    isHtmlRender?: boolean;
    noImage?: boolean;
    data: iItemInfo;
    metrics: iItemMetrics;
    isSelectionEnabled?: boolean;
    isSimpleMenu?: boolean;
    normType?: NormType;
    denyAlternativeChainOptions?: boolean;
}> = ({
    data,
    metrics,
    isExpanded,
    noImage,
    isHtmlRender,
    style,
    className,
    isSelectionEnabled,
    isSimpleMenu,
    expandToTop,
    normType = 'category',
    denyAlternativeChainOptions= false,
}) => {
    const { toggleItem, items, } = useSelectedOptions();

    const {
        id,
        uuid,
        chainId,
        price,
        dateString,
        chainName,
        title,
        imageUrl,
        legacyImageUrl,
        description,
        isConcept,
        isSurveyItem,
        status,
        note,
        hasAccess,
    } = data;
    const detailsLink = useItemDetailsLink(id, isConcept);

    const contactUs = useContactUs(`LTO Tile (${title} - ${chainName})`);
    const [isContactUsSent, setIsContactUsSent] = useState(false);

    const {
        score,
        viability,
    } = metrics;

    const isSelected = useMemo(() => items.some(item => item.id === id), [id, items]);
    const onSelect = useCallback(
        () => toggleItem({
            id,
            title,
            isBlocked: !hasAccess || !isSurveyItem,
        }),
        [id, title, toggleItem, hasAccess, isSurveyItem]
    );

    const [snippedImg, setSnippedImage] = useState<string>('');
    const [isThumbnailExpanded, setIsThumbnailExpanded] = useState(false);
    const [isSnipCopyNotificationShown, setIsSnipCopyNotificationShown] = useState(false);
    const [isImageFallback, setIsImageFallback] = useState(false);

    const copySnippedImage = (dataUrl: string) => {
        setIsSnipCopyNotificationShown(true);
        setTimeout(() => {
            setIsSnipCopyNotificationShown(false)
        }, 3000);
        copyToClipboard(dataUrl);
    };

    const snipImage = async () => {
        let dataUrl: string = '';

        try {
            dataUrl = await elementToImage(
                <Tile
                    data={{
                        ...data,
                        imageUrl: legacyImageUrl,
                    }}
                    metrics={metrics}
                    isExpanded
                    isHtmlRender
                />
            );
        } catch (error) {
            dataUrl = await elementToImage(
                <Tile
                    data={{
                        ...data,
                        imageUrl: '',
                    }}
                    metrics={metrics}
                    isExpanded
                    isHtmlRender
                />
            );
        }

        setSnippedImage(dataUrl);
        copySnippedImage(dataUrl);
    };

    return (
        <div
            className={cn(
                className,
                "Tile",
                isSelectionEnabled && 'is-selectable',
                isSelectionEnabled && isSelected && 'is-selected',
                isExpanded && 'is-expanded',
                expandToTop && 'is-expand-to-top',
                isHtmlRender && 'is-html-render',
            )}
            style={style}
        >
            <div className="Tile__inner">
                <div className="Tile__top-header">
                    <div>{dateString}</div>
                    {isConcept && (
                        <div className="Tile__label">Concept Locker</div>
                    )}
                    <div>{price}</div>
                    {isSelectionEnabled && (
                        <div
                            className="Tile__checkbox"
                            onClick={onSelect}
                        >
                            <BsCheck/>
                        </div>
                    )}
                </div>
                <div className="Tile__header">
                    {isHtmlRender ? (
                        <div className="Tile__sup-title">
                            {chainName}
                        </div>
                    ) : (
                        <ChainLink
                            chainId={chainId as ID}
                            className="Tile__sup-title"
                            disableAlternativeOptions={denyAlternativeChainOptions}
                        >
                            {chainName}
                        </ChainLink>
                    )}
                    {!isHtmlRender && isSurveyItem && hasAccess ? (
                        <Link to={detailsLink} className="Tile__title">
                            {title}
                        </Link>
                    ) : (
                        <div className="Tile__title">
                            {title}
                        </div>
                    )}
                </div>
                {!noImage && (
                    <div className="Tile__image-container">
                        {(isImageFallback || !imageUrl) ? (
                            <div className="Tile__image">
                                <GiHotMeal style={{ width: 100, height: 100 }}/>
                            </div>
                        ) : (
                            <img
                                alt="test"
                                src={imageUrl.replace('&w=200&h=200', '&w=300&h=170')}
                                className="Tile__image"
                                onError={() => setIsImageFallback(true)}
                            />
                        )}
                        {!isHtmlRender && (
                            <div className="Tile__menu">
                                {isSurveyItem && hasAccess && (
                                    <Link to={detailsLink} className="Tile__menu-item">
                                        VIEW ITEM DETAILS
                                        <BiLinkExternal style={{
                                            position: 'absolute',
                                            right: 6,
                                            width: 16,
                                            height: 16,
                                            top: 7,
                                        }}/>
                                    </Link>
                                )}

                                {!isSimpleMenu && (
                                    <>
                                        {isSurveyItem && hasAccess && (
                                            <TileDownloadMenu itemId={id}/>
                                        )}

                                        <div
                                            onClick={() => setIsThumbnailExpanded(true)}
                                            className="Tile__menu-item"
                                        >
                                            EXPAND THUMBNAIL
                                        </div>

                                        <div
                                            className={cn(
                                                "Tile__menu-item",
                                                isSnipCopyNotificationShown && 'is-status'
                                            )}
                                            onClick={snippedImg ? () => copySnippedImage(snippedImg) : snipImage}
                                        >
                                            {isSnipCopyNotificationShown ? 'Screenshot is in clipboard' : (
                                                <>{snippedImg ? 'SNIPPED! CLICK TO COPY' : 'SNIP IMAGE'}</>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className={cn(
                    'Tile__description',
                    !isSurveyItem && 'is-extended',
                    !!status && 'is-smaller',
                    !isSurveyItem && !!status && 'is-padded',
                )}>
                    {description}
                </div>
                {!!status && (
                    <div
                        className={cn(
                            "Tile__status",
                            !isSurveyItem && 'is-padded',
                        )}
                    >
                        <StatusIcon status={status}/>&nbsp;&nbsp;{status}
                        {!!note && (
                            <>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    data-tooltip-id={`tile-${uuid}-status-note`}
                                >
                                    <FaStickyNote/>
                                </div>
                                {!isHtmlRender && (
                                    <Popup hasWrapper={false}>
                                        <Tooltip
                                            id={`tile-${uuid}-status-note`}
                                            place={'top-end'}
                                            positionStrategy={'fixed'}
                                            delayShow={400}
                                            style={{ zIndex: 4, fontSize: 14, background: '#141932' }}
                                        >
                                            {note}
                                        </Tooltip>
                                    </Popup>
                                )}
                            </>
                        )}
                    </div>
                )}
                {isSurveyItem && (
                    <div className="Tile__scores TileMetrics">
                        {hasAccess && (
                            <>
                                <div
                                    className="TileMetrics__thumb TileMetrics__values TileMetrics__rows"
                                    data-tooltip-id={`tile-${uuid}-value-info`}
                                >
                                    {!isHtmlRender && (
                                        <MetricTooltip id={`tile-${uuid}-value-info`}>
                                            <MetricDescriptionTooltip/>
                                        </MetricTooltip>
                                    )}
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-title">Unbranded PI:</div>
                                        <div
                                            className="TileMetrics__row-value">{renderMetricValue(metrics.unbrandedValue)}</div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-title">Branded PI:</div>
                                        <div
                                            className="TileMetrics__row-value">{renderMetricValue(metrics.brandedValue)}</div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-title">Uniqueness:</div>
                                        <div
                                            className="TileMetrics__row-value">{renderMetricValue(metrics.uniquenessValue)}</div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-title">Frequency:</div>
                                        <div
                                            className="TileMetrics__row-value">{renderMetricValue(metrics.frequencyValue)}</div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-title">Draw:</div>
                                        <div
                                            className="TileMetrics__row-value">{renderMetricValue(metrics.drawValue)}</div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-title">Value:</div>
                                        <div
                                            className="TileMetrics__row-value">{renderMetricValue(metrics.valueValue)}</div>
                                    </div>
                                </div>
                                <div
                                    className="TileMetrics__thumb TileMetrics__stars TileMetrics__rows"
                                    data-tooltip-id={`tile-${uuid}-main-info`}
                                >
                                    {!isHtmlRender && (
                                        <MetricTooltip id={`tile-${uuid}-main-info`}>
                                            <MetricMainTooltip
                                                data={data}
                                                normType={normType}
                                            />
                                        </MetricTooltip>
                                    )}
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-rating">
                                            <Stars count={metrics.unbrandedStarsCount}/>
                                        </div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-rating">
                                            <Stars count={metrics.brandedStarsCount}/>
                                        </div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-rating">
                                            <Stars count={metrics.uniquenessStarsCount}/>
                                        </div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-rating">
                                            <Stars count={metrics.frequencyStarsCount}/>
                                        </div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-rating">
                                            <Stars count={metrics.drawStarsCount}/>
                                        </div>
                                    </div>
                                    <div className="TileMetrics__row">
                                        <div className="TileMetrics__row-rating">
                                            <Stars count={metrics.valueStarsCount}/>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="TileMetrics__score TileMetrics__thumb"
                                    data-tooltip-id={`tile-${uuid}-score-info`}
                                >
                                    {!isHtmlRender && (
                                        <MetricTooltip id={`tile-${uuid}-score-info`}>
                                            <MetricScoreTooltip viability={viability}/>
                                        </MetricTooltip>
                                    )}
                                    <div className="TileMetrics__score-title">score</div>
                                    <div className="TileMetrics__score-value">{score}</div>
                                    <div className="TileMetrics__score-label">{viability}</div>
                                </div>
                            </>
                        )}
                        {!hasAccess && (
                            <div className={cn(
                                "Tile__access-overlay",
                                isContactUsSent && 'is-sent',
                            )}>
                                <div className="Tile__access-overlay-top">
                                    {isContactUsSent ? (
                                        <FaRegCheckCircle className="Tile__access-overlay-icon"/>
                                    ) : (
                                        <IconLock className="Tile__access-overlay-icon"/>
                                    )}
                                    <div className="Tile__access-overlay-text">
                                        {isContactUsSent ? 'Request Sent' : (
                                            <>
                                                Unlock consumer ratings with
                                                <br/>
                                                an upgraded subscription
                                            </>
                                        )}
                                    </div>
                                </div>
                                {!isHtmlRender && (
                                    <Button
                                        modifiers={['blue']}
                                        onClick={() => {
                                            setIsContactUsSent(true);
                                            contactUs.doFetch();
                                        }}
                                        style={{
                                            display: isContactUsSent ? 'none' : undefined,
                                        }}
                                    >
                                        CONTACT US
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isThumbnailExpanded && (
                <Modal onClose={() => setIsThumbnailExpanded(false)}>
                    <img
                        alt="test"
                        src={imageUrl.replace('&w=200&h=200', '&w=300&h=170')}
                        onError={() => setIsImageFallback(true)}
                    />
                </Modal>
            )}
        </div>
    );
};

export default Tile