import { AxiosResponse, AxiosHeaders } from 'axios';
import { toast } from 'react-toastify';

type NavigatorIncludesMS = Navigator & { msSaveBlob?: (blob: Blob, filename?: string) => void };

export function downloadFile(url: string, filename?: string): void {
    const link = document.createElement('a');

    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.download = filename || "true";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function downloadBlobFile(blob: Blob, filename?: string) {
    const navigator = window.navigator as NavigatorIncludesMS;

    if (navigator.msSaveBlob && typeof navigator.msSaveBlob !== 'undefined') {
        navigator.msSaveBlob(blob, filename);
    } else {
        let URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            downloadFile(downloadUrl, filename);
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
}

export const getFilenameFromContentDisposition = (disposition: string | null): string => {
    let filename = "";
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const matches = disposition.match(filenameRegex);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
}

export async function downloadReport (request: Promise<AxiosResponse>) {
    try {
        const result = await request;
        if (result.status === 200) {
            const blob = result.data;
            const disposition = (result.headers as AxiosHeaders).get('Content-Disposition');
            const filename = getFilenameFromContentDisposition(disposition as string);
            downloadBlobFile(blob, filename);
            toast.success('Downloaded successfully!');
        }
    } catch (err) {
        toast.error(`Something went wrong`);
    }
    return;
}