import React, { useState } from 'react';
import cn from 'classnames';
import Button from '@components/Button';
import { FCX, ID } from '@models';
import { components, paths } from '@api/api';
import { IconSpinner } from '@svg/index';
import { FiTrash2 } from 'react-icons/fi';
import { FaRegCircleDown } from 'react-icons/fa6';
import { api } from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import { toast } from 'react-toastify';
import { useItemLikesReport } from '@api/useItemLikesReport';
import Scrollbar from 'react-scrollbars-custom';

const HighlightingModal: FCX<{
    name: string;
    answers: components["schemas"]["FilteredOpenEndDto"][];
    responses: components["schemas"]["NameValueModel"][];
    isDislike?: boolean;
    itemId: number;
    onRemoveAnswer: (answerId: ID) => void;
    onCloseClick: () => void;
}> = ({
    className,
    name = '',
    isDislike = false,
    responses,
    answers,
    itemId,
    onCloseClick,
    onRemoveAnswer,
}) => {
    const [requestId, setRequestId] = useState<number | undefined>(undefined);
    const report = useItemLikesReport(itemId);

    const handleRemoveItemClick = (answer: components["schemas"]["FilteredOpenEndDto"]) => () => {
        setRequestId(answer.id);

        const url: keyof paths = '/api/Items/DeleteAnswer';

        api.request({
            url: getApiLink(url, {
                id: answer.id,
            }),
            method: 'post',
        })
            .then(() => {
                onRemoveAnswer(answer.id!);
            })
            .catch(() => {
                toast.error(`Something went wrong`);
            })
            .finally(() => {
                setRequestId(undefined);
            });
    }

    return (
        <div className={cn('highlighting-modal', className)}>
            <div className="highlighting-modal-header">
                % Highlighting as a <span className={cn('card-highlighting-name', {
                'card-highlighting-name--dislike': isDislike
            })}>{name}</span>
            </div>
            <div className="highlighting-modal-content">
                {(Array.isArray(responses) === true) && (
                    <div className="highlighting-modal-words">
                        <Scrollbar noScrollX>
                            <div className="highlighting-modal-words__list">
                                {responses.map((response, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="highlighting-modal-words__item">
                                            <div
                                                className="highlighting-modal-words__text"
                                                title={response.name || ''}
                                            >
                                                {response.name}
                                            </div>
                                            <div className="highlighting-modal-words__freq">
                                                {(response.value === null) ? `-` : `${Math.round(Number(response.value))}%`}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </Scrollbar>
                    </div>
                )}
                {(Array.isArray(answers) === true && answers.length > 0) && (
                    <div className="highlighting-modal-answers-container">
                        <div className="highlighting-modal-caption">IN THEIR OWN WORDS</div>
                        <div className="highlighting-modal-answers">
                            <div className="highlighting-modal-answers__list">
                                <Scrollbar noScrollX>
                                    {answers
                                        .filter((answer, index) => (answer.isDeleted === false))
                                        .map((answer, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="highlighting-modal-answers__item">
                                                    {answer.text}

                                                    {(requestId === answer.id) ? (
                                                        <IconSpinner className="highlighting-modal-answers__spiner"/>
                                                    ) : (
                                                        <button
                                                            className="highlighting-modal-answers__button"
                                                            onClick={handleRemoveItemClick(answer)}
                                                        >
                                                            <FiTrash2/>
                                                        </button>
                                                    )}

                                                </div>
                                            )
                                        })}
                                </Scrollbar>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="highlighting-modal-bottom">
                <Button
                    modifiers={['blue', 'naked',]}
                    onClick={onCloseClick}
                    className="font-bold"
                >
                    CLOSE
                </Button>
                <Button
                    link={`/ConceptLocker/ExportLikes?itemId=${itemId}`}
                    modifiers={['green']}
                    className="font-bold"
                    onClick={report.load}
                    style={{ position: 'relative', overflow: 'hidden', width: 140 }}
                >
                    {report.isLoading ? (
                        <>
                            Downloading...
                            <div className="ProgressLoader is-inside-button"/>
                        </>
                    ) : (
                        <>
                            <FaRegCircleDown className="icon" style={{ width: 20, height: 20 }}/>
                            &nbsp;DOWNLOAD
                        </>
                    )}
                </Button>
            </div>

        </div>
    );
};

export default HighlightingModal;