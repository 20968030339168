import { FCX } from '@models';
import { BsArrowUp } from 'react-icons/bs';
import Button from '@components/Button';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';

const ScrollTopButton: FCX = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

    useEffect(() => {
        const handleDocumentScroll = () => {
            setIsScrollButtonVisible((document.scrollingElement?.scrollTop || 0) > 20 );
        };

        handleDocumentScroll();
        document.addEventListener('scroll', handleDocumentScroll);

        return () => {
            document.removeEventListener('scroll', handleDocumentScroll);
        }
    }, []);
    return (
        <Button
            className={cn("Layout__scroll-top", isScrollButtonVisible && 'is-shown')}
            onClick={() => {
                document.scrollingElement?.scrollTo({ top: 0, behavior: 'smooth' });
            }}
        >
            <BsArrowUp style={{ width: 20, height: 20 }}/>
        </Button>
    )
};

export default ScrollTopButton;