import { TableContext } from '@components/Table/TableContext';
import { FCX, ID } from '@models';
import { components } from '@api/api';
import { memo, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { getFormattedThousand } from '@core/utils/number';
import TableTooltip from '@vms/TableTooltip';
import { TABLE_TOOLTIPS } from '@core/constants';
import StarsCell from '@vms/ItemsTable/StarCell';
import { Columns } from './TotalTable';
import { useFiltersContext } from '@core/FiltersContext';
import { useNavigate } from 'react-router-dom';
import { FiltersVariant } from '@models/Filter';
import { Route } from '@core/routes/Route';
import { markMatch } from '@core/utils/string';
import { MainMetrics } from '@models/MainMetrics';
import KeywordSettings from '@vms/KeywordSettings';

function getDefaultStyle(isActive?: boolean): iTableConfigItem['styles'] {
    return {
        cell: {
            justifyContent: 'center',
            backgroundColor: isActive ? '#fffae5' : undefined,
            flex: '0 0 10%',
        },
    };
}

const TotalTableConfig: FCX<{
    data: Array<components['schemas']['KeywordIndexViewModel']>;
    searchQuery: string;
    highlightedMetricId: ID;
}> = ({
    data,
    children,
    searchQuery,
    highlightedMetricId,
}) => {
    const navigate = useNavigate();
    const {
        resetSpecificValue,
        options: { mainMetrics },
        value: {
            among,
            date,
            categories,
            segments,
            chainId,
        },
    } = useFiltersContext();

    const [
        unbrandedPIId,
        brandedPIId,
        uniquenessId,
        frequencyId,
        drawId,
        valueId,
    ] = useMemo(() => {
        return [
            mainMetrics.find(i => i.title === MainMetrics.UnbrandedPI)?.id,
            mainMetrics.find(i => i.title === MainMetrics.BrandedPI)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Uniqueness)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Frequency)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Draw)?.id,
            mainMetrics.find(i => i.title === MainMetrics.Value)?.id,
        ];
    }, [mainMetrics]);

    const config = useMemo((): iTableConfigItem[] => [
        {
            key: Columns.Name,
            title: 'Flavor / Ingredient',
            sortingColName: Columns.Name,
            Value: ({ index }) => (
                <>
                    <KeywordSettings
                        appliedKeywords={[data[index].name || '']}
                        style={{
                            marginRight: 10,
                            flexBasis: 20,
                            height: 20,
                        }}
                    />
                    <div
                        className="link lines-3"
                        onClick={() => {
                            resetSpecificValue(FiltersVariant.Homepage, {
                                chainId: chainId,
                                search: chainId ? null : data[index].name,
                                additionalSearch: chainId ? data[index].name : null,
                                among,
                                date,
                                categories,
                                segments,

                            });
                            navigate(Route.Home);
                        }}
                        dangerouslySetInnerHTML={{ __html: markMatch(data[index].name || '', searchQuery) }}
                    />
                </>
            ),
        },
        {
            key: Columns.ItemsCount,
            title: (<>
                # of items
                <TableTooltip
                    title="# Of Items"
                    id="keywords-table-number-of-items"
                >
                    {TABLE_TOOLTIPS.get('# of units')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-number-of-items'
            } : {})),
            sortingColName: Columns.ItemsCount,
            Value: ({ index }) => (
                <>{getFormattedThousand(data[index].itemsCount)}</>
            ),
            styles: getDefaultStyle(),
        },
        {
            key: Columns.UnbrandedPi,
            title: (<>
                Unbranded PI
                <TableTooltip
                    title="Unbranded PI"
                    id="keywords-table-unbranded-pi"
                >
                    {TABLE_TOOLTIPS.get('unbranded pi')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-unbranded-pi'
            } : {})),
            sortingColName: Columns.UnbrandedPi,
            Value: ({ index }) => (
                <StarsCell value={data[index].unbrandedPi || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === unbrandedPIId),
        },
        {
            key: Columns.BrandedPi,
            title: (<>
                Branded PI
                <TableTooltip
                    title="Branded PI"
                    id="keywords-table-branded-pi"
                >
                    {TABLE_TOOLTIPS.get('branded pi')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-branded-pi'
            } : {})),
            sortingColName: Columns.BrandedPi,
            Value: ({ index }) => (
                <StarsCell value={data[index].brandedPi || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === brandedPIId),
        },
        {
            key: Columns.Uniqueness,
            title: (<>
                Uniqueness
                <TableTooltip
                    title="Uniqueness"
                    id="keywords-table-uniqueness"
                >
                    {TABLE_TOOLTIPS.get('uniqueness')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-uniqueness'
            } : {})),
            sortingColName: Columns.Uniqueness,
            Value: ({ index }) => (
                <StarsCell value={data[index].uniqueness || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === uniquenessId),
        },
        {
            key: Columns.Frequency,
            title: (<>
                Frequency
                <TableTooltip
                    title="Frequency"
                    id="keywords-table-frequency"
                >
                    {TABLE_TOOLTIPS.get('frequency')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-frequency'
            } : {})),
            sortingColName: Columns.Frequency,
            Value: ({ index }) => (
                <StarsCell value={data[index].frequency || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === frequencyId),
        },
        {
            key: Columns.Draw,
            title: (<>
                Draw
                <TableTooltip
                    title="Draw"
                    id="keywords-table-draw"
                >
                    {TABLE_TOOLTIPS.get('draw')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-draw'
            } : {})),
            sortingColName: Columns.Draw,
            Value: ({ index }) => (
                <StarsCell value={data[index].draw || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === drawId),
        },
        {
            key: Columns.Value,
            title: (<>
                Value
                <TableTooltip
                    title="Value"
                    id="keywords-table-value"
                >
                    {TABLE_TOOLTIPS.get('value')}
                </TableTooltip>
            </>),
            getAttrs: (index => (index === -1 ? {
                'data-tooltip-id': 'keywords-table-value'
            } : {})),
            sortingColName: Columns.Value,
            Value: ({ index }) => (
                <StarsCell value={data[index].value || 0} starsCount={0}/>
            ),
            styles: getDefaultStyle(highlightedMetricId === valueId),
        },
    ], [
        data,
        resetSpecificValue,
        navigate,
        searchQuery,
        unbrandedPIId,
        brandedPIId,
        uniquenessId,
        frequencyId,
        drawId,
        valueId,
        highlightedMetricId,
        among,
        date,
        categories,
        segments,
        chainId,
    ]);

    return (
        <TableContext.Provider value={{
            config,
        }}>
            {children}
        </TableContext.Provider>
    );
};

export default memo(TotalTableConfig);