import { memo } from 'react';
import { FCX, ID } from '@models';
import { renderMetricValue } from '@core/utils/string';
import Stars from '@components/Stars';
import './StarCell.scss';

const StarsCell: FCX<{
    value: number;
    starsCount: number;
    uuid?: ID;
}> = ({
    value,
    starsCount,
    uuid,
}) => {
    return (
        <>
            <div className="StarCell">
                {renderMetricValue(value)}
                <Stars count={starsCount}/>
            </div>
            {starsCount > 0 && uuid && (
                <div
                    className="Overflow"
                    style={{ cursor: 'pointer' }}
                    data-tooltip-id={uuid as string}
                />
            )}
        </>
    );
}

export default memo(StarsCell);