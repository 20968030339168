import React, { FC, useMemo, useState } from 'react';
import Wrapper from '@components/Wrapper';
import { Route } from '@core/routes/Route';
import BreadCrumbs from '@components/BreadCrumbs';
import HeaderBar from '@components/HeaderBar';
import Button from '@components/Button';
import { ButtonType } from '@components/Button/Button';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { useFoodWatch } from '@api/useFoodWatch';
import Table from '@components/Table';
import dayjs from 'dayjs';
import { useFiltersContext } from '@core/FiltersContext';
import { useGetFrequencyString } from '@hooks/useGetFrequencyString';
import { ID } from '@models';
import Modal from '@components/Modal';

const FoodWatchContent: FC = () => {
    const { data, isLoading, deleteItem } = useFoodWatch();
    const getFrequency = useGetFrequencyString();

    const [itemIdToDelete, setItemIdToDelete] = useState<ID | null>(null);

    const config = useMemo((): iTableConfigItem[] => [
        {
            key: 'name',
            title: 'Name',
            Value: ({ index }) => <>
                {data[index].name}
            </>,
        },
        {
            key: 'frequency',
            title: 'Frequency',
            Value: ({ index }) => <>
                {getFrequency(data[index].frequency as ID)}
            </>,
            styles: {
                cell: {
                    flex: '0 0 250px'
                }
            }
        },
        {
            key: 'date-modified',
            title: 'Last Modified',
            Value: ({ index }) => <>
                {
                    !!data[index].modifyDate
                        ? dayjs(data[index].modifyDate).format('MM/DD/YYYY')
                        : '-'
                }
            </>,
            styles: {
                cell: {
                    flex: '0 0 250px'
                }
            }
        },
        {
            key: 'date-created',
            title: 'Date Created',
            Value: ({ index }) => <>
                {dayjs(data[index].createdDate).format('MM/DD/YYYY')}
            </>,
            styles: {
                cell: {
                    flex: '0 0 250px'
                }
            }
        },
        {
            key: 'actions',
            title: '',
            Value: ({ index }) => <>
                <Button
                    modifiers={['red', 'naked']}
                    onClick={() => setItemIdToDelete(data[index].id || null)}
                >
                    delete
                </Button>
                <Button
                    type={ButtonType.Link}
                    modifiers={['blue', 'naked']}
                    link={Route.FoodWatchItemEdit.replace(':id', `${data[index].id}`)}
                >
                    edit
                </Button>
            </>,
            styles: {
                cell: {
                    flex: '0 0 150px',
                    justifyContent: 'flex-end',
                }
            }
        },
    ], [data, getFrequency, setItemIdToDelete]);

    const itemToDelete = useMemo(() => {
        if (itemIdToDelete === null || data.length === 0) return null;

        return data.find(i => i.id === itemIdToDelete);
    }, [itemIdToDelete, data]);

    return (
        <div className="FoodWatch" style={{ position: 'relative', minHeight: 'calc(100vh - 140px)' }}>
            <Wrapper>
                <BreadCrumbs items={[
                    { link: Route.Home, title: 'Home', },
                    { link: '', title: 'Food Watch', }
                ]}/>
                <HeaderBar style={{
                    marginBottom: 20,
                    height: 60,
                    position: 'sticky',
                    top: 80,
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="font-medium color-dimyellow"
                                style={{ fontSize: 24, marginRight: 10 }}
                            >
                                Food Watch
                            </div>
                            <div className="header-sub-title" style={{ paddingTop: 8 }}>
                                Get emails about your target accounts and categories
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Button
                                modifiers={['blue']}
                                type={ButtonType.Link}
                                link={Route.FoodWatchItemCreate}
                            >
                                CREATE NEW
                            </Button>
                        </div>
                    </div>
                </HeaderBar>
                <TableContext.Provider value={{
                    config,
                }}>
                    <Table
                        rowsCount={isLoading ? 0 : data.length}
                        isLoading={isLoading}
                        noResultsTitle="Looks like you don't have Food Watch set up yet"
                        noResultsDescription="Click the Create New button to get started"
                    />
                </TableContext.Provider>
            </Wrapper>
            {itemIdToDelete !== null && (
                <Modal
                    onClose={() => setItemIdToDelete(null)}
                    style={{
                        width: 560,
                    }}
                >
                    <div
                        className="font-medium"
                        style={{
                            textAlign: 'center',
                            fontSize: 30,
                            lineHeight: '40px',
                            marginBottom: 10,
                        }}
                    >
                        DELETE
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}>
                        Are you sure you want to delete your list?
                        <div className="font-medium" style={{ fontSize: 18 }}>
                            "{itemToDelete?.name}"
                        </div>
                    </div>
                    <br/>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            modifiers={['blue', 'naked']}
                            onClick={() => setItemIdToDelete(null)}
                        >
                            CANCEL
                        </Button>
                        &nbsp;
                        <Button
                            modifiers={['red']}
                            onClick={() => {
                                deleteItem(itemIdToDelete);
                                setItemIdToDelete(null);
                            }}
                        >
                            DELETE LIST
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

const FoodWatch: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <FoodWatchContent/>;
}

export default FoodWatch;