import { memo } from 'react';
import { FCX, NormType } from '@models';
import { iItemInfo } from '@models/Item';
import Stars from '@components/Stars';
import { getFormattedThousand } from '@core/utils/number';

const MetricMainTooltip: FCX<{
    data: iItemInfo;
    normType: NormType;
}> = ({
    data,
    normType,
}) => {

    const {
        segmentName, segmentCount,
        categoryName, categoryCount,
        chainName, chainCount,
        totalCount,
    } = data;
    return (
        <div className="metric-main-tooltip">
            <div className="metric-main-tooltip__description">
                <span>STAR RATINGS</span> for each metric show how this item compares to&nbsp;
                {normType === 'all' && (
                    <span className="important">
                        {getFormattedThousand(totalCount)} other items
                    </span>
                )}
                {normType === 'segment' && (
                    <span className="important">
                        {getFormattedThousand(segmentCount)} other {segmentName} items
                    </span>
                )}
                {normType === 'category' && (
                    <span className="important">
                        {getFormattedThousand(categoryCount)} other {categoryName}
                    </span>
                )}
                {normType === 'chain' && (
                    <span className="important">
                        {getFormattedThousand(chainCount)} other {chainName} items
                    </span>
                )}
                &nbsp;{normType === 'chain' ? 'that have debuted there.' : 'that have debuted at major chains.'}
            </div>

            <div className="metric-main-tooltip-legend">
                <div className="metric-main-tooltip-legend__item">
                    <div className="metric-main-tooltip-legend__score">
                        <div className="star-rating-meter">
                            <Stars gap={0.1} style={{ flexDirection: 'row-reverse'}} count={5}/>
                        </div>
                    </div>
                    <div className="metric-main-tooltip-legend__name">Top Performer</div>
                    <div className="display display--top-performer">{'>'}90<sup>th</sup></div>
                </div>
                <div className="metric-main-tooltip-legend__item">
                    <div className="metric-main-tooltip-legend__score">
                        <div className="star-rating-meter">
                            <Stars gap={0.1} style={{ flexDirection: 'row-reverse'}} count={4}/>
                        </div>
                    </div>
                    <div className="metric-main-tooltip-legend__name">Above Average</div>
                    <div className="display display--above-average">70-90<sup>th</sup></div>
                </div>

                <div className="metric-main-tooltip-legend__item">
                    <div className="metric-main-tooltip-legend__score">
                        <div className="star-rating-meter">
                            <Stars gap={0.1} style={{ flexDirection: 'row-reverse'}} count={3}/>
                        </div>
                    </div>
                    <div className="metric-main-tooltip-legend__name">Average</div>
                    <div className="display display--average">30-70<sup>th</sup></div>
                </div>

                <div className="metric-main-tooltip-legend__item">
                    <div className="metric-main-tooltip-legend__score">
                        <div className="star-rating-meter">
                            <Stars gap={0.1} style={{ flexDirection: 'row-reverse'}} count={2}/>
                        </div>
                    </div>
                    <div className="metric-main-tooltip-legend__name">Below Average</div>
                    <div className="display display--below-average">10-30<sup>th</sup></div>
                </div>

                <div className="metric-main-tooltip-legend__item">
                    <div className="metric-main-tooltip-legend__score">
                        <div className="star-rating-meter">
                            <Stars gap={0.1} style={{ flexDirection: 'row-reverse'}} count={1}/>
                        </div>
                    </div>
                    <div className="metric-main-tooltip-legend__name">Weak Performer</div>
                    <div className="display display--weak-performer">
                        {'<'}10<sup>th</sup></div>
                </div>
            </div>
        </div>
    );
};

export default memo(MetricMainTooltip);