import { FCX, ID, Season, UnitType } from '@models';
import Loader from '@components/Loader';
import React, { ReactNode, useMemo } from 'react';
import { useSeasonalBySegments } from '@api/useSeasonalBySegments';
import { getFormattedThousand } from '@core/utils/number';
import { iBarChartItem } from '@components/BarChart/BarChart';
import BarChart from '@components/BarChart';
import DataFallback from '@components/DataFallback';
import { useCategoryActivityBySegment } from '@api/useCategoryActivityBySegment';

const LaunchesBySegments: FCX<{
    categoryId: ID;
    renderCategoryName: ReactNode;
    selectedUnit: UnitType;
    isGlobalLoading: boolean;
}> = ({
    categoryId,
    renderCategoryName,
    selectedUnit,
}) => {
    const { isLoading, data: rawData } = useCategoryActivityBySegment(categoryId);

    const data = useMemo(
        (): iBarChartItem[] => {
            return rawData.map(i => ({
                id: i.id,
                title: i.name,
                value: selectedUnit === UnitType.Count ? i.count : i.percentage,
            }))
        },
        [selectedUnit, rawData]
    );

    return (
        <div className="card" style={{ flex: '0 1 100%', marginRight: 20, }}>
            <div className="card-header">
                {renderCategoryName}&nbsp;Launches by Segment
            </div>
            {isLoading ? (
                <div style={{ padding: 20 }}>
                    <div style={{ position: 'relative', height: 310 }}>
                        <Loader/>
                    </div>
                </div>
            ) : (
                <div className="card-content" style={{ height: 350, position: 'relative' }}>
                    {data.some(i => i.value > 0) ? (
                        <BarChart
                            className="font-medium"
                            style={{ padding: '20px 0'}}
                            data={data}
                            valueFormatter={(value) => selectedUnit === UnitType.Pct
                                ? `${Math.round(value)}%`
                                : getFormattedThousand(value)
                            }
                            stretch
                        />
                    ) : (
                        <DataFallback
                            style={{ opacity: isLoading ? 0 : 1, height: '100%' }}
                            title="No results"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default LaunchesBySegments;