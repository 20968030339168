import { FCX, ID } from '@models';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFiltersContext } from '@core/FiltersContext';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FiltersVariant, iFilterValue } from '@models/Filter';
import { PAGES_HOME } from '@core/constants';
import { Route } from '@core/routes/Route';
import cn from 'classnames';
import useOutsideClick from '@hooks/useOutsideClick';
import Button from '@components/Button';
import { IconFileSearch, IconProfileChart } from '@svg/index';
import { ButtonType } from '@components/Button/Button';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { UiSettingsKey, useUserContext } from "@core/UserContext";
const ChainLink: FCX<{
    chainId: ID | undefined;
    disableAlternativeOptions?: boolean;
    additionalFilters?: Partial<iFilterValue>;
}> = ({
    children,
    chainId,
    className,
    disableAlternativeOptions = false,
    additionalFilters,
}) => {
    const { uiSettings: { hiddenElements }} = useUserContext();
    const uuid = useMemo(() => uuidv4(), [])
    const location = useLocation();
    const history = useNavigate();
    const [isOpened, setIsOpened] = useState(false);
    const ref = useRef(null);

    useOutsideClick({
        ref,
        fn: () => setIsOpened(false),
    });

    const {
        updateSpecificValue,
    } = useFiltersContext();

    const searchByChain = useCallback(() => {
        updateSpecificValue(FiltersVariant.Homepage, {
            chainId,
            search: null,
            additionalSearch: null,
            ...additionalFilters,
        });
        if (!PAGES_HOME.includes(location.pathname as Route)) {
            history(Route.Home);
        }
    }, [updateSpecificValue, location, history, chainId, additionalFilters]);

    if (disableAlternativeOptions || hiddenElements.includes(UiSettingsKey.PageChainProfile)) {
        return (
            <div
                ref={ref}
                className={cn(className)}
                onClick={searchByChain}
            >
                {children}
            </div>
        );
    }

    return (
        <div
            ref={ref}
            className={cn(className)}
            onClick={() => setIsOpened(!isOpened)}
            data-tooltip-id={uuid}
        >
            {children}
            <Tooltip
                id={uuid}
                variant="light"
                isOpen={isOpened}
                place="bottom-start"
                noArrow
                clickable
                offset={1}
                positionStrategy="fixed"
                style={{ zIndex: 4, padding: 4 }}
            >
                <Button
                    modifiers={['blue', 'naked']}
                    onClick={searchByChain}
                    style={{ height: 28, padding: '0 8px', justifyContent: 'flex-start' }}
                >
                    <IconFileSearch
                        style={{
                            width: 20,
                            height: 20,
                            marginRight: 4,
                            fill: 'currentColor',
                            transform: 'translateY(1px)',
                        }}
                    />
                    Launches
                </Button>
                <Button
                    type={ButtonType.Link}
                    link={Route.ChainProfile.replace(':id', `${chainId}`)}
                    modifiers={['blue', 'naked']}
                    style={{ height: 28, padding: '0 8px', justifyContent: 'flex-start' }}
                >
                    <IconProfileChart style={{
                        width: 20,
                        height: 20,
                        marginRight: 4,
                        fill: 'currentColor',
                    }}/>
                    Profile
                </Button>
            </Tooltip>
        </div>
    )
};

export default ChainLink;