import { FCX, ID, iOptionBase } from '@models';
import cn from 'classnames';

const Options: FCX<{
    data: iOptionBase[];
    value: ID[];
    onOptionClick: (id: ID) => void;
    isButtonsStyle?: boolean;
}> = ({
    data,
    value,
    onOptionClick,
    isButtonsStyle= false,
    style,
}) => {
    return (
        <>
            {data.map(option => (
                <div
                    key={option.id}
                    onClick={() => onOptionClick(option.id)}
                    style={style}
                    className={cn(
                        "Select__option",
                        isButtonsStyle && "Select__option--button",
                        value.includes(option.id) && 'is-active',
                    )}
                >
                    {option.renderTitle || option.title}
                </div>
            ))}
        </>
    );
};

export default Options