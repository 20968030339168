import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Route } from '@core/routes/Route';
import { getFullApiLink } from '@core/utils/url';

const ConceptPage: FC = () => {
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoadedFirstTime, setIsLoadedFirstTime] = useState(false);

    const src = useMemo(() => {
        if (location.pathname === Route.ConceptPageCreate) {
            return getFullApiLink('/ConceptLockerItem/');
        } else if (id && !isNaN(+id)) {
            return getFullApiLink(`/ConceptLockerItem/Edit/${id}`);
        }
    }, [location, id]);

    const checkLoad = useCallback(() => {
        if (!isLoadedFirstTime) {
            setIsLoadedFirstTime(true);
        } else {
            navigate(Route.MyConcepts);
        }
    }, [isLoadedFirstTime, navigate]);

    if (!src) navigate(Route.MyConcepts);

    return (
        <iframe
            onLoad={checkLoad}
            title="Create concept"
            src={src}
            style={{
                width: '100%',
                height: 'calc(100vh - 80px)',
            }}
        />
    );
};

export default ConceptPage;