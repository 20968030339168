import { FCX } from '@models';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export interface iBreadCrumbsItem {
    link: string;
    title: string;
}

const BreadCrumbs: FCX<{
    items: iBreadCrumbsItem[];
}> = ({
    items,
    className,
    style,
}) => {
    return (
        <div
            className={cn("BreadCrumbs", className)}
            style={style}
        >
            {items.map(item => (
                <Link
                    to={item.link}
                    key={item.link}
                    className={cn(
                        "BreadCrumbs__item",
                        item.link === '' && 'is-disabled'
                    )}
                >
                    {item.title}
                </Link>
            ))}
        </div>
    );
};

export default BreadCrumbs;