import React from 'react';
import classNames from 'classnames';
import { FCX } from '@models';

const Preloader: FCX<{
	backgroundColor?: string;
}> = ({
	className = null,
	backgroundColor ,
}) => {
	return (
		<div className={classNames('content-loader', className)}>
			<div className="content-loader-block content-loader-block--one">
				<div className="content-loader-block__front" />
				<div 
					className="content-loader-block__back" 
					style={{
						backgroundColor
					}}
				/>
			</div>
			<div className="content-loader-block content-loader-block--two">
				<div className="content-loader-block__front" />
				<div 
					className="content-loader-block__back" 
					style={{
						backgroundColor
					}}
				/>
			</div>
			<div className="content-loader-block content-loader-block--three">
				<div className="content-loader-block__front" />
				<div 
					className="content-loader-block__back" 
					style={{
						backgroundColor
					}}
				/>
			</div>
			<div className="content-loader-block content-loader-block--four">
				<div className="content-loader-block__front" />
				<div 
					className="content-loader-block__back" 
					style={{
						backgroundColor
					}}
				/>
			</div>
			<div className="content-loader-block content-loader-block--five">
				<div className="content-loader-block__front" />
				<div 
					className="content-loader-block__back" 
					style={{
						backgroundColor
					}}
				/>
			</div>
		</div>
	);
};

export default Preloader;