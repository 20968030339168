import React, { useState, useEffect, useMemo, useRef, FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FCX, ID } from '@models';
import { useItemDetails } from '@api/useItemDetails';
import Wrapper from '@components/Wrapper';
import BreadCrumbs from '@components/BreadCrumbs';
import { Route } from '@core/routes/Route';
import { iBreadCrumbsItem } from '@components/BreadCrumbs/BreadCrumbs';
import { includesInLC } from '@core/utils/string';
import ItemHeader from './parts/ItemHeader';
import CardObservations from './parts/CardObservations';
import CardTargetAudiences from './parts/CardTargetAudiences';
import CardDaypartFit from './parts/CardDaypartFit';
import CardBrandFit from './parts/CardBrandFit';
import CardHighlighting from './parts/CardHighlighting';
import CardScoresPlus from './parts/CardScoresPlus';
import CardDiningPreference from './parts/CardDiningPreference';
import CardCompositeScore from './parts/CardCompositeScore';
import CardBenchmark from './parts/CardBenchmark';
import TilesCarousel from '@components/TilesCarousel';
import { useFiltersContext } from '@core/FiltersContext';
import { useSimilarItems } from '@api/useSimilarItems';
import DataFallback from '@components/DataFallback';
import { IconSpinner } from '@svg/index';
import Footer from '@vms/Footer';
import { toggleInArray } from '@core/utils/array';
import { getRem, usePlatformFeatureFlag } from "@datassential/platform-ui-lib";

const ItemDetailsComponent: FCX = () => {
    const navigate = useNavigate();
    const headerRef = useRef<HTMLDivElement>(null);
    const headerSizerRef = useRef<HTMLDivElement>(null);
    const { options: { totalRespondentType } } = useFiltersContext();
    const { pathname } = useLocation();
    const { id: paramId } = useParams();
    const { data, isLoading, httpStatus } = useItemDetails(paramId as ID);
    const [selectedMetric, setSelectedMetric] = useState<number | undefined>(undefined);
    const [deletedAnswersIds, setDeletedAnswersIds] = useState<ID[]>([]);
    const similarItems = useSimilarItems(paramId as ID);

    const isPlatformEnabled = usePlatformFeatureFlag('enable platform');

    const {
        id,
        userType,
        name,
        overview,
        targetAudience,
        brandFit,
        dayFit,
        conceptPersonalQuestions,
        responsesDislike,
        responsesLike,
        compositeModel,
        scoresPlus,
        diningPreference,
        categoryNameSecondary,
        benchmark,
        segmentName,
        chainName,
    } = data || {};


    useEffect(() => {
        document.body.classList.add('item-details-loaded');
    }, []);

    useEffect(() => {
        if (httpStatus === 404 || httpStatus === 204 || httpStatus === 400) {
            navigate(Route.Home);
        }
    }, [httpStatus]);

    useEffect(() => {

        if (compositeModel) {
            const [metric = null] = compositeModel.purchaseIntent!;
            metric && setSelectedMetric(metric?.type);
        }

    }, [compositeModel]);

    const breadCrumbs = useMemo(() => {
        const items: iBreadCrumbsItem[] = [
            { link: Route.Home, title: 'Home', },
        ];

        if (includesInLC(pathname, Route.ConceptLocker)) {
            items.push({ link: Route.ConceptLocker, title: 'Concept Locker', });
        }

        items.push({ link: '', title: name || '', },);

        return items;
    }, [pathname, name]);

    useEffect(() => {
        const handleDocumentScroll = () => {
            if (headerRef.current && headerSizerRef.current && document.scrollingElement) {
                const scrollTop = document.scrollingElement.scrollTop || 0;
                const headerHeight = headerSizerRef.current.getBoundingClientRect().height;
                const textHeight = headerSizerRef.current.querySelector('.card-item-header-content__text')?.getBoundingClientRect().height || 0;
                const desirableHeaderHeight = 80;
                const headerHeightDiff = headerHeight - desirableHeaderHeight;
                const diffProportion = headerHeightDiff / headerHeight;
                const animationPct = Math.min(
                    1,
                    Math.max(
                        0,
                        scrollTop - 42,
                    ) / headerHeightDiff
                );

                const header: HTMLElement | null = headerRef.current.querySelector('.item-header');
                const headerCard: HTMLElement | null = headerRef.current.querySelector('.item-header-card');
                const headerDownload: HTMLElement | null = headerRef.current.querySelector('.item-header-report');
                const headerDownloadText: HTMLElement | null = headerRef.current.querySelector('.item-header-report__curtain');
                const headerDownloadIcon: HTMLElement | null = headerRef.current.querySelector('.item-header-report__icon');
                const headerImage: HTMLElement | null = headerRef.current.querySelector('.card-item-header-image');
                const headerImageFigure: HTMLElement | null = headerRef.current.querySelector('.card-item-header-image__figure');
                const headerText: HTMLElement | null = headerRef.current.querySelector('.card-item-header-content__text');

                if (!!header) {
                    header.style.backgroundColor = `rgba(255, 255, 255, ${animationPct})`;
                    header.style.boxShadow = `0px 4px 10px 0px rgba(0, 0, 0, ${0.3 * animationPct})`;
                }
                if (!!headerCard) {
                    headerCard.style.padding = `${8 * (1 - diffProportion * animationPct)}px`;
                    headerCard.style.minHeight = `${140 * (1 - diffProportion * animationPct)}px`;
                }
                if (!!headerDownload) {
                    headerDownload.style.marginLeft = `${8 * (1 - animationPct)}px`;
                }
                if (!!headerDownloadText) {
                    headerDownloadText.style.fontSize = `${25 * (1 - 0.5 * animationPct)}px`;
                    headerDownloadText.style.lineHeight = `${30 * (1 - 0.5 * animationPct)}px`;
                }
                if (!!headerDownloadIcon) {
                    headerDownloadIcon.style.fontSize = `${50 * (1 - 0.5 * animationPct)}px`;
                    headerDownloadIcon.style.lineHeight = `${50 * (1 - 0.5 * animationPct)}px`;
                    headerDownloadIcon.style.marginTop = `${20 * (1 - animationPct)}px`;
                }
                if (!!headerImage) {
                    headerImage.style.marginRight = `${24 * (1 - diffProportion * animationPct)}px`;
                }
                if (!!headerImageFigure) {
                    headerImageFigure.style.width = `${236 * (1 - diffProportion * animationPct)}px`;
                }
                if (!!headerText) {
                    headerText.style.maxHeight = `${textHeight * (1 - animationPct)}px`;
                    headerText.style.opacity = `${1 - 2 * animationPct}`;
                }

                if (!!header) {
                    document.querySelectorAll('.table-target-audiences .table-head--sticky .table-target-audiences-head-cell').forEach((el) => {
                        (el as HTMLElement).style.top = isPlatformEnabled
                            ? getRem(110 + header?.getBoundingClientRect().height)
                            : `${80 + header?.getBoundingClientRect().height}px`;
                    });
                    document.querySelectorAll('.table-body-sticky-row').forEach((el) => {
                        (el as HTMLElement).style.top = isPlatformEnabled
                            ? getRem(110 + 30 +  header?.getBoundingClientRect().height)
                            : `${80 + 30 + header?.getBoundingClientRect().height}px`;
                    });
                }
            }
        };
        handleDocumentScroll();

        document.addEventListener('scroll', handleDocumentScroll);

        return () => {
            document.removeEventListener('scroll', handleDocumentScroll);
        }
    }, [headerSizerRef, headerRef, isPlatformEnabled])

    return (
        <>
            <Wrapper>
                <div className="item-details">
                    <div className="item-details-content">
                        <BreadCrumbs items={breadCrumbs}/>
                        <div
                            className="item-details__header"
                            ref={headerRef}
                        >
                            <ItemHeader
                                key={paramId}
                                item={data || {}}
                                isLoading={isLoading}
                            />
                        </div>
                        <div
                            className="item-details__header-sizer"
                            ref={headerSizerRef}
                        >
                            <ItemHeader
                                item={data || {}}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="flex-row flex-row-details flex-row-details--main">
                            <div
                                className="flex-col-6 flex-col-details flex-col-details--composite-score"
                                style={{
                                    flexBasis: 700,
                                    maxWidth: 700,
                                }}
                            >
                                <CardCompositeScore
                                    className="flex-col-details__card"
                                    composite={compositeModel}
                                    benchmark={benchmark}
                                    onChangeMetric={setSelectedMetric}
                                    selectedMetric={selectedMetric}
                                    categoryName={categoryNameSecondary || ''}
                                    itemId={id || 0}
                                    isLoading={isLoading}
                                />
                            </div>
                            <div className="flex-col flex-col-details flex-col-details--benchmark">
                                <CardBenchmark
                                    className="flex-col-details__card"
                                    benchmark={benchmark}
                                    selectedMetric={selectedMetric}
                                    categoryName={categoryNameSecondary || ''}
                                    segmentName={segmentName || ''}
                                    chainName={chainName || ''}
                                    isLoading={(isLoading)}
                                />
                            </div>
                        </div>

                        {(Array.isArray(overview) === true) && (
                            <div className="flex-row flex-row-details">
                                <div className="flex-col-12">
                                    <CardObservations
                                        data={overview as string[]}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>
                        )}

                        {targetAudience && (
                            <div className="flex-row flex-row-details">
                                <div className="flex-col-12">
                                    <CardTargetAudiences
                                        audience={targetAudience}
                                        userType={userType}
                                    />
                                </div>
                            </div>
                        )}

                        {(dayFit || brandFit) && (
                            <div className="flex-row flex-row-details">
                                {(dayFit) && (
                                    <div className="flex-col-6 flex-col-details">
                                        <CardDaypartFit
                                            className="flex-col-details__card"
                                            dayFit={dayFit}
                                        />
                                    </div>
                                )}
                                {(brandFit) && (
                                    <div className="flex-col-6 flex-col-details">
                                        <CardBrandFit
                                            className="flex-col-details__card"
                                            dataList={brandFit}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {(responsesLike && responsesDislike) && (
                            <div className="flex-row flex-row-details">
                                {(responsesLike) && (
                                    <div className="flex-col-6 flex-col-details">
                                        <CardHighlighting
                                            name="like"
                                            responses={responsesLike}
                                            itemId={id as number}
                                            answers={(conceptPersonalQuestions?.likeAnswers || []).filter(i => !deletedAnswersIds.includes(i.id as ID))}
                                            className="flex-col-details__card"
                                            onRemoveAnswer={(id) => setDeletedAnswersIds(toggleInArray(deletedAnswersIds, id))}
                                        />
                                    </div>
                                )}
                                {(responsesDislike) && (
                                    <div className="flex-col-6 flex-col-details">
                                        <CardHighlighting
                                            isDislike={true}
                                            name="dislike"
                                            responses={responsesDislike}
                                            itemId={id as number}
                                            answers={(conceptPersonalQuestions?.dislikeAnswers || []).filter(i => !deletedAnswersIds.includes(i.id as ID))}
                                            className="flex-col-details__card"
                                            onRemoveAnswer={(id) => setDeletedAnswersIds(toggleInArray(deletedAnswersIds, id))}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {scoresPlus && (
                            <div className="flex-row flex-row-details">
                                <div className="flex-col-12">
                                    <CardScoresPlus
                                        scoresPlus={scoresPlus}
                                    />
                                </div>
                            </div>
                        )}

                        {diningPreference && (
                            <div className="flex-row flex-row-details">
                                <div className="flex-col-6">
                                    <CardDiningPreference
                                        diningPreference={diningPreference}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Wrapper>
            <Wrapper style={{ overflow: 'hidden' }}>
                <div className="flex-row flex-row-details">
                    <div className="flex-col-12" style={{ position: 'relative' }}>
                        <div className="card-header">
                            <div>STANDOUT SIMILAR ITEMS</div>
                        </div>
                        {similarItems.isLoading && (
                            <div style={{
                                width: '100%',
                                height: 200,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <IconSpinner/>
                            </div>
                        )}
                        {similarItems.data.length > 0 ? (
                            <TilesCarousel
                                key={id}
                                data={similarItems.data}
                                tileIndexId={totalRespondentType.id}
                            />
                        ) : (
                            <DataFallback
                                style={{ opacity: similarItems.isLoading ? 0 : 1 }}
                                title="No similar items found."
                            />
                        )}
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
            </Wrapper>
            <Footer/>
        </>
    );
}

const ItemDetails: FC = () => {
    const { id: paramId } = useParams();
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <ItemDetailsComponent key={paramId}/>
};

export default ItemDetails;