import { FCX, ID } from '@models';
import Button from '@components/Button';
import { ReactNode, useMemo } from 'react';
import Popup from '@components/Popup';
import { Tooltip } from 'react-tooltip';

export interface iStackedPercentChartValue {
    sliceId: ID;
    seriesId: ID;
    value: number;
    title: string;
}

export interface iStackedPercentChartSeries {
    color: string;
    title: string;
    id: ID;
    tooltip?: ReactNode;
}

export interface iStackedPercentChartSlice {
    title: string;
    id: ID;
}

const StackedPercentChart: FCX<{
    data: iStackedPercentChartValue[];
    series: iStackedPercentChartSeries[];
    slices: iStackedPercentChartSlice[];
    ContentWrapper?: FCX;
}> = ({
    data,
    series,
    slices,
    ContentWrapper,
}) => {
    const content = useMemo(
        () => (
            <div className="StackedPercentChart__content">
                {slices.map(slice => (
                    <div key={slice.id} className="StackedPercentChart__slice">
                        <div className="StackedPercentChart__slice-title">
                            {slice.title}
                        </div>
                        <div className="StackedPercentChart__slice-values">
                            {series.map(seriesItem => {
                                const value = data.find(i => i.seriesId === seriesItem.id && i.sliceId === slice.id);

                                if (!value || value.value === 0) return null;

                                return (
                                    <div
                                        key={seriesItem.id}
                                        className="StackedPercentChart__slice-value"
                                        style={{
                                            flexBasis: `${value.value}%`,
                                            backgroundColor: seriesItem.color,
                                        }}
                                    >
                                        {value.title}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        ),
        [slices, series, data]
    );

    return (
        <div className="StackedPercentChart">
            <div className="StackedPercentChart__legend">
                {series.map((item) => (
                    <Button
                        key={item.id}
                        modifiers={['white']}
                        className="StackedPercentChart__legend-button"
                        data-tooltip-id={`stacked-chart-legend-${item.id}`}
                    >
                        <div
                            className="StackedPercentChart__legend-color"
                            style={{ backgroundColor: item.color }}
                        />
                        {item.title}
                        {!!item.tooltip && (
                            <Popup hasWrapper={false}>
                                <Tooltip
                                    id={`stacked-chart-legend-${item.id}`}
                                    place={'bottom'}
                                >
                                    {item.tooltip}
                                </Tooltip>
                            </Popup>
                        )}
                    </Button>
                ))}
            </div>
            {ContentWrapper ? (
                <ContentWrapper>{content}</ContentWrapper>
            ) : content}
        </div>
    );
};

export default StackedPercentChart;