import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { getApiLink } from '@core/utils/url';
import { Item } from '@models/Item';
import { useUserContext } from "@core/UserContext";

const url: keyof paths = '/api/Items/GetStandoutSimilarItems';

export function useSimilarItems(id: ID) {
    const { uiSettings: { currencySymbol }} = useUserContext();
    const result = useFetch<components['schemas']['ItemViewModel'][]>({
        url: getApiLink(url, { itemId: id }) as keyof paths,
    });

    return {
        isLoading: result.isLoading,
        data: result.data ? result.data.map(i => new Item({ apiModel: i, currencySymbol })) : [],
    };
}