import { iItemBase, iItemMetricsBase } from '@models/ItemBase';
import { components } from '@api/api';
import { v4 as uuidv4 } from 'uuid';

export interface iChartItem extends iItemBase, iItemMetricsBase {
    isConceptLocker: boolean;
}

type ApiModel = components['schemas']['ChartModel'];

interface iData {
    apiModel?: ApiModel;
}

export class ChartItem implements iChartItem {
    static defaultData: iChartItem = {
        id: 0,
        uuid: 0,
        categoryId: null,
        chainId: null,
        segmentId: null,
        unbrandedValue: 0,
        brandedValue: 0,
        frequencyValue: 0,
        uniquenessValue: 0,
        valueValue: 0,
        drawValue: 0,
        isConceptLocker: false,
    };

    id = ChartItem.defaultData.id;
    uuid = ChartItem.defaultData.uuid;
    categoryId = ChartItem.defaultData.categoryId;
    chainId = ChartItem.defaultData.chainId;
    segmentId = ChartItem.defaultData.segmentId;
    unbrandedValue = ChartItem.defaultData.unbrandedValue;
    brandedValue = ChartItem.defaultData.brandedValue;
    frequencyValue = ChartItem.defaultData.frequencyValue;
    uniquenessValue = ChartItem.defaultData.uniquenessValue;
    valueValue = ChartItem.defaultData.valueValue;
    drawValue = ChartItem.defaultData.drawValue;
    isConceptLocker = ChartItem.defaultData.isConceptLocker;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApiModel(data.apiModel);
            }
        }
    }

    private setData (model: iChartItem) {
        Object.assign(this, model);
    }

    mapFromApiModel (apiData: ApiModel) {
        this.setData({
            id: apiData.id ?? ChartItem.defaultData.id,
            uuid: uuidv4(),
            categoryId: apiData.categoryId ?? ChartItem.defaultData.categoryId,
            chainId: apiData.chainId ?? ChartItem.defaultData.chainId,
            segmentId: apiData.segmentId ?? ChartItem.defaultData.segmentId,
            unbrandedValue: apiData.unbrandedPi ?? ChartItem.defaultData.unbrandedValue,
            brandedValue: apiData.brandedPi ?? ChartItem.defaultData.brandedValue,
            frequencyValue: apiData.frequency ?? ChartItem.defaultData.frequencyValue,
            uniquenessValue: apiData.uniqueness ?? ChartItem.defaultData.uniquenessValue,
            valueValue: apiData.value ?? ChartItem.defaultData.valueValue,
            drawValue: apiData.draw ?? ChartItem.defaultData.drawValue,
            isConceptLocker: apiData.isConceptLocker ?? ChartItem.defaultData.isConceptLocker,
        });
    }
}