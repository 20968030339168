import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { useFiltersContext } from '@core/FiltersContext';

export function usePptHistoricTrendReport() {
    const [isLoading, setIsLoading] = useState(false);
    const {
        value: { concepts },
        defaultFiltersPayload,
    } = useFiltersContext();

    const load = async (rangeType: 'month' | 'season' | 'year') => {
        setIsLoading(true);
        const dateRangeType: components['schemas']['HistoricTrendModel']['dateRangeType'] = (
            rangeType === 'month' ? 1 : (
                rangeType === 'season' ? 2 : 3
            )
        );
        const data: components['schemas']['HistoricTrendModel'] = {
            dateRangeType,
            searchFilter: {
                filter: {
                    ...defaultFiltersPayload,
                    conceptTypeIds: [1, ...concepts] as number[],
                    respondentTypes: [1],
                },
                page: {
                    perPage: 0,
                    currentPage: 1,
                },
            }
        };
        const url: keyof paths = '/api/Export/PptHistoricTrend';
        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));
        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}