import { FCX } from '@models';
import Tile from '@vms/Tile';
import { Item } from '@models/Item';
import { IconSpinner } from '@svg/index';

const TileLoader: FCX<{ inList?: boolean; }> = ({
    inList = false,
}) => {
    return (
        <div className="TileLoader">
            <Tile
                data={Item.defaultData}
                metrics={Item.defaultMetricsData}
                style={{ margin: inList ? undefined : 0 }}
            />
            {!inList && <IconSpinner className="center-absolute"/>}
        </div>
    );
}

export default TileLoader;