import { FCX } from '@models';
import FiltersButton from '@vms/FiltersButton';
import SelectionStatus from '@components/SelectionStatus';
import ActionsBar from '@vms/ActionsBar';
import { useSelectedOptions } from '@hooks/useSelectedOptions';
import { PptFormat, usePptReport } from '@api/usePptReport';
import { useMemo } from 'react';
import { useXlsReport } from '@api/useXlsReport';
import { components } from '@api/api';
import { useXlsReportSearch } from '@api/useXlsReportSearch';
import { iFilterValue } from '@models/Filter';
import { useFromToOverrides } from '@hooks/useFromToOverrides';

const SearchPageActionBar: FCX<{
    filtersPayload: components['schemas']['SearchFilterModel'];
    filtersOverrides?: Partial<iFilterValue>;
    useAmongRespondentType?: boolean;
}> = ({
    filtersPayload,
    filtersOverrides,
    useAmongRespondentType= false,
}) => {
    const { items } = useSelectedOptions();
    const pptReport = usePptReport();
    const xlsReport = useXlsReport(false, !useAmongRespondentType);
    const xlsSearchReport = useXlsReportSearch();

    const isLoading = useMemo(() => {
        return pptReport.isLoading || xlsReport.isLoading || xlsSearchReport.isLoading;
    }, [pptReport.isLoading, xlsReport.isLoading, xlsSearchReport.isLoading]);

    const fromToOverrides = useFromToOverrides(
        filtersPayload,
        filtersOverrides
    );

    const overwrittenFilters = useMemo((): components['schemas']['SearchFilterModel'] => {
        return {
            ...filtersPayload,
            filter: {
                ...filtersPayload.filter,
                date: {
                    ...filtersPayload.filter?.date,
                    ...(fromToOverrides || {}),
                }
            },
        };
    }, [fromToOverrides, filtersPayload]);

    return (
        <ActionsBar>
            <FiltersButton
                id="actions-bar-filters-popup-open"
            />
            <div>
                {isLoading ? (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}>
                        DOWNLOADING
                        <div className="ProgressLoader"/>
                    </div>
                ) : (
                    <>
                        <SelectionStatus/>
                        {items.length > 0 && (
                            <>
                                {!items.some(i => i.isBlocked) && (
                                    <div
                                        className="ActionButton"
                                        onClick={() => pptReport.load(items.map(i => i.id))}
                                    >
                                        <div className="ActionButton__icon font-icons">&#59702;</div>
                                        PPT
                                    </div>
                                )}
                                <div
                                    className="ActionButton"
                                    onClick={() => xlsReport.load(items.map(i => i.id))}
                                >
                                    <div className="ActionButton__icon font-icons">&#59700;</div>
                                    XLS
                                </div>
                            </>
                        )}
                        {items.length === 0 && (
                            <div
                                className="ActionButton"
                                onClick={() => xlsSearchReport.load(overwrittenFilters)}
                            >
                                <div className="ActionButton__icon font-icons">&#59700;</div>
                                XLS REPORT
                            </div>
                        )}
                    </>
                )}
            </div>
            <div/>
        </ActionsBar>
    );
};

export default SearchPageActionBar;