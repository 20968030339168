import { FCX } from '@models';
import cn from 'classnames';
import { ReactNode } from 'react';
import Field from '@components/Field';
import { FaCheck } from 'react-icons/fa';

export enum ToggleType {
    Switch = 'switch',
    Checkbox = 'check',
    Radio = 'radio',
}

const Toggle: FCX<{
    isChecked: boolean;
    onChange: () => void;
    label?: ReactNode;
    type?: ToggleType;
    labelPosition?: 'right' | 'left';
}> = ({
    type = 'switch',
    isChecked,
    onChange,
    label,
    labelPosition = 'left',
    className,
    style,
    ...attrs
}) => {
    return (
        <div
            style={style}
            onClick={onChange}
            className={cn(
                "Toggle",
                className,
                `is-${type}`,
                isChecked && 'is-checked',
            )}
            {...attrs}
        >
            <Field
                label={<>{label}&nbsp;</>}
                inline
                style={{
                    flexDirection: labelPosition === 'right' ? 'row-reverse' : 'row',
                }}
            >
                <div className="Toggle__icon">
                    {type === ToggleType.Checkbox && <FaCheck style={{ width: 9, height: 9 }}/>}
                </div>
            </Field>
        </div>
    );
};

export default Toggle;