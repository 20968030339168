import { FCX, ID } from '@models';
import { useChainProfileMostPopularFoodFlavors } from '@api/useChainProfileMostPopularFoodFlavors';
import React, { memo, ReactNode, useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { getFormattedThousand } from '@core/utils/number';
import { TableContext } from '@components/Table/TableContext';
import SimpleTable from '@components/Table/SimpleTable';
import { Tooltip } from 'react-tooltip';
import { useFiltersContext } from '@core/FiltersContext';
import { Link } from 'react-router-dom';
import { Route } from '@core/routes/Route';
import { FiltersVariant } from '@models/Filter';

const MostPopularFoodFlavors: FCX<{
    isGlobalLoading: boolean;
    chainId: ID;
    chainName: string;
    filterDateRangeString: ReactNode;
}> = ({
    chainId,
    chainName,
    filterDateRangeString,
}) => {
    const {
        updateSpecificValue,
        value: { date: filterDate, categories },
    } = useFiltersContext();
    const { data, isLoading } = useChainProfileMostPopularFoodFlavors(chainId);

    const tableConfig = useMemo((): iTableConfigItem[] => [
        {
            key: 'name',
            title: '',
            Value: ({ index }) => <>
                {data[index].name}
            </>,
        },
        {
            key: 'count',
            title: 'Count',
            Value: ({ index }) => <>
                <Link
                    to={Route.Home}
                    onClick={() => {
                        updateSpecificValue(FiltersVariant.Homepage, {
                            additionalSearch: data[index].name,
                            chainId,
                            date: filterDate,
                            categories,
                        });
                    }}
                >
                    {getFormattedThousand(data[index].itemsCount)}
                </Link>
            </>,
            styles: {
                cell: {
                    flex: '0 0 30%',
                    justifyContent: 'center',
                },
            },
        },
        {
            key: 'percentage',
            title: <>
                Percentage
                <Tooltip
                    id="table-most-popular-percentage"
                >
                    <div style={{ textAlign: 'center', width: 150 }}>
                        % of total{' '}
                        <div className="color-dimyellow inline">{chainName}</div>
                        {' '}launched items & LTOs
                    </div>
                </Tooltip>
            </>,
            Value: ({ index }) => <>
                {Math.round(data[index].percentage || 0)}%
            </>,
            getAttrs: (index) => (index === -1 ? {
                'data-tooltip-id': `table-most-popular-percentage`,
            } : {}),
            styles: {
                cell: {
                    flex: '0 0 30%',
                    justifyContent: 'center',
                },
                head: {
                    cursor: 'pointer',
                }
            },
        },
    ], [data, chainName, chainId, updateSpecificValue, filterDate, categories]);

    return (
        <div className="card" style={{ flex: '1 1 100%' }}>
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    Most Popular Foods / Flavors in&nbsp;
                    <div className="color-dimyellow inline">{chainName}</div>
                    &nbsp;Launches
                </div>
                <div style={{ opacity: 0.5, textTransform: 'uppercase' }}>
                    {filterDateRangeString}
                </div>
            </div>
            <TableContext.Provider value={{ config: tableConfig }}>
                <SimpleTable
                    rowsCount={isLoading ? 10 : data.length}
                    isLoading={isLoading}
                />
            </TableContext.Provider>
        </div>
    );
};

export default memo(MostPopularFoodFlavors);