import React, { memo, useRef } from 'react';
import classNames from 'classnames';

import {
    XAxis, YAxis, Area, ComposedChart, Text, ResponsiveContainer
} from 'recharts';
import { FCX } from '@models';
import { components } from '@api/api';


const POINT_TYPE_ID = {
    INDEX: 'INDEX',
    AVG: 'AVG',
}

const getCoordByX = function (xPoint: number, values: any[]) {

    for (var k = 0; k < values.length; k++) {
        if (values[k].x === xPoint) {

            return {
                x: values[k].x,
                y: values[k].y,
            };

        } else {

            if (!values[k + 1])
                return {
                    x: xPoint,
                    y: values[k].y,
                }

            if (values[k].x < xPoint && values[k + 1].x > xPoint) {
                var x = xPoint,
                    y1 = values[k].y,
                    x1 = values[k].x,
                    y2 = values[k + 1].y,
                    x2 = values[k + 1].x,

                    // f(x) = (y2 - y1)/(x2 - x1) * (x1 - x) + y1 = y
                    ///_cY = (y2 - y1)/(x2 - x1) * (x1 - x) + y1;
                    _cY = ((x * 100 - x1 * 100) * (y2 * 100 - y1 * 100) + (x2 * 100 - x1 * 100) * y1 * 100) / (x2 * 100 - x1 * 100);
                _cY = _cY / 100;

                return {
                    x: xPoint,
                    y: _cY,
                };
            }


        }
    }

};


const CustomizedDot: FCX<{
    cx?: number;
    cy?: number;
    payload?: any;
}> = ({
    cx,
    cy,
    payload,
}) => {
    if (payload.pointId === null) return null;


    if (payload.pointId === POINT_TYPE_ID.AVG) return (
        <g>
            <line x1={cx} y1={267} x2={cx} y2={cy} stroke="white" strokeDasharray="2.24 3.73"/>
            <Text
                width={100}
                x={cx}
                y={267}
                textAnchor="middle"
                verticalAnchor="start"
                fontFamily="DIN Pro Regular"
                fontSize="12"
                fill="#141831"
            >
                {`average = ${Math.round(payload.x)}%`}
            </Text>
        </g>
    );

    if (payload.pointId === POINT_TYPE_ID.INDEX) return (
        <circle
            cx={cx}
            cy={cy}
            r="1"
            fill="none"
            className="benchmark-chart-point-index"
        />
    );

    return null;
};

const CardBenchmarkChart: FCX<{
    metricData: components["schemas"]["Graph"];
    bottomCaption: string;
    bottomValue: number;
}> = ({
    className = null,
    metricData,
    bottomCaption = '',
    bottomValue = null
}) => {

    const refNodeContainer = useRef<HTMLDivElement>(null);

    const avgPoint = getCoordByX(metricData.average || 0, metricData.dataString || []);
    const indexPoint = getCoordByX(metricData.index || 0, metricData.dataString || []);

    const chartData = (metricData.dataString || []).reduce(
        /* @ts-ignore */
        (list, point, index, src) => {

            const {
                /* @ts-ignore */
                x: nextX = 100
            } = src[index + 1] || {};

            return [
                /* @ts-ignore */
                ...list,
                {
                    /* @ts-ignore */
                    ...point,
                    /* @ts-ignore */
                    pointId: null,
                },

                /* @ts-ignore */
                ...((point.x <= avgPoint.x && nextX > avgPoint.x) ? [{
                    /* @ts-ignore */
                    x: avgPoint.x,
                    /* @ts-ignore */
                    y: avgPoint.y,
                    pointId: POINT_TYPE_ID.AVG,
                }] : []),


                /* @ts-ignore */
                ...((point.x <= indexPoint.x && nextX > indexPoint.x) ? [{
                    /* @ts-ignore */
                    x: indexPoint.x,
                    /* @ts-ignore */
                    y: indexPoint.y,
                    pointId: POINT_TYPE_ID.INDEX,
                }] : []),
            ];
        },
        []
    ) as any[];

    return (
        <div
            className={classNames('card-benchmark-chart', className)}
            ref={refNodeContainer}
        >
            {(!!metricData.index && metricData.index > 0) && (
                <div className="card-benchmark-chart-point">
                    <div className="card-benchmark-chart-point__tooltip">
                        this item = <div className="value">{Math.round(metricData.index)}%</div>
                    </div>
                </div>
            )}

            <ResponsiveContainer
                width="100%"
                height={295}
            >
                <ComposedChart
                    className="card-benchmark-chart___rechart"
                    data={chartData}
                    margin={{
                        top: 35,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient
                            id="areaFillBenchmark"
                            x1="105.63"
                            y1="191.139"
                            x2="110.115"
                            y2="16.6417"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#ffcc02"/>
                            <stop offset="1" stopColor="#ff6a00"/>
                        </linearGradient>
                    </defs>
                    <XAxis
                        dataKey="x"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={0}
                        shapeRendering="auto"
                        padding={{
                            left: -5,
                            right: -5,
                        }}

                        tick={{
                            fill: '#CFCECE',
                            fontFamily: 'DIN Pro Light',
                            fontSize: '12px'
                        }}
                        tickFormatter={value => {
                            return `${Math.round(value)}%`;
                        }}
                        type="number"
                        interval="preserveStartEnd"

                        domain={['dataMin', 'dataMax']}
                    >
                    </XAxis>

                    <YAxis
                        axisLine={true}
                        tickLine={true}
                        unit=""
                        hide
                        padding={{
                            bottom: 5,
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey="y"
                        stroke=""
                        strokeWidth={1}
                        fillOpacity={1}
                        fill="url(#areaFillBenchmark)"
                        isAnimationActive={true}
                        onAnimationStart={() => {
                            if (refNodeContainer.current) {
                                const nodePointerIndex = refNodeContainer.current.querySelector('.card-benchmark-chart-point');
                                if (nodePointerIndex)
                                    nodePointerIndex.classList.remove('is-active');
                            }
                        }}

                        onAnimationEnd={() => {
                            setTimeout(() => {
                                const nodeDotIndex: SVGGraphicsElement = refNodeContainer.current?.querySelector('.benchmark-chart-point-index')!;
                                const nodePointerIndex: SVGGraphicsElement = refNodeContainer.current?.querySelector('.card-benchmark-chart-point')!;

                                if (nodeDotIndex && nodePointerIndex) {
                                    const { x, y } = nodeDotIndex.getBBox();

                                    nodePointerIndex.style.left = `${x}px`;
                                    nodePointerIndex.style.top = `${y}px`;
                                    nodePointerIndex.classList.add('is-active');
                                }
                            }, 0);
                        }}

                        dot={<CustomizedDot/>}
                    >
                    </Area>

                </ComposedChart>
            </ResponsiveContainer>
            <div className="card-benchmark-chart__bottom">
                <div className="caption">versus all other</div>
                <div className="name">{bottomCaption}</div>
                {(bottomValue !== null) && (
                    <div className="value">
                        ({bottomValue.toLocaleString('en-US')})
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(CardBenchmarkChart);