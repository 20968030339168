import { Season } from '@models';
import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { useSeasonalActivityFilters } from '@core/selectors/useSeasonalActivityFilters';

type Response = components['schemas']['SeasonalCategory'][];

export function useSeasonalByCategories (season: Season) {
    const payload = useSeasonalActivityFilters(season);

    return useFetch<Response>({
        url: '/api/Seasonal/GetCategories',
        method: 'post',
        payload,
    });
}