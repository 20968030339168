import { FCX } from '@models';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import React, { FormEvent, useCallback, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import { RxCross2 } from 'react-icons/rx';

const Search: FCX = () => {
    const {
        value,
        updateValue,
    } = useFiltersContext();
    const [query, setQuery] = useState('');

    const handleSearchApply = useCallback((event: FormEvent) => {
        event.preventDefault();
        updateValue({ searchCL: query.trim() === '' ? null : query });
    }, [query, updateValue]);

    return (
        <form
            onSubmit={handleSearchApply}
            style={{ display: 'flex', position: 'relative' }}
        >
                <TextInput
                    value={query}
                    setValue={setQuery}
                    placeholder="Search by Company, Chain, Concept"
                    style={{ width: 300, paddingRight: 32 }}
                />
                <button style={{
                    opacity: 0,
                    pointerEvents: 'none',
                    position: 'absolute',
                }}>
                    submit
                </button>
                {value.searchCL !== null && (
                    <Button
                        style={{
                            width: 30,
                            height: 30,
                            padding: 0,
                            position: 'absolute',
                            right: 1,
                            top: 1,
                        }}
                        modifiers={['white']}
                        onClick={() => {
                            setQuery('');
                            updateValue({ searchCL: ''});
                        }}
                    >
                        <RxCross2 style={{ width: 20, height: 20 }}/>
                    </Button>
                )}
        </form>
    )
};

export default Search;