import { FCX, ID, iOption } from '@models';
import cn from 'classnames';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

const PlatesSelect: FCX<{
    value: ID[];
    options: iOption[];
    onOptionClick: (id: ID) => void;
    isGenericItemWidth?: boolean;
    isTagDecoration?: boolean;
    isDisabled?: boolean;
}> = ({
    value,
    options: rawOptions,
    onOptionClick,
    style,
    className,
    isGenericItemWidth,
    isTagDecoration,
    isDisabled,
    ...attrs
}) => {
    const options = [
        ...rawOptions.filter(o => !o.isDisabled),
        ...rawOptions.filter(o => o.isDisabled),
    ];

    return (
        <div
            {...attrs}
            className={cn(
                "PlatesSelect",
                className,
                isDisabled && 'is-disabled'
            )}
            style={style}
        >
            {options.map(o => (
                <div
                    key={o.id}
                    className={cn(
                        'PlatesSelect__option',
                        o.isDisabled && 'is-disabled',
                        isGenericItemWidth && 'is-generic-width',
                        isTagDecoration && 'is-tag-decoration',
                        value.includes(o.id) && 'is-active',
                    )}
                    onClick={() => onOptionClick(o.id)}
                >
                    <div>
                        {o.title}
                        {!!o.subTitle && (
                            <div className="PlatesSelect__option-sub-title">
                                {o.subTitle}
                            </div>
                        )}
                    </div>
                    {isTagDecoration && (
                        <IoMdClose className="PlatesSelect__option-icon"/>
                    )}
                </div>
            ))}
        </div>
    );
};

export default PlatesSelect;