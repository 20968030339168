import { FCX } from '@models';
import cn from 'classnames';

const Dropdown: FCX = ({
    style,
    className,
    children,
    ...attrs
}) => {
    return (
        <div
            {...attrs}
            className={cn(
                "Select__dropdown",
                "SelectCustom__dropdown",
                className,
            )}
            style={style}
        >
            {children}
        </div>
    );
};

export default Dropdown;