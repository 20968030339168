import { FCX } from '@models';
import { SelectCustomSingle } from '@components/Select';
import { useFiltersContext } from '@core/FiltersContext';

const SelectMetric: FCX = () => {
    const {
        options: { metrics },
        value: { metric },
        updateValue: updateFiltersValue,
    } = useFiltersContext();

    return (
        <SelectCustomSingle
            value={metric}
            options={metrics}
            label="Metric"
            dpdnClassName="w-auto"
            style={{ minWidth: 130, marginLeft: 16 }}
            onOptionClick={(id) => {
                updateFiltersValue({
                    metric: id,
                })
            }}
        />
    );
};

export default SelectMetric;