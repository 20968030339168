import { FCX } from '@models';

const ResetFiltersButton: FCX<{
    onClick: () => void;
}> = ({
    children,
    onClick,
}) => {
    return (
        <div
            className="FiltersPopup__reset-button"
            onClick={(event) => {
                event.stopPropagation();
                onClick();
            }}
        >
            {children || 'Reset filters'}
        </div>
    )
};

export default ResetFiltersButton;