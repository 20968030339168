import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { ID } from '@models';
import { getFullApiLink } from '@core/utils/url';

export function useCategoryActivityReport() {
    const [isLoading, setIsLoading] = useState(false);

    const load = async (categoryId: ID) => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/PptCategoryActivity';

        await downloadReport(api.request({
            method: 'get',
            url: getFullApiLink(url, { categoryId }),
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}