import { useState } from 'react';
import { downloadReport } from '@core/utils/file';
import { api } from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import { ID } from '@models';

export function useItemLikesReport(itemId: ID) {
    const [isLoading, setIsLoading] = useState(false);

    const load = async () => {
        setIsLoading(true);

        await downloadReport(api.request({
            method: 'post',
            url: getApiLink('/api/Export/ExportLikes', { itemId }),
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    };
}