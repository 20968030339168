import { ID } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import { useEffect, useMemo, useState } from 'react';
import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { defaultSort } from '@core/utils/array';
import { useCategoryActivityFilters } from '@core/selectors/useCategoryActivityFilters';

type Request = components['schemas']['CategoryLaunchesRequest'];
type Response = components['schemas']['CategoryLaunchesByKeywordResponse'];

export type iCategoryActivityFood = components['schemas']['CategoryLaunchesByKeywordItem'] & {
    name: string;
    index: number;
    percentage: number;
}

export function useCategoryActivityByFoods(categoryId: ID) {
    const {
        options: { keywords },
    } = useFiltersContext();

    const [data, setData] = useState<iCategoryActivityFood[]>([]);

    const filter = useCategoryActivityFilters();

    const payload = useMemo((): Request => {
        return {
            filter,
            id: categoryId as number,
        };
    }, [filter, categoryId]);

    const result = useFetch<Response>({
        url: '/api/Category/LaunchesByKeyword',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading && !!result.data) {
            (result.data.keywords || []).sort((a, b) => defaultSort(a.count, b.count));

            setData(
                (result.data.keywords || [])
                    .map(item => {
                        const option = keywords.find(o => o.id === item.keywordId);
                        const index = item.index;
                        const indexValue = (index !== null && index !== undefined) ? index : Infinity;

                        return {
                            ...item,
                            name: option?.title || '',
                            index: indexValue,
                            percentage: item.percent || 0,
                        };
                    })
            )
        } else {
            setData([]);
        }
    }, [result.isLoading, result.data, keywords]);

    return {
        isLoading: result.isLoading,
        data,
    }
}