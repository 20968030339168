import { FCX, ID, Sort } from '@models';
import React, { memo, ReactNode, useMemo, useState } from 'react';
import { useChainProfileTopPerformingItems } from '@api/useChainProfileTopPerformingItems';
import { SelectCustomSingle } from '@components/Select';
import { useFiltersContext } from '@core/FiltersContext';
import TopPerformingItemsTableConfig from '@pages/ChainProfile/TopPerformingItemsTableConfig';
import SimpleTable from '@components/Table/SimpleTable';
import { useUserContext } from '@core/UserContext';
import LockAccessOverlay from '@components/LockAccessOverlay';
import TableViewSorting from '@vms/ItemsTable/TableViewSorting';

const TopPerformingItems: FCX<{
    isGlobalLoading: boolean;
    chainId: ID;
    chainName: string;
    filterDateRangeString: ReactNode;
}> = ({
    chainId,
    chainName,
    filterDateRangeString,
}) => {
    const { fullAccess } = useUserContext();
    const { options: { mainMetrics } } = useFiltersContext();
    const [selectedMetricId, setSelectedMetricId] = useState<ID>(mainMetrics[0].id);
    const [sortingDirection, setSortingDirection] = useState<Sort>(Sort.Desc);
    const [sortingColumn, setSortingColumn] = useState<ID>(mainMetrics[0].id);
    const {
        data,
        isLoading,
        hasSegmentPermission,
    } = useChainProfileTopPerformingItems(chainId, selectedMetricId, sortingColumn, sortingDirection);

    const hasAccess = useMemo(
        () => fullAccess || hasSegmentPermission,
        [fullAccess, hasSegmentPermission]
    );

    return (
        <div className="card">
            <div
                className="card-header"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                    zIndex: 3,
                }}
            >
                <div style={{ display: 'flex' }}>
                    Top Performing&nbsp;
                    <div className="color-dimyellow">{chainName}</div>
                    &nbsp;Launches&nbsp;&nbsp;
                    <div style={{ opacity: 0.5, textTransform: 'uppercase' }}>
                        {filterDateRangeString}
                    </div>
                </div>
                <SelectCustomSingle
                    value={selectedMetricId}
                    options={mainMetrics}
                    label="Based On"
                    dpdnClassName="w-auto"
                    style={{ minWidth: 180 }}
                    onOptionClick={(id) => {
                        setSelectedMetricId(id);
                        setSortingColumn(id);
                        setSortingDirection(Sort.Desc);
                    }}
                />
            </div>
            <TableViewSorting
                sortingColId={sortingColumn}
                setSortingColId={setSortingColumn}
                sortingDirection={sortingDirection}
                setSortingDirection={setSortingDirection}
            >
                <TopPerformingItemsTableConfig data={data}>
                    <SimpleTable
                        rowHeight={48}
                        rowsCount={isLoading ? 10 : data.length}
                        isLoading={isLoading}
                    >
                        {!isLoading && !hasAccess && (
                            <LockAccessOverlay
                                subTitle="of menu launches from this chain"
                                toolLocation={`Chain Profile (${chainName})`}
                                style={{
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            />
                        )}
                    </SimpleTable>
                </TopPerformingItemsTableConfig>
            </TableViewSorting>
        </div>
    );
};

export default memo(TopPerformingItems);