import { components } from '@api/api';
import dayjs from 'dayjs';
import { getFullApiLink } from '@core/utils/url';
import { getFormattedPrice } from '@core/utils/number';
import { equalInLC } from '@core/utils/string';
import { iItemBase, iItemMetricsBase } from '@models/ItemBase';
import { v4 as uuidv4 } from 'uuid';

export interface iItemInfo extends iItemBase {
    itemType: string;
    title: string;
    chainName: string;
    chainCount: number;
    segmentName: string;
    segmentCount: number;
    categoryName: string;
    categoryCount: number;
    totalCount: number;
    dateString: string;
    dateTimestamp: number;
    price: string;
    imageUrl: string;
    legacyImageUrl: string;
    description: string;
    testedFor: string;
    companyName: string;
    isConcept: boolean;
    isSurveyItem?: boolean;
    status?: string;
    note?: string;
    hasAccess?: boolean;
    rank?: number;
    rankRange?: number;
}

export interface iItemMetrics extends iItemMetricsBase {
    score: number;
    viability: string;
    unbrandedStarsCount: number;
    brandedStarsCount: number;
    uniquenessStarsCount: number;
    frequencyStarsCount: number;
    drawStarsCount: number;
    valueStarsCount: number;
    priceValue: number;
    delivery: number;
    toGo: number;
    dineIn: number;
}

export type ItemForSorting = iItem & iItemMetrics & {
    originalItem: iItem;
    date: number;
};

export interface iItem extends iItemInfo {
    indices: Map<number, iItemMetrics>;
}

type ApiModel = components['schemas']['ItemViewModel'];

interface iData {
    apiModel?: ApiModel;
    currencySymbol?: string;
}

export class Item implements iItem {
    static defaultData: iItem = {
        id: 0,
        uuid: 0,
        itemType: '',
        title: '',
        chainId: null,
        segmentId: null,
        categoryId: null,
        chainName: '',
        segmentName: '',
        categoryName: '',
        chainCount: 0,
        segmentCount: 0,
        categoryCount: 0,
        totalCount: 0,
        dateString: '',
        dateTimestamp: 0,
        price: '',
        imageUrl: '',
        legacyImageUrl: '',
        description: '',
        indices: new Map<number, iItemMetrics>(),
        testedFor: '-',
        companyName: '-',
        isConcept: false,
        isSurveyItem: false,
    };

    static defaultMetricsData: iItemMetrics = {
        score: 0,
        viability: '',
        unbrandedStarsCount: 0,
        unbrandedValue: 0,
        brandedStarsCount: 0,
        brandedValue: 0,
        uniquenessStarsCount: 0,
        uniquenessValue: 0,
        frequencyStarsCount: 0,
        frequencyValue: 0,
        drawStarsCount: 0,
        drawValue: 0,
        valueStarsCount: 0,
        valueValue: 0,
        priceValue: 0,
        delivery: 0,
        toGo: 0,
        dineIn: 0,
    }

    uuid = Item.defaultData.uuid;
    id = Item.defaultData.id;
    itemType = Item.defaultData.itemType;
    title = Item.defaultData.title;
    chainId = Item.defaultData.chainId;
    segmentId = Item.defaultData.segmentId;
    categoryId = Item.defaultData.categoryId;
    chainName = Item.defaultData.chainName;
    dateString = Item.defaultData.dateString;
    dateTimestamp = Item.defaultData.dateTimestamp;
    price = Item.defaultData.price;
    imageUrl = Item.defaultData.imageUrl;
    legacyImageUrl = Item.defaultData.legacyImageUrl;
    description = Item.defaultData.description;
    testedFor = Item.defaultData.testedFor;
    companyName = Item.defaultData.companyName;
    isConcept = Item.defaultData.isConcept;
    indices = Item.defaultData.indices;
    segmentName = Item.defaultData.segmentName;
    categoryName = Item.defaultData.categoryName;
    chainCount = Item.defaultData.chainCount;
    segmentCount = Item.defaultData.segmentCount;
    categoryCount = Item.defaultData.categoryCount;
    totalCount = Item.defaultData.totalCount;


    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApiModel(data.apiModel, data.currencySymbol);
            }
        }
    }

    private setData (model: iItem) {
        Object.assign(this, model);
    }

    mapFromApiModel (apiData: ApiModel, currencySymbol: string = '$') {

        const indices: iItem['indices'] = new Map();
        apiData.indexes?.forEach(index => {
            indices.set(index.respondentTypeId as number, {
                score: index.score ?? Item.defaultMetricsData.score,
                viability: index.viability ?? Item.defaultMetricsData.viability,
                unbrandedStarsCount: index.unbrandedPiStar ?? Item.defaultMetricsData.unbrandedStarsCount,
                unbrandedValue: index.unbrandedPi ?? Item.defaultMetricsData.unbrandedValue,
                brandedStarsCount: index.brandedPiStar ?? Item.defaultMetricsData.brandedStarsCount,
                brandedValue: index.brandedPi ?? Item.defaultMetricsData.brandedValue,
                uniquenessStarsCount: index.uniquenessStar ?? Item.defaultMetricsData.uniquenessStarsCount,
                uniquenessValue: index.uniqueness ?? Item.defaultMetricsData.uniquenessValue,
                frequencyStarsCount: index.frequencyStar ?? Item.defaultMetricsData.frequencyStarsCount,
                frequencyValue: index.frequency ?? Item.defaultMetricsData.frequencyValue,
                drawStarsCount: index.drawStar ?? Item.defaultMetricsData.drawStarsCount,
                drawValue: index.draw ?? Item.defaultMetricsData.drawValue,
                valueStarsCount: index.valueStar ?? Item.defaultMetricsData.valueStarsCount,
                valueValue: index.value ?? Item.defaultMetricsData.valueValue,
                priceValue: index.pricedPi ?? Item.defaultMetricsData.priceValue,
                delivery: index.delivered ?? Item.defaultMetricsData.delivery,
                toGo: index.toGoDriveThru ?? Item.defaultMetricsData.toGo,
                dineIn: index.inRestaurant ?? Item.defaultMetricsData.dineIn,
            });
        });


        this.setData({
            uuid: uuidv4(),
            id: apiData.id ?? Item.defaultData.id,
            itemType: apiData.itemType ?? Item.defaultData.itemType,
            title: apiData.name ?? Item.defaultData.title,
            dateString: dayjs(apiData.date).format('MMM YYYY'),
            dateTimestamp: dayjs(apiData.date).valueOf(),
            price: apiData.price ? `${currencySymbol}${getFormattedPrice(apiData.price)}` : Item.defaultData.price,
            imageUrl: apiData.imageUrl ?? Item.defaultData.imageUrl,
            legacyImageUrl: getFullApiLink(`/api/Image/${apiData.id}`),
            description: apiData.description ?? Item.defaultData.description,
            testedFor: apiData.testedFor ?? Item.defaultData.testedFor,
            companyName: apiData.companyName ?? Item.defaultData.companyName,
            isConcept: !equalInLC(apiData.itemType || '', 'regular'),
            segmentId: Item.defaultData.segmentId,
            segmentName: apiData.segmentName || Item.defaultData.segmentName,
            segmentCount: apiData.segmentCount || Item.defaultData.segmentCount,
            categoryName: apiData.categoryName || Item.defaultData.categoryName,
            categoryCount: apiData.categoryCount || Item.defaultData.categoryCount,
            totalCount: apiData.totalCount || Item.defaultData.totalCount,
            chainId: apiData.chainId ?? Item.defaultData.chainId,
            chainName: apiData.chainName ?? Item.defaultData.chainName,
            chainCount: apiData.chainCount || Item.defaultData.chainCount,
            categoryId: Item.defaultData.categoryId,
            isSurveyItem: apiData.isSurveyItem ?? Item.defaultData.isSurveyItem,
            status: apiData.kind || '',
            note: apiData.note || '',
            hasAccess: apiData.hasAccess,
            rank: apiData.rank || Item.defaultData.rank,
            indices,
        });
    }
}