import { FCX } from '@models';
import { TableContext } from '@components/Table/TableContext';
import { useCallback, useMemo } from 'react';
import { iItem, iItemMetrics, Item } from '@models/Item';
import useDateColumn from '@vms/ItemsTable/useDateColumn';
import useNameColumn from '@vms/ItemsTable/useNameColumn';
import { useMetricColumns } from '@vms/ItemsTable/useMetricsColumns';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { useFiltersContext } from '@core/FiltersContext';
import useChainAggregatedColumn from '@vms/ItemsTable/useChainAggregatedColumn';
import { useStatusColumn } from '@vms/ItemsTable/useStatusColumn';

const HomeTableViewConfig: FCX<{
    data: iItem[];
}> = ({
    children,
    data,
}) => {
    const {
        value: { among },
    } = useFiltersContext();

    const getMetrics = useCallback((index: number): iItemMetrics => {
        return data[index].indices.get(among as number) || Item.defaultMetricsData;
    }, [among, data]);

    const dateColumn = useDateColumn(data);
    const nameColumn = useNameColumn(data, getMetrics, true);
    const statusColumn = useStatusColumn(data);
    const chainColumn = useChainAggregatedColumn(data, 'Chain');
    const metricColumns = useMetricColumns(data, getMetrics);


    const config: iTableConfigItem[] = useMemo(() => {
        return [
            dateColumn,
            chainColumn,
            nameColumn,
            statusColumn,
            ...metricColumns,
        ];
    }, [dateColumn, nameColumn, metricColumns, chainColumn, statusColumn]);

    return (
        <TableContext.Provider value={{ config }}>
            {children}
        </TableContext.Provider>
    );
};

export default HomeTableViewConfig;