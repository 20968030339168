import { FCX } from '@models';
import { createContext, useContext, useRef, useState } from 'react';

const GlobalLoaderContext = createContext<{
    show: () => void;
    hide: () => void;
    isShown: boolean;
}>({
    show: () => null,
    hide: () => null,
    isShown: false,
});

const GlobalLoader: FCX = ({
    children,
}) => {
    const [isShown, setIsShown] = useState(true);
    const timerRef = useRef<any>(null);

    const hide = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setIsShown(false);
        }, 1000);
    };

    return (
        <GlobalLoaderContext.Provider value={{
            show: () => setIsShown(true),
            hide,
            isShown,
        }}>
            {/*{isShown && <Loader style={{ position: 'fixed' }}/>}*/}
            {children}
        </GlobalLoaderContext.Provider>
    );
}

export function useGlobalLoader() {
    return useContext(GlobalLoaderContext);
}

export default GlobalLoader;