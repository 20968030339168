import useFetch from "@hooks/useFetch";
import { components, paths } from "@api/api";
import { useEffect, useMemo, useState } from "react";
import { ChartItem, iChartItem } from '@models/ChartItem';

export function useChartItems (
    filtersPayload: components['schemas']['FilterViewModel'],
) {
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const [data, setData] = useState<iChartItem[]>([]);

    const tilesTableDataPayload: components['schemas']['SearchFilterModel'] = useMemo(() => {
        return {
            filter: filtersPayload,
            page: {
                sortId: undefined,
                sortDirection: undefined,
                currentPage: 1,
                perPage: 100000,
            },
        };
    }, [filtersPayload]);

    const tilesTableData = useFetch<components['schemas']['ChartResultModel']>({
        url: '/api/Search/ChartData' as keyof paths,
        method: 'post',
        payload: tilesTableDataPayload,
    });

    useEffect(() => {
        if (tilesTableData.data) {
            setData(tilesTableData.data?.items?.map(
                (item) => new ChartItem({ apiModel: item})
            ) || []);
            setTotalRecords(tilesTableData.data?.count);
        }
    }, [tilesTableData.data]);

    return {
        data,
        totalRecords,
        isLoading: tilesTableData.isLoading,
    };
}