import { FCX } from '@models';
import cn from 'classnames';

const TableCellLoading: FCX<{
    onClick?: () => void;
    attrs?: any;
}> = ({
    children,
    onClick,
    className,
    style,
    attrs,
}) => {
    return (
        <div
            {...attrs}
            className={cn("TableCell", "TableCellLoading", className)}
            style={style}
            onClick={onClick}
        />
    );
};

export default TableCellLoading;