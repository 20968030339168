import { FCX, ID, iOption, } from '@models';
import DataFallback from '@components/DataFallback';
import React, { useCallback, useMemo, useRef, useState, KeyboardEvent } from 'react';
import TextInput from '@components/TextInput';
import { useFiltersContext } from '@core/FiltersContext';
import { equalInLC, includesInLC, startsFromInLC } from '@core/utils/string';
import Options from '@components/Select/Options';
import Dropdown from '@components/Select/Dropdown';
import useOutsideClick from '@hooks/useOutsideClick';
import PlatesSelect from '@components/PlatesSelect';
import Button from '@components/Button';

const Keywords: FCX<{
    value: ID[];
    setValue: (value: ID[]) => void;
}> = ({
    value,
    setValue,
}) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const { options: { keywords } } = useFiltersContext();
    const [query, setQuery] = useState('');
    const [isSelectOpened, setIsSelectOpened] = useState(false);

    useOutsideClick({
        ref: selectRef,
        fn: () => setIsSelectOpened(false),
    });

    const selectOptionsList = useMemo(
        (): iOption[] => {
            if (query.trim() === '') return [];

            return keywords
                .filter(
                    i => includesInLC(i.title.trim(), query.trim()) && !value.includes(i.id)
                )
                .sort((a, b) => {
                    const aScore = startsFromInLC(a.title.trim(), query.trim()) ? 10 : 1;
                    const bScore = startsFromInLC(b.title.trim(), query.trim()) ? 10 : 1;

                    return bScore - aScore;
                })
                .slice(0, 6)
                .map(i => ({
                    ...i,
                    renderTitle: <div
                        dangerouslySetInnerHTML={{
                            __html: i.title.replace(new RegExp(`(${query})`, 'i'), `<b>$1</b>`)
                        }}
                    />
                }));
        },
        [keywords, query, value]
    );

    const selectedItems = useMemo(
        () => value.map(id => keywords.find(i => i.id === id)!),
        [value, keywords]
    );

    const handleOptionSelect = useCallback((id: ID) => {
        setValue([...value, id]);
        setQuery('');
    }, [setValue, value]);

    const handleInputKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (equalInLC(event.code, 'enter')) {
                const exactMatch = selectOptionsList.find(i => equalInLC(i.title.trim(), query.trim()));

                if (exactMatch) {
                    handleOptionSelect(exactMatch.id);
                }
            }
        },
        [query, selectOptionsList, handleOptionSelect]
    );

    return (
        <div className="card">
            <div
                className="card-header is-sticky"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <div className="font-medium">
                    Track Keywords
                </div>
                <div style={{ display: 'flex', }}>
                    {value.length > 0 && (
                        <Button
                            modifiers={['blue', 'naked']}
                            onClick={() => setValue([])}
                            style={{ marginRight: 10 }}
                        >
                            Reset All Selections
                        </Button>
                    )}
                    <div
                        style={{ position: 'relative' }}
                        ref={selectRef}
                    >
                        <TextInput
                            value={query}
                            setValue={setQuery}
                            placeholder="Enter a keyword"
                            style={{ width: 300 }}
                            onFocus={() => setIsSelectOpened(true)}
                            onKeyDown={handleInputKeyDown}
                        />
                        {isSelectOpened && query.trim().length > 0 && (
                            <Dropdown style={{
                                opacity: 1,
                                pointerEvents: 'all',
                            }}>
                                {selectOptionsList.length > 0 ? (
                                    <Options
                                        data={selectOptionsList}
                                        value={[]}
                                        onOptionClick={handleOptionSelect}
                                    />
                                ) : (
                                    <DataFallback
                                        title=""
                                        subTitle="no matches."
                                        style={{
                                            paddingBlock: 30,
                                        }}
                                    />
                                )}
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-content">
                {value.length > 0 ? (
                    <PlatesSelect
                        value={[]}
                        options={selectedItems}
                        onOptionClick={(id) => setValue(value.filter(i => i !== id))}
                        isGenericItemWidth
                        isTagDecoration
                    />
                ) : (
                    <DataFallback
                        title=""
                        subTitle="No keywords added yet."
                    />
                )}
            </div>
        </div>
    )
}

export default Keywords;