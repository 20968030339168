import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import ActionsBar from '@vms/ActionsBar';
import FiltersButton from '@vms/FiltersButton';
import { Route } from '@core/routes/Route';
import BreadCrumbs from '@components/BreadCrumbs';
import ButtonTabs from '@components/ButtonTabs';
import { ID, Timeframe, UnitType } from '@models';
import Button from '@components/Button';
import Switch from '@components/Switch';
import Wrapper from '@components/Wrapper';
import FiltersPopup from '@vms/FiltersPopup';
import { useChainProfile } from '@api/useChainProfile';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@components/Loader';
import RecentlyReleasedItems from './RecentlyReleasedItems';
import TopPerformingItems from './TopPerformingItems';
import MostPopularFoodFlavors from './MostPopularFoodFlavors';
import SharedBySeason from './SharedBySeason';
import TimeframesBarChartData, { iTimeframesBarChartData } from '@models/TimeframesBarChartData';
import TimeframesBarChart from '@components/TimeframesBarChart';
import { getFormattedThousand } from '@core/utils/number';
import { FiltersVariant } from '@models/Filter';
import ChainComparison, { ChainComparisonType } from '@pages/ChainProfile/ChainComparison';
import { useChainProfileReport } from '@api/useChainProfileReport';
import { useFilterDateRangeString } from '@hooks/useFilterDateRangeString';
import ExportButton from '@vms/ExportButton';

const ChainProfileContent: FC = () => {
    const navigate = useNavigate();
    const {
        options: { chains, presets, otherSegments },
        value: { date: filterDate, categories },
        resetSpecificValue,
    } = useFiltersContext();
    const [activeTimeframe, setActiveTimeframe] = useState<Timeframe>(Timeframe.Season);
    const [selectedUnit, setSelectedUnit] = useState<UnitType>(UnitType.Count);
    const { id: paramId } = useParams();

    const id: ID = useMemo(() => Number(paramId) as ID, [paramId]);

    const currentChain = useMemo(() => {
        return chains.find(i => i.id === id);
    }, [chains, id]);

    const isOtherSegment = useMemo(
        () => !!otherSegments.find(i => i.id === currentChain?.segmentId),
        [currentChain, otherSegments]
    );

    const [comparisonType, setComparisonType] = useState<ChainComparisonType>(ChainComparisonType.Preset);
    const initialPresetId = currentChain?.defaultPresetId || presets[0].id;
    const [selectedPresetId, setSelectedPresetId] = useState<ID>(initialPresetId);
    const [selectedChainsIds, setSelectedChainsIds] = useState<ID[]>([]);

    const report = useChainProfileReport(id,
        comparisonType === ChainComparisonType.Preset ? selectedPresetId : undefined,
        comparisonType === ChainComparisonType.Preset ? undefined : selectedChainsIds,
    );

    if (!currentChain) {
        navigate(Route.Home);
    }

    const { data, isLoading } = useChainProfile(id);

    const barChartData = useMemo((): iTimeframesBarChartData => {
        return new TimeframesBarChartData({
            apiModel2: data?.data || [],
            unitType: selectedUnit,
        });
    }, [data, selectedUnit]);

    const filterDateRangeString = useFilterDateRangeString();

    return (
        <>
            <div className="ChainProfile">
                <Wrapper>
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            minHeight: 70
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BreadCrumbs items={[
                                    { link: Route.Home, title: 'Home', },
                                    { link: Route.ChainPerformance, title: 'Chain Performance', },
                                    { link: '', title: data?.name || currentChain?.title || '', }
                                ]}/>
                                <ExportButton isLoading={report.isLoading} onClick={report.load}/>
                            </div>
                            <Switch
                                leftLabel={{
                                    title: "Counts",
                                    id: UnitType.Count,
                                    icon: '#',
                                    color: 'blue',
                                }}
                                rightLabel={{
                                    title: "Percent",
                                    id: UnitType.Pct,
                                    icon: '%',
                                    color: 'green',
                                }}
                                value={selectedUnit}
                                onChange={(value) => setSelectedUnit(value as UnitType)}
                                style={{ marginLeft: 20 }}
                            />
                        </div>
                        <div className="card">
                            <div
                                className="card-header"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingBlock: '15px',
                                }}
                            >
                                <div
                                    className="font-medium color-dimyellow"
                                    style={{ fontSize: 24, lineHeight: '30px', }}
                                >
                                    {data?.name || currentChain?.title || ''}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {selectedUnit === UnitType.Count
                                        ? 'Number of Menu Launches by'
                                        : 'Percent of Menu Launches by'
                                    }
                                    <ButtonTabs
                                        style={{ marginLeft: 10 }}
                                        data={[
                                            {
                                                id: Timeframe.Season,
                                                title: 'Season',
                                                buttonProps: {
                                                    onClick: () => setActiveTimeframe(Timeframe.Season),
                                                }
                                            },
                                            {
                                                id: Timeframe.Month,
                                                title: 'Month',
                                                buttonProps: {
                                                    onClick: () => setActiveTimeframe(Timeframe.Month),
                                                }
                                            },
                                        ]}
                                        activeItemId={activeTimeframe}
                                    />
                                </div>
                            </div>
                            {isLoading ? (
                                <div style={{ padding: 20 }}>
                                    <div style={{ position: 'relative', height: 380 }}>
                                        <Loader/>
                                    </div>
                                </div>
                            ) : (
                                <TimeframesBarChart
                                    key={activeTimeframe}
                                    data={barChartData}
                                    currentView={activeTimeframe}
                                    onBarClick={(barId) => {
                                        resetSpecificValue(FiltersVariant.Homepage, {
                                            chainId: id,
                                            date: filterDate,
                                            categories,
                                        });
                                        navigate(`${Route.Home}?timeframe=${activeTimeframe}&barId=${barId}`);
                                    }}
                                    legendMessage={`click on a bar to see that ${activeTimeframe}'s launches`}
                                    valueFormatter={selectedUnit === UnitType.Count
                                        ? getFormattedThousand
                                        : (value) => `${Math.round(value)}%`}
                                    valueSuffix={selectedUnit === UnitType.Pct ? '%' : ''}
                                    selectCurrentSeasonFirst
                                    showYAxis={activeTimeframe === Timeframe.Month}
                                    showGroupValue
                                />
                            )}
                        </div>
                    </>

                    <ChainComparison
                        isGlobalLoading={isLoading}
                        chainId={id}
                        chainName={data?.name || currentChain?.title || ''}
                        itemsCount={data?.totalCount}
                        selectedUnitType={selectedUnit}
                        selectedPresetId={selectedPresetId}
                        selectedChainsIds={selectedChainsIds}
                        setSelectedPresetId={setSelectedPresetId}
                        setSelectedChainsIds={setSelectedChainsIds}
                        comparisonType={comparisonType}
                        setComparisonType={setComparisonType}
                        filterDateRangeString={filterDateRangeString}
                    />
                    <div style={{ display: 'flex' }}>
                        <SharedBySeason
                            isGlobalLoading={isLoading}
                            chainId={id}
                            chainName={data?.name || currentChain?.title || ''}
                            selectedUnitType={selectedUnit}
                            isSingleChild={isOtherSegment}
                        />
                        {!isOtherSegment && (
                            <MostPopularFoodFlavors
                                isGlobalLoading={isLoading}
                                chainId={id}
                                chainName={data?.name || currentChain?.title || ''}
                                filterDateRangeString={filterDateRangeString}
                            />
                        )}
                    </div>
                    {!isOtherSegment && (
                        <TopPerformingItems
                            chainId={id}
                            chainName={data?.name || currentChain?.title || ''}
                            isGlobalLoading={isLoading}
                            filterDateRangeString={filterDateRangeString}
                        />
                    )}
                </Wrapper>
                <Wrapper style={{ overflow: 'hidden' }}>
                    <RecentlyReleasedItems
                        chainId={id}
                        chainName={data?.name || currentChain?.title || ''}
                        isGlobalLoading={isLoading}
                    />
                </Wrapper>
            </div>
            <FiltersPopup/>
            <ActionsBar>
                <FiltersButton id="actions-bar-filters-popup-open"/>
            </ActionsBar>
        </>
    );
};

const ChainProfile: FC = () => {
    const { isLoaded, resetSpecificValue } = useFiltersContext();
    const { id: paramId } = useParams();

    useEffect(() => {
        resetSpecificValue(FiltersVariant.ChainProfile);
    }, [paramId]);

    if (!isLoaded) return null;

    return <ChainProfileContent key={paramId}/>;
};

export default ChainProfile;