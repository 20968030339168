import { FCX, Season, UnitType } from '@models';
import Loader from '@components/Loader';
import React, { ReactNode, useMemo } from 'react';
import { getFormattedThousand } from '@core/utils/number';
import VersusBarChart from '@components/VersusBarChart';
import { useFiltersContext } from '@core/FiltersContext';
import { useSeasonalByCategories } from '@api/useSeasonalByCategories';
import { iVersusBarChartData } from '@components/VersusBarChart/VersusBarChart';
import DataFallback from '@components/DataFallback';

const LaunchesByCategory: FCX<{
    selectedSeason: Season;
    selectedUnit: UnitType;
    renderSeasonName: ReactNode;
    totalLaunchesCount: number;
    isGlobalLoading: boolean;
}> = ({
    selectedSeason,
    selectedUnit,
    renderSeasonName,
    totalLaunchesCount,
}) => {
    const { options: { categories } } = useFiltersContext();
    const { isLoading, data } = useSeasonalByCategories(selectedSeason);

    const chartData = useMemo(
        (): iVersusBarChartData => {
            if (!data) {
                return {
                    mainTitle: '',
                    compareTitle: '',
                    values: [],
                };
            }

            const categoriesCopy = [...categories];
            categoriesCopy.sort((a, b) => {
                const aCount = data.find(i => i.id === a.id)?.count || 0;
                const bCount = data.find(i => i.id === b.id)?.count || 0;
                return bCount - aCount;
            });

            return ({
                mainTitle: '',
                compareTitle: '',
                values: categoriesCopy
                    .map(option => {
                        const count = data.find(i => i.id === option.id)?.count || 0;

                        return ({
                            id: option.id,
                            title: option.title,
                            mainValue: selectedUnit === UnitType.Count
                                ? count
                                : Math.round((count / totalLaunchesCount) * 100),
                            compareValue: 0,
                        });
                    }),
            });
        },
        [data, categories, selectedUnit, totalLaunchesCount]
    );

    return (
        <div className="card" style={{ flex: '0 1 100%' }}>
            <div
                className="card-header"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <div>{renderSeasonName}&nbsp;Launches by Category</div>
                <div>{selectedUnit === UnitType.Count ? 'Number' : 'Share'} of Launches</div>
            </div>
            {isLoading ? (
                <div style={{ padding: 20 }}>
                    <div style={{ position: 'relative', height: 280 }}>
                        <Loader/>
                    </div>
                </div>
            ) : (
                <div className="card-content" style={{ height: 320 }}>
                    {chartData.values.some(i => i.mainValue > 0) ? (
                        <VersusBarChart
                            style={{ flex: '0 1 100%' }}
                            data={chartData}
                            valueFormatter={(value) => selectedUnit === UnitType.Pct
                                ? `${Math.round(value)}%`
                                : getFormattedThousand(value)
                            }
                            hideCompare
                        />
                    ) : (
                        <DataFallback
                            style={{ opacity: isLoading ? 0 : 1, height: '100%' }}
                            title="No results"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default LaunchesByCategory;