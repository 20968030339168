import { useMemo, useState } from 'react';
import { ID } from '@models';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { useFiltersContext } from '@core/FiltersContext';

export enum PptFormat {
    Format16x9 = 0,
    Format4x3 = 1,
}

export function usePptReport(isDefaultNormType: boolean = false) {
    const [isLoading, setIsLoading] = useState(false);
    const {
        options: { norms },
        value: { norm },
    } = useFiltersContext();

    const normTypeId = useMemo((): ID => {
        if (isDefaultNormType || norm === null) {
            return norms[0].id;
        }
        return norm;
    }, [isDefaultNormType, norm, norms]);

    const load = async (ids: ID[]) => {
        setIsLoading(true);
        const data: components['schemas']['PptModelV2'] = {
            itemsId: ids as number[],
            normType: normTypeId as components['schemas']['PptModelV2']['normType'],
        };
        const url: keyof paths = '/api/Export/PptFullReport';
        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}