import { FCX, iChain, ID, iOption, UnitType } from '@models';
import { iChainProfileComparisonData } from '@models/ChainProfileComparisonData';
import React, { memo, ReactNode, useMemo } from 'react';
import VersusBarChart from '@components/VersusBarChart';
import { iVersusBarChartData } from '@components/VersusBarChart/VersusBarChart';
import { useFiltersContext } from '@core/FiltersContext';
import { getFormattedThousand } from '@core/utils/number';
import Loader from '@components/Loader';
import PlatesSelect from '@components/PlatesSelect';

const ChainComparisonChart: FCX<{
    isLoading: boolean;
    groupColumnName: string;
    chainName: string;
    chainId: ID;
    data: iChainProfileComparisonData;
    unitType: UnitType;
    isSingleChainComparison: boolean;
    selectedChainsIds: ID[];
    filterDateRangeString: ReactNode;
}> = ({
    isLoading,
    groupColumnName,
    chainName,
    data,
    unitType,
    isSingleChainComparison,
    selectedChainsIds,
    filterDateRangeString,
}) => {
    const {
        options: {
            categories: unsortedCategories,
            chains,
        },
    } = useFiltersContext();
    const { currentChain, group } = data;

    const categories = useMemo(
        (): iOption[] => {
            const result = [...unsortedCategories];
            const { countsByCategories, pctByCategories } = currentChain;

            const dataToSort = unitType === UnitType.Pct ? pctByCategories : countsByCategories;

            result.sort((a, b) => (dataToSort.get(b.id) || 0) - (dataToSort.get(a.id) || 0));

            return result;
        },
        [unsortedCategories, currentChain, unitType]
    );

    const selectedChains = useMemo(
        (): iChain[] => chains
            .filter(i => selectedChainsIds.includes(i.id)),
        [chains, selectedChainsIds]
    );

    const chartData = useMemo(
        (): iVersusBarChartData => {
            const { countsByCategories, pctByCategories } = currentChain;
            const { avgCountsByCategories, avgPctByCategories } = group;
            const mainData = unitType === UnitType.Pct ? pctByCategories : countsByCategories;
            const groupData = unitType === UnitType.Pct ? avgPctByCategories : avgCountsByCategories;

            return {
                mainTitle: chainName,
                compareTitle: groupColumnName,
                values: categories.map(c => ({
                    id: c.id,
                    title: c.title,
                    mainValue: mainData.get(c.id) || 0,
                    compareValue: groupData.get(c.id) || 0,
                })),
            }
        },
        [categories, currentChain, unitType, group, groupColumnName, chainName],
    );

    const chainsPopupMaxWidth = useMemo(
        (): number => {
            if (selectedChains.length > 50) {
                return 1000;
            } else if (selectedChains.length > 25) {
                return 800
            }
            // else if (selectedChains.length > 20) {
            return 600;
            // }
        },
        [selectedChains]
    )

    return (
        <div className="card ChainComparisonChart">
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    Share of{' '}
                    <div className="color-dimyellow" style={{ display: 'inline' }}>{chainName}</div>
                    {' '}Launches by Category{' '}
                    <div style={{ display: 'inline', opacity: 0.5 }}>
                        vs.{' '}{isSingleChainComparison ? '' : 'Average Among'}{' '}{groupColumnName}
                    </div>
                </div>
                <div style={{ opacity: 0.5, textTransform: 'uppercase' }}>
                    {filterDateRangeString}
                </div>
            </div>
            <div className="card-content" style={{ display: 'flex', alignItems: 'center', height: 350 }}>
                {isLoading && (
                    <div style={{ flex: '0 1 100%', position: 'relative' }}>
                        <Loader/>
                    </div>
                )}
                {!isLoading && !data.isEmpty && (
                    <>
                        <VersusBarChart
                            style={{ flex: '0 1 100%' }}
                            data={chartData}
                            valueFormatter={(value) => unitType === UnitType.Pct
                                ? `${Math.round(value)}%`
                                : getFormattedThousand(value)
                            }
                            compareLegendTooltip={isSingleChainComparison || selectedChainsIds.length === 0 ? null : (
                                <PlatesSelect
                                    value={[]}
                                    options={selectedChains}
                                    onOptionClick={() => null}
                                    style={{
                                        pointerEvents: 'none',
                                        paddingTop: 4,
                                        maxWidth: chainsPopupMaxWidth,
                                    }}
                                    isGenericItemWidth
                                />
                            )}
                            showTooltip
                            showLegend
                            showHover
                        />
                        <div className="ChainComparisonChart__legend">
                            <div className="font-medium">Total Menu Launches</div>
                            <div className="ChainComparisonChart__legend-item">
                                <div className="ChainComparisonChart__legend-count is-main">
                                    {currentChain.totalCount}
                                </div>
                                {chainName}
                            </div>
                            <div className="ChainComparisonChart__legend-item">
                                <div className="ChainComparisonChart__legend-count is-compare">
                                    {group.avgTotalCount}
                                </div>
                                {groupColumnName} {isSingleChainComparison ? '' : 'Average'}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(ChainComparisonChart);