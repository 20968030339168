import { FCX, ID } from '@models';
import { RxCross2 } from 'react-icons/rx';
import { BsArrowUp } from 'react-icons/bs';
import Button from '@components/Button';
import Popup from '@components/Popup';
import { Tooltip } from 'react-tooltip';

const ACTIVE_ITEMS_LIMIT = 5;

const TableChartButton: FCX<{
    isActive: boolean;
    activeItemsCount: number;
    color?: string;
    onClick?: () => void;
    itemId: ID;
}> = ({
    itemId,
    isActive,
    activeItemsCount,
    color,
    onClick,
}) => {
    const isDisabled = !isActive && activeItemsCount >= ACTIVE_ITEMS_LIMIT;

    return (
        <>
            <div data-tooltip-id={`item-${itemId}-chart-button`}>
                <Button
                    modifiers={[isActive ? 'blue' : 'black']}
                    onClick={onClick}
                    isDisabled={isDisabled}
                    style={{
                        padding: 0,
                        height: 24,
                        width: 24,
                        marginRight: 10,
                        background: color,
                    }}
                >
                    {isActive ? <RxCross2/> : <BsArrowUp/>}
                </Button>
            </div>
            <Popup hasWrapper={false}>
                <Tooltip
                    id={`item-${itemId}-chart-button`}
                    place={'bottom-start'}
                    positionStrategy="fixed"
                    style={{ zIndex: 4 }}
                >
                    {isDisabled ? `the items per chart limit is ${ACTIVE_ITEMS_LIMIT}` : (
                        <>
                            {isActive ? 'remove from chart' : 'send to chart'}
                        </>
                    )}
                </Tooltip>
            </Popup>
        </>
    )
}

export default TableChartButton;