import { useCallback, useState } from 'react';

export function useSkipTake (chunkSize: number, totalRecords?: number) {
    const [currentPage, setCurrentPage] = useState(1);

    const pagesCount = totalRecords ? Math.ceil(totalRecords / chunkSize) : undefined;

    const inc = useCallback(
        () => {
            if (pagesCount) {
                setCurrentPage(Math.min(pagesCount, currentPage + 1))
            }
        },
        [pagesCount, currentPage]
    );
    const dec = useCallback(
        () => setCurrentPage(Math.max(0, currentPage - 1)),
        [currentPage]
    );

    const reset = useCallback(() => {
        setCurrentPage(1);
    }, [setCurrentPage]);

    return {
        reset,
        inc,
        dec,
        totalRecords,
        currentPage,
        perPage: chunkSize,
        pagesCount,
    };
}