import { FCX } from '@models';
import { TableContext } from '@components/Table/TableContext';
import useWindowSizeDebounced from '@hooks/useWindowSizeDebounced';
import { useCallback, useMemo } from 'react';
import { iItem, iItemMetrics, Item } from '@models/Item';
import useDateColumn from '@vms/ItemsTable/useDateColumn';
import useNameColumn from '@vms/ItemsTable/useNameColumn';
import { useStatusColumn } from '@vms/ItemsTable/useStatusColumn';
import { useMetricColumns } from '@vms/ItemsTable/useMetricsColumns';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { useFiltersContext } from '@core/FiltersContext';

const TopPerformingItemsTableConfig: FCX<{
    data: iItem[];
}> = ({
    data,
    children,
}) => {
    const [ wWidth ] = useWindowSizeDebounced();
    const { options: { totalRespondentType } } = useFiltersContext();

    const getMetrics = useCallback((index: number): iItemMetrics => {
        return data[index].indices.get(totalRespondentType.id as number) || Item.defaultMetricsData;
    }, [totalRespondentType, data]);

    const metricsColumnScale = useMemo((): number => {
        if (wWidth > 1500) return 1.3;
        return 1;
    }, [wWidth]);


    const dateColumn = useDateColumn(data, false);
    const nameColumn = useNameColumn(data, getMetrics);
    const statusColumn = useStatusColumn(data);
    const metricsColumns = useMetricColumns(data, getMetrics, metricsColumnScale);

    const tableConfig = useMemo(
        (): iTableConfigItem[] => [
            {
                key: 'rank',
                title: 'Rank',
                Value: ({ index }) => (
                    <div className="font-medium">
                        # {data[index].rank} of {data[index].rankRange}
                    </div>
                ),
                styles: {
                    cell: {
                        flex: '0 0 80px',
                    },
                },
            },
            dateColumn,
            nameColumn,
            statusColumn,
            ...metricsColumns,
        ],
        [dateColumn, nameColumn, statusColumn, metricsColumns, data]
    );

    return (
        <TableContext.Provider value={{ config: tableConfig }}>
            {children}
        </TableContext.Provider>
    )
};
export default TopPerformingItemsTableConfig;