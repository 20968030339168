import { FCX } from '@models';
import Scrollbar from 'react-scrollbars-custom';
import { CSSProperties, useMemo, useRef } from 'react';
import TableRowLoading from '@components/Table/TableRowLoading';
import TableRow from '@components/Table/TableRow';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import TableHead from '@components/Table/TableHead';
import DataFallback from '@components/DataFallback';
import ButtonClipboardCopy from '@components/ButtonClipboardCopy';
import copy from 'copy-to-clipboard';

const VirtualizedTable: FCX<{
    isLoading: boolean;
    rowsCount: number;
    rowHeight?: number;
    copyTable?: {
        isLoading: boolean;
        data: string | null;
    };
    headStyle?: CSSProperties;
    isScrollXEnabled?: boolean;
    dataPlaceholderText?: string;
}> = ({
    isLoading,
    rowsCount,
    rowHeight = 30,
    headStyle,
    copyTable,
    dataPlaceholderText,
    isScrollXEnabled= false,
    style,
}) => {
    const listRef = useRef<HTMLDivElement>(null);
    const itemsCount = useMemo(() => {
        if (!isLoading) return rowsCount;
        return Math.max(30, rowsCount);
    }, [isLoading, rowsCount]);

    return (
        <AutoSizer>
            {({ width, height }) => (
                <div style={{ width, height }}>
                    {!!copyTable && (
                        <ButtonClipboardCopy
                            style={{ top: 20 }}
                            onClick={() => copy(copyTable.data || '', { format: 'text/plain' })}
                            isBusy={copyTable.isLoading || copyTable.data === null}
                        />
                    )}
                    <Scrollbar
                        noScrollX={!isScrollXEnabled}
                        // @ts-ignore
                        onScroll={({ scrollTop}) => {
                            listRef.current && listRef.current.scrollTo(scrollTop);
                        }}
                        trackYProps={{
                            renderer: ({ elementRef, style, ...restProps }) => (
                                <div
                                    {...restProps}
                                    ref={elementRef}
                                    style={{
                                        ...style,
                                        transform: `translateY(30px)`,
                                        height: `calc(100% - 50px)`,
                                    }}
                                />
                            ),
                        }}
                    >
                        <div className="Table" style={{ ...style, minHeight: height }}>
                            <TableHead style={{
                                top: 0,
                                paddingTop: 0,
                                height: 30,
                                ...headStyle,
                            }}/>
                            <FixedSizeList
                                // @ts-ignore
                                ref={listRef}
                                itemSize={rowHeight}
                                width="100%"
                                height={height}
                                itemCount={itemsCount}
                                className="window-scroller-override"
                            >
                                {({ index, style }) => (
                                    (isLoading && rowsCount === 0) ? (
                                        <TableRowLoading style={style} index={index}/>
                                    ) : (
                                        <TableRow style={style} index={index}/>
                                    )
                                )}
                            </FixedSizeList>
                            {!isLoading && rowsCount === 0 && (
                                <DataFallback
                                    title="No results"
                                    subTitle={dataPlaceholderText}
                                />
                            )}
                        </div>
                    </Scrollbar>
                </div>
            )}
        </AutoSizer>
    );
};

export default VirtualizedTable;