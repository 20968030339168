import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import Wrapper from '@components/Wrapper';
import { Route } from '@core/routes/Route';
import BreadCrumbs from '@components/BreadCrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { useFoodWatchItem } from '@api/useFoodWatchItem';
import Loader from '@components/Loader';
import Button from '@components/Button';
import EditTitlePopup from '@pages/FoodWatchItem/EditTitlePopup';
import ButtonTabs from '@components/ButtonTabs';
import { useUserContext } from '@core/UserContext';
import { iButtonTabsItem } from '@components/ButtonTabs/ButtonTabs';
import Chains from '@pages/FoodWatchItem/Chains';
import Categories from '@pages/FoodWatchItem/Categories';
import Keywords from '@pages/FoodWatchItem/Keywords';
import ActionsBar from '@vms/ActionsBar';
import { toast } from 'react-toastify';
import { ButtonType } from '@components/Button/Button';

const FoodWatchItemContent: FC = () => {
    const { options: { frequencies } } = useFiltersContext();
    const { email } = useUserContext();
    const params = useParams();
    const navigate = useNavigate();
    const id = Number(params.id) || undefined;

    const isEditMode = useMemo(() => id !== undefined, [id]);

    const { isLoading, data, setData, updateData } = useFoodWatchItem(id);
    const [isEditTitlePopupOpened, setIsEditTitlePopupOpened] = useState(!isEditMode);

    const frequenciesTabsData = useMemo((): iButtonTabsItem[] => frequencies.map(i => ({
        ...i,
        buttonProps: {
            onClick: () => setData({
                ...data,
                frequencyId: i.id,
            }),
        }
    })), [frequencies, data, setData]);

    const onSave = useCallback(async () => {
        const result = await updateData();

        if (result.status === 200) {
            toast.success('Changes saved.');
            navigate(Route.FoodWatch);
        } else {
            toast.error('Something went wrong.');
        }
    }, [updateData, navigate]);

    if (isEditMode && id === undefined) {
        navigate(Route.FoodWatch);
    }

    return (
        <>
            <div className="FoodWatchItem" style={{ position: 'relative', minHeight: 'calc(100vh - 140px)' }}>
                <Wrapper>
                    {isLoading && (
                        <Loader/>
                    )}

                    {!isLoading && (
                        <>
                            <BreadCrumbs items={[
                                { link: Route.Home, title: 'Home', },
                                { link: Route.FoodWatch, title: 'Food Watch', },
                                { link: '', title: data.title, }
                            ]}/>
                            <div className="card">
                                <div
                                    className="card-header"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <div
                                        className="font-medium color-dimyellow"
                                        style={{ fontSize: 24, lineHeight: '30px', }}
                                    >
                                        {data.title}
                                    </div>
                                    <Button
                                        modifiers={['blue']}
                                        onClick={() => setIsEditTitlePopupOpened(true)}
                                    >
                                        EDIT NAME
                                    </Button>
                                </div>
                                <div
                                    className="card-content"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <div className="FoodWatchItem__column">
                                        <div
                                            className="font-medium"
                                            style={{
                                                fontSize: 16,
                                                lineHeight: "20px",
                                                marginBottom: 10,
                                            }}
                                        >
                                            FREQUENCY
                                        </div>
                                        <div>How often would you like to receive emails?</div>
                                        <br/>
                                        <ButtonTabs
                                            defaultButtonModifier={'lightgray'}
                                            style={{
                                                border: '1px solid #ddd',
                                                display: 'inline-flex',
                                            }}
                                            data={frequenciesTabsData}
                                            activeItemId={data.frequencyId}
                                        />
                                    </div>
                                    <div className="FoodWatchItem__column">
                                        <div
                                            className="font-medium"
                                            style={{
                                                fontSize: 16,
                                                lineHeight: "20px",
                                                marginBottom: 10,
                                            }}
                                        >
                                            EMAIL PREFERENCE
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            Your Food Watch notifications will be sent to&nbsp;
                                            <div style={{ fontStyle: 'italic', fontFamily: 'sans-serif' }}>{email}</div>
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Chains
                                value={data.chainsIds}
                                setValue={(chainsIds) => setData({ ...data, chainsIds })}
                            />
                            <Categories
                                value={data.categoriesIds}
                                setValue={(categoriesIds) => setData({ ...data, categoriesIds })}
                            />
                            <Keywords
                                value={data.keywordsIds}
                                setValue={(keywordsIds) => setData({ ...data, keywordsIds })}
                            />
                        </>
                    )}
                </Wrapper>
                <EditTitlePopup
                    isOpened={isEditTitlePopupOpened}
                    setIsOpened={setIsEditTitlePopupOpened}
                    applyNewValue={(title) => setData({ ...data, title, })}
                    isInitialValueMode={!isEditMode && data.title === ''}
                    appliedValue={data.title}
                />
            </div>
            <ActionsBar style={{ padding: 0 }}>
                <Wrapper style={{ justifyContent: 'flex-end' }}>
                    <Button
                        type={ButtonType.Link}
                        link={Route.FoodWatch}
                        modifiers={['blue', 'naked']}
                        className="font-bold"
                        style={{ height: 40, marginRight: 10 }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        modifiers={['green']}
                        onClick={onSave}
                        className="font-bold"
                        style={{ width: 150, height: 40 }}
                    >
                        SAVE
                    </Button>
                </Wrapper>
            </ActionsBar>
        </>
    )
};

const FoodWatchItem: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <FoodWatchItemContent/>;
};

export default FoodWatchItem;