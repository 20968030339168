import { Month, Season } from "@models";

export function getSeason(month: number): Season | null {
    switch (month) {
        case Month.Dec:
        case Month.Jan:
        case Month.Feb: return Season.Winter;
        case Month.Mar:
        case Month.Apr:
        case Month.May: return Season.Spring;
        case Month.Jun:
        case Month.Jul:
        case Month.Aug: return Season.Summer;
        case Month.Sep:
        case Month.Oct:
        case Month.Now: return Season.Fall;
        default: return null
    }
}