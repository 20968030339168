import useFetch from '@hooks/useFetch';
import { toast } from 'react-toastify';

export function useContactUs(location: string) {
    const request = useFetch({
        url: '/SubscribeRequest/ContactUs',
        method: 'post',
        payload: {
            toolLocation: location,
        },
        lazy: true,
    });

    function doFetch() {
        request.doFetch().finally(() => {
            toast.success('Request has been sent.');
        });
    }

    return {
        isLoading: request.isLoading,
        doFetch,
    }
}