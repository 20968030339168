import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { FCX, ID } from '@models';
import Button from '@components/Button';
import { RiFilePpt2Fill } from 'react-icons/ri';
import { components } from '@api/api';
import Preloader from '@components/Preloader';
import { getFormattedPrice } from '@core/utils/number';
import { PptFormat, usePptReport } from '@api/usePptReport';
import Modal from '@components/Modal';
import ChainLink from '@vms/ChainLink';
import cn from 'classnames';
import StatusIcon from '@components/StatusIcon';
import { FaStickyNote } from 'react-icons/fa';
import { useUserContext } from "@core/UserContext";

const ItemHeader: FCX<{
    item: components['schemas']['ItemDetailsModel'];
    isLoading: boolean;
}> = ({
    className,
    isLoading,
    item
}) => {
    const {
        id,
        imageUrl,
        isPhoto,
        ffChainId,
        chainName,
        price,
        name,
        description,
        note,
        kind,
        date = '',
        segmentId,
        segmentName,
        totalRespondentsCount,
        totalBrandedCount,
        typeId,
    } = item;

    const { uiSettings: { currencySymbol}, } = useUserContext();

    const refNode = useRef<HTMLDivElement>(null);
    const [isThumbnailExpanded, setIsThumbnailExpanded] = useState(false);
    const pptReport = usePptReport(true);

    const formattedDate = new Date(date || '').toLocaleDateString('en', {
        month: 'short',
        year: 'numeric'
    });


    const testedFor = (ffChainId === null && segmentId !== null) ? `| Tested for: ${segmentName}` : ((segmentId === null) ? `| Tested for: ${(typeId === 4) ? 'Express' : 'General (no chain or segment specified)'}` : ``);

    return (
        <div className={classNames('item-header', className)} ref={refNode}>
            <div className="item-header-container">
                <div className="item-header-enter">
                    <div className="card item-header-card">
                        {(isPhoto === true) && (
                            <div className="card-item-header-image">
                                <figure
                                    className="card-item-header-image__figure"
                                    onClick={() => setIsThumbnailExpanded(true)}
                                >
                                    <img
                                        src={imageUrl || ''}
                                        alt={name || ''}
                                    />
                                </figure>
                            </div>
                        )}
                        <div className="card-item-header-content">
                            {isLoading && (
                                <div className="item-header-wrapper">
                                    <Preloader backgroundColor="#fff"/>
                                </div>
                            )}
                            {!isLoading && (
                                <>
                                    <div className="card-item-header-content__chain">
                                        {(ffChainId) && (
                                            <ChainLink
                                                className="link"
                                                chainId={ffChainId}
                                            >
                                                {chainName}
                                            </ChainLink>
                                        )}
                                    </div>

                                    <div className="card-item-header-content__name">
                                        <div className="item-header-wrapper">
                                            {name}
                                            {!!price && (
                                                <>
                                                    &nbsp;({currencySymbol}{getFormattedPrice(price)})
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card-item-header-content__text">
                                        <div className="card-item-header-content__description">
                                            <div className="item-header-wrapper">
                                                {description}
                                            </div>
                                        </div>
                                        {!!kind && (
                                            <div className="card-item-header-content__status">
                                                <div className="item-header-wrapper">
                                                    <div className="card-item-header-content__status-title">
                                                        <StatusIcon
                                                            status={kind}
                                                            style={{
                                                                color: '#808080',
                                                                width: 20,
                                                                height: 20,
                                                                flex: '0 0 20px',
                                                                marginRight: 10,
                                                            }}
                                                        />
                                                        {kind}
                                                    </div>
                                                    {!!note && (
                                                        <div className="card-item-header-content__status-note">
                                                            <FaStickyNote style={{
                                                                color: '#808080',
                                                                width: 20,
                                                                height: 20,
                                                                flex: '0 0 20px',
                                                                marginRight: 10,
                                                            }}/>
                                                            {note}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="item-header-wrapper">
                                            <div className={cn(
                                                "card-item-header-content__summary",
                                                !kind && 'is-separate',
                                            )}>
                                                <span className="infoblock">{formattedDate}</span>
                                                {(testedFor) && (
                                                    <span className="infoblock">{testedFor}</span>
                                                )}

                                                {(totalRespondentsCount !== null) && (
                                                    <span
                                                        className="infoblock">| {totalRespondentsCount} Respondents</span>
                                                )}

                                                {(totalBrandedCount !== null) && (
                                                    <span
                                                        className="infoblock">({totalBrandedCount} for Branded PI)</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className={classNames('item-header-report', {
                            'is-loading': pptReport.isLoading,
                            'is-disabled': isLoading,
                        })}
                        onClick={() => pptReport.load([id as ID])}
                    >
                        <div className="item-header-report__curtain">
                            {pptReport.isLoading ? (
                                <div>Loading...</div>
                            ) : (
                                <>
                                    <div>ITEM</div>
                                    <div>REPORT</div>
                                    <div className="item-header-report__icon font-icons">&#59702;</div>
                                </>
                            )}
                        </div>
                        <div className="item-header-report__preloader"/>
                    </div>
                </div>
            </div>
            {isThumbnailExpanded && (
                <Modal onClose={() => setIsThumbnailExpanded(false)}>
                    <img
                        alt="test"
                        src={imageUrl || ''}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ItemHeader;