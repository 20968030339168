import { useMemo } from 'react';
import Popup from '@components/Popup';
import { Tooltip } from 'react-tooltip';
import Tile from '@vms/Tile';
import { iItem, iItemMetrics } from '@models/Item';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { Link } from 'react-router-dom';
import useItemDetailsLink from '@hooks/useItemDetailsLink';
import { CONCEPT_LOCKER_INDICATOR_COLOR } from '@core/constants';

export default function useNameColumn(
    data: iItem[],
    getMetrics: (index: number) => iItemMetrics,
    indicateCLItems = false
): iTableConfigItem {
    return useMemo(() => ({
        key: 'name',
        title: 'Name',
        sortingColName: 'name',
        Value: ({ index }) => (
            <>
                <Link
                    to={useItemDetailsLink(data[index].id, data[index].isConcept)}
                    className="lines-3"
                >
                    {data[index].title}
                </Link>
                <Popup style={{ pointerEvents: 'none', zIndex: 100 }} hasWrapper={false}>
                    <Tooltip
                        id={`Item-table-${data[index].uuid}`}
                        place="right"
                        style={{
                            transition: 'none',
                            opacity: '1 !important',
                            background: 'transparent',
                            padding: 0,
                            position: 'fixed',
                            zIndex: 100,
                        }}
                        positionStrategy="fixed"
                    >
                        <Tile
                            data={data[index]}
                            metrics={getMetrics(index)}
                            noImage
                            isExpanded
                            style={{ zIndex: 3 }}
                        />
                    </Tooltip>
                </Popup>
                {indicateCLItems && data[index].isConcept && (
                    <div
                        className="Indicator"
                        style={{ background: CONCEPT_LOCKER_INDICATOR_COLOR }}
                    />
                )}
            </>
        ),
        getAttrs: (index) => (index >= 0 ? {
            'data-tooltip-id': `Item-table-${data[index]?.uuid}`,
        } : {}),
    }), [data, getMetrics, indicateCLItems]);
}