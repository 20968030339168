import { iTableConfigItem } from '@components/Table/TableConfig';
import { useMemo } from 'react';
import { iItem } from '@models/Item';
import TableRowCheck from '@components/Table/TableRowCheck';
import { useTableSorting } from '@vms/ItemsTable/TableSortingContext';


export default function useDateColumn (
    data: iItem[],
    hasSelect: boolean = true,
): iTableConfigItem {
    const { getIsSorted } = useTableSorting();

    return useMemo(() => ({
        key: 'date',
        title: 'Date',
        sortingColName: 'date',
        Value: ({ index }) => <>
            {hasSelect && (
                <TableRowCheck option={{
                    id: data[index].id,
                    title: data[index].title,
                }}/>
            )}
            {data[index].dateString}
        </>,
        styles: {
            cell: {
                flex: '0 0 100px',
            },
            head: {
                paddingLeft: getIsSorted('date') ? 15 : undefined,
            }
        },
    }), [data, getIsSorted, hasSelect]);
}