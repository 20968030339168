import { equalInLC } from '@core/utils/string';

export function hasAnyProperties<T>(obj: T, ...keys: Array<keyof T>): boolean {
    let flag = false;
    keys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            flag = true;
        }
    });

    return flag;
}

export function findByTitle<T>(items: T[], title: string): T | undefined {
    /* @ts-ignore */
    return items.find(i => equalInLC((i.title || '').trim(), title.trim()));
}

export function deepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}