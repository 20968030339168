export enum Route {
    Home = '/',
    HomeViewTable = '/table/',
    HomeViewPeople = '/people/',
    HomeViewChart = '/chart/',
    ConceptLocker = '/concept-locker/',
    ConceptLockerViewTiles = '/concept-locker/tiles/',
    ConceptLockerViewPeople = '/concept-locker/people/',
    ConceptLockerViewChart = '/concept-locker/chart/',
    MyConcepts = '/concept-locker/items/',
    ConceptPageCreate = '/concept-locker/items/new',
    ConceptPageEdit = '/concept-locker/items/:id',
    ItemDetails = '/items/:id',
    CLItemDetails = '/concept-locker/:id',
    ChainPerformance = '/chain-performance',
    ChainProfile = '/chain-performance/:id',
    ChainsChart = '/chain-performance/chart/',
    FoodsFlavors = '/foods-and-flavors/',
    Benchmark = '/benchmark/',
    Search = '/Search',
    FoodWatch = '/food-watch',
    FoodWatchItemEdit = '/food-watch/edit/:id',
    FoodWatchItemCreate = '/food-watch/new',
    SeasonalActivity = '/seasonal-activity',
    CategoryActivity = '/category-activity',
}