import { FCX, ID } from '@models';
import { iItem, Item } from '@models/Item';
import { useEffect, useMemo, useState, memo } from 'react';
import useWindowSizeDebounced from '@hooks/useWindowSizeDebounced';
import Tile from '@vms/Tile';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import cn from 'classnames';
import { sliceArrayLoop } from '@core/utils/array';

const TilesCarousel: FCX<{
    data: iItem[];
    tileIndexId: ID;
    denyAlternativeChainOptions?: boolean;
}> = ({
    data,
    tileIndexId,
    denyAlternativeChainOptions= false,
}) => {
    const [firstVisibleItemIndex, setFirstVisibleItemIndex] = useState(0);
    const [isMoving, setIsMoving] = useState(false);
    const [wWidth] = useWindowSizeDebounced();

    const itemsCountInViewport = useMemo(() => {
        if (wWidth >= 1680) {
            return 5;
        } else {
            return 4;
        }
    }, [wWidth]);

    const itemsToRender = useMemo(() => {
        let from = firstVisibleItemIndex;
        let size = itemsCountInViewport;

        if (data.length > itemsCountInViewport) {
            from -= 1;
            size += 2;
        }

        return sliceArrayLoop(data, from, size);
    }, [data, itemsCountInViewport, firstVisibleItemIndex]);

    const next = () => {
        setIsMoving(true);
        setTimeout(() => {
            setIsMoving(false);
        }, 600)
        let index = firstVisibleItemIndex + 1;
        setFirstVisibleItemIndex(index);
    };
    const prev = () => {
        setIsMoving(true);
        setTimeout(() => {
            setIsMoving(false);
        }, 600)
        let index = firstVisibleItemIndex - 1;
        setFirstVisibleItemIndex(index);
    };

    useEffect(() => {
        setFirstVisibleItemIndex(0)
    }, [data]);

    return (
        <div className="TilesCarousel">
            {data.length > itemsCountInViewport && (
                <div
                    className={cn("TilesCarousel__nav TilesCarousel__prev",)}
                    onClick={!isMoving ? prev : undefined}
                >
                    <FiArrowLeft/>
                </div>
            )}
            <div className={cn(
                "TilesCarousel__viewport",
                `items-${itemsCountInViewport}`,
            )}>
                {data.length > itemsCountInViewport && itemsToRender.map((item, index) => (
                    <div
                        key={firstVisibleItemIndex + index - 1}
                        className={cn(
                            `item-${index}`,
                            "TilesCarousel__item",
                            data.length > itemsCountInViewport && index === 0 && 'is-first',
                            data.length > itemsCountInViewport && index === itemsToRender.length - 1 && 'is-last',
                            data.length > itemsCountInViewport && index === 1 && 'is-after-first',
                            data.length > itemsCountInViewport && index === itemsToRender.length - 2 && 'is-before-last',
                        )}
                    >
                        <Tile
                            data={item}
                            expandToTop
                            denyAlternativeChainOptions={denyAlternativeChainOptions}
                            metrics={item.indices.get(tileIndexId as number) || Item.defaultMetricsData}
                        />
                    </div>
                ))}
                {data.length <= itemsCountInViewport && data.map((item) => (
                    <Tile
                        key={item.id}
                        data={item}
                        expandToTop
                        denyAlternativeChainOptions={denyAlternativeChainOptions}
                        metrics={item.indices.get(tileIndexId as number) || Item.defaultMetricsData}
                    />
                ))}
            </div>
            {data.length > itemsCountInViewport && (
                <div
                    className={cn("TilesCarousel__nav TilesCarousel__next",)}
                    onClick={!isMoving ? next : undefined}
                >
                    <FiArrowRight/>
                </div>
            )}
        </div>
    );
};

export default memo(TilesCarousel);