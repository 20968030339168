import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { ID } from '@models';

type RequestType = components['schemas']['ChainPerformancePresentationExportFilter'];

export function useChainProfileReport(chainId: ID, presetId?: ID, chainsIds?: ID[]) {
    const [isLoading, setIsLoading] = useState(false);

    const load = async () => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/PptChainPerformance';

        const data: RequestType = {
            chainId: chainId as number,
            presetId: presetId as RequestType['presetId'],
            chainsToCompare: chainsIds as RequestType['chainsToCompare'],
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}