import { FCX } from '@models';
import Button from '@components/Button';
import React from 'react';

const ExportButton: FCX<{
    isLoading: boolean;
    onClick: () => void;
}> = ({
    isLoading,
    onClick,
    style,
    className,
}) => {
    return (
        <Button
            modifiers={['green']}
            style={{
                ...style,
                marginLeft: 20,
                width: 170,
                position: 'relative',
                overflow: 'hidden',
            }}
            onClick={onClick}
            className={className}
        >
            {isLoading && (
                <>
                    Downloading...
                    <div className="ProgressLoader is-inside-button"/>
                </>
            )}
            {!isLoading && 'DOWNLOAD REPORT'}
        </Button>
    );
};

export default ExportButton;