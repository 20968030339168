import React from 'react';
import classNames from 'classnames';

import {
	BarChart, 
	Bar, 
	XAxis, 
	YAxis, 
	ResponsiveContainer, 
	LabelList, 
	Cell,
	Text,
} from 'recharts';
import { FCX } from '@models';
import { defaultSort } from '@core/utils/array';
import { components } from '@api/api';

const CustomizedAxisTick: FCX<{
	data: components["schemas"]["NameValueModel"][];
	payload?: any;
	fill: string;
	width: number;
	textAnchor?: any;
	verticalAnchor?: any;
	x?: number;
	y?: number;
}> = ({
	x,
	y,
	data,
	payload = {},
	width,
	textAnchor = 'middle',
	verticalAnchor = 'start',
	fill
}) => {


	const {
		name = '',
	} = data[payload.index] || {};

	return (
		<Text
			width={width}
			x={x}  y={y}
			textAnchor={textAnchor}
			verticalAnchor={verticalAnchor}
			lineHeight={16}
			fill={fill}
		>
			{name || ''}
		</Text>
	);
}



const CardBrandFit: FCX<{
	dataList: components["schemas"]["NameValueModel"][];
}> = ({
	className,
	dataList = [],
}) => {
	const [total, ...chartData] = dataList;
	chartData.sort((a,b) => (defaultSort(a.order, b.order)));

	return (
		<div className={classNames('card card-brand-fit', className)}>
			<div className="card-header card-brand-fit-header">
				<div>BRAND FIT</div>
				<div className="card-brand-fit-header__description"/>
			</div>
			<div className="card-brand-fit-content">
				<div className="card-brand-fit-legend">
					<div className="card-brand-fit-legend-column">
						<div className="card-brand-fit-legend__value">
							{Math.round(Number(total.value))}%
						</div>
						<div className="card-brand-fit-legend__caption">
							believe this concept <b>fits well (or better)</b> with the brand
						</div>
					</div>
					<div className="card-brand-fit-legend-column">
						- scores above 80% indicate a very strong perceived fit
					</div>
				</div>

				<div className="card-brand-fit-chart">
					<ResponsiveContainer 
						width="100%"
						height={232}
					>
						<BarChart
							data={chartData}
							margin={{
								top: 20, 
								right: 0, 
								left: 0, 
								bottom: 10,
							}}
						>
							<XAxis 
								dataKey="none"
								axisLine={false}
								tickLine={false}
								tickMargin={8}
								tick={<CustomizedAxisTick
									data={chartData}
									fill="#4A4A4A"
									width={100} 
								/>}
							>
							</XAxis>			
							<YAxis
								domain={[0, 100]}
								axisLine={false}
								tickLine={false}
								unit=""
								hide
							/>
							<Bar 
								dataKey="value" 
								barSize={108}
								fill="#B9BFC7"
							>
								{chartData.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={(index < 3) ? '#32C1E9' : '#B9BFC7'} />
								))}
								<LabelList 
									fill="#000000"
									position="top"
									fontSize= '14px'
									fontFamily={'DIN Pro Medium'}
									formatter={(value: number) => (`${Math.round(value)}%`)}
								/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};

export default CardBrandFit;