import { FCX } from '@models';
import { useTableContext } from './TableContext';
import TableCell from '@components/Table/TableCell';
import TableHeadCellSorting from '@components/Table/TableHeadCellSorting';
import cn from 'classnames';

const TableHead: FCX = ({
    style,
    className,
}) => {
    const { config } = useTableContext();
    return (
        <div
            className={cn("TableHead", className)}
            style={style}
        >
            {config.map(item => {
                const {
                    title,
                    styles,
                    key,
                    sortingColName,
                    getAttrs,
                } = item;

                const attributes = {
                    key,
                    style: {
                        ...styles?.cell,
                        ...styles?.head,
                    },
                    children: title,
                    attrs: getAttrs ? getAttrs(-1) : undefined,
                };

                if (sortingColName) {
                    return (
                        <TableHeadCellSorting
                            {...attributes}
                            sortingColName={sortingColName}
                        />
                    );
                }
                return (
                    <TableCell {...attributes}/>
                );
            })}
        </div>
    )
};

export default TableHead;