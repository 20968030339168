import { createContext, useContext } from "react";
import { iTableConfigItem } from "./TableConfig";

export const TableContext = createContext<{
    config: iTableConfigItem[];
} | undefined>(undefined);

export function useTableContext () {
    const ctx =  useContext(TableContext);

    if (ctx === undefined) throw Error(`Table context can't be used outside of the TableContext.Provider`);

    return ctx;
}
