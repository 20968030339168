import { FCX, getSeasonMonthsFull, getSeasonStrByIndex, ID, Season, UnitType } from '@models';
import StackedPercentChart from '@components/StackedPercentChart';
import React, { memo, ReactNode, useMemo } from 'react';
import { Colors } from '@core/constants';
import { getFormattedThousand } from '@core/utils/number';
import { getUnique } from '@core/utils/array';
import {
    iStackedPercentChartSeries,
    iStackedPercentChartSlice,
    iStackedPercentChartValue
} from '@components/StackedPercentChart/StackedPercentChart';
import Scrollbar from 'react-scrollbars-custom';
import DataFallback from '@components/DataFallback';
import Loader from '@components/Loader';
import { useCategoryActivityLaunchesBySeason } from '@api/useCategoryActivityLaunchesBySeason';

const ChartContentWrapper: FCX = ({ children }) => {
    return (
        <div style={{ width: '100%', height: 255 }}>
            <Scrollbar noScrollX>
                {children}
            </Scrollbar>
        </div>
    );
}

const LaunchesBySeasons: FCX<{
    categoryId: ID;
    renderCategoryName: ReactNode;
    selectedUnit: UnitType;
    isGlobalLoading: boolean;
}> = ({
    categoryId,
    renderCategoryName,
    selectedUnit,
}) => {
    const { data, isLoading } = useCategoryActivityLaunchesBySeason(categoryId);

    const series = useMemo(
        (): iStackedPercentChartSeries[] => [
            {
                id: Season.Winter,
                color: Colors.Blue,
                title: Season.Winter,
                tooltip: getSeasonMonthsFull(Season.Winter).join(', ')
            },
            {
                id: Season.Spring,
                color: Colors.Green,
                title: Season.Spring,
                tooltip: getSeasonMonthsFull(Season.Spring).join(', ')
            },
            {
                id: Season.Summer,
                color: Colors.Orange,
                title: Season.Summer,
                tooltip: getSeasonMonthsFull(Season.Summer).join(', ')
            },
            {
                id: Season.Fall,
                color: Colors.Pink,
                title: Season.Fall,
                tooltip: getSeasonMonthsFull(Season.Fall).join(', ')
            },
        ],
        []
    );

    const slices = useMemo(
        (): iStackedPercentChartSlice[] => {
            if (data.length === 0) return [];

            const years = getUnique(data.filter(i => i.year !== 0).map(i => i.year!));

            years.sort((a, b) => b - a);

            return [
                { id: 0, title: 'Total' },
                ...years
                    .map(year => ({
                        id: year,
                        title: `${year}`,
                    })),
            ];
        },
        [data]
    );

    const chartData = useMemo(
        (): iStackedPercentChartValue[] => {
            return data.map(i => ({
                value: i.percent || 0,
                title: selectedUnit === UnitType.Count
                    ? getFormattedThousand(i.count)
                    : `${Math.round(i.percent || 0)}%`,
                seriesId: getSeasonStrByIndex(i.season!),
                sliceId: i.year!,
            }));
        },
        [data, selectedUnit]
    );

    return (
        <div className="card" style={{ flex: '0 0 45%' }}>
            <div className="card-header">
                Share of&nbsp;{renderCategoryName}&nbsp;Launches by Season
            </div>
            {isLoading && (
                <div className="card-content" style={{ height: 'calc(100% - 50px)'}}>
                    <div style={{ height: '100%', position: 'relative' }}>
                        <Loader/>
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    {data.length > 0 ? (
                        <StackedPercentChart
                            data={chartData}
                            series={series}
                            slices={slices}
                            ContentWrapper={ChartContentWrapper}
                        />
                    ) : (
                        <div className="card-content" style={{ height: 'calc(100% - 50px)'}}>
                            <DataFallback
                                style={{
                                    opacity: isLoading ? 0 : 1,
                                    height: '100%',
                                }}
                                title="No results"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default memo(LaunchesBySeasons);