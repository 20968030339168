import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';

import { FCX } from '@models';
import { GoCopy } from 'react-icons/go';
import { toast } from 'react-toastify';

export interface ButtonClipboardCopyProps {
	isBusy?: boolean;
	onClick?: () => void;
	caption?: ReactNode;
	notify?: boolean;
	isCollapsible?: boolean;
}
const ButtonClipboardCopy: FCX<ButtonClipboardCopyProps> = ({
	className = null,
	isBusy = false,
	onClick = () => {},
	caption = 'copy table',
	children,
	notify = true,
	isCollapsible,
	style,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpenMouseEnter = (bool: boolean) => () => {
		if (!isOpen)
			setIsOpen(bool);
	};

	const handleButtonClick = () => {
		if (!isBusy){
			onClick();
			setIsOpen(false);

			if (notify) {
				toast.success('Successfully copied to your clipboard!');
			}
		}
	}

	return (
		<div
			style={style}
			className={classNames(
				'button-clipboard-copy',
				isBusy && 'is-busy',
				(!isCollapsible || isOpen) && 'is-open',
				className
			)}
		>
			<div className="button-clipboard-copy__more" />
			<button 
				className="button-clipboard-copy__button"
				onMouseEnter={handleOpenMouseEnter(true)}
				onClick={handleButtonClick}
			>
				{children || <GoCopy style={{ width: 20, height: 20, transform: 'rotateY(180deg)'}}/>}
				<span className="caption">{caption}</span>
			</button>
		</div>
	);
};

export default ButtonClipboardCopy;