import React, { useState } from 'react';
import Modal from '@components/Modal';
import HighlightingModal from './HighlightingModal';

import { FCX, ID } from '@models';
import { components } from '@api/api';
import cn from 'classnames';

const RESPONSES_MAX_LENGTH = 10;
const ANSWERS_MAX_LENGTH = 5;

const CardHighlighting: FCX<{
	name: string;
	isDislike?: boolean;
	itemId: number;
	answers: components["schemas"]["FilteredOpenEndDto"][];
	responses: components["schemas"]["NameValueModel"][];
	onRemoveAnswer: (answerId: ID) => void;
}> = ({
	className,
	name,
	isDislike = false,
	responses ,
	answers,
	itemId,
	onRemoveAnswer,
}) => {

	const [isOpenModal, setIsOpenModal] = useState(false);

	return (
		<div className={cn('card card-highlighting', className)}>
			<div className="card-header card-highlighting-header">
				% Highlighting as a <span className={cn('card-highlighting-name',{
					'card-highlighting-name--dislike' : isDislike
				})}>{name}</span>
			</div>
			<div className="card-content card-highlighting-content">
				<div className="card-highlighting-container">
					{(Array.isArray(responses) === true && responses.length > 0) && (
						<div className={cn('card-highlighting-words', {
							'card-highlighting-words--wide': (Array.isArray(answers) === false)
						})}>

							{responses
								.filter((_,index) => (index < RESPONSES_MAX_LENGTH))
								.map((response, index) => {
								return (
									<div 
										key={index}
										className="card-highlighting-words__item"
									>
										<div>{response.name}</div>
										<div>{(response.value === null) ? `-` : `${Math.round(Number(response.value))}%`}</div>
									</div>
								)
							})}
						</div>
					)}

					{(Array.isArray(answers) === true && answers.length > 0) && (
						<div className={cn('card-highlighting-answers', {
							'card-highlighting-answers--wide': (Array.isArray(responses) === false)
						})}>

							{answers
								.filter((answer, index) => (answer.isDeleted === false && index < ANSWERS_MAX_LENGTH ))
								.map((answer, index) => {
								return (
									<div 
										key={index}
										className="card-highlighting-answers__item"
									>
										{answer.text}
									</div>
								)
							})}
						</div>
					)}
				</div>
				<div className="card-highlighting-bottom">
					<div className="card-highlighting-bottom__column">% highlighting as a {name}</div>
					{answers.length > 0 &&
						<div className="card-highlighting-bottom__column">
							<button
								className="card-highlighting-bottom__button"
								onClick={() => setIsOpenModal(true)}
					
							>
								show more
							</button>
						</div>
					}
					

				</div>
			</div>

			{isOpenModal && (
				<Modal
					className="card-highlighting-modal"
					onClose={() => setIsOpenModal(false)}
				>	
					<HighlightingModal
						name={name}
						responses={responses}
						answers={answers}
						isDislike={isDislike}
						itemId={itemId}
						onCloseClick={() => setIsOpenModal(false)}
						onRemoveAnswer={onRemoveAnswer}
					/>
				</Modal>
			)}
		</div>
	);
};

export default CardHighlighting;