import { useFiltersContext } from '@core/FiltersContext';
import { useMemo } from 'react';
import { components } from '@api/api';

type CategoryActivityFilters = components['schemas']['CategoryFilterModel'];

export function useCategoryActivityFilters() {
    const { defaultFiltersPayload } = useFiltersContext();

    return useMemo((): CategoryActivityFilters => ({
        segmentIds: defaultFiltersPayload.segmentIds,
        date: defaultFiltersPayload.date,
        chainId: defaultFiltersPayload.chainId,
        includeAll: defaultFiltersPayload.includeAll,
        includeAny: defaultFiltersPayload.includeAny,
        excludeAll: defaultFiltersPayload.excludeAll,
    }), [defaultFiltersPayload]);
}