import { FCX, ID } from '@models';
import cn from 'classnames';
import Button from '@components/Button';
import { PptFormat, usePptReport } from '@api/usePptReport';
import { RiFilePpt2Fill } from 'react-icons/ri';

const TileDownloadMenu: FCX<{
    itemId: ID;
}> = ({
    itemId
}) => {
    const pptReport = usePptReport();

    return (
        <div
            className={cn(
            "Tile__menu-item",
                pptReport.isLoading && 'is-loading',
            )}
            onClick={() => pptReport.load([itemId])}
        >
            {pptReport.isLoading ? 'Downloading...' : 'DOWNLOAD REPORT'}
            {pptReport.isLoading && (
                <div className="ProgressLoader is-inside-button"/>
            )}
        </div>
    );
};

export default TileDownloadMenu;