import { ID, Season } from '@models';
import { components } from '@api/api';
import { useEffect, useState } from 'react';
import useFetch from '@hooks/useFetch';
import { useFiltersContext } from '@core/FiltersContext';
import { useSeasonalActivityFilters } from '@core/selectors/useSeasonalActivityFilters';

type Response = components['schemas']['SeasonalChain'][];

export interface iSeasonalChain {
    id: ID;
    name: string;
    segmentId: ID;
    segmentName: string;
    count: number;
    percentage: number;
}

export function useSeasonalMostActiveChains(season: Season) {
    const [data, setData] = useState<iSeasonalChain[]>([]);
    const {
        options: { chains, segments },
    } = useFiltersContext();

    const payload = useSeasonalActivityFilters(season);

    const result = useFetch<Response>({
        url: '/api/Seasonal/GetChains',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading && result.data) {
            setData(result.data.map(i => {
                const chainOption = chains.find(o => o.id === i.id);
                const segment = segments.find(o => o.id === chainOption?.segmentId);

                return ({
                    id: i.id || 0,
                    name: chainOption?.title || '',
                    segmentId: segment?.id || 0,
                    segmentName: segment?.title || '',
                    count: i.count || 0,
                    percentage: i.percentage || 0,
                });
            }))
        }
        else {
            setData([]);
        }
    }, [result.isLoading, result.data, chains, segments]);

    return {
        isLoading: result.isLoading,
        data,
    }
}