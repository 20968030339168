import { useEffect, useMemo, useState } from 'react';
import { ChartItem, iChartItem } from '@models/ChartItem';
import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { ID } from '@models';
import { useFiltersContext } from '@core/FiltersContext';

type RequestPayloadType = components["schemas"]["CompareChainsFilter"];
type ResponseType = paths['/api/Chain/ChartData']['post']['responses']['200']['content']['application/json'];

export function useChainsChartItems (chainsIds: ID[]) {
    const [data, setData] = useState<iChartItem[]>([]);
    const { defaultFiltersPayload } = useFiltersContext();

    const payload = useMemo((): RequestPayloadType => {
        return {
            chainsIds: chainsIds as number[],
            categoryIds: defaultFiltersPayload.categoryIds,
            date: defaultFiltersPayload.date,
        };
    }, [chainsIds, defaultFiltersPayload]);

    const { isLoading, data: fetchData } = useFetch<ResponseType>({
        url: '/api/Chain/ChartData',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (fetchData) {
            setData(fetchData.map(i => new ChartItem({ apiModel: i })));
        }
    }, [fetchData]);

    return {
        isLoading,
        data,
    };
}