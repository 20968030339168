import { FCX } from '@models';
import { getEnding } from '@core/utils/string';
import React, { useMemo, useRef, useState } from 'react';
import { useSelectedOptions } from '@hooks/useSelectedOptions';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';
import useOutsideClick from '@hooks/useOutsideClick';
import { FiTrash2 } from 'react-icons/fi';

const SelectionStatus: FCX<{
    itemSingularTitle?: string;
    isPopupOnBottom?: boolean;
}> = ({
    itemSingularTitle = 'item',
    isPopupOnBottom,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const uuid = useMemo(() => uuidv4(), []);
    const [isOpened, setIsOpened] = useState(false);
    useOutsideClick({
        ref,
        fn: () => setIsOpened(false),
    })
    const { items, toggleItem } = useSelectedOptions();

    if (items.length === 0) return null;

    return (
        <div className="SelectionStatus">
            <div
                ref={ref}
                className="SelectionStatus__title"
                data-tooltip-id={uuid}
                onClick={() => setIsOpened(!isOpened)}
            >
                {items.length} {getEnding(itemSingularTitle, items.length)} selected
                <Tooltip
                    id={uuid}
                    isOpen={isOpened}
                    place={isPopupOnBottom ? 'bottom' : 'top'}
                    variant="light"
                    clickable
                    style={{ padding: 0, opacity: 1 }}
                >
                    <div
                        onClick={(event) => event.stopPropagation()}
                        className="SelectionStatus__popup"
                    >
                        {items.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className="SelectionStatus__popup-item"
                                >
                                    {item.title}
                                    <FiTrash2
                                        className="SelectionStatus__popup-icon"
                                        onClick={() => toggleItem(item)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </Tooltip>
            </div>
            <div className="SelectionStatus__sub-title">
                <div className="font-medium">esc</div>
                &nbsp;to cancel
            </div>
        </div>
    );
};

export default SelectionStatus;