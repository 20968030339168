import React from 'react';
import cn from 'classnames';
import './ScoresPlusSection.scss';
import { FCX } from '@models';
import { components } from '@api/api';
import { Tooltip } from 'react-tooltip';


const displayValue = (value: number | null | undefined) => {
	return (value === null || value === undefined || Number.isNaN(value)) ? '-' : `${Math.round(value)}%`;
}

const ScoresPlusSection: FCX<{
	section: components["schemas"]["ScoresPlusResult"];
}> = ({
	className,
	section,
}) => {
	const tooltipId = `scores-plus-${section.header?.replace(/\s/gi, '_')}`

	return (
		<div className={cn('card-scores-plus-section', className)}>
			<div className="card-scores-plus-section__caption">
				<span data-tooltip-id={tooltipId}>
					{section.header}
				</span>
			</div>
			<Tooltip
				id={tooltipId}
				variant={'light'}
				place={'bottom-start'}
				className="tooltip-question-name"
				style={{
					maxWidth: 300,
				}}
			>
				{section.questionName}
			</Tooltip>
			<div className="card-scores-plus-list">
				{section.answers?.map((answer, index) => {
					const {
						name = '',
						raw,
					} = answer;

					return (
						<div 
							key={index}
							className="card-scores-plus-list__item"
						>
							<div>{name}</div>
							<div>
								{displayValue(Number(raw))}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	);
};

export default ScoresPlusSection;