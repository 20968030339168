import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FCX } from '@models';
import { Tooltip as TooltipComponent } from 'react-tooltip';
import { components } from '@api/api';
import { equalInLC } from '@core/utils/string';
import Stars from '@components/Stars';
import LegendScoreTooltipContent from './LegendScoreTooltipContent';
import Preloader from '@components/Preloader';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { DESCRIPTION_METRIC_LIST, METRIC_TYPE, VIABILITY_GROUP } from '../../ItemDetails.constants';
import Popup from '@components/Popup';

function sanitizeName (name: string) {
	return name.replace(/\r?\n|\r/g, "");
}

const Tooltip: FCX<{

}> = ({
	children,
	className,
	id,
}) => {
	return (
		<Popup hasWrapper={false}>
			<TooltipComponent
				className={className}
				variant="light"
				id={id}
				place="bottom-start"
				positionStrategy="fixed"
				noArrow
				offset={-1}
			>
				{children}
			</TooltipComponent>
		</Popup>
	);
};


const TooltipCompositeScoreContent = () => {
	return (
		<div className="tooltip-composite-score-content">
			SCORE = composite score based on performance across all 6 key metrics (SCORES above 70 are considered good, while SCORES above 90 are considered great)
		</div>
	);
}

const TooltipCompositeViabilityContent: FCX<{
	viabilityIndex: number;
}> = ({
	viabilityIndex
}) => {
	return (
		<div className="tooltip-composite-viability-content">
			<div className="tooltip-composite-viability-content__header">Viability ratings</div>
			{VIABILITY_GROUP.map((viability, index) => {
				return (
					<div
						key={index} 
						className={cn('tooltip-composite-viability-content__item', {
							'is-selected': (viability.index === viabilityIndex)
						})}

					>
						<span className="name">{viability.name}</span> – {viability.description}
					</div>
				)
			})}
		</div>
	);
}

 
const TooltipCompositeRatingContent: FCX<{
	scoreData: components["schemas"]["CompositeRowModel"]["scoreData"];
	indexDescription: components["schemas"]["CompositeRowModel"]["indexDescription"];
	metricType: components["schemas"]["CompositeRowModel"]["type"];
	description: components["schemas"]["CompositeRowModel"]["description"];
}> = ({
	scoreData = [], 
	indexDescription = null,
	metricType = null,
	description = null,
}) => {
	return (
		<div className="tooltip-composite-rating-content">
			<div className="tooltip-composite-rating-content__header">
				{description}
			</div>
			<div className="tooltip-composite-rating-content__list">
				{(scoreData || []).map(({value, name}, index) => {

					return (
						<div 
							key={index}
							className={cn(
								'score-item',
								DESCRIPTION_METRIC_LIST.some(item => equalInLC(item, sanitizeName(name || ''))) && 'score-item--bold'
							)}
						>
							{name}: {(value === null) ? `-` : `${Math.round(Number(value))}%`}
						</div>
					)
				})}
			</div>
			{(indexDescription && metricType !== METRIC_TYPE.FREQUENCY) && (
				<div className="tooltip-composite-rating-content__footer">{indexDescription}</div>
			)}
		</div>
	);
}


const isAllowBrandedPiBenchmark = (benchmark: components["schemas"]["BenchmarkData"]["benchmark"]): boolean => {

	if (!benchmark) return false;

	const chainItems = (benchmark?.chain ?? []).find(metric => metric.indexType === METRIC_TYPE.BRANDED_PI) || null;

	if (chainItems && chainItems.totalConceptsCount && chainItems.totalConceptsCount > 10){
		return true;
	} else {
		const segmentMetric = (benchmark?.segment ?? []).find(metric => metric.indexType === METRIC_TYPE.BRANDED_PI) || null;

		return !!segmentMetric && !!segmentMetric.totalConceptsCount && segmentMetric.totalConceptsCount > 10;
	}
}


const CardCompositeScore: FCX<{
	composite: components["schemas"]["ItemDetailsModel"]['compositeModel'];
	benchmark: components["schemas"]["BenchmarkData"]["benchmark"];
	onChangeMetric: (metric: number | undefined) => void;
	selectedMetric: number | undefined;
	categoryName: string;
	itemId: number;
	isLoading: boolean;
}> = ({
	className = null,
	composite,
	benchmark,
	onChangeMetric,
	selectedMetric,
	categoryName = null,
	itemId = null,
	isLoading = false,
}) => {

	const {
		otherTopMetrics,
		purchaseIntent,
		score = null,
		viability = null,
		viabilityDescription = null,
		viabilityIndex = null,
	} = composite || {};


	const handleSelectMetricClick = (metric: components["schemas"]["CompositeRowModel"], isDisabledMetricSelect: boolean) => () => {
		if (!isDisabledMetricSelect)
			onChangeMetric(metric.type);
	}


	const getMetricRow = (metricsList: components["schemas"]["CompositeRowModel"][]) => {

		return metricsList.map((metric, index) => {

			const isMetricData = (metric.rating !== null);
			const ratingValue = isMetricData ? `${Math.round(Number(metric.rating))}%` : `-`;


			const isDisabledMetricSelect = [
				(metric.type === METRIC_TYPE.PRICED_PI),
				(metric.type === METRIC_TYPE.BRANDED_PI) ? !isAllowBrandedPiBenchmark(benchmark) : false,
			].some(condition => condition);

			return (
				<div 
					key={index}
					className={cn('card-composite-score-row', {
						'card-composite-score-row--empty': !isMetricData,
						'card-composite-score-row--disabled': isDisabledMetricSelect,
					})}
				>
 
					{(Array.isArray(metric.scoreData) && metric.scoreData.length > 0) ? (
							<>
								<div
									className="card-composite-score-column card-composite-score-column--main"
									onClick={handleSelectMetricClick(metric, isDisabledMetricSelect)}
									data-tooltip-id={`card-composite-score-metric-name-${metric.name?.replace(/\s/gi, '_')}`}
								>

									<div className={cn(
										'card-composite-score-button-metric',
										metric.type === selectedMetric && 'card-composite-score-button-metric--selected'
									)} />

									{metric.name}

									{[METRIC_TYPE.FREQUENCY, METRIC_TYPE.DRAW].includes(metric.type as METRIC_TYPE) && (
										<span className="metric-caption">(top box)</span>
									)}

									{[METRIC_TYPE.BRANDED_PI].includes(metric.type as METRIC_TYPE) && (
										<span className="metric-caption">(stars vs. same chain)</span>
									)}

									<div className="rating-value">
										{ratingValue}
									</div>
								</div>
								<Popup hasWrapper={false}>
									<Tooltip
										id={`card-composite-score-metric-name-${metric.name?.replace(/\s/gi, '_')}`}
										className="tooltip-composite"
									>
										<TooltipCompositeRatingContent
											scoreData={metric.scoreData}
											indexDescription={metric.indexDescription}
											metricType={metric.type}
											description={metric.description}
										/>
									</Tooltip>
								</Popup>
							</>
						) : (
						<div className="card-composite-score-column card-composite-score-column--main">
							{metric.name}

							{[METRIC_TYPE.FREQUENCY, METRIC_TYPE.DRAW].includes(metric.type as METRIC_TYPE) && (
								<span className="text-caption">(top box)</span>
							)}

							<div className="rating-value">
								{ratingValue}
							</div>
						</div>
					)}
					 
					<div className="card-composite-score-column card-composite-score-column--rating">
			 		
			 			{(isMetricData && metric.star) ? (
							<div className="rating">
								<div className="rating__stars">
									<Stars count={metric.starRating || 0}/>
								</div>
								<div className="rating__label">{metric.star}</div>
							</div>
		 				) : (
		 					<span>-</span>
		 				)}
						 
					</div>
				</div>
			);
		});
	}

	return (
		<div className={cn('card card--overflow-hidden card-composite-score', className)}>
			<div className="card-header card-composite-score-header">
				<div className="card-composite-score-header__meta">
					<div className="caption">Viability</div>
					<div className="name" data-tooltip-id="card-composite-score-viability">
						{viability}

						<div className="description">
							{viabilityDescription}
						</div>
					</div>
					<Tooltip id="card-composite-score-viability">
						<TooltipCompositeViabilityContent
							viabilityIndex={viabilityIndex || 0}
						/>
					</Tooltip> 
				</div>
				<div className="card-composite-score-header__score">
					<div className="caption">score</div>
					<div className="score" data-tooltip-id="card-composite-score-score">
						{(score === null) ? `-` : `${score}`}
					</div>
					<Tooltip id="card-composite-score-score">
						<TooltipCompositeScoreContent />
					</Tooltip> 
				</div>
			</div>

			{isLoading ? (
				<div className="card-composite-score-content card-composite-score-content--loading">
					<Preloader 
						backgroundColor="#ffffff"
					/>
				</div>
				) : (
					<div className="card-composite-score-content">
						<div className="card-composite-score-row card-composite-score-row--header">
							<div className="card-composite-score-column card-composite-score-column--main">
								<div className="rating-value">
									consumer rating
									<div className="text-caption">(top 2 box)</div>
								</div>
							</div>
							<div className="card-composite-score-column card-composite-score-column--rating">
								<div>
									<div className="card-composite-score-column__name">
										<span data-tooltip-id="card-composite-score-star-rating">
											star rating
										</span>
										<Tooltip
											id="card-composite-score-star-rating"
											className="tooltip-composite"
										>
											<LegendScoreTooltipContent />
										</Tooltip>
										<Link
											className="benchmark-direct-link"
											to={`/benchmark?itemId=${itemId}`}
											data-tooltip-id="card-composite-score-benchmark-direct-link"
										>
											<BsFillArrowRightCircleFill className="color-yellow"/>
										</Link>
										<Tooltip id="card-composite-score-benchmark-direct-link">
											Change comparison set…
										</Tooltip>
									</div>
									<div className="text-caption">(versus other {categoryName})</div>
								</div>
							</div>
						</div>
						<div className="card-composite-score-row card-composite-score-row--group">Purchase Intent</div>
						<div className="card-composite-score-group">
							{getMetricRow(purchaseIntent || [])}
						</div>
						<div className="card-composite-score-row card-composite-score-row--group">Other top Metrics</div>
						<div className="card-composite-score-group">
							{getMetricRow(otherTopMetrics || [])}
						</div> 
					</div>
			)} 
		</div>
	);
};

export default CardCompositeScore;