export function getFormattedThousand(number: number = 0, separator: string = ','): string {
    if (number < 0) return `-${getFormattedThousand(-number)}`;
    let numberString = Math.trunc(number).toString();
    let result = "";
    while (numberString.length > 3) {
        result = `${separator}${numberString.substring(numberString.length - 3)}${result}`;
        numberString = numberString.substring(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}

export function getFormattedPrice(price: number): string {
    return price.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export function getWithSign(
    num: number,
    valueFormatter: (n: number) => string = (n) => `${n}`
): string {
    if (num > 0) return `+${valueFormatter(num)}`;
    return valueFormatter(num);
}

export function getAverage (values: number[]): number {
    if (values.length === 0) return 0;
    return values.reduce((acc, v) => acc + v, 0) / values.length;
}