import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import { components } from '@api/api';
import { useFiltersContext } from '@core/FiltersContext';
import { useCategoryActivityFilters } from '@core/selectors/useCategoryActivityFilters';

type Request = components['schemas']['CategoryLaunchesRequest'];
type Response = components['schemas']['CategoryLaunchesBySegmentResponse'];

export interface iCategoryActivitySegment {
    id: ID;
    name: string;
    count: number;
    percentage: number;
}

export function useCategoryActivityBySegment(categoryId: ID) {
    const [data, setData] = useState<iCategoryActivitySegment[]>([]);
    const {
        options: { segments },
    } = useFiltersContext();

    const filter = useCategoryActivityFilters();

    const payload = useMemo((): Request => {
        return {
            filter,
            id: categoryId as number,
        };
    }, [filter, categoryId]);

    const result = useFetch<Response>({
        url: '/api/Category/LaunchesBySegment',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading && !!result.data) {
            setData(segments.map(o => {
                const item = result?.data?.segments?.find(i => o.id === i.id);

                return {
                    id: item?.id || 0,
                    name: o?.title || '',
                    count: item?.count || 0,
                    percentage: item?.percent || 0,
                };
            }))
        } else {
            setData([]);
        }
    }, [result.isLoading, result.data, segments]);

    return {
        isLoading: result.isLoading,
        data,
    }
}