import { FCX, Sort } from '@models';
import cn from 'classnames';
import { GoSortAsc } from 'react-icons/go';

const TableSortIcon: FCX<{
    isActive: boolean;
    direction: Sort;
}> = ({
    isActive,
    direction,
}) => {
    return (
        <div className={cn(
            "TableSortIcon",
            isActive && 'is-active',
            direction === Sort.Desc && 'is-desc',
        )}>
            <GoSortAsc className="TableSortIcon__icon"/>
        </div>
    );
};

export default TableSortIcon;