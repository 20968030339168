import { copyImageToClipboard } from 'copy-image-clipboard';
import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { toPng } from 'html-to-image';

export function copyToClipboard (dataUrl: string): void {
    if (dataUrl) {
        copyImageToClipboard(dataUrl).catch(console.error);
    }
}

export function captureImage(
    src: string,
    width?: number,
    height?: number,
): Promise<string> {
    return new Promise((resolve) => {
        const image = document.createElement('img');
        image.src = src;
        document.body.appendChild(image);

        image.addEventListener('load', () => {
            const canvas = document.createElement('canvas');
            canvas.width = width || image.getBoundingClientRect().width;
            canvas.height = height || image.getBoundingClientRect().height;
            document.body.appendChild(canvas);
            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/png'));
            canvas.remove();
            image.remove();
        });
    });
}

export async function elementToImage(element: ReactElement<HTMLDivElement>): Promise<string> {
    const tileHtmlString = renderToStaticMarkup(element);
    const div = document.createElement('div');
    div.innerHTML = tileHtmlString;
    div.style.opacity = '0';
    div.style.height = '0';
    div.style.overflow = 'hidden';
    document.body.appendChild(div);
    const inner = div.querySelector('div')!;

    const dataUrl = await toPng(inner, { cacheBust: true });
    const { width, height } = inner.getBoundingClientRect();

    div.remove();

    return await captureImage(dataUrl, width, height);
}