import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from '@core/routes';
import { UserContextProvider, useUserContext } from '@core/UserContext';
import { FiltersProvider } from '@core/FiltersContext';
import GlobalLoader from '@vms/GlobalLoader';
import AxiosInterceptor from '@core/AxiosInterceptors';
import './App.scss';
import {
    AppId, cssX,
    Env,
    GtmInit,
    PlatformContextProvider,
} from "@datassential/platform-ui-lib";
import cn from "classnames";

function AppContent() {
    const { hasAdminAccess } = useUserContext();
    const env = useMemo(
        () => {
            switch ((process.env.REACT_APP_PLATFORM_ENV || '').toLowerCase()) {
                case 'qa': return Env.QA;
                case 'staging': return Env.Staging;
                default: return Env.Live;
            }
        },
        []
    );

    const userActions = useMemo(
        () => hasAdminAccess ? [
            {
                id: 'admin',
                content: (
                    <a
                        className={cn(cssX("Dropdown__item"), 'is-tall')}
                        target={'_blank'}
                        href={process.env.REACT_APP_ADMIN}
                    >
                        Tool Admin
                    </a>
                ),
            }
        ] : [],
        [hasAdminAccess]
    );

    return (
        <PlatformContextProvider
            appId={AppId.BrandPerformance}
            env={env}
            enableDevTools={env !== Env.Live}
            additionalUserMenu={userActions}
        >
            <div className="App">
                {process.env.NODE_ENV !== 'development' && <GtmInit/>}
                <Router/>
            </div>
        </PlatformContextProvider>
    );
}

function App() {

    return (
        <GlobalLoader>
            <BrowserRouter>
                <AxiosInterceptor>
                    <UserContextProvider>
                        <FiltersProvider>
                            <AppContent/>
                        </FiltersProvider>
                    </UserContextProvider>
                </AxiosInterceptor>
            </BrowserRouter>
        </GlobalLoader>
    );
}

export default App;
