import { FCX } from '@models';
import { iItem, iItemMetrics, Item } from '@models/Item';
import { useFiltersContext } from '@core/FiltersContext';
import { useCallback, useMemo } from 'react';
import useNameColumn from '@vms/ItemsTable/useNameColumn';
import useDateColumn from '@vms/ItemsTable/useDateColumn';
import { useRespondentsColumns } from '@vms/ItemsTable/useRespondentsColumns';
import useChainAggregatedColumn from '@vms/ItemsTable/useChainAggregatedColumn';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { TableContext } from '@components/Table/TableContext';

const CLPeopleViewConfig: FCX<{
    data: iItem[];
}> = ({
    data,
    children,
}) => {
    const {
        options: { totalRespondentType },
    } = useFiltersContext();

    const getMetrics = useCallback((index: number): iItemMetrics => {
        return data[index].indices.get(totalRespondentType.id as number) || Item.defaultMetricsData;
    }, [totalRespondentType, data]);

    const nameColumn = useNameColumn(data, getMetrics);
    const dateColumn = useDateColumn(data);
    const respondentsColumns = useRespondentsColumns(data);
    const chainColumn = useChainAggregatedColumn(data, 'Tested for', '[chain/segment]');

    const config: iTableConfigItem[] = useMemo(() => {
        return [
            dateColumn,
            {
                key: 'type',
                title: 'Type',
                sortingColName: 'type',
                Value: ({ index }) => <>{data[index].itemType}</>,
                styles: {
                    cell: {
                        flex: '0 0 80px',
                    }
                },
            },
            {
                key: 'company',
                title: 'Company',
                sortingColName: 'company',
                Value: ({ index }) => <div className="lines-3">{data[index].companyName}</div>,
            },
            chainColumn,
            nameColumn,
            ...respondentsColumns,
        ];
    }, [data, nameColumn, respondentsColumns, dateColumn, chainColumn]);

    return (
        <TableContext.Provider value={{ config }}>
            {children}
        </TableContext.Provider>
    );
};

export default CLPeopleViewConfig;