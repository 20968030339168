import { FCX, iKeyValue } from '@models';
import VirtualizedTable from '@components/Table/VirtualizedTable';
import { TableContext } from '@components/Table/TableContext';
import { useMemo } from 'react';
import { iTableConfigItem } from '@components/Table/TableConfig';

const FastestGrowingTable: FCX<{
    data: iKeyValue[];
    isLoading: boolean;
}> = ({
    data,
    isLoading,
}) => {
    const config = useMemo((): iTableConfigItem[] => [
        {
            key: 'keyword',
            title: '1-Year Trend',
            Value: ({ index }) => <>{data[index].key.toLowerCase()}</>,
            styles: {
                cell: {
                    flex: '0 1 100%',
                    textTransform: 'capitalize',
                }
            }

        },
        {
            key: 'value',
            title: '%',
            Value: ({ index }) => <>+{data[index].value}%</>,
            styles: {
                cell: {
                    flex: '0 0 50px',
                }
            }
        },
    ], [data]);

    return (
        <TableContext.Provider value={{ config, }}>
            <VirtualizedTable
                isLoading={isLoading}
                rowsCount={data.length}
                style={{ padding: 0 }}
                dataPlaceholderText="Looks like no foods or flavors saw positive 1-year growth during this period"
            />
        </TableContext.Provider>
    );
};

export default FastestGrowingTable;