import { FCX } from '@models';
import cn from 'classnames';

const Checkbox: FCX<{
    isChecked: boolean;
    onSelect: () => void;
}> = ({
    isChecked,
    children,
    onSelect,
    className,
    style,
}) => {
    return (
        <div
            className={cn("Checkbox", className, isChecked && 'is-checked')}
            onClick={onSelect}
            style={style}
        >
            <div className="Checkbox__icon"/>
            <div className="Checkbox__label">
                {children}
            </div>
        </div>
    );
};

export default Checkbox;