import { FCX, iOption } from '@models';
import { useMemo } from 'react';
import KeywordSettings from '@vms/KeywordSettings';
import MultiSelectBox from '@components/MultiSelectBox';
import { useFiltersContext } from '@core/FiltersContext';
import { iFilterValue } from '@models/Filter';

const AddedKeyword: FCX<{ option: iOption; }> = ({ option }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <KeywordSettings
                appliedKeywords={[option.title]}
                style={{
                    marginRight: 10,
                    flexBasis: 20,
                    height: 20,
                }}
            />
            <div
                style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                title={option.title}
            >
                {option.title}
            </div>
        </div>
    );
};

const KeywordFilter: FCX<{
    value: iFilterValue;
    field: 'includeAll' | 'includeAny' | 'excludeAll';
    setValue: (field: keyof iFilterValue) => (value: iFilterValue[keyof iFilterValue]) => void;
}> = ({
    value,
    field,
    setValue,
}) => {

    const label = useMemo(
        () => {
            switch (field) {
                case 'includeAll':
                    return 'Only items that INCLUDE ALL of these terms';
                case 'includeAny':
                    return 'Only items that INCLUDE ANY of these terms';
                case 'excludeAll':
                    return 'EXCLUDE ITEMS that contain any of these terms';
            }
        },
        [field]
    );
    const { options: { keywords } } = useFiltersContext();
    return (
        <div style={{ flex: '0 1 100% '}}>
            <div className="font-medium" style={{ marginBottom: 6, whiteSpace: 'nowrap' }}>
                {label}
            </div>
            <MultiSelectBox
                allowGeneric
                SelectedItemComponent={AddedKeyword}
                options={keywords}
                value={value[field]}
                groupValue={[...value.includeAll, ...value.includeAny, ...value.excludeAll]}
                onChange={setValue(field)}
            />
        </div>
    )
};

export default KeywordFilter;