import { FCX, ID, iOption } from '@models';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';
import useOutsideClick from '@hooks/useOutsideClick';
import { getEnding, includesInLC, startsFromInLC } from '@core/utils/string';
import { HiOutlineSearch } from 'react-icons/hi';
import Scrollbar from 'react-scrollbars-custom';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';

const Select: FCX<{
    options: iOption[];
    value: ID[];
    label?: ReactNode;
    subjectSingular?: string;
    onOptionClick: (id: ID) => void;
    placeholder?: string;
    closeOnOptionClick?: boolean;
    hasSearch?: boolean;
    useSubItems?: boolean;
    requiresOptionsVirtualization?: boolean;
    isOpenedByDefault?: boolean;
    onClose?: () => void;
    sortSelectedToTop?: boolean;
    isCheckBoxDecoration?: boolean;
    innerRender?: (onClose: () => void) => ReactNode;
}> = ({
    options: rawOptions,
    value,
    label,
    onOptionClick,
    placeholder = 'Select',
    subjectSingular= 'item',
    closeOnOptionClick = false,
    hasSearch = false,
    className,
    style,
    useSubItems,
    requiresOptionsVirtualization,
    isOpenedByDefault = false,
    onClose,
    isCheckBoxDecoration = false,
    sortSelectedToTop= true,
    innerRender,
    ...attrs
}) => {
    const [search, setSearch] = useState('');
    const ref = useRef(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(isOpenedByDefault);

    useOutsideClick({
        ref,
        fn: () => {
            setIsOpened(false);
            if (onClose) onClose();
        },
    });

    const thumbValue: string = useMemo(() => {
        if (value.length === 0) {
            return placeholder;
        } else if (value.length === 1) {
            if (useSubItems) {
                return rawOptions.flatMap(o => o.subItems || [])
                    .filter(o => value.includes(o.id))
                    .map(o => o.title)
                    .join(', ');
            }
            return rawOptions
                .filter(o => value.includes(o.id))
                .map(o => o.title)
                .join(', ');
        } else {
            return `${value.length} ${getEnding(subjectSingular, value.length)} selected`;
        }
    }, [value, rawOptions, placeholder, useSubItems]);

    const options: iOption[] = useMemo(() => {
        if (hasSearch) {
            if (search.trim() !== '') {
                let optionsCopy: iOption[] = [...rawOptions];

                optionsCopy = optionsCopy.filter(o => includesInLC(o.title, search));
                optionsCopy.sort((a, b) => {
                    const aIndex = startsFromInLC(a.title, search) ? 10 : 1;
                    const bIndex = startsFromInLC(b.title, search) ? 10 : 1;

                    return aIndex > bIndex ? -1 : 1;
                });

                return optionsCopy.map(o => {
                    const title = o.title.replace(new RegExp(`(${search})`, 'i'), `<i>$1</i>`);
                    return {
                        ...o,
                        renderTitle: (<span dangerouslySetInnerHTML={{ __html: title, }}/>),
                    };
                });
            } else if (sortSelectedToTop) {
                return [
                    ...rawOptions.filter(o => value.includes(o.id)),
                    ...rawOptions.filter(o => !value.includes(o.id)),
                ];
            } else {
                return rawOptions;
            }
        }

        return rawOptions;
    }, [rawOptions, hasSearch, search, value, sortSelectedToTop]);


    const handleChange = useCallback((id: ID) => {
        if (closeOnOptionClick) {
            setIsOpened(false);
            if (onClose) onClose();
        }

        onOptionClick(id);
    }, [closeOnOptionClick, onOptionClick, setIsOpened, onClose]);

    useEffect(() => {
        setSearch('');
    }, [isOpened, value]);

    const OptionRenderer = useMemo(
        (): FCX<{ option: iOption }> => {
            return ({ option, style }) => {
                return (
                    <div
                        key={option.id}
                        className={cn(
                            "Select__option",
                            option.isDisabled && 'is-disabled',
                            !useSubItems && isCheckBoxDecoration && 'is-checkbox',
                            useSubItems && !!option.subItems && "Select__sup-option",
                            !useSubItems && value.includes(option.id) && 'is-active',
                        )}
                        style={style}
                        onClick={useSubItems ? undefined : () => handleChange(option.id)}
                    >
                        {option.renderTitle || option.title}
                        {useSubItems && !!option.subItems && option.subItems.map((subOption) => (
                            <div
                                key={subOption.id}
                                className={cn(
                                    "Select__option",
                                    "Select__sub-option",
                                    isCheckBoxDecoration && 'is-checkbox',
                                    subOption.isDisabled && 'is-disabled',
                                    value.includes(subOption.id) && 'is-active',
                                )}
                                onClick={() => handleChange(subOption.id)}
                            >
                                {subOption.renderTitle || subOption.title}
                            </div>
                        ))}
                    </div>
                );
            };
        },
        [handleChange, useSubItems, value, isCheckBoxDecoration]
    );

    return (
        <div
            {...attrs}
            className={cn('Select', isOpened && 'is-opened', className)}
            style={style}
            ref={ref}
        >
            <div
                className="Select__thumb"
                onClick={() => {
                    setIsOpened(!isOpened);
                    if (onClose) onClose();
                }}
            >
                {!!label && (
                    <div className="Select__label">
                        {label}
                    </div>
                )}
                <div className="Select__thumb-inner">
                    {thumbValue}
                </div>
                <div className="Select__arrow">
                    <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                </div>
            </div>

            {isOpened && (
                <div className={cn(
                    "Select__dropdown",
                    hasSearch && 'has-search',
                )}>
                    {hasSearch ? (
                        <>
                            <div className="Select__search">
                                <input
                                    autoFocus
                                    type="text"
                                    value={search}
                                    onChange={({ target }) => setSearch(target.value)}
                                    placeholder="Start typing"
                                />
                                <HiOutlineSearch className="Select__search-icon"/>
                            </div>
                            <AutoSizer>
                                {({ width, height }) => (
                                    <div style={{ width, height }}>
                                        <Scrollbar
                                            noScrollX
                                            // @ts-ignore
                                            onScroll={({ scrollTop }) => {
                                                listRef.current && listRef.current.scrollTo(scrollTop);
                                            }}
                                        >
                                            <div>
                                                <FixedSizeList
                                                    // @ts-ignore
                                                    ref={listRef}
                                                    itemCount={options.length}
                                                    itemSize={31}
                                                    width="100%"
                                                    height={height}
                                                    className="window-scroller-override"
                                                >
                                                    {({ style, index }) => {
                                                        return (
                                                            <OptionRenderer option={options[index]} style={style}/>
                                                        )
                                                    }}
                                                </FixedSizeList>
                                                {options.length === 0 && (
                                                    <div className="Select__option is-disabled text-center">
                                                        No matches found.
                                                    </div>
                                                )}
                                            </div>
                                        </Scrollbar>
                                    </div>
                                )}
                            </AutoSizer>
                        </>
                    ) : options.map(option => <OptionRenderer key={option.id} option={option}/>)}
                    {innerRender ? innerRender(
                        () => {
                            setIsOpened(false);
                            if (onClose) onClose();
                        }
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default Select;