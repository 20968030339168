import { components } from '@api/api';
import { useMemo } from 'react';
import { getSeasonIndex, ID, Season } from '@models';
import { useFiltersContext } from '@core/FiltersContext';

type SeasonalActivityBaseFilters = components['schemas']['SeasonalBaseFilterModel'];
type SeasonalActivityFilters = components['schemas']['SeasonalFilterModel'];
type SeasonalActivityExportFilters = components['schemas']['SeasonalitySlidePresentationExportFilter'];

export function useSeasonalActivityBaseFilters () {
    const { defaultFiltersPayload } = useFiltersContext();

    return useMemo((): SeasonalActivityBaseFilters => ({
        segmentIds: defaultFiltersPayload.segmentIds,
        categoryIds: defaultFiltersPayload.categoryIds,
        date: defaultFiltersPayload.date,
        chainId: defaultFiltersPayload.chainId,
        includeAll: defaultFiltersPayload.includeAll,
        includeAny: defaultFiltersPayload.includeAny,
        excludeAll: defaultFiltersPayload.excludeAll,
    }), [defaultFiltersPayload]);
}
export function useSeasonalActivityFilters (season: Season) {
    const baseModel = useSeasonalActivityBaseFilters();

    return useMemo((): SeasonalActivityFilters => ({
        season: getSeasonIndex(season) as SeasonalActivityFilters['season'],
        ...baseModel,
    }), [season, baseModel]);
}
export function useSeasonalActivityExportFilters (selectedCategoriesIds: ID[]) {
    const baseModel = useSeasonalActivityBaseFilters();

    return useMemo((): SeasonalActivityExportFilters => ({
        ...baseModel,
        keywordGroupIds: selectedCategoriesIds as number[],
    }), [selectedCategoriesIds, baseModel]);
}