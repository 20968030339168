import { FCX } from '@models';
import { ReactNode } from 'react';
import cn from 'classnames';

const Field: FCX<{
    label: ReactNode;
    options?: ReactNode;
    inline?: boolean;
}> = ({
    label,
    options,
    children,
    style,
    className,
    inline,
    ...attrs
}) => {
    return (
        <div
            {...attrs}
            className={cn(
                "Field",
                className,
                inline && 'is-inline',
            )}
            style={style}
        >
            {!!label && (
                <div className="Field__label">
                    {label}
                    {!!options && (
                        <div className="Field__options">
                            {options}
                        </div>
                    )}
                </div>
            )}
            <div className="Field__content">
                {children}
            </div>
        </div>
    );
};

export default Field;