import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { useFiltersContext } from '@core/FiltersContext';
import { getTimeframeEnumValue, ID, Timeframe } from '@models';

export function useChainHistoricTrendPptReport(timeframe: Timeframe, indexType: ID | null, chainsIds: ID[]) {
    const [isLoading, setIsLoading] = useState(false);
    const {
        value: { among },
        defaultFiltersPayload,
    } = useFiltersContext();

    const load = async () => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/PptHistoricTrendChainPerformance';

        const data: components['schemas']['ChainPerformanceFilterModel'] = {
            respondentTypes: [among] as components['schemas']['FoodsFlavorsFilterModel']['respondentTypes'],
            categoryIds: defaultFiltersPayload.categoryIds,
            segmentIds: defaultFiltersPayload.segmentIds,
            date: defaultFiltersPayload.date,
            indexType: indexType as components['schemas']['FoodsFlavorsFilterModel']['indexType'],
            periodType: getTimeframeEnumValue(timeframe),
            chains: chainsIds as number[],
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}