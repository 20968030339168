import { memo, useEffect, useMemo, useRef } from 'react';
import { FCX } from '@models';
import TilesWrapper from '@components/TilesWrapper';
import { iItem, Item } from '@models/Item';
import { FixedSizeList } from 'react-window';
import { WindowScroller } from 'react-virtualized';
import Tile from '@vms/Tile';
import { InView } from 'react-intersection-observer';
import useWindowSizeDebounced from '@hooks/useWindowSizeDebounced';
import { useFiltersContext } from '@core/FiltersContext';
import { useItems } from '@api/useItems';
import { components } from '@api/api';
import { FiltersVariant } from '@models/Filter';
import DataFallback from '@components/DataFallback';
import { getDefaultSortDirection, getNormTypeById } from '@core/constants';
import TileLoader from '@vms/TileLoader';

const TilesView: FCX<{
    setTotalRecords?: (value: number) => void;
    setAppliedFilterPayload?: (value: components['schemas']['SearchFilterModel']) => void;
}> = ({
    setTotalRecords= () => null,
    setAppliedFilterPayload= () => null,
}) => {
    const {
        options: { sortAllElements },
        value: { among, concepts, sort, norm },
        defaultFiltersPayload,
        variant,
    } = useFiltersContext();
    const sortingOption = sortAllElements.find(o => o.id === sort);
    const [wWidth, wHeight] = useWindowSizeDebounced();

    const filtersPayload = useMemo((): components['schemas']['SearchFilterModel'] => {
        return {
            filter: {
                ...defaultFiltersPayload,
                respondentTypes: [among] as components['schemas']['FilterViewModel']['respondentTypes'],
                conceptTypeIds: (variant === FiltersVariant.Homepage ? [1, ...concepts] : concepts) as number[],
            },
            page: {
                sortId: sort as number,
                sortDirection: getDefaultSortDirection(sortingOption?.title || '')
            }
        };
    }, [defaultFiltersPayload, variant, among, concepts, sort, sortingOption]);

    const {
        isLoading,
        data,
        totalRecords,
        fetchMore,
    } = useItems(filtersPayload,60);

    useEffect(() => {
        if (!isLoading) {
            setTotalRecords(totalRecords || 0);
        }
    }, [isLoading, totalRecords, setTotalRecords]);

    useEffect(() => {
        setAppliedFilterPayload(filtersPayload);
    }, [filtersPayload, setAppliedFilterPayload]);

    const listRef = useRef<HTMLDivElement>(null);

    const itemsPerRow = useMemo(() => {
        if (wWidth >= 1680) {
            return 5;
        } else {
            return 4;
        }
    }, [wWidth]);

    const rowsCount = useMemo(
        () => Math.ceil(data.length / itemsPerRow),
        [itemsPerRow, data]
    );

    const itemsCount = useMemo(() => {
        if (!isLoading) return rowsCount;
        return Math.max(3, rowsCount);
    }, [isLoading, rowsCount]);

    const itemsRows: iItem[][] = useMemo(() => {
        return Array(rowsCount).fill(null).map((_, rowIndex) => {
            const startIndex = rowIndex * itemsPerRow;
            const endIndex = startIndex + itemsPerRow;
            return data.slice(startIndex, endIndex);
        });
    }, [data, itemsPerRow, rowsCount]);

    return (
        <TilesWrapper style={{ paddingBottom: 80 }}>
            <WindowScroller
                onScroll={
                    // @ts-ignore
                    ({ scrollTop }) => listRef.current && listRef.current.scrollTo(scrollTop)
                }
            >
                {() => <div/>}
            </WindowScroller>
            <FixedSizeList
                // @ts-ignore
                ref={listRef}
                itemSize={475}
                width="100%"
                height={wHeight}
                itemCount={itemsCount}
                className="window-scroller-override"
            >
                {({ index, style }) => (
                    <div style={{
                        ...style,
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        {(isLoading && rowsCount === 0) ? (
                            <>
                                {Array(itemsPerRow).fill(null).map((_, index) => (
                                    <TileLoader key={`dummy-tile-${index}`} inList/>
                                ))}
                            </>
                        ) : (
                            <>
                                {itemsRows[index].map(item => (
                                    <Tile
                                        key={item.id}
                                        data={item}
                                        metrics={item.indices.get(among as number) || Item.defaultMetricsData}
                                        isSelectionEnabled
                                        normType={getNormTypeById(norm)}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                )}
            </FixedSizeList>
            {data.length > 0 && (
                <InView
                    as="div"
                    style={{
                        pointerEvents: 'none',
                        height: 900,
                        position: 'absolute',
                        top: '100%',
                        width: '100%',
                        transform: 'translateY(-100%)',
                    }}
                    onChange={(inView) => {
                        if (inView) fetchMore();
                    }}
                />
            )}
            {!isLoading && rowsCount === 0 && (
                <DataFallback
                    title="Your search conditions do not match any results."
                    subTitle="Please make sure that your keywords are spelled correctly, at least one Chain Segment selected and at least one Item Category selected"
                />
            )}
        </TilesWrapper>
    );
};

export default memo(TilesView);