import { FCX, ID, Timeframe } from '@models';
import cn from 'classnames';
import { Scrollbar } from 'react-scrollbars-custom';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getAxisValues, getSvgPath, iPoint } from '@core/utils/chart';
import ValuesByTimeframeData, { iValuesByTimeframeData } from '@models/ValuesByTimeframeData';
import { AutoSizer } from 'react-virtualized';
import { Tooltip } from 'react-tooltip';
import { RxCross2 } from 'react-icons/rx';
import Popup from '@components/Popup';
import { getFormattedThousand } from '@core/utils/number';

interface iCharValue {
    id: string;
    title?: string;
    subTitle?: string;
    values: Array<{
        id: ID;
        title: string;
        color: string;
        value: number | null;
    }>;
}

export interface iChartGroup {
    label: string;
    values: iCharValue[];
}

export interface iSeries {
    id: ID;
    title: string;
    color: string;
    values: iValuesByTimeframeData;
}

const Curves: FCX<{
    data: iSeries[];
    labels: number[];
    timeframe: Timeframe;
    skipNullValues: boolean;
    height: number;
    width: number;
    activeSeriesId: ID | null;
    activeBarId: ID | null;
}> = ({
    skipNullValues,
    data,
    timeframe,
    labels,
    height,
    width,
    activeSeriesId,
    activeBarId,
}) => {
    const allTimeframes = useMemo(
        () => ValuesByTimeframeData.getAllTimeframes(timeframe).reverse(),
        [timeframe]
    );
    const intervalHalf = useMemo(
        () => width / (2 * allTimeframes.length),
        [width, allTimeframes]
    );
    const minValue = useMemo(
        (): number => labels[labels.length - 1],
        [labels]
    );
    const maxValue = useMemo(
        (): number => labels[0],
        [labels]
    );
    const getX = useCallback(
        (index: number): number => intervalHalf * (2 * index + 1),
        [intervalHalf]
    );
    const getY = useCallback(
        (value: number): number => (height) * (1 - (value - minValue) / (maxValue - minValue)),
        [height, maxValue, minValue]
    );

    const seriesCurves = useMemo(() => {
        return data.map(series => {
            const valuesByTimeframes = allTimeframes.map(timeframe => series.values[timeframe.id]);
            const points: Array<iPoint & { id: ID }> = [];
            let leftShift = 0;

            while (valuesByTimeframes[0] === null && valuesByTimeframes.length > 0) {
                valuesByTimeframes.shift();
                leftShift++;
            }

            while (valuesByTimeframes[valuesByTimeframes.length - 1] === null && valuesByTimeframes.length > 0) {
                valuesByTimeframes.pop();
            }

            valuesByTimeframes.forEach((value, index) => {
                if (value === null) {
                    if (!skipNullValues) {
                        points.push({
                            x: getX(leftShift + index),
                            y: getY(0),
                            id: allTimeframes[leftShift + index].id,
                        });
                    }
                } else {
                    points.push({
                        x: getX(leftShift + index),
                        y: getY(value),
                        id: allTimeframes[leftShift + index].id,
                    });
                }
            });

            return (
                <>
                    <path
                        key={`${series.id}`}
                        fill="none"
                        style={{
                            opacity: !!activeSeriesId && activeSeriesId !== series.id ? 0.1 : undefined,
                            zIndex: !!activeSeriesId && activeSeriesId === series.id ? 2 : undefined,
                        }}
                        stroke={series.color}
                        strokeWidth={2}
                        d={getSvgPath(points)}
                        className="TimeframesChart__curve"
                    />
                    {points.map((point => (
                        <circle
                            key={`${series.id}-${point.id}`}
                            cx={point.x}
                            cy={point.y}
                            r={4}
                            fill={point.id === activeBarId ? series.color : '#fff'}
                            stroke={series.color}
                            strokeWidth={1}
                            style={{ opacity: !!activeSeriesId && activeSeriesId !== series.id ? 0.1 : undefined }}
                            className="TimeframesChart__dot"
                        />
                    )))}
                </>
            );
        })
    }, [data, skipNullValues, allTimeframes, getX, getY, activeSeriesId, activeBarId]);

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
        >
            {seriesCurves}
        </svg>
    );
};

const TimeframesChart: FCX<{
    data: iSeries[];
    timeframe: Timeframe;
    valueFormatter: (value: number) => ReactNode;
    hasYAxis?: boolean;
    skipNullValues?: boolean;
    removeSeries: (id: ID) => void;
    valueSuffix?: string;
}> = ({
    data,
    timeframe,
    valueFormatter,
    hasYAxis = false,
    skipNullValues = false,
    removeSeries,
    valueSuffix = '',
}) => {
    const scrollTargetRef = useRef<HTMLDivElement>(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [activeSeriesId, setActiveSeriesId] = useState<ID | null>(null);
    const [activeBarId, setActiveBarId] = useState<ID | null>(null);

    const groups = useMemo((): iChartGroup[] => {
        const allTimeframes = ValuesByTimeframeData.getAllTimeframes(timeframe);
        const years = Array.from(new Set(allTimeframes.map(i => i.year)));
        years.sort((a, b) => b - a);
        return years.map(year => {
            const timeframes = allTimeframes.filter(i => i.year === year);
            timeframes.sort((a, b) => b.normalizedIndex - a.normalizedIndex);

            return {
                label: `${year}`,
                values: timeframes.map(i => {
                    let values: iCharValue['values'] = data.map(
                        (series) => ({
                            id: series.id,
                            title: series.title,
                            color: series.color,
                            value: series.values[i.id],
                        })
                    );

                    values.sort((a, b) => (b.value || 0) - (a.value || 0));

                    return {
                        id: i.id,
                        title: i.title,
                        subTitle: i.description,
                        values,
                    };
                }),
            };
        });
    }, [timeframe, data]);

    const firstNonEmptyGroupIndex = useMemo(() => {
        return groups.findIndex(
            (group) => group.values.some(
                (value) => data.some(
                    (i) => i.values[value.id] !== null
                )
            )
        );
    }, [data, groups]);

    const maxValue = useMemo(() => {
        return Math.max(...data.flatMap(
            (group) => Object.keys(group.values).map(
                (key) => group.values[key]
            )
        ));
    }, [data]);

    const minValue = useMemo(() => {
        return Math.min(...data
            .flatMap((group) => Object.keys(group.values).map(
                (key) => group.values[key]
            ))
            .filter(i => skipNullValues ? i !== null : true)
        );
    }, [data, skipNullValues]);

    const labels = useMemo(() => {
        return getAxisValues(maxValue, minValue);
    }, [maxValue, minValue]);

    useEffect(() => {
        if (scrollTargetRef.current) {
            const parentLeft = (scrollTargetRef.current?.offsetParent as HTMLDivElement).getBoundingClientRect().left;
            const parentWidth = (scrollTargetRef.current?.offsetParent as HTMLDivElement).getBoundingClientRect().width;
            const right = scrollTargetRef.current?.getBoundingClientRect().right;
            setScrollLeft(right - parentLeft - parentWidth + 20);
        }
    }, [scrollTargetRef]);

    useEffect(() => {
        setActiveSeriesId(null);
    }, [data]);

    return (
        <div className={cn("TimeframesChart", `variant-${timeframe}`)}>
            <div className={cn(
                "TimeframesChart__viewport",
                hasYAxis && 'has-y-axis',
                `variant-${timeframe}`,
            )}>
                {hasYAxis && (
                    <div className="TimeframesChart__y-axis">
                        {labels.map(label => (
                            <div
                                key={label}
                                className="TimeframesChart__y-axis-label"
                            >
                                {getFormattedThousand(label)}{valueSuffix}
                            </div>
                        ))}
                    </div>
                )}
                <Scrollbar
                    onScroll={(data) => {
                        // @ts-ignore
                        setScrollLeft(data.scrollLeft);
                    }}
                    scrollLeft={scrollLeft}
                    rtl
                    noScrollY
                    trackXProps={{
                        renderer: ({ elementRef, style, ...restProps }) => (
                            <div
                                {...restProps}
                                ref={elementRef}
                                style={{
                                    ...style,
                                    transform: 'translateY(-55px)',
                                    height: 8,
                                    width: '100%',
                                    left: 0,
                                }}
                            />
                        ),
                    }}
                >
                    <div className="TimeframesChart__view">
                        <div className="TimeframesChart__groups">
                            <div className="TimeframesChart__curves">
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <Curves
                                            data={data}
                                            labels={labels}
                                            timeframe={timeframe}
                                            skipNullValues={skipNullValues}
                                            height={height}
                                            width={width}
                                            activeSeriesId={activeSeriesId}
                                            activeBarId={activeBarId}
                                        />
                                    )}
                                </AutoSizer>
                            </div>
                            {groups.map((group, groupIndex) => (
                                <div
                                    key={group.label}
                                    ref={groupIndex === firstNonEmptyGroupIndex ? scrollTargetRef : undefined}
                                    className={cn("TimeframesChart__group", `variant-${timeframe}`)}
                                >
                                    <div className="TimeframesChart__values">
                                        {group.values.map((bar) => (
                                            <>
                                                <div
                                                    key={bar.id}
                                                    className={cn(
                                                        "TimeframesChart__bar",
                                                        `variant-${timeframe}`,
                                                    )}
                                                >
                                                    <div
                                                        className={cn(
                                                            "TimeframesChart__bar-inner",
                                                            bar.values.every(v => v.value === null) && 'is-empty',
                                                        )}
                                                        data-tooltip-id={`bar-${timeframe}-${bar.id}`}
                                                        onMouseEnter={() => setActiveBarId(bar.id)}
                                                        onMouseLeave={() => setActiveBarId(null)}
                                                    />
                                                    {timeframe === Timeframe.Season && (
                                                        <>
                                                            <div
                                                                className="TimeframesChart__bar-label"
                                                                data-tooltip-id={`chart-bar-${bar.id}-label`}
                                                            >
                                                                {bar.title?.substring(0, 3)}
                                                            </div>
                                                            <Popup hasWrapper={false}>
                                                                <Tooltip
                                                                    id={`chart-bar-${bar.id}-label`}
                                                                    place={'bottom'}
                                                                    positionStrategy="fixed"
                                                                    style={{ zIndex: 4 }}
                                                                >
                                                                    {bar.subTitle}
                                                                </Tooltip>
                                                            </Popup>
                                                        </>
                                                    )}
                                                </div>
                                                <Tooltip
                                                    id={`bar-${timeframe}-${bar.id}`}
                                                    place={'right'}
                                                    float
                                                    style={{
                                                        animation: 'none',
                                                        zIndex: 2,
                                                        transition: "none",
                                                    }}
                                                    offset={20}
                                                    variant={'light'}
                                                >
                                                    <div className="TimeframesChart__tooltip">
                                                        <div className="TimeframesChart__tooltip-title">
                                                            {bar.title}
                                                        </div>
                                                        {bar.values.map(value => (
                                                            <div
                                                                key={value.id}
                                                                className="TimeframesChart__tooltip-row"
                                                            >
                                                                <div>{value.title}</div>
                                                                <div
                                                                    className="TimeframesChart__tooltip-row-color"
                                                                    style={{ background: value.color }}
                                                                />
                                                                <div className="TimeframesChart__tooltip-row-value">
                                                                    {valueFormatter(value.value || 0)}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Tooltip>
                                            </>
                                        ))}
                                    </div>
                                    <div className="TimeframesChart__group-label">
                                        {group.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Scrollbar>
            </div>
            <div className="TimeframesChart__legend">
                {data.map(series => (
                    <>
                        <div
                            key={series.id}
                            onClick={() => removeSeries(series.id)}
                            onMouseEnter={() => setActiveSeriesId(series.id)}
                            onMouseLeave={() => setActiveSeriesId(null)}
                            className="TimeframesChart__legend-button"
                            data-tooltip-id={`chart-item-${series.id}-legend-button`}
                        >
                            <div
                                className="TimeframesChart__legend-button-color"
                                style={{ background: series.color }}
                            />
                            {series.title}
                            <RxCross2 className="TimeframesChart__legend-button-icon"/>
                        </div>
                        <Popup hasWrapper={false}>
                            <Tooltip
                                id={`chart-item-${series.id}-legend-button`}
                                place={'bottom'}
                                positionStrategy="fixed"
                                style={{ zIndex: 4 }}
                            >
                                remove from chart
                            </Tooltip>
                        </Popup>
                    </>
                ))}
            </div>
        </div>
    );
};

export default TimeframesChart;