import { ID, Sort } from '@models';
import { useEffect, useMemo, useState } from 'react';
import { iItem, Item } from '@models/Item';
import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { useChainProfileFilters } from '@core/selectors/useChainProfileFilters';
import { useUserContext } from "@core/UserContext";

type RequestModel = components['schemas']['ChainProfileTopPerformedRequest'];
type ResponseModel = components['schemas']['ChainProfileTopPerformedResponse'];

export function useChainProfileTopPerformingItems (
    chainId: ID,
    baseMetricId: ID,
    sortingColumn: ID,
    sortingDirection: Sort,
) {
    const { uiSettings: { currencySymbol }} = useUserContext();
    const [data, setData] = useState<iItem[]>([]);
    const [hasSegmentPermission, setHasSegmentPermission] = useState(true);

    const filter = useChainProfileFilters();

    const payload = useMemo((): RequestModel => {
        return {
            id: chainId as number,
            filter,
            sortColumn: baseMetricId as RequestModel['sortColumn'],
            innerSorting: {
                sortId: sortingColumn as number,
                sortDirection: sortingDirection,
            }
        };
    }, [chainId, filter, baseMetricId, sortingDirection, sortingColumn]);

    const result = useFetch<ResponseModel>({
        url: '/api/ChainProfile/TopPerformedItems',
        method: 'post',
        payload,
    });

    useEffect(() => {
        setTimeout(() => {
            setData([]);
        }, 100);
    }, [payload]);

    useEffect(() => {
        if (!result.isLoading) {
            setData((result.data?.items || []).map((i) => ({
                ...new Item({ apiModel: i, currencySymbol }),
                rankRange: result.data?.items?.length || 0,
            })));
            setHasSegmentPermission(result.data?.hasSegmentPermission || false);
        }
    }, [result.isLoading, result.data, currencySymbol]);

    return {
        isLoading: result.isLoading,
        data,
        hasSegmentPermission,
    }
}