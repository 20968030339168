import { ID } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import { useEffect, useMemo, useState } from 'react';
import { components } from '@api/api';
import useFetch from '@hooks/useFetch';
import { ChainProfileComparisonData, iChainProfileComparisonData } from '@models/ChainProfileComparisonData';
import { useChainProfileFilters } from '@core/selectors/useChainProfileFilters';

type RequestType = components['schemas']['ChainProfileSharesRequest'];
type ResponseType = components['schemas']['ChainProfileSharesResponse'];

export function useChainProfileComparisonData(chainId: ID, presetId?: ID, chainsIds?: ID[]) {
    const {
        options: { chains, categories },
    } = useFiltersContext();
    const [data, setData] = useState<iChainProfileComparisonData | null>(null);

    const filter = useChainProfileFilters();
    
    const payload = useMemo((): RequestType => {
        return {
            id: chainId as number,
            filter,
            presetId: presetId as RequestType['presetId'],
            chainIds: chainsIds as RequestType['chainIds'],
        };
    }, [filter, chainId, chainsIds, presetId]);

    const result = useFetch<ResponseType>({
        url: '/api/ChainProfile/ChainShares',
        method: 'post',
        payload,
    });

    useEffect(() => {
        setData(null);
    }, [payload, chainId, chainsIds, presetId]);

    useEffect(() => {
        if (!result.isLoading && result.data && result.data.items) {
            setData(new ChainProfileComparisonData({
                apiModel: result.data.items,
                chains,
                categories,
                currentChainId: chainId,
                isCurrentChainInGroup: result.data.isPartOfThePreset,
            }))
        }
    }, [result.isLoading, result.data, chainId, categories, chains])

    return {
        isLoading: result.isLoading,
        data,
    }
}