import { ID } from '@models';
import { useEffect, useMemo, useState } from 'react';
import useFetch from '@hooks/useFetch';
import { components } from '@api/api';
import { useChainProfileFilters } from '@core/selectors/useChainProfileFilters';

type RequestModel = components['schemas']['ChainProfileLaunchesRequest'];
type ResponseModel = components['schemas']['ChainProfileLaunchesResponse'];

export function useChainProfileSharedBySeason (chainId: ID) {
    const [data, setData] = useState<components["schemas"]["ChainLaunchesItem"][]>([]);

    const filter = useChainProfileFilters();

    const payload = useMemo((): RequestModel => {
        return {
            id: chainId as number,
            filter,
        };
    }, [chainId, filter]);

    const result = useFetch<ResponseModel>({
        url: '/api/ChainProfile/LaunchesBySeason',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (!result.isLoading) {
            setData(result.data?.items || []);
        }
    }, [result.isLoading, result.data]);

    return {
        isLoading: result.isLoading,
        data,
    }

}