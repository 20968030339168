import { FCX, ID } from '@models';
import React, { ReactNode, useMemo, useState } from 'react';
import SimpleTable from '@components/Table/SimpleTable';
import DataFallback from '@components/DataFallback';
import { useFilterSort } from '@hooks/useFilterSort';
import Button from '@components/Button';
import FoodFlavorsTableConfig from './FoodFlavorsTableConfig';
import FoodFlavorsTableSortingConfig from './FoodFlavorsTableSortingConfig';
import Modal from '@components/Modal';
import VirtualizedTable from '@components/Table/VirtualizedTable';
import { useCategoryActivityByFoods } from '@api/useCategoyActivityByFoods';

const FoodsFlavors: FCX<{
    categoryId: ID;
    renderCategoryName: ReactNode;
    isGlobalLoading: boolean;
}> = ({
    categoryId,
    renderCategoryName,
}) => {
    const { isLoading, data: rawData } = useCategoryActivityByFoods(categoryId);
    const [isPopupOpened, setIsPopupOpened] = useState(false);

    const {
        data: sortedData,
        sortingDirection,
        sortingColumn,
        setSortingColumn,
        setSortingDirection,
    } = useFilterSort(rawData, 'count');

    const data = useMemo(
        () => sortedData.slice(0, 10),
        [sortedData],
    );

    return (
        <div className="card" style={{ flex: '0 0 45%' }}>
            <div className="card-header">
                Most Popular Flavors / Ingredients in&nbsp;{renderCategoryName}&nbsp;
            </div>
            {isLoading || data.length > 0 ? (
                <FoodFlavorsTableSortingConfig
                    sortingColumn={sortingColumn}
                    setSortingColumn={setSortingColumn}
                    setSortingDirection={setSortingDirection}
                    sortingDirection={sortingDirection}
                >
                    <FoodFlavorsTableConfig
                        id="top10"
                        data={data}
                        renderCategoryName={renderCategoryName}
                    >
                        <SimpleTable
                            style={{ minHeight: 375, flex: '0 1 100%' }}
                            rowsCount={isLoading ? 10 : data.length}
                            isLoading={isLoading}
                        >
                            {!isLoading && data.length < sortedData.length && (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: 5,
                                }}>
                                    <Button
                                        style={{ height: 24 }}
                                        modifiers={['naked', 'blue']}
                                        onClick={() => setIsPopupOpened(true)}
                                    >
                                        show more
                                    </Button>
                                </div>
                            )}

                        </SimpleTable>
                    </FoodFlavorsTableConfig>
                </FoodFlavorsTableSortingConfig>
            ) : (
                <div className="card-content" style={{ height: 350 }}>
                    <DataFallback
                        style={{ opacity: isLoading ? 0 : 1, height: '100%' }}
                        title="No results"
                    />
                </div>
            )}
            {isPopupOpened && (
                <Modal
                    onClose={() => setIsPopupOpened(false)}
                    style={{
                        padding: 0,
                    }}
                >
                    <FoodFlavorsTableSortingConfig
                        sortingColumn={sortingColumn}
                        setSortingColumn={setSortingColumn}
                        setSortingDirection={setSortingDirection}
                        sortingDirection={sortingDirection}
                    >
                        <FoodFlavorsTableConfig
                            id="all"
                            data={sortedData}
                            renderCategoryName={renderCategoryName}
                        >
                            <div style={{ height: 500, width: 800 }}>
                                <VirtualizedTable
                                    rowsCount={sortedData.length}
                                    isLoading={isLoading}
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                    headStyle={{
                                        paddingTop: 8,
                                        height: 38,
                                    }}
                                />
                            </div>
                        </FoodFlavorsTableConfig>
                    </FoodFlavorsTableSortingConfig>
                </Modal>
            )}
        </div>
    );
};

export default FoodsFlavors;