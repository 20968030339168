import { FCX, ID } from '@models';
import ScatterChartComponent from '@components/ScatterChart';
import { useFiltersContext } from '@core/FiltersContext';
import { equalInLC } from '@core/utils/string';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import cn from 'classnames';
import useOutsideClick from '@hooks/useOutsideClick';
import { Tooltip } from 'react-tooltip';
import Tile from '@vms/Tile';
import Popup from '@components/Popup';
import { iChartItem } from '@models/ChartItem';
import { Item } from '@models/Item';
import { useChartItem } from '@api/useChartItem';
import TileLoader from '@vms/TileLoader';
import { v4 as uuidv4 } from 'uuid';

export type ChartItemType = iChartItem & { color: string };

const VersusScatterChart: FCX<{
    data: ChartItemType[];
    setActiveMetricIds?: (ids: ID[]) => void;
}> = ({
    data,
    setActiveMetricIds,
}) => {
    const uuid = useMemo(() => uuidv4(), []);
    const {
        value: { among },
        options: { mainMetrics },
    } = useFiltersContext();
    const [activeItemId, setActiveItemId] = useState<ID | undefined>();
    const { data: activeItem } = useChartItem(activeItemId, among);
    const xMetricDropdown = useRef<HTMLDivElement>(null);
    const yMetricDropdown = useRef<HTMLDivElement>(null);
    const [isXMetricSelectOpened, setIsXMetricSelectOpened] = useState(false);
    const [isYMetricSelectOpened, setIsYMetricSelectOpened] = useState(false);
    useOutsideClick({
        ref: xMetricDropdown,
        fn: () => setIsXMetricSelectOpened(false)}
    );
    useOutsideClick({
        ref: yMetricDropdown,
        fn: () => setIsYMetricSelectOpened(false)}
    );
    const unbrandedMetric = mainMetrics.find(i => equalInLC(i.title, 'unbranded pi'))!;
    const uniquenessMetric = mainMetrics.find(i => equalInLC(i.title, 'uniqueness'))!;

    const [xMetricId, setXMetricId] = useState<ID>(unbrandedMetric.id);
    const [yMetricId, setYMetricId] = useState<ID>(uniquenessMetric.id);

    const xMetric = useMemo(() => mainMetrics.find(i => i.id === xMetricId), [mainMetrics, xMetricId]);
    const yMetric = useMemo(() => mainMetrics.find(i => i.id === yMetricId), [mainMetrics, yMetricId]);

    useEffect(() => {
        if (setActiveMetricIds) {
            setActiveMetricIds([xMetricId, yMetricId]);
        }
    }, [xMetricId, yMetricId, setActiveMetricIds]);

    const series = useMemo(() => {
        if (xMetric && yMetric) {
            return [{
                data: data.map((item) => ({
                    id: item.id,
                    x: item[xMetric.valueField] ?? 0,
                    y: item[yMetric.valueField] ?? 0,
                    color: item.color,
                })).filter(i => i.x > 0 && i.y > 0),
            }];
        }

        return [];
    }, [data, xMetric, yMetric]);

    const restOptions = useMemo(() => {
        return mainMetrics.filter(i => i.id !== xMetricId && i.id !== yMetricId);
    }, [mainMetrics, xMetricId, yMetricId]);

    return (
        <div className="VersusScatterChart">
            <div className="VersusScatterChart__inner">
                <ScatterChartComponent
                    key={`${xMetricId}:${yMetricId}`}
                    series={series}
                    onPointClick={setActiveItemId}
                    tooltipSelector={`.versus-scatter-chart-tooltip-${uuid}`}
                />
                <div
                    ref={xMetricDropdown}
                    className={cn(
                        "VersusScatterChartSelect",
                        "bottom-right",
                        isXMetricSelectOpened && 'is-opened',
                    )}
                >
                    <div
                        className="VersusScatterChartSelect__thumbnail"
                        onClick={() => setIsXMetricSelectOpened(!isXMetricSelectOpened)}
                    >
                        {xMetric?.title}
                        <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                    </div>
                    <div className="VersusScatterChartSelect__dropdown">
                        {restOptions.map(o => (
                            <div
                                key={o.id}
                                className="VersusScatterChartSelect__option"
                                onClick={() => {
                                    setIsXMetricSelectOpened(false);
                                    setXMetricId(o.id);
                                }}
                            >
                                {o.title}
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    ref={yMetricDropdown}
                    className={cn(
                        "VersusScatterChartSelect",
                        "top-left",
                        isYMetricSelectOpened && 'is-opened',
                    )}
                >
                    <div
                        className="VersusScatterChartSelect__thumbnail"
                        onClick={() => setIsYMetricSelectOpened(!isYMetricSelectOpened)}
                    >
                        {yMetric?.title}
                        <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                    </div>
                    <div className="VersusScatterChartSelect__dropdown">
                        {restOptions.map(o => (
                            <div
                                key={o.id}
                                className="VersusScatterChartSelect__option"
                                onClick={() => {
                                    setIsYMetricSelectOpened(false);
                                    setYMetricId(o.id);
                                }}
                            >
                                {o.title}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {!!activeItemId && (
                <Popup hasWrapper={false}>
                    <Tooltip
                        className={`versus-scatter-chart-tooltip-${uuid}`}
                        id={`scatter-chart-item-${activeItemId}`}
                        isOpen={true}
                        place={'left'}
                        style={{
                            padding: 0,
                            zIndex: 4,
                        }}
                        variant={'light'}
                        clickable
                    >
                        {activeItem === null ? (
                            <TileLoader/>
                        ) : (
                            <Tile
                                key={activeItemId}
                                data={activeItem}
                                metrics={activeItem.indices.get(among as number) || Item.defaultMetricsData}
                                isSimpleMenu
                                style={{ margin: 0, zIndex: 1 }}
                            />
                        )}
                    </Tooltip>
                </Popup>
            )}
        </div>
    );
}

export default VersusScatterChart;