import { useEffect, useMemo, useState } from 'react';
import { Sort } from '@models';
import { includesInLC } from '@core/utils/string';
import { defaultSort } from '@core/utils/array';

export function getFilterSort<ItemType>({
    data: rawData,
    query = '',
    keysForSearch,
    sortingColumn,
    sortingDirection,
}: {
    data: ItemType[];
    query?: string;
    keysForSearch?: keyof ItemType | Array<keyof ItemType>;
    sortingColumn: keyof ItemType;
    sortingDirection: Sort;
}): ItemType[] {
    const filteredData = [...(rawData || [])].filter(item => {
        if (query.trim() !== '') {
            if (Array.isArray(keysForSearch)) {
                return keysForSearch.some(key => includesInLC(`${item[key]}`, query.trim()));
            } else if (typeof keysForSearch === 'string') {
                return includesInLC(`${item[keysForSearch]}`, query.trim());
            }
        }

        return true;
    });

    const result = [...filteredData];

    result.sort((a, b) => defaultSort(a[sortingColumn], b[sortingColumn]));

    if (sortingDirection === Sort.Desc) {
        result.reverse();
    }

    return result;
}


export function useFilterSort<ItemType>(
    rawData: ItemType[],
    initialSortingKey: keyof ItemType,
    keysForSearch?: keyof ItemType | Array<keyof ItemType>,
) {
    const [query, setQuery] = useState('');
    const [sortingColumn, setSortingColumn] = useState<keyof ItemType>(initialSortingKey);
    const [sortingDirection, setSortingDirection] = useState<Sort>(Sort.Desc);

    const data = useMemo(
        () => getFilterSort({
            data: rawData,
            query,
            keysForSearch,
            sortingColumn,
            sortingDirection,
        }),
        [rawData, query, keysForSearch, sortingColumn, sortingDirection]
    );

    useEffect(() => {
        setSortingColumn(initialSortingKey);
        setSortingDirection(Sort.Desc);
    }, [initialSortingKey])

    return {
        data,
        sortingColumn,
        setSortingColumn,
        sortingDirection,
        setSortingDirection,
        query,
        setQuery,
    };
}