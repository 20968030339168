import { FCX } from '@models';
import { iCharValue as iHomeChartDataItem } from '@models/TimeframesBarChartData';
import cn from 'classnames';
import { strToId } from '@core/utils/string';
import Popup from '@components/Popup';
import { Tooltip } from 'react-tooltip';
import * as React from 'react';
import { ReactNode } from 'react';

const Bar: FCX<{
    data: iHomeChartDataItem;
    valueFormatter: (value: number) => ReactNode;
    onClick?: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    isSelected?: boolean;
    isActiveDimmed?: boolean;
    isDisabled?: boolean;
    isDimmed?: boolean;
    showTooltip?: boolean;
    renderValue?: boolean;
    renderLabel?: boolean;
}> = ({
    data,
    valueFormatter,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className,
    isSelected,
    isActiveDimmed,
    isDisabled,
    isDimmed,
    showTooltip,
    renderValue,
    renderLabel,
    style,
}) => {
    return (
        <div
            className={cn(
                "TimeframesBarChart__bar",
                className,
                {
                    'is-clickable': !!onClick,
                    'is-selected': isSelected,
                    'is-active-dimmed': isActiveDimmed,
                    'is-disabled': isDisabled,
                    'is-dimmed': isDimmed,
                }
            )}
            onClick={onClick}
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-tooltip-id={`chart-bar-${strToId(data.id)}`}
        >
            {renderValue && (
                <div className="TimeframesBarChart__bar-value">
                    {!!data.value && valueFormatter(data.value)}
                </div>
            )}
            {renderLabel && (
                <div className="TimeframesBarChart__bar-label">
                    {data.title}
                </div>
            )}
            {showTooltip && (
                <Popup hasWrapper={false}>
                    <Tooltip
                        isOpen={true}
                        id={`chart-bar-${strToId(data.id)}`}
                        positionStrategy="fixed"
                        style={{ zIndex: 4 }}
                    >
                        {data.title}: {valueFormatter(data.value)}
                    </Tooltip>
                </Popup>
            )}
        </div>
    );
}

export default Bar;