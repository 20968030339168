import { FCX } from '@models';
import TableTooltip from '@vms/TableTooltip';
import { MainMetrics } from '@models/MainMetrics';
import { useMemo } from 'react';

export const DATA = {
    [MainMetrics.UnbrandedPI]: {
        name: 'Unbranded PI (top 2 box)',
        description: 'If it were available, how likely would you be to order / buy this item?',
        explanations: [{
            isMark: true,
            name: 'Definitely would buy'
        }, {
            isMark: true,
            name: 'Probably would buy'
        }, {
            isMark: false,
            name: 'Might / might not buy'
        }, {
            isMark: false,
            name: 'Probably would not buy'
        }, {
            isMark: false,
            name: 'Definitely would not buy'
        }]
    },

    [MainMetrics.BrandedPI]: {
        name: 'Branded PI (top 2 box)',
        description: 'How likely would you be to order this item if it were offered at {CHAIN NAME}?',
        explanations: [{
            isMark: true,
            name: 'Definitely would buy'
        }, {
            isMark: true,
            name: 'Probably would buy'
        }, {
            isMark: false,
            name: 'Might / might not buy'
        }, {
            isMark: false,
            name: 'Probably would not buy'
        }, {
            isMark: false,
            name: 'Definitely would not buy'
        }]
    },

    [MainMetrics.Uniqueness]: {
        name: 'Uniqueness (top 2 box)',
        description: 'How new and different is this item?',
        explanations: [{
            isMark: true,
            name: 'Extremely new & different'
        }, {
            isMark: true,
            name: 'Very new & different'
        }, {
            isMark: false,
            name: 'Somewhat new & different'
        }, {
            isMark: false,
            name: 'Not really new & different'
        }, {
            isMark: false,
            name: 'Not at all new & different'
        }]
    },

    [MainMetrics.Frequency]: {
        name: 'FREQUENCY (% who would have it all the time)',
        description: 'Is this an item you could see yourself having...?',
        explanations: [{
            isMark: true,
            name: 'Frequently / all the time'
        }, {
            isMark: false,
            name: 'Every now and then'
        }, {
            isMark: false,
            name: 'Rarely / only on specific occasions'
        }, {
            isMark: false,
            name: 'NEVER – would not ever have this'
        }]
    },

    [MainMetrics.Draw]: {
        name: 'Draw (% who would go just for this item)',
        description: 'Would you visit a somewhere just for this item?',
        explanations: [{
            isMark: true,
            name: 'Yes'
        }, {
            isMark: false,
            name: 'No'
        }]
    },

    [MainMetrics.Value]: {
        name: 'Value (top 2 box)',
        description: 'If this item were offered at this particular chain at this price, would you say it was a...?',
        explanations: [{
            isMark: true,
            name: 'Excellent value for the dollar'
        }, {
            isMark: true,
            name: 'Good value for the dollar'
        }, {
            isMark: false,
            name: 'Moderate value for the dollar'
        }, {
            isMark: false,
            name: 'Somewhat poor value for the dollar'
        }, {
            isMark: false,
            name: 'Very poor value for the dollar'
        }]
    },
}

const BenchmarkTooltip: FCX<{
    metric: MainMetrics;
}> = ({
    metric,
}) => {
    const data = useMemo(() => DATA[metric], [metric]);

    return (
        <TableTooltip
            id={`benchmark-metric-${metric}`}
            title={data.name.toUpperCase()}
            className="Benchmark__tooltip"
        >
            <div style={{ textAlign: 'left'}}>
                <div style={{ fontSize: 14}}>{data.description}</div>
                <div style={{ marginTop: 5, fontSize: 15, lineHeight: '22px'}}>
                    {data.explanations.map(i => (
                        <div key={i.name} className={i.isMark ? 'font-bold' : 'font-light'}>
                            {i.name}
                        </div>
                    ))}
                </div>
            </div>
        </TableTooltip>
    );
};

export default BenchmarkTooltip;