import { FCX, ID } from '@models';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { components, paths } from '@api/api';
import { Columns } from './MyConcepts';
import dayjs from 'dayjs';
import { markMatch } from '@core/utils/string';
import { ImPencil } from 'react-icons/im';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Route } from '@core/routes/Route';
import Modal from '@components/Modal';
import useFetch from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import Button from '@components/Button';
import { IconSpinner } from '@svg/index';

const TableConfig: FCX<{
    data: components['schemas']['NewItemForSurveyModel'][];
    searchQuery: string;
    allowActions: boolean;
    reFetchData: () => void;
}> = ({
    data,
    searchQuery,
    allowActions,
    children,
    reFetchData,
}) => {
    const [isDeleteConfirmOpened, setIsDeleteConfirmOpened] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState<ID | null>(null);

    const deleteItem = useFetch({
        url: getApiLink('/api/Items/DeleteItem', { id: itemIdToDelete }) as keyof paths,
        method: 'delete',
        lazy: true
    });

    const onDelete = useCallback(() => {
        deleteItem.doFetch().then(() => {
            setIsDeleteConfirmOpened(false);
            reFetchData();
        });
    }, [deleteItem, reFetchData]);

    useEffect(() => {
        if (itemIdToDelete) {
            setIsDeleteConfirmOpened(true);
        }
    }, [itemIdToDelete]);

    useEffect(() => {
        if (!isDeleteConfirmOpened) {
            setItemIdToDelete(null);
        }
    }, [isDeleteConfirmOpened]);

    const config = useMemo((): iTableConfigItem[] => [
        {
            key: Columns.Date,
            title: 'Date',
            Value: ({ index }) => (
                <>{dayjs(data[index].fullDate).format('MMM YYYY')}</>
            ),
            sortingColName: Columns.Date,
            styles: {
                cell: {
                    flex: '0 0 100px',
                },
            },
        },
        {
            key: Columns.Company,
            title: 'Company',
            Value: ({ index }) => (
                <div
                    className="lines-3"
                    dangerouslySetInnerHTML={{ __html: markMatch(data[index].companyName || '', searchQuery) }}
                />
            ),
            sortingColName: Columns.Company,
            styles: {
                cell: {
                    flex: '0 0 125px',
                },
            },
        },
        {
            key: Columns.Name,
            title: 'Concept name',
            Value: ({ index }) => (
                <div
                    className="lines-3"
                    dangerouslySetInnerHTML={{ __html: markMatch(data[index].name || '', searchQuery) }}
                />
            ),
            sortingColName: Columns.Name,
            styles: {
                cell: {
                    flex: '0 0 250px',
                },
            },
        },
        {
            key: Columns.Description,
            title: 'Description',
            Value: ({ index }) => (
                <div
                    className="lines-3"
                    dangerouslySetInnerHTML={{ __html: markMatch(data[index].description || '', searchQuery) }}
                />
            ),
            sortingColName: Columns.Description,
            styles: {
                cell: {
                    flex: '0 1 100%',
                },
            },
        },
        {
            key: Columns.Price,
            title: 'Price',
            Value: ({ index }) => (
                <>{data[index].price}</>
            ),
            sortingColName: Columns.Price,
            styles: {
                cell: {
                    flex: '0 0 100px',
                    justifyContent: 'center',
                },
            },
        },
        {
            key: Columns.Type,
            title: 'Type',
            Value: ({ index }) => (
                <div
                    className="lines-3"
                    dangerouslySetInnerHTML={{ __html: markMatch(data[index].type || '', searchQuery) }}
                    style={{
                        border: '1px solid #b2b2b2',
                        borderRadius: 4,
                        fontSize: 10,
                        padding: '1px 4px',
                        textTransform: 'uppercase',
                    }}
                />
            ),
            sortingColName: Columns.Type,
            styles: {
                cell: {
                    flex: '0 0 100px',
                    justifyContent: 'center',
                },
            },
        },
    ], [data, searchQuery]);

    const extendedConfig = useMemo((): iTableConfigItem[] => {
        if (allowActions) return [
            ...config,

            {
                key: 'actions',
                title: 'Actions',
                Value: ({ index }) => (
                    <>
                        <Link
                            to={Route.ConceptPageEdit.replace(':id', `${data[index].id}`)}
                            className="action-icon"
                            style={{
                                fontSize: 0,
                                lineHeight: 0,
                            }}
                        >
                            <ImPencil style={{ width: 16, height: 16, color: 'black' }}/>
                        </Link>
                        <div style={{ opacity: 0.3, fontSize: 20 }}>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                        </div>
                        <BsFillTrash3Fill
                            className="action-icon"
                            style={{ width: 16, height: 16, color: 'black' }}
                            onClick={() => setItemIdToDelete(data[index].id as ID)}
                        />
                    </>
                ),
                styles: {
                    cell: {
                        flex: '0 0 100px',
                        justifyContent: 'center',
                    },
                },
            },
        ];
        return config;
    }, [config, data, allowActions]);

    return (
        <TableContext.Provider value={{
            config: extendedConfig,
        }}>
            {children}
            {isDeleteConfirmOpened && (
                <Modal
                    onClose={() => setIsDeleteConfirmOpened(false)}
                    style={{
                        width: 560,
                        height: 130,
                    }}
                >
                    {deleteItem.isLoading ? (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}>
                            <IconSpinner/>
                        </div>
                    ) : (
                        <>
                            <div>Are you sure you want to delete this item?</div>
                            <br/>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    modifiers={['blue', 'naked']}
                                    onClick={onDelete}
                                >
                                    YES
                                </Button>
                                &nbsp;
                                <Button
                                    modifiers={['green']}
                                    onClick={() => setIsDeleteConfirmOpened(false)}
                                >
                                    NO
                                </Button>
                            </div>
                        </>
                    )}
                </Modal>
            )}
        </TableContext.Provider>
    );
};

export default memo(TableConfig);