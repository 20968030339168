import { ID } from '@models/index';
import { components } from '@api/api';

export interface iFoodWatchItem {
    title: string;
    frequencyId: ID;
    categoriesIds: ID[];
    chainsIds: ID[];
    keywordsIds: ID[];
}

export type ApiModel = components['schemas']['FoodWatchItemViewModel'];

interface iData {
    apiModel?: ApiModel;
}

const DEFAULT_FREQUENCY_ID = 1;

export class FoodWatchItem implements iFoodWatchItem {
    static defaultData: iFoodWatchItem = {
        title: '',
        frequencyId: DEFAULT_FREQUENCY_ID,
        categoriesIds: [],
        chainsIds: [],
        keywordsIds: [],
    };
    title = FoodWatchItem.defaultData.title;
    frequencyId = FoodWatchItem.defaultData.frequencyId;
    categoriesIds = FoodWatchItem.defaultData.categoriesIds;
    chainsIds = FoodWatchItem.defaultData.chainsIds;
    keywordsIds = FoodWatchItem.defaultData.keywordsIds;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
        }
    }

    private setData (model: iFoodWatchItem) {
        Object.assign(this, model);
    }

    private mapFromApi (apiData: ApiModel) {
        this.setData({
            title: apiData.name || FoodWatchItem.defaultData.title,
            frequencyId: apiData.frequency as ID || FoodWatchItem.defaultData.frequencyId,
            categoriesIds: apiData.categories as ID[] || FoodWatchItem.defaultData.categoriesIds,
            chainsIds: apiData.chains as ID[] || FoodWatchItem.defaultData.chainsIds,
            keywordsIds: apiData.keywords as ID[] || FoodWatchItem.defaultData.keywordsIds,
        })
    }

    static mapForApi (model: iFoodWatchItem): ApiModel {
        return {
            name: model.title,
            frequency: model.frequencyId as ApiModel['frequency'],
            categories: model.categoriesIds as number[],
            chains: model.chainsIds as number[],
            keywords: model.keywordsIds as number[],
        }
    }

}