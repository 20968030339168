import { FCX, ID, iOption } from '@models';
import { IconSearch } from '@svg/index';
import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import Dropdown from '@components/Select/Dropdown';
import Options from '@components/Select/Options';
import { includesInLC, markMatch, startsFromInLC } from '@core/utils/string';

const SelectCustomSearch: FCX<{
    options: iOption[];
    onOptionClick: (id: ID) => void;
    label: string;
}> = ({
    label,
    options: rawOptions,
    onOptionClick,
    style,
    className,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [query, setQuery] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);

    const options = useMemo((): iOption[] => {
        if (query.trim().length === 0) return [];

        return [
            ...rawOptions.filter(i => startsFromInLC(i.title, query.trim())),
            ...rawOptions.filter(i => !startsFromInLC(i.title, query.trim()) && includesInLC(i.title, query.trim())),
        ].map(i => ({
            ...i,
            renderTitle: (
                <div dangerouslySetInnerHTML={{ __html: markMatch(i.title, query.trim() )}}/>
            )
        }));
    }, [query, rawOptions]);

    const handleOptionClick = useCallback((id: ID) => {
        setQuery('');
        onOptionClick(id);
    }, [onOptionClick]);

    return (
        <div
            ref={ref}
            className={cn(
                "Select SelectCustom",
                className,
                !!label && 'is-with-label',
                { 'is-opened': query.trim().length > 0 && isInputFocused}
            )}
            style={{
                ...style,
                color: '#1a2024',
            }}
        >
            <div className="Select__thumb SelectCustom__thumb">
                <div
                    className="SelectCustom__label"
                    style={{
                        color: '#1a2024',
                    }}
                >
                    {label}
                </div>
                <IconSearch
                    className="SelectCustom__icon"
                    width={16}
                    height={16}
                />
                <input
                    className="SelectCustom__input"
                    type="text"
                    value={query}
                    onChange={({ target}) => setQuery(target.value)}
                    placeholder="Start typing here"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(true)}
                />
            </div>
            <Dropdown>
                {options.length > 0 ? (
                    <Options
                        data={options}
                        value={[]}
                        onOptionClick={handleOptionClick}
                    />
                ) : (
                    <div className="Select__option is-disabled text-center">
                        No matches found.
                    </div>
                )}
            </Dropdown>
        </div>
    );
};

export default SelectCustomSearch;