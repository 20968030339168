import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { getApiLink } from '@core/utils/url';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route } from '@core/routes/Route';

interface iData {
    data: components['schemas']['ItemDetailsModel'] & components['schemas']['BenchmarkData'];
    isLoading: boolean;
    reFetch: () => void;
    httpStatus: null | number;
}

export function useItemDetails (id: ID): iData {
    const navigate = useNavigate();
    const mainData = useFetch<components['schemas']['ItemDetailsModel']>({
        url: getApiLink('/api/Items/GetItemDetails', { id: id as string }) as keyof paths,
    });
    const benchmarkData = useFetch<components['schemas']['BenchmarkData']>({
        url: getApiLink('/api/Items/GetBenchmark', { id: id as string }) as keyof paths,
    });


    useEffect(() => {
        if (mainData.httpStatus !== null && mainData.httpStatus !== 200) {
            navigate(Route.Home);
        }
    }, [mainData.httpStatus, navigate]);

    const reFetch = () => {
        mainData.doFetch();
        benchmarkData.doFetch();
    };

    return {
        data: {
            ...mainData.data,
            ...benchmarkData.data,
        },
        isLoading: mainData.isLoading || benchmarkData.isLoading,
        reFetch,
        httpStatus: mainData.httpStatus,
    };
};