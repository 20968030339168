import { FCX } from '@models';
import { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IconLock } from '@svg/index';

const CollapsibleSection: FCX<{
    title: ReactNode;
    subTitle?: ReactNode;
    options?: ReactNode;
    collapseTrigger?: any;
    isOpenedByDefault?: boolean;
    isDisabled?: boolean;
}> = ({
    title,
    subTitle,
    options,
    collapseTrigger,
    isOpenedByDefault = false,
    children,
    style,
    className,
    isDisabled,
    ...attrs
}) => {
    const [isOpened, setIsOpened] = useState(isOpenedByDefault );

    useEffect(() => {
        setIsOpened(isOpenedByDefault);
    }, [collapseTrigger, isOpenedByDefault ]);

    return (
        <div
            {...attrs}
            className={cn(
                'CollapsibleSection',
                isOpened && 'is-opened',
                isDisabled && 'is-disabled',
                className,
            )}
            style={style}
        >
            <div
                className="CollapsibleSectionHeader" onClick={() => setIsOpened(!isOpened)}>
                {isDisabled && (
                    <IconLock
                        className="CollapsibleSection__icon"
                        style={{ height: 14 }}
                    />
                )}
                {!isDisabled && (
                    <div className="CollapsibleSection__icon">
                        <MdKeyboardArrowDown style={{ width: 20, height: 20, }}/>
                    </div>
                )}
                <div className="CollapsibleSectionHeader__content">
                    <div className="CollapsibleSectionHeader__column">
                        <div className="CollapsibleSectionHeader__caption">
                            {title}
                        </div>
                        <div className="CollapsibleSectionHeader__sub-caption">
                            {subTitle}
                        </div>
                    </div>
                    <div className="CollapsibleSectionHeader__column">
                        {options}
                    </div>
                </div>
            </div>
            <div className="CollapsibleSection__content">
                {children}
            </div>
        </div>
    );
};

export default CollapsibleSection;