import { FCX, ID } from '@models';
import { useUserContext } from '@core/UserContext';
import { IconCAN, IconUSA } from '@svg/index';
import { useRef, useState, useMemo, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import useOutsideClick from '@hooks/useOutsideClick';
import Dropdown from '@components/Select/Dropdown';
import Options from '@components/Select/Options';
import cn from 'classnames';
import { api } from '@hooks/useFetch';
import { getApiLink } from '@core/utils/url';
import { Route } from '@core/routes/Route';
import { Tooltip } from 'react-tooltip';
import Button from '@components/Button';
import { CountriesSelect, ICountriesSelectOption } from "@datassential/platform-ui-lib";

const CountryIcon: FCX<{ countryId: ID }> = ({
    countryId,
    className,
    style,
}) => {
    const props = {
        className,
        style,
    }
    switch (countryId) {
        case 1:
            return <IconUSA {...props}/>;
        case 2:
            return <IconCAN {...props}/>;
        default:
            return null;
    }
}

const Countries: FCX = () => {
    const [isOpened, setIsOpened] = useState(false);
    const location = useLocation();

    const ref = useRef(null);

    useOutsideClick({
        ref,
        fn: () => setIsOpened(false),
    });

    const { countries: rawCountries, } = useUserContext();
    const [countries, setCountries] = useState(rawCountries);

    useEffect(() => {
        setCountries(rawCountries);
    }, [rawCountries]);

    const currentCountry = countries.find(c => c.selected);

    const switchCountry = (id: ID) => {
        if (id !== currentCountry?.id) {
            setCountries(countries.map(c => ({
                ...c,
                selected: c.id === id,
            })));
            api.request({
                url: getApiLink('api/User/SetCountry', { countryId: id }),
            }).finally(() => {
                window.location.href = window.location.pathname;
            });
        }
    };

    const isDisabled = useMemo(
        (): boolean => countries.length <= 1
            || !!matchPath(Route.FoodWatchItemCreate, location.pathname)
            || !!matchPath(Route.FoodWatchItemEdit, location.pathname)
            || !!matchPath(Route.ItemDetails, location.pathname)
            || !!matchPath(Route.CLItemDetails, location.pathname)
            || !!matchPath(Route.ChainProfile, location.pathname)
        ,
        [countries, location]
    );

    const countriesOptionsPlatform = useMemo(
        (): ICountriesSelectOption[] => countries.map(c => ({
            id: c.id ?? 0,
            shortName: c.alpha3Code ?? '',
            name: c.fullName ?? '',
        })),
        [countries]
    )

    return (
        <>
            {currentCountry && (
                <CountriesSelect
                    options={countriesOptionsPlatform}
                    value={currentCountry.id}
                    setValue={(id) => switchCountry(id ?? 0)}
                    isDisabled={isDisabled}
                    className={"MainHeader__countries-select"}
                    testId={"selectDEMOHeaderCountry"}
                />
            )}
        </>
    );
};

export default Countries;