import { FCX } from '@models';
import { animated, useSpring } from 'react-spring';
import { memo, useEffect, useState } from 'react';
import { getFormattedThousand } from '@core/utils/number';

const AnimatedNumber: FCX<{
    value: number;
    durationMs?: number;
}> = ({
    value,
    style,
    durationMs = 1000,
}) => {
    const [previousValue, setPreviousValue] = useState(0);

    const { number } = useSpring({
        from: { number: previousValue},
        number: value,
        delay: 100,
        config: { duration: durationMs },
    });

    useEffect(() => {
        return () => {
            setPreviousValue(value);
        };
    }, [value]);

    return <animated.div style={style}>{number.to((v) => getFormattedThousand(v))}</animated.div>
}

export default memo(AnimatedNumber);