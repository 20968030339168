import React, { FC, useMemo, useState } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import Wrapper from '@components/Wrapper';
import BreadCrumbs from '@components/BreadCrumbs';
import { Route } from '@core/routes/Route';
import ExportButton from '@vms/ExportButton';
import ExportByCategoryPopup from '@vms/ExportByCategoryPopup';
import Switch from '@components/Switch';
import { ID, UnitType } from '@models';
import { useCategoryActivityReport } from '@api/useCategoryActivityReport';
import AnimatedNumber from '@components/AnimatedNumber';
import HeaderBar from '@components/HeaderBar';
import FiltersPopup from '@vms/FiltersPopup';
import ActionsBar from '@vms/ActionsBar';
import FiltersButton from '@vms/FiltersButton';
import RecentlyReleasedItems from './RecentlyReleasedItems';
import { iCategoryValue, useCategoryActivityLaunches } from '@api/useCategoryActivityLaunches';
import { getFormattedThousand } from '@core/utils/number';
import VersusBarChart from '@components/VersusBarChart';
import { iVersusBarChartData } from '@components/VersusBarChart/VersusBarChart';
import Loader from '@components/Loader';
import { useFilterDateRangeString } from '@hooks/useFilterDateRangeString';
import CircleChart from '@components/CircleChart';
import LaunchesTimeframesBarChart from './LaunchesTimeframesBarChart';
import LaunchesBySegments from './LaunchesBySegments';
import LaunchesBySeasons from './LaunchesBySeasons';
import FoodsFlavors from './FoodsFlavors';
import MostActiveChains from './MostActiveChains';

const CategoryActivityContent: FC = () => {
    const { options: { categories } } = useFiltersContext();
    const [selectedUnit, setSelectedUnit] = useState<UnitType>(UnitType.Count);
    const [selectedCategoryId, setSelectedCategoryId] = useState<ID>(categories[0].id);
    const [isExportPopupOpened, setIsExportPopupOpened] = useState(false);
    const { data, isLoading, totalCount } = useCategoryActivityLaunches();

    const report = useCategoryActivityReport();

    const selectedCategory = useMemo(
        (): iCategoryValue => {
            return data.find((category) => category.id === selectedCategoryId) || {
                id: 0,
                title: '',
                percentage: 0,
                count: 0,
            };
        },
        [data, selectedCategoryId]
    );

    const categoryAccentRender = useMemo(
        () => {
            const selectedCategory = categories.find((category) => category.id === selectedCategoryId);
            return (
                <div
                    className="color-dimyellow"
                    style={{ display: 'inline' }}
                >
                    {selectedCategory?.title}
                </div>
            )
        },
        [selectedCategoryId, categories]
    );

    const chartData = useMemo(
        (): iVersusBarChartData => {
            return {
                mainTitle: '',
                compareTitle: '',
                values: data.map((item) => ({
                    id: item.id,
                    title: item.title,
                    mainValue: selectedUnit === UnitType.Count ? item.count : item.percentage,
                    compareValue: 0,
                })),
            };
        },
        [data, selectedUnit]
    );

    const filterDateRangeString = useFilterDateRangeString();

    return (
        <>
            <div
                className="CategoryActivity"
                style={{
                    minHeight: 'calc(100vh - 140px)',
                    paddingBottom: 60,
                }}
            >
                <Wrapper>
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            minHeight: 70
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <BreadCrumbs items={[
                                    { link: Route.Home, title: 'Home', },
                                    { link: '', title: 'Category Activity', }
                                ]}/>
                                <ExportButton
                                    isLoading={report.isLoading}
                                    onClick={() => setIsExportPopupOpened(true)}
                                />
                                {isExportPopupOpened && (
                                    <ExportByCategoryPopup
                                        close={() => setIsExportPopupOpened(false)}
                                        onConfirm={report.load}
                                        description="Select a category for your category report."
                                    />
                                )}
                            </div>
                            <Switch
                                leftLabel={{
                                    title: "Counts",
                                    id: UnitType.Count,
                                    icon: '#',
                                    color: 'blue',
                                }}
                                rightLabel={{
                                    title: "Percent",
                                    id: UnitType.Pct,
                                    icon: '%',
                                    color: 'green',
                                }}
                                value={selectedUnit}
                                onChange={(value) => setSelectedUnit(value as UnitType)}
                                style={{ marginLeft: 20 }}
                            />
                        </div>
                        <HeaderBar className="CategoryActivity__header-bar">
                            <div className="Home__header-section">
                                {totalCount !== 0 && (
                                    <div
                                        style={{
                                            fontSize: 30,
                                            paddingRight: 16,
                                        }}
                                        className="font-caption color-dimyellow"
                                    >
                                        <AnimatedNumber value={totalCount}/>
                                    </div>
                                )}
                                <div>Launched menu items & LTO’s</div>
                            </div>
                            <div>Launched by Category</div>
                        </HeaderBar>
                        <div className="card">
                            {isLoading ? (
                                <div style={{ padding: 20 }}>
                                    <div style={{ position: 'relative', height: 280 }}>
                                        <Loader/>
                                    </div>
                                </div>
                            ) : (
                                <div className="card-content" style={{ height: 320 }}>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                                        className="font-medium"
                                    >
                                        click on a bar to see that category's launches
                                    </div>
                                    <VersusBarChart
                                        style={{ flex: '0 1 100%' }}
                                        data={chartData}
                                        valueFormatter={(value) => selectedUnit === UnitType.Pct
                                            ? `${Math.round(value)}%`
                                            : getFormattedThousand(value)
                                        }
                                        hideCompare
                                        activeItemId={selectedCategoryId}
                                        onItemClick={setSelectedCategoryId}
                                        showHover
                                    />
                                </div>
                            )}
                        </div>
                        <HeaderBar className="CategoryActivity__header-bar is-sticky">
                            <div className="Home__header-section">
                                {selectedCategory.count !== 0 && (
                                    <div
                                        style={{
                                            fontSize: 30,
                                            paddingRight: 16,
                                        }}
                                        className="font-caption color-dimyellow"
                                    >
                                        <AnimatedNumber value={selectedCategory.count}/>
                                    </div>
                                )}
                                <div>Launched&nbsp;{categoryAccentRender}&nbsp;items & LTO’s</div>
                            </div>
                            <div
                                style={{ opacity: 0.5, textTransform: 'uppercase', }}
                                className="font-medium"
                            >
                                {filterDateRangeString}
                            </div>
                        </HeaderBar>
                        <div style={{ display: 'flex' }}>
                            <div className="card" style={{ marginRight: 20, flex: '0 0 380px' }}>
                                <div className="card-header">
                                    {categoryAccentRender}&nbsp;Share of Total Launches
                                </div>
                                {isLoading ? (
                                    <div style={{ padding: 20 }}>
                                        <div style={{ position: 'relative', height: 380 }}>
                                            <Loader/>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="card-content"
                                        style={{
                                            height: 420,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CircleChart
                                            value={Math.round(selectedCategory.percentage)}
                                            realValue={selectedCategory.percentage}
                                        />
                                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                                            of all launches are {selectedCategory.title}
                                            <br/>
                                            items & LTO's
                                        </div>
                                    </div>
                                )}
                            </div>
                            <LaunchesTimeframesBarChart
                                categoryId={selectedCategoryId}
                                selectedUnit={selectedUnit}
                                renderCategoryName={categoryAccentRender}
                                isGlobalLoading={isLoading}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <LaunchesBySegments
                                categoryId={selectedCategoryId}
                                selectedUnit={selectedUnit}
                                renderCategoryName={categoryAccentRender}
                                isGlobalLoading={isLoading}
                            />
                            <LaunchesBySeasons
                                categoryId={selectedCategoryId}
                                selectedUnit={selectedUnit}
                                renderCategoryName={categoryAccentRender}
                                isGlobalLoading={isLoading}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <MostActiveChains
                                categoryId={selectedCategoryId}
                                renderCategoryName={categoryAccentRender}
                                isGlobalLoading={isLoading}
                            />
                            <FoodsFlavors
                                categoryId={selectedCategoryId}
                                renderCategoryName={categoryAccentRender}
                                isGlobalLoading={isLoading}
                            />
                        </div>
                    </>
                </Wrapper>
                <Wrapper style={{ overflow: 'hidden' }}>
                    <RecentlyReleasedItems
                        categoryId={selectedCategoryId}
                        renderCategoryName={categoryAccentRender}
                        isGlobalLoading={false}
                    />
                </Wrapper>
            </div>
            <FiltersPopup/>
            <ActionsBar>
                <FiltersButton id="actions-bar-filters-popup-open"/>
            </ActionsBar>
        </>
    );
};


const CategoryActivity: FC = () => {
    const { isLoaded } = useFiltersContext();

    if (!isLoaded) return null;

    return <CategoryActivityContent/>;
};

export default CategoryActivity;