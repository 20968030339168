import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_FILTER_VALUE, Filter, FiltersVariant, iFilterValue } from '@models/Filter';
import { FCX } from '@models';
import CollapsibleSection from '@components/CollapsibleSection';
import PlatesSelect from '@components/PlatesSelect';
import { toggleInArray } from '@core/utils/array';
import Select, { SelectDateRange } from '@components/Select';
import Field from '@components/Field';
import cn from 'classnames';
import RangeSlider, { StarsRangeSlider } from '@components/RangeSlider';
import Toggle from '@components/Toggle';
import Button from '@components/Button';
import ResetFiltersButton from '@vms/FiltersPopup/ResetFiltersButton';
import SelectResetFiltersButtons from '@vms/FiltersPopup/SelectResetFiltersButtons';
import useOutsideClick from '@hooks/useOutsideClick';
import { useFiltersPopupContext } from '@vms/FiltersPopup/FiltersPopupContext';
import { useFiltersContext } from '@core/FiltersContext';
import { hasAnyProperties } from '@core/utils/object';
import { getEnding } from '@core/utils/string';
import { Tooltip } from 'react-tooltip';
import { UiSettingsKey, useUserContext } from '@core/UserContext';
import { IconLock } from '@svg/index';
import KeywordFilter from '@vms/FiltersPopup/KeywordFilter';

enum Part {
    TilesSort ,
    DatePeriod,
    DateSeason,
    DateRange,
    Concepts,
    Segments,
    SegmentsToggles,
    ClSegments,
    Chain,
    Categories,
    Status,
    KeyMetric,
    NewItemsPreview,
    Keywords,
}

const CONFIG = new Map<Part, FiltersVariant[]>([
    [Part.TilesSort, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
    ]],
    [Part.DatePeriod, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
        FiltersVariant.Chains,
        FiltersVariant.Foods,
        FiltersVariant.ChainsChart,
        FiltersVariant.ChainProfile,
        FiltersVariant.Seasonal,
        FiltersVariant.CategoryActivity,
    ]],
    [Part.DateSeason, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
    ]],
    [Part.DateRange, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
        FiltersVariant.Chains,
        FiltersVariant.Foods,
        FiltersVariant.ChainsChart,
        FiltersVariant.ChainProfile,
        FiltersVariant.Seasonal,
        FiltersVariant.CategoryActivity,
    ]],
    [Part.Concepts, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
    ]],
    [Part.Segments, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
        FiltersVariant.Chains,
        FiltersVariant.Foods,
        FiltersVariant.Seasonal,
        FiltersVariant.CategoryActivity,
    ]],
    [Part.SegmentsToggles, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
    ]],
    [Part.ClSegments, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
    ]],
    [Part.Chain, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
        FiltersVariant.Foods,
        FiltersVariant.Seasonal,
        FiltersVariant.CategoryActivity,
    ]],
    [Part.Categories, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
        FiltersVariant.Chains,
        FiltersVariant.Foods,
        FiltersVariant.ChainsChart,
        FiltersVariant.ChainProfile,
        FiltersVariant.Seasonal,
    ]],
    [Part.Status, [
        FiltersVariant.Homepage,
    ]],
    [Part.KeyMetric, [
        FiltersVariant.Homepage,
        FiltersVariant.ConceptLocker,
    ]],
    [Part.NewItemsPreview, [
        FiltersVariant.Homepage,
    ]],
    [Part.Keywords, [
        FiltersVariant.ConceptLocker,
        FiltersVariant.Chains,
        FiltersVariant.ChainProfile,
        FiltersVariant.Foods,
        FiltersVariant.Seasonal,
        FiltersVariant.CategoryActivity,
    ]],
]);

const FiltersPopup: FCX<{
    hideTilesSort?: boolean;
}> = ({
    hideTilesSort= false,
}) => {
    const ref = useRef(null);
    const { isOpened, position, close } = useFiltersPopupContext();
    useOutsideClick({
        ref,
        fn: close,
        ignoreNodesIds: [
            'header-filters-popup-open',
            'actions-bar-filters-popup-open',
        ],
    });
    const {
        countries,
        hasConceptLockerAccess,
        freeAccess,
        uiSettings: { hiddenElements },
    } = useUserContext();
    const currentCountry = countries.find(c => c.selected);

    const [localValue, setLocalValue] = useState<iFilterValue>(DEFAULT_FILTER_VALUE);

    const {
        options,
        value,
        defaultValue,
        updateValue,
        variant,
    } = useFiltersContext();

    const isShown = useCallback((...pieces: Part[]): boolean => {
        return pieces.some(piece => (
            CONFIG.get(piece)?.includes(variant) || false
        ));
    }, [variant])

    const isValid = true;

    const setField = (key: keyof iFilterValue) => (value: any) => {
        setLocalValue({
            ...localValue,
            [key]: value,
        })
    };

    const resetField = (key: keyof iFilterValue) => {
        setField(key)(defaultValue[key]);
    };

    const resetSegmentsChains = () => {
        setLocalValue({
            ...localValue,
            segments: [],
            isMealKit: false,
            isGroceryDeli: false,
            chainId: null,
        })
    };

    const setDate = (key: keyof iFilterValue['date']) => (value: any) => {
        setField('date')({
            ...DEFAULT_FILTER_VALUE.date,
            [key]: value,
        });
    };

    useEffect(() => {
        setLocalValue(value);
    }, [value, isOpened]);

    const isConceptsLocked = useMemo(
        (): boolean => {
            if (variant === FiltersVariant.ConceptLocker) {
                return false;
            }

            return !!freeAccess || !hasConceptLockerAccess || hiddenElements.includes(UiSettingsKey.FilterConcepts);
        },
        [variant, freeAccess, hasConceptLockerAccess, hiddenElements],
    );

    const isKeyMetricsLocked = useMemo(
        (): boolean => {
            if (variant === FiltersVariant.ConceptLocker) {
                return false;
            }

            return !!freeAccess || hiddenElements.includes(UiSettingsKey.FilterConcepts);
        },
        [variant, freeAccess, hiddenElements],
    );

    const filtersDiff = useMemo(
        (): Partial<iFilterValue> => Filter.getValueDiff(localValue, defaultValue),
        [localValue, defaultValue]
    );

    const sortTilesDiffTitle = useMemo(
        (): string => {
        const tileSortOption = [
                ...options.sortAllElements,
                ...options.sortAllMetrics
            ].find(o => o.id === localValue.sort);

        return hasAnyProperties(filtersDiff,'sort') && tileSortOption ? tileSortOption.title : ''
    },
        [filtersDiff, options, localValue]
    );

    const dateDiffTitle = useMemo(
        (): string => {
        if (!filtersDiff.date) return '';
        else if (hasAnyProperties(filtersDiff.date, 'season')) {
            const option = options.dateSeasons.find(o => o.id === localValue.date.season);

            if (option) return `only ${option.title} items`;
        }
        else if (hasAnyProperties(filtersDiff.date, 'period')) {
            const { date: { period: { to, from } }} = localValue;
            if (!!from && !!to) {
                return `items in range ${from} - ${to}`;
            }
        } else {
            const option = options.dateWithinMonth.find(o => o.id === localValue.date.withinMonth);
            if (option) return `items within the past ${option.title}`;
        }
        return '';
    },
        [localValue, filtersDiff, options]
    );

    const clTypeDiffTitle = useMemo(
        (): string => {
        if (!filtersDiff.concepts) return '';

        return `${localValue.concepts.length} ${getEnding('type', localValue.concepts.length)} selected`;
    },
        [filtersDiff, localValue]
    );

    const segmentsChainDiffTitle = useMemo(
        (): string => {
        let segmentsCount = 0;
        const selectedChain = options.chains.find(i => i.id === localValue.chainId);

        if (!!filtersDiff.segments) {
            options.segments.forEach(i => {
                if (localValue.segments?.includes(i.id)) {
                    segmentsCount += 1;
                }
            });

            if (!isConceptsLocked && isShown(Part.Concepts) && localValue.concepts.length > 0) {
                options.specially.forEach(i => {
                    if (localValue.segments?.includes(i.id)) {
                        segmentsCount += 1;
                    }
                });
            }
        }

        if (filtersDiff.isGroceryDeli) segmentsCount += 1;
        if (filtersDiff.isMealKit) segmentsCount += 1;

        return `${
            segmentsCount ? `${segmentsCount} ${getEnding('segment', segmentsCount)} selected` : ''
        }${
            segmentsCount !== 0 && !!selectedChain ? ' & ' : ''
        }${
            !!selectedChain ? `${selectedChain.title} selected` : ''
        }`;
    },
        [filtersDiff, localValue, options, isShown, isConceptsLocked]
    );

    const categoriesDiffTitle = useMemo(
        (): string => {
        if (!filtersDiff.categories) return '';

        return `${localValue.categories.length} selected`;
    },
        [filtersDiff, localValue]
    );

    const keywordsDiffTitle = useMemo(
        (): string => {
            const addedWords = [
                ...(localValue.includeAll || []),
                ...(localValue.includeAny || []),
                ...(localValue.excludeAll || []),
            ];

            if (addedWords.length === 0) return '';

            return `${addedWords.length} selected`;
        },
        [localValue]
    );

    const statusesDiffTitle = useMemo(
        (): string => {
        if (!filtersDiff.statuses) return '';

        return `${localValue.statuses.length} selected`;
    },
        [filtersDiff, localValue]
    );

    const keyMetricsDiffTitle = useMemo(
        (): string => {
        let changes: string[] = [];

        if (!!filtersDiff.viability) {
            changes.push('Viability');
        }

        if (!!filtersDiff.score) {
            changes.push('Score');
        }

        if (
            !!filtersDiff.unbrandedStarsCount
            || !!filtersDiff.brandedStarsCount
            || !!filtersDiff.uniquenessStarsCount
            || !!filtersDiff.frequencyStarsCount
            || !!filtersDiff.drawStarsCount
            || !!filtersDiff.valueStarsCount
        ) {
            changes.push('Star Ratings');
        }

        if (localValue.concepts.length > 0) {
            if (!!filtersDiff.brandFit) {
                changes.push('Brand Fit');
            }
            if (!!filtersDiff.dayPartFit) {
                changes.push('Daypart Fit');
            }
        }

        if (changes.length === 0) return '';
        return `filtered by ${changes.join(', ')}`;
    },
        [filtersDiff, localValue]
    );

    return (
        <>
            <div
                ref={ref}
                className={cn(
                    "FiltersPopup",
                    isOpened && 'is-opened',
                    `position-${position}`,
                )}
            >
                <div className="FiltersPopup__scroll-container">
                    {!hideTilesSort && isShown(Part.TilesSort) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="SORT TILES BY"
                            subTitle={sortTilesDiffTitle}
                            options={<ResetFiltersButton onClick={() => resetField('sort')}/>}
                        >
                            <Field label="Elements">
                                <PlatesSelect
                                    value={[localValue.sort]}
                                    options={options.sortElements}
                                    onOptionClick={setField('sort')}
                                />
                            </Field>
                            {!hiddenElements.includes(UiSettingsKey.FilterSortByMetrics) && (
                                <Field label="Metrics">
                                    <PlatesSelect
                                        value={[localValue.sort]}
                                        options={options.sortMetrics}
                                        onOptionClick={setField('sort')}
                                    />
                                </Field>
                            )}
                        </CollapsibleSection>
                    )}
                    {isShown(Part.DatePeriod, Part.DateSeason, Part.DateRange) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="DATE"
                            subTitle={dateDiffTitle}
                            options={<ResetFiltersButton onClick={() => resetField('date')}/>}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {isShown(Part.DatePeriod) && (
                                    <Field label="Within the past">
                                        <Select
                                            options={options.dateWithinMonth}
                                            value={localValue.date.withinMonth === null ? [] : [localValue.date.withinMonth]}
                                            onOptionClick={(id) => setDate('withinMonth')(id)}
                                            closeOnOptionClick
                                        />
                                    </Field>
                                )}
                                {isShown(Part.DatePeriod) && isShown(Part.DateSeason, Part.DateRange) && (
                                    <Field label={<>&nbsp;</>}>
                                        <div className="FiltersPopup__field-separator">or</div>
                                    </Field>
                                )}
                                {isShown(Part.DateSeason) && (
                                    <Field label="Season (launched in)">
                                        <Select
                                            options={options.dateSeasons}
                                            value={localValue.date.season === null ? [] : [localValue.date.season]}
                                            onOptionClick={(id) => setDate('season')(id)}
                                            closeOnOptionClick
                                        />
                                    </Field>
                                )}
                                {isShown(Part.DateRange) && isShown(Part.DateSeason) && (
                                    <Field label={<>&nbsp;</>}>
                                        <div className="FiltersPopup__field-separator">or</div>
                                    </Field>
                                )}
                                {isShown(Part.DateRange) && (
                                    <Field label="Select date range">
                                        <SelectDateRange
                                            from={localValue.date.period.from}
                                            to={localValue.date.period.to}
                                            onChange={setDate('period')}
                                        />
                                    </Field>
                                )}
                            </div>
                        </CollapsibleSection>
                    )}
                    {!isConceptsLocked && isShown(Part.Concepts) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="Concepts"
                            subTitle={clTypeDiffTitle}
                            options={(
                                <div onClick={e => e.stopPropagation()}>
                                    <Toggle
                                        isChecked={localValue.concepts.length > 0}
                                        onChange={() => setField('concepts')(
                                            localValue.concepts.length > 0 ? [] : options.concepts.map(o => o.id)
                                        )}
                                        label="Concept Locker"
                                    />
                                </div>
                            )}
                        >
                            <PlatesSelect
                                value={localValue.concepts}
                                options={options.concepts}
                                onOptionClick={(id) => setField('concepts')(toggleInArray(localValue.concepts, id))}
                            />
                        </CollapsibleSection>
                    )}
                    {isShown(Part.Segments, Part.Chain) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title={(() => {
                                if (isShown(Part.Segments) && !isShown(Part.Chain)) {
                                    return "Segments";
                                }
                                else if (!isShown(Part.Segments) && isShown(Part.Chain)) {
                                    return "Chains"
                                }
                                return "Segments & Chains";
                            })()}
                            subTitle={segmentsChainDiffTitle}
                            options={<ResetFiltersButton onClick={resetSegmentsChains}/>}
                        >
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '504px 100px auto',
                                paddingBlock: 20,
                            }}>
                                {isShown(Part.Segments) && (
                                    <div className="FiltersPopup__block">
                                        <Field
                                            label="Segments"
                                            options={(
                                                <SelectResetFiltersButtons
                                                    onSelect={() => {
                                                        setField('segments')([
                                                            ...options.segments.filter(o => !o.isDisabled).map(o => o.id),
                                                            ...options.specially.filter(o => !o.isDisabled).map(o => o.id),
                                                        ])
                                                    }}
                                                    onClear={() => setField('segments')([])}
                                                />
                                            )}
                                        >
                                            <Field label="Public Items">
                                                <PlatesSelect
                                                    value={localValue.segments}
                                                    options={options.segments}
                                                    onOptionClick={(id) => setField('segments')(toggleInArray(localValue.segments, id))}
                                                />
                                            </Field>
                                            {isShown(Part.SegmentsToggles) && currentCountry?.id === 1 && (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    paddingTop: 15,
                                                    paddingBottom: 15,
                                                    alignItems: 'flex-start',
                                                }}>
                                                    <Toggle
                                                        isChecked={localValue.isGroceryDeli}
                                                        onChange={() => setField('isGroceryDeli')(!localValue.isGroceryDeli)}
                                                        label={<>&nbsp;&nbsp;Grocery Deli</>}
                                                        labelPosition="right"
                                                    />
                                                    <Toggle
                                                        isChecked={localValue.isMealKit}
                                                        onChange={() => setField('isMealKit')(!localValue.isMealKit)}
                                                        label={<>&nbsp;&nbsp;Meal Kit</>}
                                                        labelPosition="right"
                                                    />
                                                </div>
                                            )}
                                            {!isConceptsLocked && isShown(Part.ClSegments) && localValue.concepts.length > 0 && options.specially.length > 0 && (
                                                <Field label="Locker-Only Items">
                                                    <PlatesSelect
                                                        value={localValue.segments}
                                                        options={options.specially}
                                                        onOptionClick={(id) => setField('segments')(toggleInArray(localValue.segments, id))}
                                                    />
                                                </Field>
                                            )}
                                            {isConceptsLocked && isShown(Part.ClSegments) && localValue.concepts.length > 0 && options.specially.length > 0 && (
                                                <Field label={(
                                                    <div>
                                                        <IconLock
                                                            style={{ height: 12, width: 12, marginRight: 4 }}
                                                        />
                                                        Locker-Only Items
                                                    </div>
                                                )}>
                                                    <PlatesSelect
                                                        isDisabled
                                                        value={[]}
                                                        options={options.specially}
                                                        onOptionClick={() => null}
                                                    />
                                                </Field>
                                            )}
                                        </Field>
                                    </div>
                                )}
                                {isShown(Part.Segments) && isShown(Part.Chain) && (
                                    <div style={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <div className="FiltersPopup__line-separator"/>
                                    </div>
                                )}
                                {isShown(Part.Chain) && (
                                    <div className="FiltersPopup__block">
                                        <Field label="Chains">
                                            <div>
                                                <Field label="Select a specific chain">
                                                    <Select
                                                        options={options.chains}
                                                        value={localValue.chainId === null ? [] : [localValue.chainId]}
                                                        onOptionClick={setField('chainId')}
                                                        closeOnOptionClick
                                                        hasSearch
                                                        className="width-l"
                                                    />
                                                </Field>
                                            </div>
                                        </Field>
                                    </div>
                                )}
                            </div>
                        </CollapsibleSection>
                    )}
                    {isShown(Part.Categories) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="Categories (item types)"
                            subTitle={categoriesDiffTitle}
                            options={(
                                <SelectResetFiltersButtons
                                    onSelect={() => setField('categories')(
                                        options.categories.filter(o => !o.isDisabled).map(o => o.id),
                                    )}
                                    onClear={() => setField('categories')([])}
                                />
                            )}
                        >
                            <div style={{ width: 800 }}>
                                <PlatesSelect
                                    value={localValue.categories}
                                    options={options.categories}
                                    onOptionClick={(id) => setField('categories')(toggleInArray(localValue.categories, id))}
                                />
                            </div>
                        </CollapsibleSection>
                    )}
                    {isShown(Part.Keywords) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="Keywords"
                            subTitle={keywordsDiffTitle}
                            options={(
                                <ResetFiltersButton onClick={() => {
                                    setLocalValue({
                                        ...localValue,
                                        includeAll: defaultValue.includeAll,
                                        includeAny: defaultValue.includeAny,
                                        excludeAll: defaultValue.excludeAll,
                                    })
                                }} />
                            )}
                        >
                            <div style={{ display: 'flex', gap: 30, paddingRight: 30 }}>
                                <KeywordFilter
                                    value={localValue}
                                    field={'includeAll'}
                                    setValue={setField}
                                />
                                <KeywordFilter
                                    value={localValue}
                                    field={'includeAny'}
                                    setValue={setField}
                                />
                                <KeywordFilter
                                    value={localValue}
                                    field={'excludeAll'}
                                    setValue={setField}
                                />
                            </div>
                        </CollapsibleSection>
                    )}
                    {isShown(Part.Status) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="Status"
                            subTitle={statusesDiffTitle}
                            options={(
                                <SelectResetFiltersButtons
                                    onSelect={() => setField('statuses')(
                                        options.statuses.filter(o => !o.isDisabled).map(o => o.id),
                                    )}
                                    onClear={() => setField('statuses')([])}
                                />
                            )}
                        >
                            <div style={{ width: 800 }}>
                                <PlatesSelect
                                    value={localValue.statuses}
                                    options={options.statuses}
                                    onOptionClick={(id) => setField('statuses')(toggleInArray(localValue.statuses, id))}
                                />
                            </div>
                        </CollapsibleSection>
                    )}
                    {!isKeyMetricsLocked && isShown(Part.KeyMetric) && (
                        <CollapsibleSection
                            collapseTrigger={isOpened}
                            title="Key Metrics"
                            subTitle={keyMetricsDiffTitle}
                            options={<ResetFiltersButton onClick={() => setLocalValue({
                                ...localValue,
                                viability: defaultValue.viability,
                                score: defaultValue.score,
                                unbrandedStarsCount: defaultValue.unbrandedStarsCount,
                                brandedStarsCount: defaultValue.brandedStarsCount,
                                uniquenessStarsCount: defaultValue.uniquenessStarsCount,
                                frequencyStarsCount: defaultValue.frequencyStarsCount,
                                drawStarsCount: defaultValue.drawStarsCount,
                                valueStarsCount: defaultValue.valueStarsCount,
                                brandFit: defaultValue.brandFit,
                                dayPartFit: defaultValue.dayPartFit,
                            })}/>}
                        >
                            <Field label="Viability">
                                <PlatesSelect
                                    value={localValue.viability}
                                    options={options.viability}
                                    onOptionClick={(id) => setField('viability')(toggleInArray(localValue.viability, id))}
                                />
                            </Field>
                            <div className="FiltersPopup__separator"/>
                            <Field label="Score">
                                <RangeSlider
                                    style={{ width: 330 }}
                                    value={[localValue.score.min, localValue.score.max]}
                                    onChange={([min, max]) => setField('score')({ min, max })}
                                    min={0}
                                    max={100}
                                    step={10}
                                />
                            </Field>
                            <div className="FiltersPopup__separator"/>
                            <Field label="Star Ratings">
                                <div style={{ display: 'flex', width: 900, justifyContent: 'space-between' }}>
                                    <div style={{ width: 420 }}>
                                        <Field label="Unbranded PI (include)" inline>
                                            <StarsRangeSlider
                                                style={{ width: 230 }}
                                                value={[localValue.unbrandedStarsCount.min, localValue.unbrandedStarsCount.max]}
                                                onChange={([min, max]) => setField('unbrandedStarsCount')({ min, max })}
                                            />
                                        </Field>
                                        <Field label="Branded PI (include)" inline>
                                            <StarsRangeSlider
                                                style={{ width: 230 }}
                                                value={[localValue.brandedStarsCount.min, localValue.brandedStarsCount.max]}
                                                onChange={([min, max]) => setField('brandedStarsCount')({ min, max })}

                                            />
                                        </Field>
                                        <Field label="Uniqueness (include)" inline>
                                            <StarsRangeSlider
                                                style={{ width: 230 }}
                                                value={[localValue.uniquenessStarsCount.min, localValue.uniquenessStarsCount.max]}
                                                onChange={([min, max]) => setField('uniquenessStarsCount')({ min, max })}

                                            />
                                        </Field>
                                    </div>
                                    <div style={{ width: 420 }}>
                                        <Field label="Frequency (include)" inline>
                                            <StarsRangeSlider
                                                style={{ width: 230 }}
                                                value={[localValue.frequencyStarsCount.min, localValue.frequencyStarsCount.max]}
                                                onChange={([min, max]) => setField('frequencyStarsCount')({ min, max })}

                                            />
                                        </Field>
                                        <Field label="Draw (include)" inline>
                                            <StarsRangeSlider
                                                style={{ width: 230 }}
                                                value={[localValue.drawStarsCount.min, localValue.drawStarsCount.max]}
                                                onChange={([min, max]) => setField('drawStarsCount')({ min, max })}

                                            />
                                        </Field>
                                        <Field label="Value (include)" inline>
                                            <StarsRangeSlider
                                                style={{ width: 230 }}
                                                value={[localValue.valueStarsCount.min, localValue.valueStarsCount.max]}
                                                onChange={([min, max]) => setField('valueStarsCount')({ min, max })}

                                            />
                                        </Field>
                                    </div>
                                </div>
                            </Field>
                            <div className="FiltersPopup__separator"/>
                            {localValue.concepts.length > 0 && (
                                <>
                                    <Field label="Daypart Fit (include, good fit at...)">
                                        <PlatesSelect
                                            value={localValue.dayPartFit}
                                            options={options.dayPartFit}
                                            onOptionClick={(id) => setField('dayPartFit')(toggleInArray(localValue.dayPartFit, id))}
                                        />
                                    </Field>
                                    <Field label="Brand Fit (include)">
                                        <PlatesSelect
                                            value={localValue.brandFit}
                                            options={options.brandFit}
                                            onOptionClick={(id) => setField('brandFit')(toggleInArray(localValue.brandFit, id))}
                                        />
                                    </Field>
                                </>
                            )}
                        </CollapsibleSection>
                    )}
                    {isShown(Part.Concepts) && isConceptsLocked && (
                        <CollapsibleSection
                            title="Concepts"
                            isDisabled
                        />
                    )}
                    {isShown(Part.Concepts) && isKeyMetricsLocked && (
                        <CollapsibleSection
                            title="Key Metrics"
                            isDisabled
                        />
                    )}
                </div>
                <div className="FiltersPopup__footer">
                    {isShown(Part.NewItemsPreview) && !hiddenElements.includes(UiSettingsKey.FilterNewItemPreview) ? (
                        <>
                            <div data-tooltip-id="new-item-preview-toggle">
                                <Toggle
                                    isChecked={localValue.includeNewItemsPreview}
                                    onChange={() => setField('includeNewItemsPreview')(!localValue.includeNewItemsPreview)}
                                    label={<>&nbsp;New Item Preview</>}
                                    labelPosition="right"
                                />
                            </div>
                            <Tooltip
                                id="new-item-preview-toggle"
                                place="top-start"
                                style={{ width: 200, zIndex: 2 }}
                            >
                                Preview new menu item launches even before they've been rated by consumers
                            </Tooltip>
                        </>
                    ) : <div/>}
                    <div>
                        <Button
                            modifiers={['h-tall', 'blue', 'naked',]}
                            className="font-bold"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                setLocalValue(defaultValue);
                            }}
                        >
                            RESET ALL FILTERS
                        </Button>
                        <Button
                            isDisabled={!isValid}
                            modifiers={['h-tall', 'green']}
                            className="font-bold"
                            onClick={() => {
                                updateValue(localValue);
                                close();
                            }}
                        >
                            APPLY FILTERS
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    "FiltersPopupOverlay",
                    isOpened && 'is-shown',
                )}
                onClick={close}
            />
        </>
    );
};

export default FiltersPopup;