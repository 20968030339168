import { FCX } from '@models';
import { Tooltip } from 'react-tooltip';
import { useMemo } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import { equalInLC } from '@core/utils/string';
import { AiOutlineInfo } from 'react-icons/ai';
import Popup from '@components/Popup';
import cn from 'classnames';
import { useUuid } from "@datassential/platform-ui-lib";

const KeywordSettings: FCX<{
    appliedKeywords: string[];
    hideWhenNoData?: boolean;
}> = ({
    appliedKeywords,
    hideWhenNoData = false,
    style,
    className,
}) => {
    const id = useUuid();
    const { options: { keywords }} = useFiltersContext();

    const appliedKeywordsInfo = useMemo(() => {
        const includes: Set<string> = new Set();
        const excludes: Set<string> = new Set();

        appliedKeywords.forEach((word) => {
            const item = keywords.find(i => equalInLC(i.title, word.trim()));

            if (item) {
                item.includes.forEach(i => includes.add(i.trim()));
                item.excludes.forEach(i => excludes.add(i.trim()));
            }
        });

        return {
            includes: Array.from(includes),
            excludes: Array.from(excludes),
        };
    }, [appliedKeywords, keywords]);

    const hasData = appliedKeywordsInfo.includes.length > 0 || appliedKeywordsInfo.excludes.length > 0;

    const popupSize = useMemo(() => {
        const length = appliedKeywordsInfo.includes.join(', ').length + appliedKeywordsInfo.excludes.join(', ').length;

        if (length > 1000) {
        }
        else if (length > 500) {
            return 'l';
        }
        else if (length > 200) {
            return 'm';
        }
        else return 's';

    }, [appliedKeywordsInfo]);

    if (!hasData && hideWhenNoData) return null;

    return (
        <>
            <div
                className={cn("KeywordSettings", className)}
                data-tooltip-id={id}
                style={{
                    ...style,
                    pointerEvents: hasData ? undefined : 'none',
                    opacity: hasData ? undefined : 0.4
                }}
            >
                <AiOutlineInfo style={{
                    width: '80%',
                    height: '80%',
                    color: hasData ? undefined : '#bbb',
                }}/>
            </div>
            <Popup hasWrapper={false}>
                <Tooltip
                    id={id}
                    place="bottom-start"
                    variant={'light'}
                    positionStrategy="fixed"
                    style={{
                        transition: 'none',
                        zIndex: 1000,
                        padding: 18,
                        color: '#919191',
                        fontSize: 14,
                        filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.5))'
                    }}
                >
                    <div className={cn(
                        "KeywordSettings__popup",
                        `size-${popupSize}`,
                    )}>
                        {appliedKeywordsInfo.includes.length > 0 && (
                            <>
                                <div className="KeywordSettings__title green">
                                    Includes
                                </div>
                                {appliedKeywordsInfo.includes.join(', ')}
                            </>
                        )}
                        {appliedKeywordsInfo.excludes.length > 0 && (
                            <>
                                <div className="KeywordSettings__title red">
                                    Excludes
                                </div>
                                {appliedKeywordsInfo.excludes.join(', ')}
                            </>
                        )}
                    </div>
                </Tooltip>
            </Popup>
        </>
    );
}

export default KeywordSettings;