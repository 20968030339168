import { FCX, ID, iSortingOption, iTableSorting, Sort } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import { useCallback, useMemo } from 'react';
import { getDefaultSortDirection } from '@core/constants';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';

const TableViewSorting: FCX<{
    sortingColId: ID;
    setSortingColId: (id: ID) => void;
    sortingDirection: Sort;
    setSortingDirection: (direction: Sort) => void;
}> = ({
    sortingColId,
    setSortingColId,
    sortingDirection,
    setSortingDirection,
    children,
}) => {
    const {
        options: { sortAllElements, sortAllMetrics },
    } = useFiltersContext();

    const applySort = useCallback((newSortValue: iTableSorting) => {
        setSortingColId(newSortValue.id);
        setSortingDirection(newSortValue.direction);
    }, [setSortingColId, setSortingDirection]);

    const sortingOptions: iSortingOption[] = useMemo(() => {
        return [...sortAllElements, ...sortAllMetrics].map((option) => ({
            id: option.id,
            name: option.title,
            defaultDirection: getDefaultSortDirection(option.title),
        }));
    }, [sortAllElements, sortAllMetrics]);

    return (
        <TableSortingContext.Provider value={{
            value: {
                id: sortingColId,
                direction: sortingDirection,
            },
            setValue: applySort,
            options: sortingOptions,
        }}>
            {children}
        </TableSortingContext.Provider>
    );
};

export default TableViewSorting;