import { FCX } from '@models';
import Button from '@components/Button';
import { useContactUs } from '@api/useContactUs';
import { IconLock } from '@svg/index';
import { useState } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';

const LockAccessOverlay: FCX<{
    toolLocation: string;
    title?: string;
    subTitle?: string;
}> = ({
    title= 'Learn what consumers think',
    subTitle= ' ',
    style,
    toolLocation,
}) => {
    const [isSent, setIsSent] = useState(false);

    const request = useContactUs(toolLocation);

    return (
        <div className="LockAccessOverlay" style={style}>
            <div className="LockAccessOverlay__inner">
                {isSent ? (
                    <FaRegCheckCircle className="LockAccessOverlay__icon"/>
                ) : (
                    <IconLock className="LockAccessOverlay__icon"/>
                )}
                <div className="LockAccessOverlay__title">
                    {isSent ? 'Request Sent!' : title}
                </div>
                <div className="LockAccessOverlay__sub-title">
                    {isSent ? <>
                        A team member will get back
                        <br/>
                        to you in a SNAP!
                    </> : subTitle}
                </div>
                {!isSent && (
                    <Button
                        className="LockAccessOverlay__button"
                        modifiers={['blue']}
                        onClick={() => {
                            setIsSent(true);
                            request.doFetch();
                        }}
                    >
                        CONTACT US
                    </Button>
                )}
            </div>
        </div>
    );
};

export default LockAccessOverlay;