import { FCX } from '@models';
import { TableContext } from '@components/Table/TableContext';
import { iTableConfigItem } from '@components/Table/TableConfig';
import { useCallback, useMemo } from 'react';
import { iItem, iItemMetrics, Item } from '@models/Item';
import useDateColumn from '@vms/ItemsTable/useDateColumn';
import useNameColumn from '@vms/ItemsTable/useNameColumn';
import { useMetricColumns } from '@vms/ItemsTable/useMetricsColumns';
import { useFiltersContext } from '@core/FiltersContext';
import useChainAggregatedColumn from '@vms/ItemsTable/useChainAggregatedColumn';

const CLTableViewConfig: FCX<{
    data: iItem[];
}> = ({
    data,
    children,
}) => {
    const {
        value: { among, },
    } = useFiltersContext();

    const getMetrics = useCallback((index: number): iItemMetrics => {
        return data[index].indices.get(among as number) || Item.defaultMetricsData;
    }, [among, data]);
    const dateColumn = useDateColumn(data);
    const nameColumn = useNameColumn(data, getMetrics);
    const chainColumn = useChainAggregatedColumn(data, 'Tested for', '[chain/segment]');
    const metricColumns = useMetricColumns(data, getMetrics);

    const config: iTableConfigItem[] = useMemo(() => {
        return [
            dateColumn,
            {
                key: 'type',
                title: 'Type',
                sortingColName: 'type',
                Value: ({ index }) => <>{data[index].itemType}</>,
                styles: {
                    cell: {
                        flex: '0 0 80px',
                    }
                },
            },
            {
                key: 'company',
                title: 'Company',
                sortingColName: 'company',
                Value: ({ index }) => <div className="lines-3">{data[index].companyName}</div>,
            },
            nameColumn,
            chainColumn,
            ...metricColumns,
        ];
    }, [data, nameColumn, metricColumns, dateColumn, chainColumn]);
    return (
        <TableContext.Provider value={{ config }}>
            {children}
        </TableContext.Provider>
    );
};

export default CLTableViewConfig;