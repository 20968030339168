import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { useChainPerformanceFilters } from '@core/selectors/useChainPerformanceFilters';

export function useChainsData (lazy: boolean = false) {
    const payload = useChainPerformanceFilters();

    const data = useFetch<components['schemas']['ChainIndexViewModel'][]>({
        method: 'post',
        url: '/api/Chain/GetChains' as keyof paths,
        payload,
        lazy,
    });

    return {
        isLoading: data.isLoading,
        data: data.data,
    };
}