import { FCX } from '@models';

const ActionsBar: FCX = ({
    children,
    style,
}) => {
    return (
        <div
            className="ActionsBar"
            style={style}
        >
            {children}
        </div>
    );
};

export default ActionsBar;