import { FCX, ID, Timeframe, UnitType } from '@models';
import Loader from '@components/Loader';
import TimeframesBarChart from '@components/TimeframesBarChart';
import { getFormattedThousand } from '@core/utils/number';
import { FiltersVariant } from '@models/Filter';
import { Route } from '@core/routes/Route';
import React, { ReactNode, useMemo } from 'react';
import { useCategoryActivityTimeframesBarChartData } from '@api/useCategoryActivityTimeframesBarChartData';
import TimeframesBarChartData from '@models/TimeframesBarChartData';
import { useFiltersContext } from '@core/FiltersContext';
import { useNavigate } from 'react-router-dom';
import ButtonTabs from '@components/ButtonTabs';

const LaunchesTimeframesBarChart: FCX<{
    categoryId: ID;
    selectedUnit: UnitType;
    renderCategoryName: ReactNode;
    isGlobalLoading: boolean;
}> = ({
    categoryId,
    renderCategoryName,
    selectedUnit,
}) => {
    const navigate = useNavigate();
    const [selectedTimeframe, setSelectedTimeframe] = React.useState<Timeframe>(Timeframe.Season);
    const {
        value: {
            chainId,
            segments,
            date: filterDate,
        },
        resetSpecificValue,
    } = useFiltersContext();
    const { isLoading, data } = useCategoryActivityTimeframesBarChartData(categoryId);

    const chartData = useMemo(
        () => {
            return new TimeframesBarChartData({
                apiModel1: data?.itemsData?.releasedItems || undefined,
                unitType: selectedUnit,
            });
        },
        [data, selectedUnit]
    );

    return (
        <div className="card" style={{ flex: '0 1 100%' }}>
            <div
                className="card-header"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
            >
                <div>
                    {renderCategoryName}&nbsp;Launches: Time Trend
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {selectedUnit === UnitType.Count ? 'Number' : 'Percent'} of Menu Launches by
                    <ButtonTabs
                        style={{ marginLeft: 10 }}
                        data={[
                            {
                                id: Timeframe.Season,
                                title: 'Season',
                                buttonProps: {
                                    onClick: () => setSelectedTimeframe(Timeframe.Season),
                                    style: { height: 30 },
                                }
                            },
                            {
                                id: Timeframe.Month,
                                title: 'Month',
                                buttonProps: {
                                    onClick: () => setSelectedTimeframe(Timeframe.Month),
                                    style: { height: 30 },
                                }
                            },
                        ]}
                        activeItemId={selectedTimeframe}
                    />
                </div>
            </div>
            {isLoading ? (
                <div style={{ padding: 20 }}>
                    <div style={{ position: 'relative', height: 300 }}>
                        <Loader/>
                    </div>
                </div>
            ) : (
                <TimeframesBarChart
                    key={categoryId}
                    data={chartData}
                    currentView={selectedTimeframe}
                    valueFormatter={
                        selectedUnit === UnitType.Count
                            ? getFormattedThousand
                            : (value) => `${Math.round(value)}%`
                    }
                    onBarClick={(barId) => {
                        resetSpecificValue(FiltersVariant.Homepage, {
                            chainId: chainId,
                            date: filterDate,
                            categories: [categoryId],
                            segments,
                        });
                        navigate(`${Route.Home}?timeframe=${selectedTimeframe}&barId=${barId}`);
                    }}
                    showGroupValue
                />
            )}
        </div>
    );
}

export default LaunchesTimeframesBarChart;