import { FCX, iChartItemShort, ID, Timeframe, UnitType } from '@models';
import AnimatedNumber from '@components/AnimatedNumber';
import TextInput from '@components/TextInput';
import HeaderBar from '@components/HeaderBar';
import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useFilterSort } from '@hooks/useFilterSort';
import ValuesByTimeframeData, { iValuesByTimeframeData } from '@models/ValuesByTimeframeData';
import { getReverse } from '@core/utils/array';
import VirtualizedTable from '@components/Table/VirtualizedTable';
import TableConfig, { NAME_CELL_WIDTH, REGULAR_CELL_WIDTH, TOTAL_AVG_CELL_WIDTH } from './TimeframesTableConfig';
import { getSortingOptions } from '@core/constants';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import Loader from '@components/Loader';
import { getFormattedThousand } from '@core/utils/number';
import { iFoodFlavorHistoricTrendItem } from '@api/useFoodFlavorsHistoricTrend';
import { useFiltersContext } from '@core/FiltersContext';
import { iMainMetric } from '@models/Filter';
import SelectKeywordCategories from '@vms/SelectKeywordCategories';
import { useUserContext } from '@core/UserContext';
import LockAccessOverlay from '@components/LockAccessOverlay';

const TimeframesTable: FCX<{
    data: iFoodFlavorHistoricTrendItem[];
    isLoading: boolean;
    selectedUnit: UnitType;
    selectedPeriod: Timeframe;
    selectedMetricId: ID;
    chartItems: iChartItemShort[];
    addChartItem: (id: ID) => void;
    removeChartItem: (id: ID) => void;
}> = ({
    data: rawData,
    isLoading,
    selectedUnit,
    selectedPeriod,
    selectedMetricId,
    chartItems,
    addChartItem,
    removeChartItem,
}) => {
    const { options: { mainMetrics } } = useFiltersContext();
    const { fullAccess } = useUserContext();
    const [selectedCategories, setSelectedCategories] = useState<ID[]>([]);

    const rawMappedData = useMemo((): Array<iFoodFlavorHistoricTrendItem & iValuesByTimeframeData> => {
        return rawData
            .map(item => ({
                ...item,
                ...(new ValuesByTimeframeData({
                    apiModel: item.data,
                    timeframe: selectedPeriod,
                }))
            }))
            .filter(i => {
                if (selectedCategories.length > 0) {
                    return i.groupKeyWordIds?.some(id => selectedCategories.includes(id));
                }
                return true;
            });
    }, [rawData, selectedPeriod, selectedCategories]);

    const availableTimeframes = useMemo(() => {
        const allTimeframes = ValuesByTimeframeData.getAllTimeframes(selectedPeriod);

        if (rawMappedData.length === 0) {
            return allTimeframes;
        }
        const firstIndex = allTimeframes.findIndex(frame => rawMappedData.some(i => i[frame.id] !== null));
        const lastIndex = getReverse(allTimeframes).findIndex(frame => rawMappedData.some(i => i[frame.id] !== null));

        return allTimeframes.slice(firstIndex, allTimeframes.length - lastIndex);
    }, [rawMappedData, selectedPeriod]);

    const {
        data,
        query,
        setQuery,
        sortingColumn,
        setSortingColumn,
        sortingDirection,
        setSortingDirection,
    } = useFilterSort(
        (rawMappedData || []),
        'total',
        'name'
    );

    const valueFormatter = useCallback((value?: number | null) => {
        if (value === null || value === undefined) return '-';

        return selectedUnit === UnitType.Pct
            ? `${Math.round(value)}%`
            : `${getFormattedThousand(value)}`;
    }, [selectedUnit]);

    const copyTableData = useMemo((): string => {
        return [
            [
                'Chain',
                'Total',
                selectedUnit === UnitType.Pct ? 'Average' : null,
                ...availableTimeframes.map(frame => frame.title),
            ].filter(i => i !== null).join('\t'),
            ...data.map(
                (item) => [
                    item.name,
                    getFormattedThousand(item.total || 0),
                    selectedUnit === UnitType.Pct ? valueFormatter(item.average) : null,
                    ...availableTimeframes.map(
                        frame => valueFormatter(item[frame.id])),
                ].filter(i => i !== null).join('\t')
            ),
        ].join('\r\n');
    }, [data, availableTimeframes, selectedUnit, valueFormatter]);

    const selectedMetric = useMemo((): iMainMetric | undefined => {
        return mainMetrics.find(i => i.id === selectedMetricId);
    }, [mainMetrics, selectedMetricId]);

    const headerSubTitle = useMemo((): ReactNode => {
        if (selectedUnit === UnitType.Count) {
            return 'count of menu launches containing that food / flavor';
        }
        else {
            return <>
                average&nbsp;
                <div className="color-dimyellow font-medium">{selectedMetric?.title.toUpperCase()}</div>
                &nbsp;for menu launches containing that food / flavor
            </>;
        }
    }, [selectedUnit, selectedMetric]);

    const tableWidth = useMemo((): number | undefined => {
        if (data.length === 0) return undefined;

        return NAME_CELL_WIDTH
            + TOTAL_AVG_CELL_WIDTH * (selectedUnit === UnitType.Count ? 1 : 2)
            + availableTimeframes.length * REGULAR_CELL_WIDTH;
    }, [availableTimeframes, data, selectedUnit]);

    const noAccess = useMemo(
        () => selectedUnit === UnitType.Pct && !fullAccess,
        [selectedUnit, fullAccess]
    )

    return (
        <div style={{ position: 'relative' }}>
            <HeaderBar style={{
                position: 'relative',
                zIndex: 3,
                marginBottom: 20,
                height: 70,
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(rawData || []).length > 0 && (
                        <div
                            style={{
                                fontSize: 30,
                                marginRight: 15,
                                width: 85,
                            }}
                            className="font-caption color-dimyellow"
                        >
                            <AnimatedNumber value={data.length}/>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div className="header-title">
                            Foods & Flavors
                        </div>
                        <div className="header-sub-title">
                            {headerSubTitle}
                        </div>
                    </div>
                    <TextInput
                        placeholder="Type any keyword"
                        value={query}
                        setValue={setQuery}
                        style={{ width: 160, marginLeft: 20 }}
                    />
                </div>
                <div>
                    <SelectKeywordCategories
                        value={selectedCategories}
                        setValue={setSelectedCategories}
                    />
                </div>
            </HeaderBar>
            {isLoading && (
                <div className="ChainPerformance__table">
                    <Loader/>
                </div>
            )}
            {!isLoading && (
                <TableSortingContext.Provider value={{
                    value: {
                        id: sortingColumn,
                        direction: sortingDirection,
                    },
                    setValue: (value) => {
                        setSortingColumn(value.id);
                        setSortingDirection(value.direction);
                    },
                    options: getSortingOptions([
                        'name',
                        'total',
                        'average',
                        ...availableTimeframes.map(i => i.id),
                    ]),
                }}>
                    <TableConfig
                        data={data}
                        searchQuery={query}
                        selectedUnit={selectedUnit}
                        availableTimeFrames={availableTimeframes}
                        chartItems={chartItems}
                        addChartItem={addChartItem}
                        removeChartItem={removeChartItem}
                        valueFormatter={valueFormatter}
                    >
                        <div className="ChainPerformance__table">
                            <VirtualizedTable
                                rowHeight={48}
                                isScrollXEnabled={!isLoading && data.length > 0}
                                copyTable={noAccess ? undefined : {
                                    isLoading: false,
                                    data: copyTableData,
                                }}
                                rowsCount={data.length}
                                isLoading={isLoading}
                                style={{
                                    minWidth: tableWidth,
                                    boxSizing: 'content-box',
                                    paddingTop: 0,
                                }}
                                headStyle={{
                                    paddingTop: 8,
                                    height: 38,
                                }}
                            />
                        </div>
                    </TableConfig>
                </TableSortingContext.Provider>
            )}
            {noAccess && (
                <LockAccessOverlay
                    subTitle="of these foods & flavors"
                    toolLocation="Specific Foods & Flavors"
                />
            )}
        </div>
    )
};

export default memo(TimeframesTable);