import { FCX } from '@models';
import { useLottie } from 'lottie-react';
import animation from './lottie-loader.json';
import cn from 'classnames';

const Loader: FCX = ({
    className,
    style,
    children,
}) => {
    const { View } = useLottie({
        animationData: animation,
        loop: true,
        style: {
            width: 200,
            height: 200,
        },
    });
    return (
        <div
            className={cn("Loader", className)}
            style={style}
        >
            {children || View}
        </div>
    );
};

export default Loader;