export function toggleInArray<T>(arr: T[], value: T): T[] {
    if (arr.includes(value)) {
        return arr.filter(i => i !== value);
    }
    return [...arr, value];
}

export const defaultSort = (a: any, b: any) => {

    // force null and undefined to the bottom
    a = (a === null || a === undefined) ? -Infinity : a
    b = (b === null || b === undefined) ? -Infinity : b
    // force any string values to lowercase
    a = typeof(a) === 'string' ? a.toLowerCase() : a
    b = typeof(b) === 'string' ? b.toLowerCase() : b
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
        return 1
    }
    if (a < b) {
        return -1
    }
    return 0
}

export function getIntersection<T>(arr1: T[], arr2: T[]): T[] {
    return arr1.filter(i => arr2.includes(i));
}

export function intersect<T>(arr1: T[], arr2: T[]): boolean {
    return getIntersection(arr1, arr2).length > 0;
}

export function normalizeIndex(index: number, arrLength: number): number {
    let iterationsCount = 0;
    if (index < 0) {
        do {
            index += arrLength;
            iterationsCount++;
        } while (index < 0)
    } else if (index > arrLength - 1 && iterationsCount < 10000) {
        do {
            index -= arrLength;
            iterationsCount++;
        } while (index > arrLength - 1 && iterationsCount < 10000)
    }

    return index;
}

export function sliceArrayLoop<T> (arr: T[], startIndex: number, size: number): T[] {
    let pull = [...arr];
    startIndex = normalizeIndex(startIndex, arr.length);

    while (pull.length < startIndex + size) {
        pull = [...pull, ...arr];
    }
    return pull.slice(startIndex, startIndex + size);
}

export function getUnique<T> (arr: T[]): T[] {
    return Array.from(new Set(arr));
}

export function getReverse<T>(arr: T[]): T[] {
    return [...arr].reverse();
}