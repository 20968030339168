import { FCX } from '@models';
import Modal from '@components/Modal';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@components/Button';
import TextInput from '@components/TextInput';
import cn from 'classnames';
import { equalInLC } from '@core/utils/string';
import { ButtonType } from '@components/Button/Button';
import { Route } from '@core/routes/Route';

const EditTitlePopup: FCX<{
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
    appliedValue: string;
    applyNewValue: (value: string) => void;
    isInitialValueMode: boolean;
}> = ({
    isOpened,
    setIsOpened,
    appliedValue,
    applyNewValue,
    isInitialValueMode,
}) => {
    const [value, setValue] = useState('');
    const [isValidationShown, setIsValidationShown] = useState(false);

    useEffect(() => {
        setValue(appliedValue);
    }, [appliedValue]);

    useEffect(() => {
        if (isInitialValueMode) setIsOpened(true);
    }, [isInitialValueMode, setIsOpened]);

    const isValueValid = useMemo(() => value.trim() !== '', [value]);

    const onSubmit = useCallback(() => {
        if (!isValueValid) {
            setIsValidationShown(true);
        } else {
            setIsValidationShown(false);
            applyNewValue(value);
            setIsOpened(false);
        }
    }, [value, applyNewValue, isValueValid, setIsOpened])

    const handleInputKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (equalInLC(event.code, 'enter')) {
                onSubmit();
            }
        },
        [onSubmit]
    );

    if (!isOpened) return null;

    return (
        <Modal
            onClose={() => {
                setIsOpened(false);
                setValue(appliedValue);
            }}
            closeOnOutsideClick={!isInitialValueMode}
            hasCloseButton={!isInitialValueMode}
            style={{
                width: 400,
            }}
        >
            <div
                className="font-medium"
                style={{
                    fontSize: 30,
                    lineHeight: '40px',
                    marginBottom: 10,
                }}
            >
                {isInitialValueMode ? 'Give it a name' : 'Edit name'}
            </div>
            <div style={{ position: 'relative' }}>
                <TextInput
                    value={value}
                    setValue={setValue}
                    onKeyDown={handleInputKeyDown}
                    placeholder="Type something"
                    autoFocus
                    className={cn(
                        isValidationShown && !isValueValid && 'border-color-red'
                    )}
                    style={{
                        width: '100%',
                        marginBottom: 20,
                    }}
                />
                {isValidationShown && !isValueValid && (
                    <div
                        className="color-red font-medium"
                        style={{
                            textTransform: 'uppercase',
                            fontSize: 10,
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                        }}
                    >
                        can't be empty
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {isInitialValueMode ? (
                    <Button
                        type={ButtonType.Link}
                        modifiers={['blue', 'naked']}
                        link={Route.FoodWatch}
                    >
                        CANCEL
                    </Button>
                ) : (
                    <Button
                        modifiers={['blue', 'naked']}
                        onClick={() => setIsOpened(false)}
                    >
                        CANCEL
                    </Button>
                )}
                &nbsp;
                <Button
                    modifiers={['green']}
                    onClick={onSubmit}
                >
                    SAVE
                </Button>
            </div>
        </Modal>
    )
}

export default EditTitlePopup;