import { FCX, ID } from '@models';
import { AutoSizer } from 'react-virtualized';
import { ReactNode, useCallback, useMemo } from 'react';
import cn from 'classnames';

export interface iBarChartItem {
    id: ID;
    title: string;
    value: number;
    valueLabelOverride?: ReactNode;
}

const BarChart: FCX<{
    data: iBarChartItem[];
    valueFormatter: (value: number) => ReactNode;
    legend?: ReactNode;
    stretch?: boolean;
    itemHeight?: number;
    sizer?: number;
    norm?: {
        value: number;
        label: ReactNode;
    };
}> = ({
    data,
    legend,
    valueFormatter,
    style,
    className,
    stretch,
    itemHeight,
    sizer,
    norm,
}) => {
    const totalCount = useMemo(
        () => data.reduce((acc, i) => i.value + acc, 0),
        [data]
    );

    const getBarSize = useCallback(
        (value: number): number => {
            if (sizer) {
                return Math.round((value / sizer) * 100);
            }
            return Math.round((value / totalCount) * 100);
        },
        [totalCount, sizer]
    );

    const getBarSizeReverse = useCallback(
        (value: number): number => {
            if (sizer) {
                return Math.round((sizer / value) * 100);
            }
            return Math.round((totalCount / value) * 100);
        },
        [totalCount, sizer]
    );

    return (
        <AutoSizer>
            {({ height, width }) => (
                <div
                    className={cn("BarChart", className)}
                    style={{ ...style, height, width }}
                >
                    <div className="BarChart__legend">
                        {legend}
                    </div>
                    <div className={cn("BarChart__items", stretch && 'is-stretch')}>
                        {data.map(i => (
                            <div
                                key={i.id}
                                className="BarChart__item"
                                style={{ height: itemHeight}}
                            >
                                <div className="BarChart__item-title">
                                    {i.title}
                                </div>
                                <div className="BarChart__item-value-bar">
                                    <div
                                        className="BarChart__item-bar"
                                        style={{
                                            width: `${getBarSize(i.value)}%`,
                                            backgroundSize: `${getBarSizeReverse(i.value)}% 100%`,
                                        }}
                                    />
                                </div>
                                <div className="BarChart__item-value">
                                    {i.valueLabelOverride || valueFormatter(i.value)}
                                </div>
                            </div>
                        ))}
                        {!!norm && (
                            <div className="BarChart__norm">
                                <div className="BarChart__item-title"/>
                                <div className="BarChart__item-value-bar">
                                    <div className="BarChart__norm-value" style={{ left: `${getBarSize(norm.value)}%`}}>
                                        <div className="BarChart__norm-label">
                                            {norm.label}
                                        </div>
                                    </div>
                                </div>
                                <div className="BarChart__item-value"/>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </AutoSizer>
    );
};

export default BarChart;