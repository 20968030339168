import { CSSProperties, FC, ReactNode } from 'react';

export type ID = number | string;

export enum Sort {
    Asc = 0,
    Desc = 1,
}

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>

export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

export interface iOptionBase {
    id: ID;
    title: string | ReactNode;
    renderTitle?: string | ReactNode;
    isDisabled?: boolean;
    subTitle?: string;
}

export interface iOption extends iOptionBase {
    title: string;
    subItems?: iOption[];
}

export interface iOptionGroup extends iOption {
    subItems: iOption[];
}

export interface iSortingOption {
    id: ID;
    name: string;
    defaultDirection?: Sort;
}

export interface iKeyword extends iOption {
    includes: string[];
    excludes: string[];
}

export interface iChain extends iOption {
    segmentId: ID;
    defaultPresetId?: ID;
}

export interface FCAdditionalProps {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
    id?: string;
}

export type FCX<T = {}> = FC<FCAdditionalProps & T>

export interface iTableSorting {
    id: ID;
    direction: Sort;
}

export type NormType = 'all' | 'category' | 'segment' | 'chain';

export enum Month {
    Jan,
    Feb,
    Mar,
    Apr,
    May,
    Jun,
    Jul,
    Aug,
    Sep,
    Oct,
    Now,
    Dec,
}

export enum MonthStr {
    Jan = 'Jan',
    Feb = 'Feb',
    Mar = 'Mar',
    Apr = 'Apr',
    May = 'May',
    Jun = 'Jun',
    Jul = 'Jul',
    Aug = 'Aug',
    Sep = 'Sep',
    Oct = 'Oct',
    Now = 'Now',
    Dec = 'Dec',
}

export function getMonthIndexFromString(monthString: string): number | undefined {
    switch (monthString.trim().toLowerCase().substring(0, 3)) {
        case 'jan':
            return Month.Jan + 1;
        case 'feb':
            return Month.Feb + 1;
        case 'mar':
            return Month.Mar + 1;
        case 'apr':
            return Month.Apr + 1;
        case 'may':
            return Month.May + 1;
        case 'jun':
            return Month.Jun + 1;
        case 'jul':
            return Month.Jul + 1;
        case 'aug':
            return Month.Aug + 1;
        case 'sep':
            return Month.Sep + 1;
        case 'oct':
            return Month.Oct + 1;
        case 'nov':
            return Month.Now + 1;
        case 'dec':
            return Month.Dec + 1;
        default:
            return -1;
    }
}

export function getMonthStrByIndex(index: number): string {
    switch (index) {
        case 1:
            return MonthStr.Jan;
        case 2:
            return MonthStr.Feb;
        case 3:
            return MonthStr.Mar;
        case 4:
            return MonthStr.Apr;
        case 5:
            return MonthStr.May;
        case 6:
            return MonthStr.Jun;
        case 7:
            return MonthStr.Jul;
        case 8:
            return MonthStr.Aug;
        case 9:
            return MonthStr.Sep;
        case 10:
            return MonthStr.Oct;
        case 11:
            return MonthStr.Now;
        case 12:
            return MonthStr.Dec;
        default:
            return '';
    }
}

export enum Season {
    Winter = 'Winter',
    Spring = 'Spring',
    Summer = 'Summer',
    Fall = 'Fall',
}

export function getSeasonStrByIndex(index: number): string {
    switch (index) {
        case 0:
            return Season.Winter;
        case 1:
            return Season.Spring;
        case 2:
            return Season.Summer;
        case 3:
            return Season.Fall;
        default:
            return '';
    }
}

export function getSeasonStartMonth(season: Season): string {
    switch (season) {
        case Season.Winter:
            return 'Dec';
        case Season.Spring:
            return 'Mar';
        case Season.Summer:
            return 'Jun';
        case Season.Fall:
            return 'Sep';
        default:
            return '';
    }
}

export function getSeasonEndMonth(season: Season): string {
    switch (season) {
        case Season.Winter:
            return 'Feb';
        case Season.Spring:
            return 'May';
        case Season.Summer:
            return 'Aug';
        case Season.Fall:
            return 'Nov';
        default:
            return '';
    }
}

export function getSeasonMonthsFull(season: Season): string[] {
    switch (season) {
        case Season.Winter:
            return ['December', 'January', 'February'];
        case Season.Spring:
            return ['March', 'April', 'May'];
        case Season.Summer:
            return ['June', 'July', 'August'];
        case Season.Fall:
            return ['September', 'October', 'November'];
        default:
            return [];
    }
}

export function getSeasonByMonth(month: string): Season {
    switch (month.toLowerCase()) {
        case 'december':
        case 'january':
        case 'february':
            return Season.Winter;
        case 'march':
        case 'april':
        case 'may':
            return Season.Spring;
        case 'june':
        case 'july':
        case 'august':
            return Season.Summer;
        case 'september':
        case 'october':
        case 'november':
            return Season.Fall;
        default:
            return Season.Winter;
    }
}


export function getSeasonIndex(season: Season): number | undefined {
    return [Season.Winter, Season.Spring, Season.Summer, Season.Fall].indexOf(season);
}

export interface iKeyValue {
    key: string;
    value: number;
}

export enum UnitType {
    Count,
    Pct,
}

export enum Timeframe {
    Year = 'year',
    Season = 'season',
    Month = 'month',
}

export function getTimeframeEnumValue(timeframe: Timeframe): 1 | 2 | 3 {
    switch (timeframe) {
        case Timeframe.Month:
            return 1;
        case Timeframe.Season:
            return 2;
        case Timeframe.Year:
            return 3;
    }
}

export interface iChartItemShort {
    id: ID;
    title: string;
    color: string;
}
