const axisSteps = [1, 1.5, 2, 2.5, 5];
const approximateLabelsCount = 5;

export function getAxisValues(maxValue: number, minValue: number = 0): number[] {
    let labelsCount = approximateLabelsCount;
    let stepIndex = 0;
    let stepMultiplier = 1;
    const bottomShift = Math.max(0, minValue - (minValue % 5) - 5);
    const topValue = maxValue - bottomShift;

    while (axisSteps[stepIndex] * stepMultiplier * (labelsCount - 1) <= topValue) {
        if (stepIndex === axisSteps.length - 1) {
            stepIndex = 0;
            stepMultiplier *= 10;
        } else {
            stepIndex += 1;
        }
    }

    const result =  Array(labelsCount)
        .fill(0)
        .map((_, index) => axisSteps[stepIndex] * stepMultiplier * index + bottomShift);

    while (maxValue < result[result.length - 2] && result.length > 2) {
        result.pop();
    }

    while (result[1] < minValue && result.length > 2) {
        result.shift();
    }

    return result.reverse();
}

export interface iPoint {
    x: number;
    y: number;
}

function line(pointA: iPoint, pointB: iPoint) {
    const lengthX = pointB.x - pointA.x
    const lengthY = pointB.y - pointA.y
    return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
    }
}

const SMOOTHING = 0.15;

function controlPoint(current: iPoint, previous: iPoint, next: iPoint, reverse?: boolean): iPoint {

    const p = previous || current;
    const n = next || current;

    const o = line(p, n);

    const angle = o.angle + (reverse ? Math.PI : 0);
    const length = o.length * SMOOTHING;

    const x = current.x + Math.cos(angle) * length
    const y = current.y + Math.sin(angle) * length
    return { x, y };
}

function bezierCommand(point: iPoint, i: number, points: iPoint[]): string {
    const cps = controlPoint(points[i - 1], points[i - 2], point);
    const cpe = controlPoint(point, points[i - 1], points[i + 1], true);

    return `C ${cps.x},${cps.y} ${cpe.x},${cpe.y} ${point.x},${point.y}`;
}

export function getSvgPath(points: iPoint[]): string {
    return points.reduce((acc, point, i, a) =>
            i === 0
                ? `M ${point.x},${point.y}`
                : `${acc} ${bezierCommand(point, i, a)}`
        , '');
}