import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import BreadCrumbs from '@components/BreadCrumbs';
import { Route } from '@core/routes/Route';
import HeaderBar from '@components/HeaderBar';
import Wrapper from '@components/Wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { ID, iOption } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import Field from '@components/Field';
import Select from '@components/Select';
import { equalInLC, includesInLC } from '@core/utils/string';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import { FaQuestionCircle } from 'react-icons/fa';
import BenchmarkTooltip from '@pages/Benchmark/BenchmarkTooltip';
import { MainMetrics } from '@models/MainMetrics';
import Button from '@components/Button';
import { iBenchmarkRequestData, useBenchmark } from '@api/useBenchmark';
import { getFormattedPrice, getFormattedThousand } from '@core/utils/number';
import BenchmarkCard from '@pages/Benchmark/BenchmarkCard';
import { Tooltip } from 'react-tooltip';
import Preloader from '@components/Preloader';
import { diff } from 'deep-object-diff';
import { components } from '@api/api';
import Footer from '@vms/Footer';

enum ImageType {
    Image = 1,
    NoImage,
    All
}

const imageTypeOptions: iOption[] = [
    { id: ImageType.All, title: 'All items' },
    { id: ImageType.NoImage, title: 'Without images' },
    { id: ImageType.Image, title: 'With images' },
];

const METRICS: iOption[] = [
    { id: MainMetrics.UnbrandedPI, title: 'Unbranded PI' },
    { id: MainMetrics.BrandedPI, title: 'Branded PI' },
    { id: MainMetrics.Uniqueness, title: 'Uniqueness' },
    { id: MainMetrics.Frequency, title: 'Frequency' },
    { id: MainMetrics.Draw, title: 'Draw' },
    { id: MainMetrics.Value, title: 'Value' },
];

const BenchmarkContent: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { search } = useLocation();
    const navigate = useNavigate();
    const {
        options,
        defaultValue: {
            among: defaultAmongValue,
        },
    } = useFiltersContext();

    const id = useMemo((): ID | null => (Number(qs.parse(search).itemId)) || null, [search]);

    const allMonthOption = useMemo((): iOption => {
        return options.dateWithinMonth.find(i => includesInLC(i.title, 'all'))!;
    }, [options]);

    const allItemsNormOption = useMemo(() => options.norms.find(i => includesInLC(i.title, 'all'))!, [options]);
    const segmentNormOption = useMemo(() => options.norms.find(i => includesInLC(i.title, 'segment'))!, [options]);
    const categoryNormOption = useMemo(() => options.norms.find(i => includesInLC(i.title, 'category'))!, [options]);
    const chainNormOption = useMemo(() => options.norms.find(i => includesInLC(i.title, 'chain'))!, [options]);

    const [among, setAmong] = useState<ID>(defaultAmongValue);
    const [imageType, setImageType] = useState<ID>(ImageType.All);
    const [date, setDate] = useState<ID>(allMonthOption.id);
    const [initialMetrics, setInitialMetrics] = useState<string[]>(Array(6).fill(''));
    const [metrics, setMetrics] = useState<string[]>(Array(6).fill(''));
    const [appliedMetrics, setAppliedMetrics] = useState<Array<number | undefined> | null>(null);
    const [compareToType, setCompareToType] = useState<ID>(allItemsNormOption.id);
    const [compareToId, setCompareToId] = useState<ID | null>(null);
    const [appliedCompareToType, setAppliedCompareToType] = useState<ID>(0);
    const [appliedCompareToId, setAppliedCompareToId] = useState<ID>(0);

    const benchmarkRequestData = useMemo((): iBenchmarkRequestData => {
        return {
            metrics: appliedMetrics,
            chainId: id,
            withinMonth: date,
            among,
            imageType,
            compareTo: {
                typeId: appliedCompareToType,
                id: appliedCompareToId,
            },
        };
    }, [appliedMetrics, id, date, among, imageType, appliedCompareToType, appliedCompareToId]);

    const { data, isLoading } = useBenchmark(benchmarkRequestData);

    useEffect(() => {
        if (data && !isLoaded) {
            setIsLoaded(true);
        }
    }, [data, isLoaded]);

    useEffect(() => {
        if (data?.item) {
            const strings = [
                !data.item.unbrandedPi ? '' : `${Math.round(data.item.unbrandedPi as number)}`,
                !data.item.brandedPi ? '' : `${Math.round(data.item.brandedPi as number)}`,
                !data.item.uniqueness ? '' : `${Math.round(data.item.uniqueness as number)}`,
                !data.item.frequency ? '' : `${Math.round(data.item.frequency as number)}`,
                !data.item.draw ? '' : `${Math.round(data.item.draw as number)}`,
                !data.item.value ? '' : `${Math.round(data.item.value as number)}`,
            ];
            setMetrics(strings);
            setInitialMetrics(strings);
        }
    }, [data]);

    const metricWidgets = useMemo((): components["schemas"]["Graph"][] => {
        if (data?.benchmark) {
            const unbrandedPIData = data.benchmark.find(i => equalInLC(i.indexName || '', 'Unbranded PI'));
            const brandedPIData = data.benchmark.find(i => equalInLC(i.indexName || '', 'Branded PI'));
            const uniquenessData = data.benchmark.find(i => equalInLC(i.indexName || '', 'Uniqueness'));
            const frequencyData = data.benchmark.find(i => equalInLC(i.indexName || '', 'Frequency'));
            const drawData = data.benchmark.find(i => equalInLC(i.indexName || '', 'Draw'));
            const valueData = data.benchmark.find(i => equalInLC(i.indexName || '', 'Value'));

            const result = [];

            if (appliedMetrics) {
                if (unbrandedPIData && appliedMetrics[0] !== undefined && appliedMetrics[0] >= 0) result.push(unbrandedPIData);
                if (brandedPIData && appliedMetrics[1] !== undefined && appliedMetrics[1] >= 0) result.push(brandedPIData);
                if (uniquenessData && appliedMetrics[2] !== undefined && appliedMetrics[2] >= 0) result.push(uniquenessData);
                if (frequencyData && appliedMetrics[3] !== undefined && appliedMetrics[3] >= 0) result.push(frequencyData);
                if (drawData && appliedMetrics[4] !== undefined && appliedMetrics[4] >= 0) result.push(drawData);
                if (valueData && appliedMetrics[5] !== undefined && appliedMetrics[5] >= 0) result.push(valueData);
            } else if (initialMetrics) {
                if (unbrandedPIData && initialMetrics[0] !== '') result.push(unbrandedPIData);
                if (brandedPIData && initialMetrics[1] !== '') result.push(brandedPIData);
                if (uniquenessData && initialMetrics[2] !== '') result.push(uniquenessData);
                if (frequencyData && initialMetrics[3] !== '') result.push(frequencyData);
                if (drawData && initialMetrics[4] !== '') result.push(drawData);
                if (valueData && initialMetrics[5] !== '') result.push(valueData);
            }

            return result;
        }

        return [];
    }, [data, appliedMetrics, initialMetrics]);

    const updateMetric = useCallback((index: number, value: string) => {
        const formattedValue = value.replace(/\D/gi, '');

        setMetrics((oldValue) => [
            ...oldValue.slice(0, index),
            formattedValue !== '' ? `${Math.min(100, Number(formattedValue))}` : '',
            ...oldValue.slice(index + 1),
        ]);
    }, []);

    const onCompare = useCallback(() => {
        const preparedMetrics = metrics.map(i => i === '' ? undefined : Number(i));
        if (data?.item) {
            if (Array.from(Object.keys(diff(metrics, initialMetrics))).length > 0) {
                navigate(Route.Benchmark);
                setAppliedMetrics(preparedMetrics);
                setInitialMetrics(Array(6).fill(''))
            }
        } else {
            setAppliedMetrics(preparedMetrics);
        }
    }, [data, metrics, initialMetrics, navigate]);

    const updateCompareToType = useCallback((id: ID) => {
        setCompareToType(id);
        setCompareToId(null);
        setAppliedCompareToType(0);
        setAppliedCompareToId(0);
    }, []);

    const updateCompareToId = useCallback((id: ID) => {
        setCompareToId(id);
        setAppliedCompareToType(compareToType);
        setAppliedCompareToId(id);
    }, [compareToType]);

    const norms = useMemo(() => {
        return options.norms.map(o => ({
            ...o,
            title: o.title.replace(new RegExp('same ', 'i'), ''),
        }));
    }, [options]);

    return (
        <>
            <div className="Benchmark">
                <Wrapper>
                    <BreadCrumbs items={[
                        { link: Route.Home, title: 'Home', },
                        { link: '', title: 'Benchmark', },
                    ]}/>
                    <HeaderBar className="Benchmark__header-bar">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="font-medium"
                                style={{ fontSize: 20, lineHeight: '26px', }}
                            >
                                Benchmark your own concept
                            </div>
                        </div>
                    </HeaderBar>
                    <div className="section">
                        <div className="card Benchmark__card">
                            <div className="Benchmark__card-text">
                                Use this page to benchmark concepts you may have tested elsewhere. <br/>
                                Just enter your raw concept scores below.
                            </div>
                            {data?.item && (
                                <div className="Benchmark__card-title">
                                    {data.item.name} <span>({getFormattedPrice(data.item.price || 0)})</span>
                                </div>
                            )}
                            <div className="Benchmark__inputs">
                                {METRICS.map((metric, index) => (
                                    <div key={metric.id} className="BenchmarkMetric">
                                        <div className="BenchmarkMetric__label">
                                            {metric.title}
                                            <div
                                                data-tooltip-id={`benchmark-metric-${metric.id}`}
                                                className="BenchmarkMetric__icon"
                                            >
                                                <FaQuestionCircle/>
                                            </div>
                                            <BenchmarkTooltip metric={metric.id as MainMetrics}/>
                                        </div>
                                        <input
                                            className="BenchmarkMetric__input"
                                            value={metrics[index]}
                                            onChange={(event) => updateMetric(index, event.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div data-tooltip-id="benchmark-compare">
                                <Button
                                    modifiers={['blue']}
                                    isDisabled={metrics.every(i => i === '')}
                                    style={{
                                        height: 32,
                                        width: 250,
                                    }}
                                    onClick={onCompare}
                                >
                                    COMPARE
                                </Button>
                            </div>
                            {metrics.every(i => i === '') && (
                                <Tooltip
                                    id="benchmark-compare"
                                    variant="error"
                                >
                                    Specify at least one metric value
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    {isLoaded && (
                        <>
                            <HeaderBar className="Benchmark__header-bar">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        className="font-medium"
                                        style={{ fontSize: 20, lineHeight: '26px', }}
                                    >
                                        Norms benchmarks vs.&nbsp;
                                        {metricWidgets[0] ? getFormattedThousand(metricWidgets[0].totalConceptsCount, ' ') : '-'}
                                        &nbsp;items
                                    </div>
                                </div>
                            </HeaderBar>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 0',
                                borderBottom: '1px solid #ccc',
                                marginBottom: 15,
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Field label="compare to" inline>
                                        <Select
                                            options={norms}
                                            value={[compareToType]}
                                            onOptionClick={updateCompareToType}
                                            closeOnOptionClick
                                        />
                                    </Field>
                                    &nbsp;
                                    {compareToType === segmentNormOption.id && (
                                        <Select
                                            placeholder="All segments"
                                            options={options.segments}
                                            value={compareToId === null ? [] : [compareToId]}
                                            onOptionClick={updateCompareToId}
                                            closeOnOptionClick
                                        />
                                    )}
                                    {compareToType === categoryNormOption.id && (
                                        <Select
                                            placeholder="All categories"
                                            options={options.categories}
                                            value={compareToId === null ? [] : [compareToId]}
                                            onOptionClick={updateCompareToId}
                                            closeOnOptionClick
                                        />
                                    )}
                                    {compareToType === chainNormOption.id && (
                                        <Select
                                            placeholder="All chains"
                                            hasSearch
                                            options={options.chains}
                                            value={compareToId === null ? [] : [compareToId]}
                                            onOptionClick={updateCompareToId}
                                            closeOnOptionClick
                                        />
                                    )}
                                    {compareToType !== 0 && (
                                        <div
                                            style={{
                                                marginLeft: 10,
                                                color: '#767676',
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => updateCompareToType(allItemsNormOption.id)}
                                        >
                                            <FaFilterCircleXmark style={{ width: 16, height: 16, marginRight: 5 }}/>
                                            Reset
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Field label="IMAGES " inline>
                                        <Select
                                            options={imageTypeOptions}
                                            value={[imageType]}
                                            onOptionClick={setImageType}
                                            closeOnOptionClick
                                        />
                                    </Field>
                                    <Field label="AMONG " inline>
                                        <Select
                                            options={options.respondentTypes}
                                            value={[among]}
                                            onOptionClick={setAmong}
                                            useSubItems
                                            closeOnOptionClick
                                        />
                                    </Field>
                                    <Field label="DATE" inline>
                                        <Select
                                            options={options.dateWithinMonth}
                                            value={[date]}
                                            onOptionClick={setDate}
                                            closeOnOptionClick
                                        />
                                    </Field>
                                </div>
                            </div>
                            {isLoading ? (
                                <Preloader backgroundColor="#fff"/>
                            ) : (
                                <>
                                    {data?.benchmark && (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            {metricWidgets.map((item) => {
                                                return (
                                                    <BenchmarkCard
                                                        key={JSON.stringify(item)}
                                                        metric={{
                                                            index: item.index,
                                                            dataString: item.dataString,
                                                            indexName: item.indexName,
                                                            median: item.median,
                                                            percentile: item.percentile,
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Wrapper>
            </div>
            <Footer/>
        </>
    );
};

const Benchmark: FC = () => {
    const { isLoaded } = useFiltersContext();
    if (!isLoaded) return null;
    return <BenchmarkContent/>;
};

export default Benchmark;