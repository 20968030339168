import { useState } from 'react';
import { ID } from '@models';
import { components, paths } from '@api/api';
import { api } from '@hooks/useFetch';
import { downloadReport } from '@core/utils/file';
import { useFiltersContext } from '@core/FiltersContext';
import { PptFormat } from '@api/usePptReport';

type RequestPayloadType = components['schemas']['ComparisonChainExportFilterModel'];

export function useChainPptReport () {
    const {
        value: { categories, date }
    } = useFiltersContext();
    const [isLoading, setIsLoading] = useState(false);

    const load = async (ids: ID[], axisMetrics: ID[], format: PptFormat) => {
        setIsLoading(true);
        const url: keyof paths = '/api/Export/PptComparisonChainNew';
        const data: RequestPayloadType = {
            filterModel: {
                categoryIds: categories as number[],
                chainsIds: ids as number[],
                date: {
                    range: date.withinMonth as components['schemas']['Date']['range'],
                    from: date.period.from,
                    to: date.period.to,
                },
            },
            userAxis: axisMetrics as RequestPayloadType['userAxis'],
            format,
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}