import { FCX, ID } from '@models';
import Modal from '@components/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@components/Button';
import { useFiltersContext } from '@core/FiltersContext';
import Checkbox from '@components/Checkbox';

const EXCLUDED_CATEGORIES_IDS: ID[] = [16, 17];

const ExportByCategoryPopup: FCX<{
    close: () => void;
    onConfirm: (id: ID) => void;
    description: string;
}> = ({
    close,
    onConfirm,
    description,
}) => {
    const { options: { categories } } = useFiltersContext();
    const filteredCategories = useMemo(
        () => categories.filter(({ id }) => !EXCLUDED_CATEGORIES_IDS.includes(id)),
        [categories],
    );

    const [value, setValue] = useState<ID>(filteredCategories[0].id || 0);

    useEffect(() => {
        setValue(filteredCategories[0]?.id);
    }, [filteredCategories]);

    return (
        <Modal
            onClose={close}
            closeOnOutsideClick
            hasCloseButton
            style={{
                width: 600,
            }}
        >
            <div
                className="font-medium"
                style={{
                    fontSize: 30,
                    lineHeight: '40px',
                    marginBottom: 10,
                }}
            >
                Download Report
            </div>
            <div>
                {description}
            </div>
            <br/>
            <div style={{ columns: 2 }}>
                {filteredCategories.map(({ id , title}) => (
                    <Checkbox
                        key={id}
                        isChecked={value === id}
                        onSelect={() => setValue(id)}
                    >
                        {title}
                    </Checkbox>
                ))}
            </div>
            <br/>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button
                    onClick={close}
                    modifiers={['blue', 'naked']}
                    style={{ marginRight: 4, }}
                >
                    CANCEL
                </Button>
                <Button
                    onClick={() => {
                        onConfirm(value);
                        close();
                    }}
                    modifiers={['green']}
                >
                    DOWNLOAD
                </Button>
            </div>
        </Modal>
    );
};

export default ExportByCategoryPopup;