import { FCX, iOption } from '@models';
import { BsCheck } from 'react-icons/bs';
import { useSelectedOptions } from '@hooks/useSelectedOptions';
import cn from 'classnames';
import { memo, useMemo } from 'react';

const TableRowCheck: FCX<{
    option: iOption;
    isPermanentlyShown?: boolean;
    onToggle?: () => void;
}> = ({
    option,
    isPermanentlyShown,
    onToggle,
}) => {
    const {
        items,
        toggleItem,
    } = useSelectedOptions();

    const isChecked = useMemo(() => items.some(i => i.id === option.id), [items, option]);

    return (
        <div
            className={cn(
                "TableRowCheck",
                isChecked && 'is-checked',
                isPermanentlyShown && 'is-permanently-shown',
            )}
            onClick={() => {
                toggleItem(option);
                if (onToggle) onToggle();
            }}
        >
            <BsCheck style={{ width: '100%', height: '100%', }}/>
        </div>
    );
}

export default memo(TableRowCheck);