import { FCX } from '@models';
import cn from 'classnames';
import ReactSlider from 'react-slider';
import { AiFillStar } from 'react-icons/ai';

const StarsRangeSlider: FCX<{
    value: number[];
    onChange: (value: number[]) => void;
}> = ({
    value,
    onChange,
    style,
    className,
    ...attrs
}) => {
    return (
        <div
            {...attrs}
            className={cn("RangeSlider", "StarsRangeSlider", className)}
            style={style}
        >
            <ReactSlider
                marks={[1, 2, 3, 4, 5]}
                min={1}
                max={5}
                step={1}
                value={value}
                onChange={onChange}
                minDistance={0}
                pearling
                renderThumb={(props) => (
                    <div
                        {...props}
                        className={cn(
                            props.className,
                            "RangeSlider__thumb",
                        )}
                    >
                        <div className="RangeSlider__thumb-circle"/>
                    </div>
                )}
                renderTrack={(props) => (
                    <div
                        {...props}
                        className={cn(
                            props.className,
                            "RangeSlider__track",
                        )}
                    />
                )}
                renderMark={(props) => (
                    <div
                        {...props}
                        className={cn(
                            props.className,
                            "RangeSlider__mark",
                            +props.key! >= value[0] && +props.key! <= value[1] && 'is-active'
                        )}
                    >
                        <AiFillStar style={{ width: 12, height: 12, }}/>
                    </div>
                )}
            />
        </div>
    );
};

export default StarsRangeSlider;