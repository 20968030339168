import { FCX } from '@models';
import { useRef, memo, useMemo, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { WindowScroller } from 'react-virtualized';
import { FixedSizeList } from 'react-window';
import useWindowSizeDebounced from '@hooks/useWindowSizeDebounced';
import TableRow from './TableRow';
import TableHead from './TableHead';
import { InView } from 'react-intersection-observer';
import TableRowLoading from '@components/Table/TableRowLoading';
import DataFallback from '@components/DataFallback';
import ButtonClipboardCopy from '@components/ButtonClipboardCopy';

const Table: FCX<{
    scrollerKey?: any;
    rowsCount: number;
    onLoadMore?: () => void;
    isLoading?: boolean;
    copyTable?: {
        isLoading: boolean;
        data: string | null;
    };
    noResultsTitle?: string;
    noResultsDescription?: string;
}> = ({
    scrollerKey,
    rowsCount,
    isLoading,
    onLoadMore,
    copyTable,
    noResultsTitle= "Your search conditions do not match any results.",
    noResultsDescription= "Please make sure that your keywords are spelled correctly, at least one Chain Segment selected and at least one Item Category selected",
}) => {
    const [, wHeight] = useWindowSizeDebounced();
    const listRef = useRef<HTMLDivElement>(null);
    const [scrollerKeyState, setScrollerKeyState] = useState(null);


    const itemsCount = useMemo(() => {
        if (!isLoading) return rowsCount;
        return Math.max(30, rowsCount);
    }, [isLoading, rowsCount]);

    useEffect(() => {
        setTimeout(() => {
            setScrollerKeyState(scrollerKey);
        }, 500);
    }, [scrollerKey]);

    return (
        <div className="Table">
            {!!copyTable && (
                <ButtonClipboardCopy
                    style={{ top: 20 }}
                    onClick={() => copy(copyTable.data || '', { format: 'text/plain' })}
                    isBusy={copyTable.isLoading || copyTable.data === null}
                />
            )}
            <TableHead/>
            <WindowScroller
                key={scrollerKeyState}
                onScroll={
                    // @ts-ignore
                    ({ scrollTop }) => listRef.current && listRef.current.scrollTo(scrollTop)
                }
            >
                {() => <div/>}
            </WindowScroller>
            <FixedSizeList
                // @ts-ignore
                ref={listRef}
                itemSize={48}
                width="100%"
                height={wHeight}
                itemCount={itemsCount}
                className="window-scroller-override"
            >
                {({ index, style }) => (
                    (isLoading && rowsCount === 0) ? (
                        <TableRowLoading style={style} index={index}/>
                    ) : (
                        <TableRow style={style} index={index}/>
                    )
                )}
            </FixedSizeList>
            {!isLoading && rowsCount === 0 && (
                <DataFallback
                    title={noResultsTitle}
                    subTitle={noResultsDescription}
                />
            )}
            {rowsCount > 0 && onLoadMore && (
                <InView
                    as="div"
                    style={{
                        pointerEvents: 'none',
                        height: 900,
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                    }}
                    onChange={(inView) => {
                        if (inView) onLoadMore();
                    }}
                />
            )}
        </div>
    );
};

export default memo(Table);