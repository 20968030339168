import { useFiltersContext } from '@core/FiltersContext';
import { useMemo } from 'react';
import { components } from '@api/api';

type ChainPerformanceFilters = components['schemas']['ChainPerformanceFilterModel'];

export function useChainPerformanceFilters() {
    const {
        defaultFiltersPayload,
        value: { among },
    } = useFiltersContext();
    return useMemo((): ChainPerformanceFilters => ({
        date: defaultFiltersPayload.date,
        categoryIds: defaultFiltersPayload.categoryIds,
        segmentIds: defaultFiltersPayload.segmentIds,
        respondentTypes: [among] as ChainPerformanceFilters['respondentTypes'],
        includeAll: defaultFiltersPayload.includeAll,
        includeAny: defaultFiltersPayload.includeAny,
        excludeAll: defaultFiltersPayload.excludeAll,
    }), [defaultFiltersPayload, among]);
}