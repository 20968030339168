import { iChain, ID, iOption } from '@models/index';
import { components } from '@api/api';
import { defaultSort, getUnique } from '@core/utils/array';
import { getAverage } from '@core/utils/number';

export interface iChainProfileComparisonValue {
    countsByCategories: Map<ID, number>;
    pctByCategories: Map<ID, number>;
    totalCount: number;
    rank?: number;
    countDiff?: number;
}

export interface iChainProfileComparisonItem extends iChainProfileComparisonValue {
    id: ID;
    title: string;
}

export interface iChainProfileComparisonData {
    isEmpty: boolean;
    isCurrentChainIncluded: boolean
    currentChain: iChainProfileComparisonItem;
    otherChains: iChainProfileComparisonItem[];
    group: iChainProfileComparisonValue & {
        avgCountsByCategories: Map<ID, number>;
        avgPctByCategories: Map<ID, number>;
        avgTotalCount: number;
    };
}

const DEFAULT_VALUE: iChainProfileComparisonValue = {
    countsByCategories: new Map(),
    pctByCategories: new Map(),
    totalCount: 0,
    rank: 0,
    countDiff: 0,
}

type ApiModel = components["schemas"]["ChainsShareItem"][];

interface iData {
    apiModel?: ApiModel;
    currentChainId?: ID;
    isCurrentChainInGroup?: boolean;
    categories?: iOption[];
    chains?: iChain[];
}

export class ChainProfileComparisonData implements iChainProfileComparisonData {
    static defaultData: iChainProfileComparisonData = {
        isEmpty: true,
        isCurrentChainIncluded: false,
        currentChain: {
            ...DEFAULT_VALUE,
            id: 0,
            title: '',
        },
        otherChains: [],
        group: {
            ...DEFAULT_VALUE,
            avgCountsByCategories: new Map(),
            avgPctByCategories: new Map(),
            avgTotalCount: 0,
        },
    }

    isEmpty = ChainProfileComparisonData.defaultData.isEmpty;
    isCurrentChainIncluded = ChainProfileComparisonData.defaultData.isCurrentChainIncluded;
    currentChain = ChainProfileComparisonData.defaultData.currentChain;
    otherChains = ChainProfileComparisonData.defaultData.otherChains;
    group = ChainProfileComparisonData.defaultData.group;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel && data.currentChainId && data.categories && data.chains) {
                this.mapFromApiModel(
                    data.apiModel,
                    data.currentChainId,
                    data.categories,
                    data.chains,
                    data.isCurrentChainInGroup || false,
                );
            }
        }
    }

    private setData(model: iChainProfileComparisonData) {
        Object.assign(this, model);
    }

    static getPctAndTotalFromCounts (counts: Map<ID, number>, categories: iOption[]) {
        const totalCount: number = Array.from(counts.values()).reduce((acc, value) => acc + value, 0);

        const pct = new Map<ID, number>();

        categories.forEach(category => {
            pct.set(
                category.id,
                (counts.get(category.id)! / totalCount) * 100,
            )
        });

        return {
            totalCount,
            pct,
        };
    }

    static getChainValue (data: ApiModel, chainId: ID, categories: iOption[]): iChainProfileComparisonValue {
        const counts = new Map<ID, number>();

        categories.forEach(category => {
            counts.set(
                category.id,
                data.find(i => i.categoryId === category.id && i.chainId === chainId)?.itemsCount || 0,
            )
        });

        const { pct, totalCount } = ChainProfileComparisonData.getPctAndTotalFromCounts(counts, categories);

        return {
            countsByCategories: counts,
            pctByCategories: pct,
            totalCount,
        };
    }

    static getGroupData (groupChains: iChainProfileComparisonItem[], categories: iOption[]): iChainProfileComparisonData['group'] {
        const counts = new Map<ID, number>();
        const avgCounts = new Map<ID, number>();
        const avgPct = new Map<ID, number>();

        categories.forEach(category => {
            counts.set(
                category.id,
                groupChains.reduce(
                    (acc, i) => {
                        return acc + (i.countsByCategories.get(category.id) || 0);
                    },
                    0,
                ),
            );

            avgCounts.set(
                category.id,
                getAverage(groupChains.map(i => i.countsByCategories.get(category.id) || 0)),
            )

            avgPct.set(
                category.id,
                getAverage(groupChains.map(i => i.pctByCategories.get(category.id) || 0)),
            )
        });

        const { pct, totalCount } = ChainProfileComparisonData.getPctAndTotalFromCounts(counts, categories);

        return {
            countsByCategories: counts,
            pctByCategories: pct,
            totalCount,
            avgCountsByCategories: avgCounts,
            avgPctByCategories: avgPct,
            avgTotalCount: Math.round(getAverage(groupChains.map(i => i.totalCount))),
        }
    }

    private mapFromApiModel (
        apiModel: ApiModel,
        currentChainId: ID,
        categories: iOption[],
        chains: iChain[],
        isCurrentChainInGroup: boolean,
    ) {
        const chainsIds = getUnique(apiModel.map(i => i.chainId)).filter(id => id !== currentChainId) as ID[];
        const currentChain = chains.find(i => i.id === currentChainId);
        const otherChains = chains.filter(i => chainsIds.includes(i.id));

        otherChains.sort((a, b) => defaultSort(a.title, b.title));

        if (currentChain) {
            const data: iChainProfileComparisonData = {
                ...ChainProfileComparisonData.defaultData,
                isEmpty: false,
                isCurrentChainIncluded: isCurrentChainInGroup,
            };

            const currentChainData: iChainProfileComparisonItem = {
                ...ChainProfileComparisonData.getChainValue(apiModel, currentChainId, categories),
                id: currentChainId,
                title: currentChain.title,
            };

            const otherChainsData: iChainProfileComparisonItem[] = otherChains.map(chain => ({
                id: chain.id,
                title: chain.title,
                ...ChainProfileComparisonData.getChainValue(apiModel, chain.id, categories),
            }));

            const allChains = [
                currentChainData,
                ...otherChainsData,
            ];

            allChains.sort((a, b) => {
                const aCount = a.totalCount;
                const bCount = b.totalCount;

                if (aCount !== bCount) return bCount - aCount;
                else return a.title < b.title ? -1 : 1;
            });

            currentChainData.rank = allChains.findIndex(i => i.id === currentChainId) + 1;

            otherChainsData.forEach(chainData => {
                chainData.countDiff = chainData.totalCount - currentChainData.totalCount;
                chainData.rank = allChains.findIndex(i => i.id === chainData.id) + 1;
            });

            data.currentChain = currentChainData;
            data.otherChains = otherChainsData;

            const groupChainsData = isCurrentChainInGroup ? allChains : otherChainsData;

            data.group = ChainProfileComparisonData.getGroupData(groupChainsData, categories);

            data.group.countDiff = data.group.totalCount - data.currentChain.totalCount;

            this.setData(data);
        }
    }
}