import { useState } from 'react';
import { api } from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { downloadReport } from '@core/utils/file';
import { useFiltersContext } from '@core/FiltersContext';
import { getTimeframeEnumValue, ID, Timeframe } from '@models';

export function useFoodFlavorsHistoricTrendPptReport(timeframe: Timeframe, indexType: ID | null, itemsIds: ID[]) {
    const [isLoading, setIsLoading] = useState(false);
    const {
        value: { among },
        defaultFiltersPayload,
    } = useFiltersContext();

    const load = async () => {
        setIsLoading(true);

        const url: keyof paths = '/api/Export/PptHistoricTrendFoodsFlavors';

        const data: components['schemas']['FoodsFlavorsFilterModel'] = {
            respondentTypes: [among] as components['schemas']['FoodsFlavorsFilterModel']['respondentTypes'],
            categoryIds: defaultFiltersPayload.categoryIds,
            segmentIds: defaultFiltersPayload.segmentIds,
            date: defaultFiltersPayload.date,
            chainId: defaultFiltersPayload.chainId,
            indexType: indexType as components['schemas']['FoodsFlavorsFilterModel']['indexType'],
            periodType: getTimeframeEnumValue(timeframe),
            keywords: itemsIds as number[],
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}