import { FCX, Sort } from '@models';
import { iSeasonalFood } from '@api/useSeasonalByFoods';
import { getSortingOptions } from '@core/constants';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';
import { iCategoryActivityChain } from '@api/useCategoyActivityByChains';

const MostActiveChainsTableSortingConfig: FCX<{
    sortingColumn: keyof iCategoryActivityChain;
    setSortingColumn: (key: keyof iCategoryActivityChain) => void;
    setSortingDirection: (value: Sort) => void;
    sortingDirection: Sort;
}> = ({
    sortingColumn,
    sortingDirection,
    setSortingColumn,
    setSortingDirection,
    children,
}) => {
    return (
        <TableSortingContext.Provider value={{
            value: {
                id: sortingColumn,
                direction: sortingDirection,
            },
            setValue: (value) => {
                setSortingColumn(value.id as keyof iCategoryActivityChain);
                setSortingDirection(value.direction);
            },
            options: getSortingOptions([
                'index',
                'count',
                'percentage',
            ]),
        }}>
            {children}
        </TableSortingContext.Provider>
    );
};

export default MostActiveChainsTableSortingConfig;