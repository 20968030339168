import useFetch, { api } from '@hooks/useFetch';
import { components } from '@api/api';
import { useCallback, useState } from 'react';
import { ID } from '@models';

interface iData {
    isLoading: boolean;
    data: components['schemas']['FoodWatchListItem'][];
    deleteItem: (id: ID) => Promise<any>;
}

export function useFoodWatch (): iData {
    const [isLoading, setIsLoading] = useState(false);
    const result = useFetch<components['schemas']['FoodWatchListItem'][]>({
        url: '/api/FoodWatch',
    });

    const deleteItem = useCallback(async (id: ID) => {
        setIsLoading(true);
        await api.request({
            url: `/api/FoodWatch/${id}`,
            method: 'delete',
        });
        setIsLoading(false);
        result.doFetch();
    }, [result])

    return {
        isLoading: isLoading || result.isLoading,
        data: result.data || [],
        deleteItem,
    };
}