import { FCX } from '@models';
import cn from 'classnames';
import ReactSlider from 'react-slider';

const RangeSlider: FCX<{
    value: number[];
    onChange: (value: number[]) => void;
    min: number;
    max: number;
    step: number;
}> = ({
    value,
    onChange,
    min,
    max,
    step,
    style,
    className,
    ...attrs
}) => {
    return (
        <div
            {...attrs}
            className={cn("RangeSlider", className)}
            style={style}
        >
            <ReactSlider
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={onChange}
                minDistance={step}
                pearling
                renderThumb={(props, state) => (
                    <div
                        {...props}
                        className={cn(
                            props.className,
                            "RangeSlider__thumb",
                        )}
                    >
                        <div className="RangeSlider__thumb-circle"/>

                        <div className="RangeSlider__thumb-value">
                            {state.value[state.index]}
                        </div>
                    </div>
                )}
                renderTrack={(props) => (
                    <div
                        {...props}
                        className={cn(
                            props.className,
                            "RangeSlider__track",
                        )}
                    />
                )}
            />
        </div>
    );
};

export default RangeSlider;