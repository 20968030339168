import { ID } from '@models';
import useFetch from '@hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import { components } from '@api/api';
import { useFiltersContext } from '@core/FiltersContext';
import { useCategoryActivityFilters } from '@core/selectors/useCategoryActivityFilters';

type Request = components['schemas']['CategoriesLaunchesRequest'];
type Response = components['schemas']['CategoriesLaunchesResponse'];

export interface iCategoryValue {
    id: ID;
    title: string;
    count: number;
    percentage: number;
}

export function useCategoryActivityLaunches() {
    const [data, setData] = useState<iCategoryValue[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const {
        options: { categories },
    } = useFiltersContext();

    const filter = useCategoryActivityFilters();

    const payload = useMemo((): Request => {
        return {
            filter,
        };
    }, [filter]);

    const result = useFetch<Response>({
        url: '/api/Category/Launches',
        method: 'post',
        payload,
    });

    useEffect(() => {
        if (result.isLoading) {
            setData([]);
            setTotalCount(0);
        }
    }, [result.isLoading]);

    useEffect(() => {
        if (!result.isLoading) {
            const totalCount = result.data?.categories?.reduce((acc, c) => acc + (c.count || 0), 0) || 0;
            setTotalCount(totalCount);
            setData(categories.map(c => {
                const item = result.data?.categories?.find(i => i.categoryId === c.id);
                const count = item?.count || 0;
                const percentage = totalCount === 0 ? 0 : (count / totalCount) * 100;

                return {
                    id: c.id,
                    title: c.title,
                    count,
                    percentage,
                };
            }));
        }
    }, [result.isLoading, result.data, categories]);

    return {
        isLoading: result.isLoading,
        data,
        totalCount,
    }
}