const LEGACY__getCoordByX = function(xPoint, values){

    for (var k = 0; k < values.length; k++) {
        if (values[k].x === xPoint) {

            return {
                x: values[k].x,
                y: values[k].y,
            };

        } else {

            if (!values[k + 1])
                return {
                    x: xPoint,
                    y: values[k].y,
                }

            if (values[k].x < xPoint && values[k + 1].x > xPoint) {
                var x = xPoint,
                    y1 = values[k].y,
                    x1 = values[k].x,
                    y2 = values[k + 1].y,
                    x2 = values[k + 1].x,

                    // f(x) = (y2 - y1)/(x2 - x1) * (x1 - x) + y1 = y
                    ///_cY = (y2 - y1)/(x2 - x1) * (x1 - x) + y1;
                    _cY = ((x * 100 - x1 * 100) * (y2 * 100 - y1 * 100) + (x2 * 100 - x1 * 100) * y1 * 100) / (x2 * 100 - x1 * 100);
                _cY = _cY / 100;

                return {
                    x: xPoint,
                    y: _cY,
                };
            }


        }
    }

};

export default LEGACY__getCoordByX;