import { FCX, ID, iOption } from '@models';
import VersusScatterChart from '@vms/VersusScatterChart';
import { useFiltersContext } from '@core/FiltersContext';
import { components } from '@api/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiltersVariant } from '@models/Filter';
import { SelectCustomSingle } from '@components/Select';
import { useChartItems } from '@api/useChartItems';
import { ChartItemType } from '@vms/VersusScatterChart/VersusScatterChart';
import SelectCustom from '@components/Select/SelectCustom';
import { iChartItem } from '@models/ChartItem';
import Button from '@components/Button';
import SelectOptionsAndColors from '@vms/SelectOptionsAndColors';
import { iOptionWithColor } from '@vms/SelectOptionsAndColors/SelectOptionsAndColors';
import { SidebarContentRow } from '@vms/ChartView/SidebarContentRow';
import Loader from '@components/Loader';
import { FaPlus } from 'react-icons/fa';

interface iColorOption {
    id: ID;
    color: string;
    value: ID[];
}

enum OptionType {
    Category,
    Segment,
    Chain,
    All,
}

const chartOptionTypes: iOption[] = [
    { id: OptionType.Category, title: 'Category' },
    { id: OptionType.Segment, title: 'Segment' },
    { id: OptionType.Chain, title: 'Chain' },
    { id: OptionType.All, title: 'All items' },
];

const COLORS: {
    [key: string]: string;
} = {
    concepts: '#f77c0f',
    initial: '#767676',
    default: '#00b0f0',
    'QSR': '#7c5034',
    'Fast Casual': '#ae0080',
    'Midscale': '#cf488c',
    'Casual Dining': '#fcbc11',
    'Fine Dining': '#d44a19',
    'C-Stores': '#447ebd',
};

function getNewColorSetting() {
    return {
        id: Date.now(),
        color: COLORS.default,
        value: [],
    };
}

const ChartView: FCX<{
    setTotalRecords?: (value: number) => void;
    setAppliedFilterPayload?: (value: components['schemas']['SearchFilterModel']) => void;
}> = ({
    setTotalRecords= () => null,
    setAppliedFilterPayload= () => null,
}) => {
    const {
        value: { among, concepts },
        options: {
            segments,
            categories,
            chains,
        },
        defaultFiltersPayload,
        variant,
    } = useFiltersContext();

    const filtersPayload: components['schemas']['FilterViewModel'] = useMemo(() => {
        return {
            ...defaultFiltersPayload,
            respondentTypes: [among] as components['schemas']['FilterViewModel']['respondentTypes'],
            conceptTypeIds: (variant === FiltersVariant.Homepage ? [1, ...concepts] : concepts) as number[],
        };
    }, [defaultFiltersPayload, variant, among, concepts]);

    const {
        isLoading,
        data,
        totalRecords,
    } = useChartItems(filtersPayload);

    useEffect(() => {
        if (!isLoading) {
            setTotalRecords(totalRecords || 0);
        }
    }, [isLoading, totalRecords, setTotalRecords]);

    useEffect(() => {
        setAppliedFilterPayload({ filter: filtersPayload });
    }, [filtersPayload, setAppliedFilterPayload]);

    const [appliedTypeId, setAppliedTypeId] = useState<OptionType>(OptionType.All);
    const [appliedColorSettings, setAppliedColorSettings] = useState<iColorOption[]>([]);
    const [selectedTypeId, setSelectedTypeId] = useState<OptionType>(OptionType.All);
    const [colorSettings, setColorSettings] = useState<iColorOption[]>([]);
    const selectedType = useMemo(() => {
        return chartOptionTypes.find(i => i.id === selectedTypeId);
    }, [selectedTypeId]);

    const property: keyof iChartItem | null = useMemo(() => {
        switch (appliedTypeId) {
            case OptionType.Segment:
                return 'segmentId';
            case OptionType.Category:
                return 'categoryId';
            case OptionType.Chain:
                return 'chainId';
            default:
                return null;
        }
    }, [appliedTypeId]);

    const getOptions = useCallback((index: number): iOptionWithColor[] => {
        let result: iOption[] = [];
        const settingValue = colorSettings[index].value;
        const restValues = [
            ...colorSettings.slice(0, index),
            ...colorSettings.slice(index + 1),
        ].flatMap(i => i.value);

        switch (selectedTypeId) {
            case OptionType.Segment: {
                result = segments
                    .map(i => ({
                        ...i,
                        color: COLORS[i.title],
                    }))
                    .filter(i => !i.isDisabled);
                break;
            }
            case OptionType.Category: {
                result = categories.filter(i => !i.isDisabled);
                break;
            }
            case OptionType.Chain: {
                result = chains;
                break;
            }
        }
        return result.map((i => ({
            ...i,
            isDisabled: restValues.includes(i.id) && !settingValue.includes(i.id),
        })));
    }, [
        selectedTypeId,
        segments,
        categories,
        chains,
        colorSettings,
    ]);

    useEffect(() => {
        if (selectedTypeId === OptionType.All) {
            setColorSettings([]);
        } else {
            setColorSettings([getNewColorSetting()]);
        }
    }, [selectedTypeId]);

    const seriesData: ChartItemType[] = useMemo(() => {
        return data.map((item) => {
            let color: string = COLORS.initial;

            if (variant === FiltersVariant.Homepage && item.isConceptLocker) {
                color = COLORS.concepts;
            } else if (property !== null) {
                const setting = appliedColorSettings.find(o => o.value.includes(item[property] as ID));

                if (setting) {
                    color = setting.color;
                }
            }

            return {
                ...item,
                color,
            };
        });

    }, [data, variant, property, appliedColorSettings]);

    const deleteSetting = useCallback((index: number) => {
        setColorSettings((oldData) => [
            ...oldData.slice(0, index),
            ...oldData.slice(index + 1),
        ]);
    }, []);

    const setSettingValue = useCallback((index: number, value: ID[]) => {
        setColorSettings((oldData) => [
            ...oldData.slice(0, index),
            {
                ...oldData[index],
                value,
            },
            ...oldData.slice(index + 1),
        ]);
    }, []);

    const addSetting = useCallback(() => {
        setColorSettings((oldValue) => [
            ...oldValue,
            getNewColorSetting(),
        ]);
    }, []);

    const updateSettingColor = useCallback((index: number, color: string) => {
        setColorSettings((oldData) => [
            ...oldData.slice(0, index),
            {
                ...oldData[index],
                color,
            },
            ...oldData.slice(index + 1),
        ])
    }, [])

    return (
        <div className="ChartView">
            {isLoading && (
                <Loader/>
            )}
            <div className="ChartView__chart">
                <VersusScatterChart data={seriesData}/>
            </div>
            <div className="ChartView__sidebar ChartViewSidebar">
                <div className="ChartViewSidebar__header">
                    <SelectCustomSingle
                        options={chartOptionTypes}
                        onOptionClick={(id) => setSelectedTypeId(id as OptionType)}
                        label="Color pins based on"
                        value={selectedTypeId}
                        style={{
                            color: '#1a2024',
                        }}
                    />
                </div>
                <div className="ChartViewSidebar__content">
                    {variant === FiltersVariant.Homepage && (
                        <SidebarContentRow color={COLORS.concepts} isDisabled>
                            <SelectCustom
                                label={""}
                                value="Concept locker items"
                                isOpened={false}
                                setIsOpened={() => {
                                }}
                                isDisabled
                                style={{
                                    color: '#1a2024',
                                }}
                            />
                        </SidebarContentRow>
                    )}
                    {colorSettings.map((setting, index) => (
                        <SidebarContentRow
                            key={setting.id}
                            color={setting.color}
                            setColor={(color) => updateSettingColor(index, color)}
                            onDelete={() => deleteSetting(index)}
                        >
                            <SelectOptionsAndColors
                                style={{ width: '100%' }}
                                options={getOptions(index)}
                                value={setting.value}
                                setValue={(value) => setSettingValue(index, value)}
                                setColor={(color) => updateSettingColor(index, color)}
                                label={selectedType?.title}
                                hasSearch={selectedTypeId === OptionType.Chain}
                            />
                        </SidebarContentRow>
                    ))}
                </div>
                <div className="ChartViewSidebar__footer">
                    <Button
                        modifiers={['green']}
                        onClick={() => {
                            setAppliedColorSettings(colorSettings);
                            setAppliedTypeId(selectedTypeId);
                        }}
                    >
                        Apply
                    </Button>
                    {selectedTypeId !== OptionType.All && (
                        <Button
                            modifiers={['blue']}
                            className="ChartViewSidebar__add-button"
                            onClick={addSetting}
                        >
                            <FaPlus style={{ width: 16, height: 16 }}/>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChartView;