import { FCX, ID } from '@models';
import { Tooltip } from 'react-tooltip';
import Popup from '@components/Popup';

const MetricTooltip: FCX<{
    id: ID;
}> = ({
    className,
    id,
    children,
    ...attrs
}) => {
    return (
        <Popup hasWrapper={false}>
            <Tooltip
                id={id}
                className={className}
                {...attrs}
                style={{ ...attrs.style, zIndex: 4, borderRadius: 8, padding: 10 }}
                place={'bottom'}
                positionStrategy={'fixed'}
                delayShow={400}
            >
                {children}
            </Tooltip>
        </Popup>
    );
};

export default MetricTooltip;