import { FCX } from '@models';
import { useTableSortingColumn } from '@vms/ItemsTable/TableSortingContext';
import TableCell from '@components/Table/TableCell';
import TableSortIcon from '@components/Table/TableSortIcon';
import cn from 'classnames';

const TableHeadCellSorting: FCX<{
    sortingColName: string;
    attrs?: any;
}> = ({
    sortingColName,
    children,
    style,
    className,
    attrs,
}) => {
    const { isSorted, direction, onSort } = useTableSortingColumn(sortingColName);

    return (
        <TableCell
            attrs={attrs}
            style={style}
            className={cn("TableHeadCellSorting", className)}
            onClick={onSort}
        >
            <TableSortIcon isActive={isSorted} direction={direction}/>
            {children}
        </TableCell>
    )
};

export default TableHeadCellSorting;