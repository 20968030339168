import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { useFoodsFlavorsFilters } from '@core/selectors/useFoodsFlavorsFilters';

export function useFoodsFlavorsData (lazy: boolean = false) {
    const payload = useFoodsFlavorsFilters();


    const data = useFetch<components['schemas']['KeywordIndexViewModel'][]>({
        method: 'post',
        url: '/api/FoodFlavor/GetKeyWords' as keyof paths,
        payload,
        lazy,
    });

    return {
        isLoading: data.isLoading,
        data: data.data,
    };
}