import { FCX, ID } from '@models';
import Highcharts from 'highcharts';
import { useEffect, useRef, memo } from 'react';
import { defaultSettings } from '@components/ScatterChart/defaultSettings';

const ScatterChartComponent: FCX<{
    series: any[];
    onPointClick: (id: ID | undefined) => void;
    tooltipSelector?: string;
}> = ({
    series,
    onPointClick,
    tooltipSelector,
}) => {
    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let chart: Highcharts.Chart;
        if (elRef.current) {

            setTimeout(() => {
                try {
                    chart = Highcharts.chart({
                        ...defaultSettings,
                        chart: {
                            ...defaultSettings.chart,
                            renderTo: elRef.current,
                        },
                        series: series.map(i => ({
                            ...i,
                            marker: {
                                symbol: 'circle',
                            },
                            point: {
                                events: {
                                    click: (event: any) => {
                                        if (event?.point.selected) {
                                            onPointClick(undefined);
                                        } else {
                                            onPointClick(event?.point?.id);
                                        }
                                    },
                                }
                            }
                        })),
                    } as Highcharts.Options);
                } catch (err) {
                    console.log('chart error: ', err);
                }
            }, 100);

            const handleDocClick = (event: MouseEvent) => {
                if (
                    chart
                    && event.target
                    && !document.querySelector(tooltipSelector || '')?.contains(event.target as HTMLElement)
                    && !(event.target as HTMLElement).classList.contains('highcharts-point')
                ) {
                    onPointClick(undefined);
                    chart.series.forEach(set => {
                        try {
                            set.data[0].select(false, false);
                        } catch (err) {}
                    })
                }
            };

            document.addEventListener('click', handleDocClick);

            return () => {
                chart?.destroy();
                document.removeEventListener('click', handleDocClick);
            };
        }
    }, [ elRef, series, onPointClick]);

    return (
        <div ref={elRef} style={{ height: 500 }}/>
    );
};

export default memo(ScatterChartComponent);