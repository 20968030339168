import React from 'react';
import classNames from 'classnames';

import {
	LineChart, XAxis, YAxis, ResponsiveContainer, Line, CartesianGrid, Legend,
} from 'recharts';
import { FCX } from '@models';
import { components } from '@api/api';




const CustomizedDotLine: FCX<{
	cx?: number;
	cy?: number;
	heightWidth: number;
	value?: number;
}> = ({
	cx = 0,
	cy= 0,
	heightWidth,
	value= 0,
}) => {
	return (
		<svg x={cx - (heightWidth / 2)} y={cy - (heightWidth / 2)} width={heightWidth} height={heightWidth} fill="none">
			<g>

				<circle cx="20" cy="20" r="18" stroke="#FC0D1B" strokeWidth="3" fill="#FCFF78" />
				<text x="50%" y="50%" textAnchor="middle" alignmentBaseline="before-edge" fill="#1A2024">
					<tspan dy={5}>{Math.round(value)}%</tspan>
				</text>
			</g>
		</svg> 
	);
};

const CustomLegend: FCX<{
	payload?: any[];
}> = ({
	payload,
}) => {

	return (
		<div className="card-daypart-fit-legend-list">
			{!!payload && Array.isArray(payload) && payload.map((entry, index) => {

				return (
					<div
						key={`item-${index}`}
						className="card-daypart-fit-legend-item"
					>
						<div
							className={classNames('card-daypart-fit-legend-marker', {
								'card-daypart-fit-legend-marker--total': (entry.dataKey === 'total')
							})}
							style={{
								backgroundColor: entry.color
							}}
						/>
						{entry.value}
					</div>

				)
			})}
		</div>
	);
}
 

const CardDaypartFit: FCX<{
	dayFit: components["schemas"]["DayFit"][];
}> = ({
	className = null,
	dayFit = [],
}) => {
	return ( 
		<div className={classNames('card card-daypart-fit', className)}>
			<div className="card-header card-daypart-fit-header">
				<div>DAYPART FIT</div>
				<div className="card-daypart-fit-header__description">% who would have this item for…</div>
			</div>
			<div className="card-daypart-fit-content">
				<div className="card-daypart-fit-chart">
					<ResponsiveContainer 
						width="100%"
						height={295}
					>
						<LineChart
							data={dayFit}
							margin={{
								top: 0, 
								right: 0, 
								left: 0, 
								bottom: 5,
							}}
						>
							<XAxis 
		
								dataKey="name"
								axisLine={false}
								tickLine={false}
								tick={{fill: '#4A4A4A'}}
								tickMargin={15}
								padding={{ 
									left: 65, 
									right: 65 
								}}
							>
							</XAxis>
							<YAxis
								domain={[0, 100]}
								axisLine={false}
								tickLine={false}
								unit=""
								hide
							/>
							<CartesianGrid
								shapeRendering="crispEdges"	
								strokeDasharray="3 3"
							/>


							<Legend 
								height={30}
								margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
								align="left"
								iconSize={14}
								iconType="plainline"
								verticalAlign="top"
								content={CustomLegend}
							/>

			 				<Line 
								isAnimationActive={false}
								dot={false}
								dataKey="genZ" 
								type="monotone"
								stroke="#97D333" 
								name="Gen Z"
							/>
			 				<Line 
								isAnimationActive={false}
								dot={false}
								dataKey="millennials" 
								type="monotone"
								stroke="#32C1E9" 
								name="Millennials"	
							/>
			 				<Line 
								isAnimationActive={false}
								dot={false}
								dataKey="genX" 
								type="monotone"
								stroke="#C57AEB" 
								name="Gen X"
							/>
			 				<Line 
								isAnimationActive={false}
								dot={false}
								dataKey="boomers" 
								type="monotone"
								stroke="#847068" 
								name="Boomers+"	
							/>
			 				<Line 
								isAnimationActive={false}
								dataKey="total" 
								type="monotone"
								strokeWidth={4}
								stroke="#FC0D1B" 
								name="Total"	
								dot={(
									<CustomizedDotLine
										heightWidth={40}
									/>
								)}
							/>	
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
};

export default CardDaypartFit;