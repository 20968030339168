import { createContext, useContext, useState } from 'react';
import { FCX } from '@models';

type FiltersPopupPosition = 'default' | 'header' | 'footer';

const FiltersPopupContext = createContext<{
    isOpened: boolean;
    open: (position?: FiltersPopupPosition) => void;
    close: () => void;
    toggle: (position?: FiltersPopupPosition) => void;
    position: FiltersPopupPosition;
} | undefined>(undefined);

export function useFiltersPopupContext() {
    const ctxData = useContext(FiltersPopupContext);
    if (ctxData === undefined)
        throw new Error('Inappropriate context usage: FiltersPopupContext context is used outside of the provider.');
    return ctxData;
}

export const FiltersPopupContextProvider: FCX = ({
    children,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [position, setPosition] = useState<FiltersPopupPosition>('default');

    const open = (newPosition: FiltersPopupPosition = 'default') => {
        setPosition(newPosition);
        setIsOpened(true);
    };

    const close = () => {
        setPosition('default');
        setIsOpened(false);
    };

    const toggle = (newPosition: FiltersPopupPosition = 'default') => {
        setIsOpened(position === newPosition ? !isOpened : true);
        setPosition(newPosition);
    };

    return (
        <FiltersPopupContext.Provider value={{
            isOpened,
            open,
            close,
            toggle,
            position,
        }}>
            {children}
        </FiltersPopupContext.Provider>
    );
};