import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Chart } from './chart.svg';
import { ReactComponent as LightBulb } from './lightbulb.svg';
import { ReactComponent as USA } from './usa.svg';
import { ReactComponent as CAN } from './can.svg';
import { ReactComponent as Spinner } from './spinner.svg';
import { ReactComponent as GraphPlaceholder } from './placeholder-benchmark-graph.svg';
import { ReactComponent as LogoDs } from './logo-ds.svg';
import { ReactComponent as ProfileChart } from './profile-chart.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as FileSearch } from './FileSearch.svg';
import { ReactComponent as ClLogo } from './cl-logo.svg';


import { FunctionComponent, SVGProps } from "react";

export type IconType = FunctionComponent<SVGProps<SVGSVGElement>>;

export const IconLogo: IconType = Logo;
export const IconSearch: IconType = Search;
export const IconSettings: IconType = Settings;
export const IconHome: IconType = Home;
export const IconChart: IconType = Chart;
export const IconLightBulb: IconType = LightBulb;
export const IconUSA: IconType = USA;
export const IconCAN: IconType = CAN;
export const IconSpinner: IconType = Spinner;
export const IconGraphPlaceholder: IconType = GraphPlaceholder;
export const IconLogoDs: IconType = LogoDs;
export const IconProfileChart: IconType = ProfileChart;
export const IconLock: IconType = Lock;
export const IconFileSearch: IconType = FileSearch;
export const IconClLogo: IconType = ClLogo;