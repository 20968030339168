import { FCX, iOption } from '@models';
import { FormEvent, ClipboardEvent, useCallback, useMemo, useRef, useState } from 'react';
import TextInput from '@components/TextInput';
import { equalInLC, getSubStrings, includesInLC, startsFromInLC } from '@core/utils/string';
import Scrollbar from 'react-scrollbars-custom';
import cn from 'classnames';
import useOutsideClick from '@hooks/useOutsideClick';
import { getUnique } from '@core/utils/array';
import { toast } from 'react-toastify';

const MultiSelectBox: FCX<{
    options: iOption[];
    value: string[];
    groupValue: string[];
    onChange: (value: string[]) => void;
    allowGeneric?: boolean;
    SelectedItemComponent?: FCX<{ option: iOption; }>;
}> = ({
    options,
    value,
    groupValue,
    onChange,
    allowGeneric,
    SelectedItemComponent,
}) => {
    const [query, setQuery] = useState('');
    const [isError, setIsError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const thumbnailRef = useRef<HTMLFormElement>(null);

    useOutsideClick({
        ref: thumbnailRef,
        fn: () => setIsFocused(false),
    });

    const selectedOptions = useMemo(
        (): iOption[] => {
            return value.map(o => {
                const option = options.find(op => equalInLC(op.title, o));
                return option || { id: o, title: o.toUpperCase() };
            });
        },
        [options, value],
    );

    const autocompleteOptions = useMemo(
        (): iOption[] => {
            if (query.trim() === '') return [];

            const filteredOptions = options
                .filter((o) => includesInLC(o.title.trim(), query.trim()))
                .filter((o) => !groupValue.some(ii => equalInLC(o.title.trim(), ii)));

            filteredOptions.sort((a, b) => {
                const aScore = startsFromInLC(a.title, query.trim()) ? 10 : 1;
                const bScore = startsFromInLC(b.title, query.trim()) ? 10 : 1;

                return bScore - aScore;
            });

            return filteredOptions.slice(0, 6);
        },
        [options, query, groupValue],
    );

    const isKeywordValid = useCallback(
        (keyword: string) => {
            if (groupValue.some(v => equalInLC(v, keyword.trim()))) return false;
            const matchedOption = options.find((o) => equalInLC(o.title, keyword.trim()));
            return matchedOption || allowGeneric;
        },
        [groupValue, options, allowGeneric],
    );

    const handleAddValue = useCallback(
        (title: string) => {
            if (isKeywordValid(title)) {
                onChange([...value, title]);
                setIsError(false);
                setQuery('');
            } else {
                setIsError(true);
            }
        },
        [isKeywordValid, onChange, value],
    );

    const onSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault();
            const rawItems = getSubStrings(query.trim(), ['\n', ',']);
            const items = getUnique(rawItems).filter(isKeywordValid);

            onChange([...value, ...items]);

            if (items.length === rawItems.length) {
                setQuery('');
            } else {
                setQuery(rawItems.filter(i => !items.includes(i)).join(', '));
                setIsError(true);
            }
        },
        [query, isKeywordValid, onChange, value],
    );

    const handlePaste = useCallback(
        (event: ClipboardEvent) => {
            const prompt = event.clipboardData.getData('Text');
            const rawItems = getSubStrings(prompt, ['\n', ',']);

            const items = getUnique(rawItems).filter(isKeywordValid);

            setTimeout(() => {
                onChange([...value, ...items]);
                setQuery('');
            }, 0);

            const removedWordsCount = rawItems.length - items.length;

            toast.info(
                <div>
                    {items.length > 0 ? `${items.length} items have been added to the list:` : 'None of the items were added.'}
                    <br/>
                    {items.map((i) => (
                        <>
                            <b>&nbsp;&nbsp;{i}</b>
                            <br/>
                        </>
                    ))}
                    {removedWordsCount > 0 ? `${removedWordsCount} items were filtered out as duplicated.` : ''}
                </div>
            );
        },
        [isKeywordValid, onChange, value],
    );

    return (
        <div className="MultiSelectBox">
            <form
                ref={thumbnailRef}
                className="MultiSelectBox__thumbnail"
                onSubmit={onSubmit}
            >
                {isError && (
                    <div className="MultiSelectBox__error-message">
                        item(s) is already in use
                    </div>
                )}
                <TextInput
                    key={groupValue.join(',')}
                    className={cn(
                        "MultiSelectBox__input",
                        isError && 'is-error',
                    )}
                    placeholder="Type keyword"
                    style={{ width: '100%' }}
                    value={query}
                    onFocus={() => setIsFocused(true)}
                    autoFocus={isFocused}
                    setValue={(value) => {
                        setQuery(value);
                        setIsError(false);
                    }}
                    onPaste={handlePaste}
                />
                {autocompleteOptions.length > 0 && isFocused && (
                    <div className="MultiSelectBox__autocomplete">
                        {autocompleteOptions.map((o) => (
                            <div
                                key={o.id}
                                className="MultiSelectBox__autocomplete-item"
                                onClick={() => handleAddValue(o.title)}
                            >
                                {o.title}
                            </div>
                        ))}
                    </div>
                )}
                <button type="submit" style={{ visibility: 'hidden', position: 'absolute' }}>submit</button>
            </form>
            <div className="MultiSelectBox__value">
                <Scrollbar noScrollX>
                    {selectedOptions.map((o, index) => (
                        <div
                            key={o.id}
                            className="MultiSelectBox__value-item"
                        >
                            {SelectedItemComponent ? <SelectedItemComponent option={o}/> : o.title}
                            <div
                                className="MultiSelectBox__value-item-remove"
                                onClick={() => onChange([
                                    ...value.slice(0, index),
                                    ...value.slice(index + 1),
                                ])}
                            />
                        </div>
                    ))}
                </Scrollbar>
            </div>
        </div>
    );
}

export default MultiSelectBox;