import { FCX } from '@models';
import { SelectCustomSingle } from '@components/Select';
import { useFiltersContext } from '@core/FiltersContext';

const SelectNorm: FCX = () => {
    const {
        options: { norms },
        value: { norm },
        updateValue: updateFiltersValue,
    } = useFiltersContext();

    return (
        <SelectCustomSingle
            value={norm}
            options={norms.map((o, index) => ({
                ...o,
                renderTitle: index === 0 ? (
                    <>
                        {o.title}&nbsp;
                        <div style={{ textTransform: 'lowercase' }}>(recommended)</div>
                    </>
                ) : undefined,
            }))}
            label="Stars (item vs. __)"
            dpdnClassName="w-auto"
            style={{ minWidth: 130, marginLeft: 16 }}
            onOptionClick={(id) => {
                updateFiltersValue({
                    norm: id,
                })
            }}
        />
    );
};

export default SelectNorm;