import { FCX } from '@models';
import { useCallback, useRef } from 'react';

const ColorPicker: FCX<{
    value: string;
    setValue?: (value: string) => void;
}> = ({
    value,
    setValue= () => null,
    style,
}) => {
    const updTimer = useRef<any>(null);

    const updateValue = useCallback((newValue: string) => {
        clearTimeout(updTimer.current);
        updTimer.current = setTimeout(() => {
            setValue(newValue);
        }, 50);
    }, [setValue, updTimer]);

    return (
        <div
            className="ColorPicker"
            style={{
                ...style,
                backgroundColor: value,
            }}
        >
            <input
                type="color"
                value={value}
                className="ColorPicker__input"
                onChange={(event) => {
                    updateValue(event.target.value);
                }}/>
        </div>
    )
};

export default ColorPicker;