import { memo } from 'react';
import { FCX } from '@models';
import { AiFillStar } from 'react-icons/ai';
import cn from 'classnames';

const Stars: FCX<{
    count: number;
    gap?: number;
}> = ({
    count,
    style,
    gap = 0,
}) => {
    return (
        <div
            className={cn("Stars", `count-${count}`)}
            style={{
                ...style,
                gap,
            }}
        >
            {Array(count).fill(null).map((_, i) => (
                <AiFillStar key={i} className="Stars__icon"/>
            ))}
        </div>
    )
};

export default memo(Stars);