import { FCX } from '@models';
import { SelectCustomSingle } from '@components/Select';
import { useFiltersContext } from '@core/FiltersContext';

const SelectDisplay: FCX = () => {
    const {
        options: { mainMetrics },
        value: { display },
        updateValue: updateFiltersValue,
    } = useFiltersContext();

    return (
        <SelectCustomSingle
            value={display}
            options={mainMetrics}
            label="Display"
            dpdnClassName="w-auto"
            style={{ minWidth: 130, marginLeft: 16 }}
            onOptionClick={(id) => {
                updateFiltersValue({
                    display: id,
                })
            }}
        />
    );
};

export default SelectDisplay;