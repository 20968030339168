import { FCX } from '@models';
import { useTableContext } from './TableContext';
import cn from 'classnames';
import TableCellLoading from '@components/Table/TableCellLoading';

const TableRowLoading: FCX<{
    index: number;
}> = ({
    index,
    style,
    className,
    children,
}) => {
    const { config } = useTableContext();
    return (
        <div
            style={style}
            className={cn("TableRow", "TableRowLoading", className)}
        >
            {config.map(item => {
                const {
                    styles,
                    key,
                } = item;
                return (
                    <TableCellLoading
                        key={key}
                        style={styles?.cell}
                    />
                );
            })}
            {children}
        </div>
    );
}

export default TableRowLoading;