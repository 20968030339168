import useFetch from '@hooks/useFetch';
import { components, paths } from '@api/api';
import { useMemo } from 'react';
import { useFiltersContext } from '@core/FiltersContext';
import { ID } from '@models';

const url: keyof paths = '/api/Search/GetItemsExtended';

type Response = components['schemas']['SearchExtendedResultModel'];
type Payload = components['schemas']['SearchExtendedFilterModel'];

export function useCategoryActivityTimeframesBarChartData (categoryId: ID) {
    const { defaultFiltersPayload } = useFiltersContext();

    const payload = useMemo((): Payload => {
        return {
            searchFilter: {
                filter: {
                    ...defaultFiltersPayload,
                    categoryIds: [categoryId] as number[],
                    conceptTypeIds: [1],
                    respondentTypes: [1],
                },
                page: {
                    perPage: 0,
                    currentPage: 1,
                },
            }
        };
    }, [defaultFiltersPayload, categoryId]);

    const { isLoading, data } = useFetch<Response>({
        url,
        method: 'post',
        payload,
    });


    return {
        isLoading,
        data,
    };
}