import { FCX, Sort } from '@models';
import React, { ReactNode } from 'react';
import { iSeasonalFood } from '@api/useSeasonalByFoods';
import { getSortingOptions } from '@core/constants';
import TableSortingContext from '@vms/ItemsTable/TableSortingContext';

const FoodFlavorsTableSortingConfig: FCX<{
    renderSeasonName: ReactNode;
    sortingColumn: keyof iSeasonalFood;
    setSortingColumn: (key: keyof iSeasonalFood) => void;
    setSortingDirection: (value: Sort) => void;
    sortingDirection: Sort;
}> = ({
    sortingColumn,
    sortingDirection,
    setSortingColumn,
    setSortingDirection,
    children,
}) => {
    return (
        <TableSortingContext.Provider value={{
            value: {
                id: sortingColumn,
                direction: sortingDirection,
            },
            setValue: (value) => {
                setSortingColumn(value.id as keyof iSeasonalFood);
                setSortingDirection(value.direction);
            },
            options: getSortingOptions([
                'index',
                'count',
                'percentage',
            ]),
        }}>
            {children}
        </TableSortingContext.Provider>
    );
};

export default FoodFlavorsTableSortingConfig;