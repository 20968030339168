import { iTableConfigItem } from '@components/Table/TableConfig';
import { useCallback, useMemo } from 'react';
import { iItem, Item } from '@models/Item';
import StarsCell from './StarCell';
import { ID, iOption, NormType } from '@models';
import { useFiltersContext } from '@core/FiltersContext';
import { iMainMetric } from '@models/Filter';
import { getNormTypeById, TABLE_TOOLTIPS } from '@core/constants';
import TableTooltip from '@vms/TableTooltip';
import MetricTooltip from '@components/MetricTooltip';
import MetricMainTooltip from '@components/MetricTooltip/MetricMainTooltip';

export function useRespondentsColumns (
    data: iItem[],
): iTableConfigItem[] {
    const {
        options: {
            totalRespondentType,
            mainMetrics,
            metrics,
        },
        value: { display, metric, norm },
        isVariantMatch,
    } = useFiltersContext();

    const activeMainMetric: iMainMetric = useMemo(() => {
        return mainMetrics.find(i => i.id === display)!;
    }, [display, mainMetrics]);

    const getStarValue = useCallback((index: number, id: ID) => {
        try {
            return (data[index].indices.get(id as number) || Item.defaultMetricsData)[activeMainMetric.starsField] as number;
        }
        catch (err) {
            return 0;
        }
    }, [data, activeMainMetric]);

    const getValue = useCallback((index: number, id: ID) => {
        try {
            return (data[index].indices.get(id as number) || Item.defaultMetricsData)[activeMainMetric.valueField] as number;
        }
        catch (err) {
            return 0;
        }
    }, [data, activeMainMetric]);

    const activeRespondentTypes: iOption[] = useMemo(() => {
        const metricObject = metrics.find(i => i.id === metric);

        if (metricObject) {
            return metricObject.subItems || [];
        }

        return [];
    }, [metric, metrics]);

    const normType = useMemo((): NormType => {
        return getNormTypeById(isVariantMatch ? norm : undefined);
    }, [norm, isVariantMatch]);

    return useMemo(() => {
        return [
            {
                key: totalRespondentType.title,
                title: totalRespondentType.title,
                sortingColName: totalRespondentType.title,
                Value: ({ index }) => (
                    <>
                        <StarsCell
                            uuid={`table-column-total-${data[index].uuid}`}
                            value={getValue(index, totalRespondentType.id)}
                            starsCount={getStarValue(index, totalRespondentType.id)}
                        />
                        <MetricTooltip id={`table-column-total-${data[index].uuid}`}>
                            <MetricMainTooltip data={data[index]} normType={normType}/>
                        </MetricTooltip>
                    </>
                ),
                styles: {
                    cell: {
                        flex: '0 0 85px',
                        justifyContent: 'center',
                    }
                },
            },
            ...activeRespondentTypes.map(i => ({
                key: i.title,
                title: (
                    <>
                        {i.title}
                        <TableTooltip
                            id={`table-column-respondent-${i.id}`}
                            title={i.title}
                        >
                            {TABLE_TOOLTIPS.get(i.title.toLowerCase())}
                        </TableTooltip>
                    </>
                ),
                getAttrs: (index) => (index === -1 ? {
                    'data-tooltip-id': `table-column-respondent-${i.id}`,
                } : {}),
                sortingColName: i.title,
                Value: ({ index }) => (
                    <>
                        <StarsCell
                            uuid={`table-column-${i.id}-${data[index].uuid}`}
                            value={getValue(index, i.id)}
                            starsCount={getStarValue(index, i.id)}
                        />
                        <MetricTooltip id={`table-column-${i.id}-${data[index].uuid}`}>
                            <MetricMainTooltip data={data[index]} normType={normType}/>
                        </MetricTooltip>
                    </>
                ),
                styles: {
                    cell: {
                        flex: '0 0 85px',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }
                },
            }) as iTableConfigItem)
        ];
    }, [data, totalRespondentType, getValue, getStarValue, activeRespondentTypes, normType]);
}