import { FCX, ID } from '@models';
import Button from '@components/Button';
import { ReactNode, useCallback, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';

export interface iVersusBarChartData {
    mainTitle: string;
    compareTitle: string;
    values: Array<{
        id: ID;
        title: string;
        mainValue: number;
        compareValue: number;
    }>
}

const VersusBarChart: FCX<{
    data: iVersusBarChartData;
    mainLegendTooltip?: ReactNode;
    compareLegendTooltip?: ReactNode;
    valueFormatter: (value: number) => ReactNode;
    showTooltip?: boolean;
    showLegend?: boolean;
    showHover?: boolean;
    hideCompare?: boolean;
    activeItemId?: ID;
    onItemClick?: (id: ID) => void;
}> = ({
    data,
    valueFormatter,
    mainLegendTooltip,
    compareLegendTooltip,
    showTooltip,
    showLegend,
    hideCompare,
    showHover,
    activeItemId,
    onItemClick,
    style,
}) => {
    const uuid = useMemo(() => uuidv4(), []);
    const maxValue = useMemo(
        (): number => {
            return Math.max(
                ...data.values.map(v => v.mainValue),
                ...data.values.map(v => v.compareValue),
            );
        },
        [data]
    );

    const getBarHeightPct = useCallback(
        (value: number): string => {
            return `${(Math.round(value)/maxValue) * 100}%`
        },
        [maxValue],
    );

    return (
        <div className="VersusBarChart" style={style}>
            {showLegend && (
                <div className="VersusBarChart__legend">
                    <Button
                        modifiers={['white']}
                        className="VersusBarChart__legend-button"
                        data-tooltip-id={`versus-bar-chart-${uuid}-legend-main`}
                    >
                        <div className="VersusBarChart__legend-color is-main"/>
                        {data.mainTitle}
                    </Button>
                    <Button
                        modifiers={['white']}
                        className="VersusBarChart__legend-button"
                        data-tooltip-id={`versus-bar-chart-${uuid}-legend-compare`}
                    >
                        <div className="VersusBarChart__legend-color is-compare"/>
                        {data.compareTitle}
                    </Button>
                    {!!mainLegendTooltip && (
                        <Tooltip
                            id={`versus-bar-chart-${uuid}-legend-main`}
                            place="bottom-start"
                            variant="light"

                        >
                            {mainLegendTooltip}
                        </Tooltip>
                    )}
                    {!!compareLegendTooltip && (
                        <Tooltip
                            id={`versus-bar-chart-${uuid}-legend-compare`}
                            place="bottom-start"
                            variant="light"
                            style={{
                                zIndex: 3,
                            }}
                        >
                            {compareLegendTooltip}
                        </Tooltip>
                    )}
                </div>
            )}
            <div className="VersusBarChart__content">
                {data.values.map((i, index) => (
                    <div
                        key={i.id}
                        className={cn(
                            "VersusBarChart__item",
                            showHover && 'is-hoverable',
                            !!activeItemId && activeItemId !== i.id && 'is-inactive',
                            !!onItemClick && 'is-clickable',
                        )}
                        onClick={onItemClick ? () => onItemClick(i.id) : undefined}
                        data-tooltip-id={`versus-bar-chart-${uuid}-${i.id}`}
                    >
                        <div
                            className="VersusBarChart__bar"
                            style={{ height: getBarHeightPct(i.mainValue) }}
                        />
                        {!hideCompare && (
                            <div
                                className="VersusBarChart__compare-bar"
                                style={{ bottom: getBarHeightPct(i.compareValue) }}
                            />
                        )}
                        <div className="VersusBarChart__bar-label">
                            {i.title}
                        </div>
                        <div
                            className="VersusBarChart__value-label"
                            style={{ bottom: getBarHeightPct(i.mainValue) }}
                        >
                            {valueFormatter(i.mainValue)}
                        </div>
                        {showTooltip && (
                            <Tooltip
                                id={`versus-bar-chart-${uuid}-${i.id}`}
                                place={'right'}
                                float
                                style={{
                                    animation: 'none',
                                    zIndex: 3,
                                    transition: "none",
                                }}
                                offset={20}
                                variant={'light'}
                            >
                                <div className="VersusBarChart__tooltip">
                                    <div className="VersusBarChart__tooltip-title">
                                        {i.title}
                                    </div>
                                    <div className="VersusBarChart__tooltip-row">
                                        <div>{data.mainTitle}</div>
                                        <div className="VersusBarChart__tooltip-color is-main"/>
                                        <div className="VersusBarChart__tooltip-row-value">
                                            {valueFormatter(i.mainValue || 0)}
                                        </div>
                                    </div>
                                    <div className="VersusBarChart__tooltip-row">
                                        <div>{data.compareTitle}</div>
                                        <div className="VersusBarChart__tooltip-color is-compare"/>
                                        <div className="VersusBarChart__tooltip-row-value">
                                            {valueFormatter(i.compareValue || 0)}
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default VersusBarChart;