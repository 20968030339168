import { FCX, iChain, ID } from '@models';
import React, { useMemo, useState } from 'react';
import Button from '@components/Button';
import PlatesSelect from '@components/PlatesSelect';
import { getUnique, toggleInArray } from '@core/utils/array';
import Modal from '@components/Modal';
import { useFiltersContext } from '@core/FiltersContext';
import { Scrollbar } from 'react-scrollbars-custom';
import TextInput from '@components/TextInput';
import { getEnding, includesInLC } from '@core/utils/string';
import CollapsibleSection from '@components/CollapsibleSection';
import DataFallback from '@components/DataFallback';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const EditChainsPopup: FCX<{
    value: ID[];
    setValue: (value: ID[]) => void;
    onClose: () => void;
}> = ({
    value: appliedValue,
    setValue: applyValue,
    onClose,
}) => {
    const { options: { chains, segments, otherSegments } } = useFiltersContext();
    const [value, setValue] = useState<ID[]>(appliedValue);
    const [query, setQuery] = useState('');

    const filteredByQuery = useMemo(
        () => chains.filter(i => includesInLC(i.title.trim(), query.trim())),
        [chains, query]
    );

    const chainsBySegments = useMemo(
        () => {
            return [
                ...segments,
                ...otherSegments,
            ]
                .map(segment => {
                    const chains = filteredByQuery.filter(chain => chain.segmentId === segment.id);
                    return {
                        id: segment.id,
                        title: segment.title,
                        chains,
                        selectedItemsCount: chains.filter(i => value.includes(i.id)).length,
                    };
                })
                .filter(i => i.chains.length > 0);
        },
        [segments, filteredByQuery, value]
    );

    return (
        <Modal onClose={onClose} style={{ width: 1000 }}>
            <div style={{
                marginBottom: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <div
                    className="font-medium"
                    style={{
                        fontSize: 30,
                        lineHeight: '40px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Edit Chain Selections
                </div>
                <div style={{ position: 'relative', }}>
                    <TextInput
                        value={query}
                        setValue={setQuery}
                        placeholder="Search by chain name"
                        style={{ width: 300 }}
                    />
                    {query.trim().length > 0 && (
                        <AiOutlineCloseCircle
                            className="color-red"
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                width: 20,
                                height: 20,
                                right: 10,
                                top: '50%',
                                transform: 'translateY(-50%)',
                            }}
                            onClick={() => setQuery('')}
                        />
                    )}
                </div>
            </div>
            <div style={{ height: 550 }}>
                <Scrollbar noScrollX>
                    {chainsBySegments.map(segment => (
                        <CollapsibleSection
                            key={segment.id}
                            title={segment.title}
                            subTitle={query.trim() === '' && segment.selectedItemsCount > 0 && (
                                <>{segment.selectedItemsCount} {getEnding('chain', segment.selectedItemsCount)} selected</>
                            )}
                            isOpenedByDefault={query.trim() !== ''}
                            options={(
                                <div onClick={(event => event.stopPropagation())}>
                                    <Button
                                        modifiers={['blue', 'naked']}
                                        onClick={() => {
                                            setValue(getUnique([...value, ...segment.chains.map(i => i.id)]));
                                        }}
                                    >
                                        Select all
                                    </Button>
                                </div>
                            )}
                        >
                            <PlatesSelect
                                value={value}
                                options={segment.chains}
                                onOptionClick={(id) => setValue(toggleInArray(value, id))}
                                isGenericItemWidth
                            />
                        </CollapsibleSection>
                    ))}
                    {filteredByQuery.length === 0 && (
                        <DataFallback title="">
                            <div style={{ fontStyle: 'italic', }}>No chains found</div>
                        </DataFallback>
                    )}
                </Scrollbar>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 20,
            }}>
                <div style={{ display: 'flex' }}>
                    <Button
                        modifiers={['blue', 'naked']}
                        onClick={onClose}
                    >
                        CANCEL
                    </Button>
                    &nbsp;
                    <Button
                        modifiers={['green']}
                        onClick={() => {
                            applyValue(value);
                            onClose();
                        }}
                    >
                        SAVE
                    </Button>
                </div>
                {value.length > 0 && (
                    <Button
                        modifiers={['red', 'naked']}
                        onClick={() => {
                            setValue([]);
                            setQuery('');
                        }}
                        style={{ marginRight: 10, justifySelf: 'flex-start' }}
                    >
                        Reset All Selections
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default EditChainsPopup;