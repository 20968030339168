import React, { useEffect, useMemo, useRef, memo } from 'react';
import classNames from 'classnames';
import Highcharts from 'highcharts';

import './BenchmarkCard.scss';
import LEGACY__getCoordByX from "@pages/Benchmark/LEGACY__getCoordByX";
import { FCX } from '@models';
import { components } from '@api/api';


const formatNumSuffix = (number: number) => {
    var j = number % 10,
        k = number % 100;

    if (j === 1 && k !== 11) {
        return number + "st";
    }
    if (j === 2 && k !== 12) {
        return number + "nd";
    }
    if (j === 3 && k !== 13) {
        return number + "rd";
    }
    return number + "th";
};


const BenchmarkCard: FCX<{
    metric: components["schemas"]["Graph"];
}> = ({
    className = null,
    metric: {
        indexName,
        index,
        median,
        dataString,
        percentile,
    }
}) => {

    const refNodeChart = useRef<HTMLDivElement>(null);

    const sumValueY = useMemo((): number => {
        if (!dataString) return 0;
        try {
            return dataString.reduce((a, item: any) => a + item.y, 0) as number;
        } catch (err) {
            console.error(err);
            return 0;
        }
    }, [dataString]);

    const values = useMemo(() => {
        if (!dataString) return [];
        try {
            return dataString.map((item: any) => {
                return {
                    ...item,
                    y: Math.round((item.y * 100) / sumValueY)
                };
            });
        } catch (err) {
            console.error(err);
            return [];
        }
    }, [dataString, sumValueY]);


    const medianPoint = LEGACY__getCoordByX(median, values);

    useEffect(() => {
        if (refNodeChart.current) {

            const chart = Highcharts.chart({

                chart: {
                    type: "spline",
                    height: 160,
                    plotBorderWidth: 0,
                    marginTop: 20,
                    spacingLeft: 0,
                    renderTo: refNodeChart.current,
                },
                title: { text: '' },

                xAxis: {
                    labels: {
                        rotation: 0
                    },
                    title: {
                        text: 'SCORE',
                        style: {
                            color: '#8F8F8F',
                            "fontSize": "8px",
                            transform: "translateY(8px)",
                        },
                        offset: 25
                    },
                    tickLength: 1,
                    tickInterval: 10,
                    showFirstLabel: true,
                    showLastLabel: true,
                },

                yAxis: {
                    max: 100,
                    labels: {
                        rotation: 0
                    },
                    title: {
                        text: "% EARNING THAT SCORE",
                        style: {
                            color: '#8F8F8F',
                            "fontSize": "8px",
                        }
                    },
                    tickInterval: 10
                },

                legend: {
                    enabled: false
                },

                tooltip: {
                    shared: true,
                    useHTML: true,
                    headerFormat: "<div>",
                    formatter: function () {
                        return Math.round(+(this.x || 0)) + "%";
                    },
                    footerFormat: "</div>",
                },

                series: [
                    {
                        type: 'spline',
                        name: "Earning",
                        data: values,
                        lineWidth: 2,
                        color: "#29ABE2",
                        marker: {
                            symbol: "circle",
                            radius: 1
                        },
                        pointStart: 10,
                    },
                    {
                        type: 'scatter',
                        name: "Earning (Median)",
                        data: [{
                            x: medianPoint?.x,
                            y: medianPoint?.y,
                        }],
                        color: '#A4A4A4',
                        marker: {
                            symbol: "circle",
                            radius: 4
                        }
                    },
                    {
                        type: 'scatter',
                        name: "Earning (Concept)",
                        data: [],
                        color: '#32c1e9',
                        marker: {
                            symbol: "circle",
                            radius: 4
                        }
                    },
                ],
                credits: {
                    enabled: false
                },

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                },
                plotOptions: {
                    series: {
                        states: {
                            inactive: {
                                enabled: false,
                            }
                        }
                    },
                },

            });

            return () => {
                chart.destroy()
            }
        }
    }, [refNodeChart, medianPoint, values]);

    return (
        <div
            className={classNames('card-benchmark', className)}
        >
            {(!!index && index > 0) && (
                <div className="card-benchmark-chart-point">
                    <div className="card-benchmark-chart-point__tooltip">
                        this item = <div className="value">{Math.round(index)}%</div>
                    </div>
                </div>
            )}

            <div className="card-benchmark__caption">{indexName}</div>

            <div className="card-benchmark-legend card-benchmark-legend--concept">
                <div className="card-benchmark-legend__value">{index}%</div>
                <div className="card-benchmark-legend__description">
                    This Concept
                    {(!!percentile && percentile > -1) && (
                        <div>({formatNumSuffix(percentile)} Percentile)</div>
                    )}
                </div>
            </div>

            <div className="card-benchmark-legend card-benchmark-legend--median">
                <div className="card-benchmark-legend__value">{median}%</div>
                <div className="card-benchmark-legend__description">Median for all<br/> concepts</div>
            </div>

            <div
                className="card-benchmark__chart"
                ref={refNodeChart}
            />

        </div>
    );
};

export default memo(BenchmarkCard, () => true);