import { FCX, ID, iOptionGroup } from '@models';
import { useState } from 'react';
import { Dropdown, Options, SelectCustom } from '@components/Select';
import Field from '@components/Field';

const SelectCustomColumns: FCX<{
    options: iOptionGroup[];
    value?: ID | null;
    onOptionClick: (id: ID) => void;
    label: string;
    colsNumber?: number;
}> = ({
    options,
    value,
    onOptionClick,
    label,
    colsNumber= 3,
    className,
    style,
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const activeOption = options.flatMap(
        option => option.subItems
    ).find(option => value === option?.id);

    return (
        <SelectCustom
            label={label}
            value={activeOption?.title}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            className={className}
            style={{ ...style, marginLeft: 16 }}
        >
            <Dropdown className="w-auto pos-r">
                <div style={{ columnCount: colsNumber, width: 700 }}>
                    {options.map(option => (
                        <div
                            key={option.id}
                            style={{
                                pageBreakInside: 'avoid',
                                marginBottom: 10,
                            }}
                        >
                            <Field label={(
                                <div
                                    className="font-medium"
                                    style={{ fontSize: 18 }}
                                >
                                    {option.title}
                                </div>
                            )}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Options
                                        data={option.subItems || []}
                                        value={activeOption ? [activeOption.id] : []}
                                        onOptionClick={(id) => {
                                            setIsOpened(false);
                                            onOptionClick(id);
                                        }}
                                        isButtonsStyle
                                    />
                                </div>
                            </Field>
                        </div>
                    ))}
                </div>
            </Dropdown>
        </SelectCustom>
    );
};

export default SelectCustomColumns;