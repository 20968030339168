import { components, paths } from '@api/api';
import { useState } from 'react';
import { ID } from '@models';
import { api } from '@hooks/useFetch';
import { downloadReport } from '@core/utils/file';
import { useFoodsFlavorsFilters } from '@core/selectors/useFoodsFlavorsFilters';

type RequestData = components['schemas']['FoodsFlavorsFilterModel'];

export function useFoodFlavorsReport (selectedCategories: ID[]) {
    const filters = useFoodsFlavorsFilters();
    const [isLoading, setIsLoading] = useState(false);

    const load = async () => {
        setIsLoading(true);
        const url: keyof paths = '/api/Export/FoodAndFlavor';
        const data: RequestData = {
            ...filters,
            groupIds: selectedCategories as number[],
        };

        await downloadReport(api.request({
            method: 'post',
            url,
            data,
            responseType: 'blob',
        }));

        setIsLoading(false);
    };

    return {
        isLoading,
        load,
    }
}