import { FCX, ID, iOption } from '@models';
import cn from 'classnames';
import Dropdown from '@components/Select/Dropdown';
import Field from '@components/Field';
import Options from '@components/Select/Options';
import Button from '@components/Button';
import { IconFileSearch, IconProfileChart, IconSearch, IconSettings } from '@svg';
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { equalInLC, getSubStrings, includesInLC, startsFromInLC } from '@core/utils/string';
import { useFiltersContext } from '@core/FiltersContext';
import { useFiltersPopupContext } from '@vms/FiltersPopup/FiltersPopupContext';
import { IoClose } from 'react-icons/io5';
import { FiltersVariant, iFilterValue } from '@models/Filter';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Route } from '@core/routes/Route';
import { PAGES_HOME, PAGES_WITH_FILTER } from '@core/constants';
import KeywordSettings from '@vms/KeywordSettings';
import { findByTitle } from '@core/utils/object';
import TextInput from '@components/TextInput';
import useOutsideClick from '@hooks/useOutsideClick';
import { cssX, IconClose, IDropdownColumn, SearchBox, SearchBoxNoResults } from "@datassential/platform-ui-lib";
import { UiSettingsKey, useUserContext } from "@core/UserContext";
import { ButtonType } from "@components/Button/Button";

const QUERY_MIN_LENGTH = 1;
const MAX_SECTION_RESULTS = 5;

const Search: FCX = () => {
    const { uiSettings: { hiddenElements }} = useUserContext();
    const location = useLocation();
    const history = useNavigate();

    const selectRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    useOutsideClick({
        ref: selectRef,
        fn: () => setIsDropdownOpened(false),
    })

    const {
        options: { keywords, chains },
        getSpecificValue,
        updateSpecificValue,
    } = useFiltersContext();

    const {
        search,
        additionalSearch,
        chainId,
    } = getSpecificValue(FiltersVariant.Homepage);

    const updateValue = useCallback((data: Partial<iFilterValue>) => {
        updateSpecificValue(FiltersVariant.Homepage, data);
        if (!PAGES_HOME.includes(location.pathname as Route)) {
            history(Route.Home);
        }
    }, [updateSpecificValue, location, history]);

    const { toggle } = useFiltersPopupContext();

    const [query, setQuery] = useState('');

    const mapItemsToOptions = useCallback((items: iOption[]): iOption[] => {
        if (query.trim().length < QUERY_MIN_LENGTH) {
            return [];
        }
        return [
            ...items.filter(i => startsFromInLC(i.title, query.trim())),
            ...items.filter(i => !startsFromInLC(i.title, query.trim()) && includesInLC(i.title, query.trim())),
        ];
    }, [query]);

    const matchedKeywords = useMemo(() => {
        return mapItemsToOptions(keywords).map((i) => ({
            id: i.title,
            title: <div dangerouslySetInnerHTML={{
                __html: (i.title || '').replace(new RegExp(`(${query})`, 'i'), `<b>$1</b>`),
            }}/>,
        }));
    }, [keywords, mapItemsToOptions, query]);

    const matchedChains = useMemo(() => {
        if (search || chainId) return [];
        return mapItemsToOptions(chains).map((i) => ({
            id: i.id,
            title: <div dangerouslySetInnerHTML={{
                __html: (i.title || '').replace(new RegExp(`(${query})`, 'i'), `<b>$1</b>`),
            }}/>,
        }));
    }, [chains, mapItemsToOptions, search, chainId, query]);

    const applyKeyword = useCallback((title: string) => {
        if (search || chainId) {
            updateValue({
                additionalSearch: title,
            });
        } else {
            updateValue({
                search: title,
            });
        }
    }, [search, chainId, updateValue]);

    const applyChain = useCallback((id: ID) => {
        updateValue({
            chainId: id,
        });
    }, [updateValue]);

    const applySearch = useCallback(() => {
        setQuery((query) => {
            const matchedKeyword = findByTitle(keywords, query);
            if (matchedKeyword) {
                applyKeyword(query.trim());
                return '';
            }
            const matchedChain = findByTitle(chains, query);
            if (matchedChain && !search && !chainId) {
                applyChain(matchedChain.id);
                return '';
            }
            applyKeyword(query.trim());
            return '';
        });
    }, [chains, keywords, applyKeyword, applyChain, search, chainId]);

    const appliedSearchValue = useMemo(() => {
        if (search) return search;
        return chains.find(i => i.id === chainId)?.title || '';
    }, [search, chainId, chains]);

    useEffect(() => {
        setQuery(additionalSearch || '');
    }, [additionalSearch]);

    const clearSearch = () => {
        updateValue({
            search: null,
            additionalSearch: null,
            chainId: null,
        });
    };

    const isDropdownAllowed = useMemo(() => {
        return query.trim().length >= QUERY_MIN_LENGTH
            && !equalInLC(query.trim(), additionalSearch?.trim())
    }, [query, additionalSearch]);

    const isDropdownVisible = useMemo(() => {
        return isDropdownOpened && isDropdownAllowed;
    }, [isDropdownAllowed, isDropdownOpened]);

    const handleInputKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (equalInLC(event.code, 'enter')) {
                applySearch();
            }
        },
        [applySearch]
    );

    const searchAutocompleteGroups = useMemo(
        (): IDropdownColumn[] | undefined => {
            const results: IDropdownColumn[] = [];

            if (matchedKeywords.length > 0) {
                results.push({
                    id: 'keywords',
                    title: 'KEYWORDS',
                    items: matchedKeywords.slice(0, MAX_SECTION_RESULTS).map(item => ({
                        id: item.id,
                        content: (
                            <div
                                className={cn(cssX("Dropdown__item"), "PlatformHeader__search-link")}
                                onClick={() => {
                                    applyKeyword(item.id as string);
                                    setQuery('');
                                }}
                            >
                                {item.title}
                            </div>
                        ),
                    })),
                });
            }

            if (matchedChains.length > 0) {
                results.push({
                    id: 'chains',
                    title: 'CHAINS',
                    items: matchedChains.slice(0, MAX_SECTION_RESULTS).map(item => ({
                        id: item.id,
                        content: (<>
                            <div className={cn(cssX("Dropdown__item"))}>
                                {item.title}
                            </div>
                            <div className={cssX('Dropdown__item-actions')}>
                                <div
                                    className={cn(
                                        cssX('Dropdown__item-action'),
                                        'PlatformHeader__search-action'
                                    )}
                                    onClick={() => {
                                        applyChain(item.id as string)
                                        setQuery('');
                                    }}
                                >
                                    Launches
                                </div>
                                {!hiddenElements.includes(UiSettingsKey.PageChainProfile) && (
                                    <Link
                                        className={cn(
                                            cssX('Dropdown__item-action'),
                                            'PlatformHeader__search-action'
                                        )}
                                        to={Route.ChainProfile.replace(':id', `${item.id}`)}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setQuery('');
                                            setIsDropdownOpened(false);
                                        }}
                                    >
                                        Profile
                                    </Link>
                                )}
                            </div>
                        </>),
                    })),
                });
            }


            if (results.length === 0) return undefined;
            return results;
        },
        [matchedKeywords, matchedChains, applyKeyword, applyChain]
    );

    const includesExcludesPopup = useMemo(
        () => (
            <KeywordSettings
                className="PlatformHeader__search-info"
                appliedKeywords={[
                    ...getSubStrings(search || '', [',']),
                    ...getSubStrings(additionalSearch || '', [',']),
                ]}
                hideWhenNoData
            />
        ),
        [search, additionalSearch]
    )

    return (
        <SearchBox
            testId="selectHeaderSearch"
            tooltipId="platform-header-search"
            inputProps={{
                value: query,
                testId: 'inputHeaderSearch',
                setValue: setQuery,
                onSubmit: applySearch,
                placeholder: !!appliedSearchValue ? 'Add to your search...' : 'Search anything...',
                style: {
                    paddingRight: 30,
                },
                children: appliedSearchValue ? (<>
                    <div className={cssX('TextInput__tag')}>
                        <div className={cssX('TextInput__tag-label')}>Searching in</div>
                        <IconClose
                            className={cssX('TextInput__tag-close')}
                            onClick={clearSearch}
                        />
                        <div className={cssX('TextInput__tag-content')}>{appliedSearchValue}</div>
                    </div>
                    {includesExcludesPopup}
                </>) : null
            }}
            dropdownProps={{
                isOpened: isDropdownAllowed,
                groups: searchAutocompleteGroups,
                id: 'platform-header-search',
                testId: "selectHeaderSearchDropdown",
                isColTitlesSticky: true,
                children: (query !== '' && !searchAutocompleteGroups) ? <SearchBoxNoResults/> : null
            }}
        />
    );
};

export default Search