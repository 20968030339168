import {
    AppId,
    cssX,
    Dropdown,
    FCX,
    Header,
    HeaderLogo, IconDropdownArrowUp,
    IDropdownItem,
    Navigation,
    ProductLogo, useOutsideClick
} from "@datassential/platform-ui-lib";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { Route } from "@core/routes/Route";
import Countries from "@vms/PlatformHeader/Countries";
import './PlatformHeader.scss';
import { useMemo, useRef, useState } from "react";
import cn from "classnames";
import Search from "@vms/PlatformHeader/Search";
import { UiSettingsKey, useUserContext } from "@core/UserContext";

const SubNavItem: FCX<{
    path: string;
}> = ({
    path,
    children,
}) => {
    return (
        <NavLink
            to={path}
            className={({ isActive }) => cn(
                cssX('Dropdown__item'),
                'is-tall',
                'PlatformHeader__nav-sub-item',
                isActive && 'active',
            )}
        >
            {children}
        </NavLink>
    )
}

const PlatformHeader: FCX = () => {
    const {
        hasConceptLockerAccess,
        uiSettings: {
            hiddenElements,
        },
    } = useUserContext();
    const analyzeRef = useRef(null);
    const [isAnalyzeOpened, setIsAnalyzeOpened] = useState(false);
    const location = useLocation();

    useOutsideClick({
        ref: analyzeRef,
        callback: () => setIsAnalyzeOpened(false),
    });

    const analyzeLinks = useMemo(
        () => [
            {
                id: 'Specific foods & flavors',
                title: 'Specific foods & flavors',
                path: Route.FoodsFlavors,
                uiSettingsKey: UiSettingsKey.PageSpecificFoodFlavors,
            },
            {
                id: 'Performance by chain',
                title: 'Performance by chain',
                path: Route.ChainPerformance,
                uiSettingsKey: UiSettingsKey.PagePerformanceByChain,
            },
            {
                id: 'Benchmark non - SCORES concepts',
                title: 'Benchmark non - SCORES concepts',
                path: Route.Benchmark,
                uiSettingsKey: UiSettingsKey.PageBenchmark,
            },
            {
                id: 'Seasonal Activity',
                title: 'Seasonal Activity',
                path: Route.SeasonalActivity,
                uiSettingsKey: UiSettingsKey.PageSeasonalActivity,
            },
            {
                id: 'Category Activity',
                title: 'Category Activity',
                path: Route.CategoryActivity,
                uiSettingsKey: UiSettingsKey.PageCategoryActivity,
            },
        ],
        []
    );

    const analyzeItems = useMemo(
        (): IDropdownItem[] => analyzeLinks
            .filter(item => !hiddenElements.includes(item.uiSettingsKey))
            .map(item => ({
            id: item.id,
            content: (
                <SubNavItem path={item.path}>
                    {item.title}
                </SubNavItem>
            ),
        })),
        [analyzeLinks, hiddenElements]
    );

    const isAnalyseActive = useMemo(
        () => analyzeLinks.some(item => matchPath(item.path, location.pathname)),
        [analyzeLinks, location.pathname],
    );

    const navigationData = useMemo(
        () => {
            const result = [];

            if (analyzeItems.length > 0) {
                result.push({
                    id: 'analyze',
                    content: (
                        <div
                            className={cn("PlatformHeader__nav-item", isAnalyseActive && 'active-accessible')}
                            onClick={() => setIsAnalyzeOpened(!isAnalyzeOpened)}
                            onMouseEnter={() => setIsAnalyzeOpened(true)}
                            data-tooltip-id="platform-header-analyze"
                            ref={analyzeRef}
                        >
                            Analyze
                            <IconDropdownArrowUp
                                className="PlatformHeader__nav-icon"
                                style={{ transform: `rotate(${isAnalyzeOpened ? 0 : 180}deg)` }}
                            />
                            <Dropdown
                                isOpened={isAnalyzeOpened}
                                id="platform-header-analyze"
                                place="bottom-start"
                                offset={0}
                                columns={[
                                    {
                                        id: 'analyze',
                                        items: analyzeItems,
                                    }
                                ]}
                            />
                        </div>
                    ),
                });
            }

            if (!hiddenElements.includes(UiSettingsKey.PageConceptLocker)) {
                result.push({
                    id: 'Concept Locker',
                    content: (
                        <NavLink
                            to={hasConceptLockerAccess ? Route.ConceptLocker : ''}
                            className={({ isActive }) => cn(
                                "PlatformHeader__nav-item",
                                isActive && 'active-accessible',
                                !hasConceptLockerAccess && 'disabled',
                            )}
                        >
                            Concept Locker
                        </NavLink>
                    ),
                });
            }

            if (!hiddenElements.includes(UiSettingsKey.PageFoodWatch)) {
                result.push({
                    id: 'Food Watch',
                    content: (
                        <NavLink
                            to={Route.FoodWatch}
                            className={({ isActive }) => cn("PlatformHeader__nav-item", isActive && 'active-accessible')}
                        >
                            Food Watch
                        </NavLink>
                    ),
                });
            }

            result.push({
                id: 'search',
                content: (
                    <Search/>
                ),
            });

            return result;
        },
        [
            analyzeItems,
            analyzeLinks,
            hasConceptLockerAccess,
            hiddenElements,
            isAnalyseActive,
            isAnalyzeOpened,
        ]
    );

    return (
        <Header
            className="PlatformHeader"
            leftContent={(
                <>
                    <NavLink to={Route.Home} className="PlatformHeader__logo">
                        <HeaderLogo>
                            <ProductLogo appId={AppId.LaunchesAndRatings}/>
                        </HeaderLogo>
                    </NavLink>
                    <Countries/>
                </>
            )}
            rightContent={(
                <Navigation
                    data={navigationData}
                />
            )}
        />
    );
}

export default PlatformHeader;