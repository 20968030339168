import { useMemo } from 'react';
import { components } from '@api/api';
import { useFiltersContext } from '@core/FiltersContext';

type ChainProfileFilters = components["schemas"]["ChainProfileFilter"];

export function useChainProfileFilters() {
    const { defaultFiltersPayload } = useFiltersContext();
    return useMemo(
        (): ChainProfileFilters => ({
            categoryIds: defaultFiltersPayload.categoryIds,
            date: defaultFiltersPayload.date,
            includeAll: defaultFiltersPayload.includeAll,
            includeAny: defaultFiltersPayload.includeAny,
            excludeAll: defaultFiltersPayload.excludeAll,
        }),
        [defaultFiltersPayload]
    );
}